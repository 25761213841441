import {getLidSize} from "../../domain/lid";
import {getVaultSize} from "../../domain/vault";
import {getFloorSize} from "../../domain/floor";
import {getOverallSize} from "../../domain/composition";
import {getMeasurementsAreaWidth, getScale} from "./layoutFunctions";
import {intermediateCeilingFunctions} from "../../domain/intermediateCeiling";

export const createLayout = (clientSize, plan, side) => {
  const getLidToIntermediateCeilingOrVaultSpacing = () => !plan.lid || plan.lid.type !== 'subfloor'
    ? 3 * scale
    : 0
  const getIntermediateCeilingToVaultSpacing = () => 3 * scale
  const lidSize = getLidSize(plan)
  const vaultSize = getVaultSize(plan)
  const floorSize = getFloorSize(plan)
  const overallSize = getOverallSize(plan)
  const verticalMeasurementsWidth = getMeasurementsAreaWidth(clientSize.width)
  const availableRemainingWidth = clientSize.width - 4 - verticalMeasurementsWidth
  const scale = getScale(availableRemainingWidth, overallSize)
  const toScreenWidth = size => side === 'front' || side === 'back'
    ? size.length * scale
    : size.width * scale
  const getLeft = size => size.x
  const getRight = size => size.x + size.length
  const getFront = size => size.y
  const getBack = size => size.y + size.width
  const toPartScreenX =
    size => side === 'front' ? (getLeft(size) - getLeft(overallSize)) * scale
      : side === 'back' ? (getRight(overallSize) - getRight(size)) * scale
        : side === 'left' ? (getBack(overallSize) - getBack(size)) * scale
          : side === 'right' ? (getFront(size) - getFront(overallSize)) * scale
            : 0
  const overallX = 2
  const overallY = 2
  const verticalMeasurementsX = overallX
  const partX = verticalMeasurementsX + verticalMeasurementsWidth
  const hasHorizontalMeasurements = () => !!plan.vault || !!plan.floor
  const topMeasurements = !!plan.lid || !!plan.intermediateCeiling
    ? {
      screenX: verticalMeasurementsX + verticalMeasurementsWidth,
      screenY: overallY,
      screenWidth: toScreenWidth(overallSize),
      screenHeight: verticalMeasurementsWidth,
      scale: scale
    }
    : null
  const lid = !!plan.lid
    ? {
      screenX: partX + toPartScreenX(lidSize),
      screenY: topMeasurements.screenY + topMeasurements.screenHeight,
      screenWidth: toScreenWidth(lidSize),
      screenHeight: lidSize.height * scale,
      scale: scale
    }
    : null
  const intermediateCeiling = !!plan.intermediateCeiling
    ? {
      screenX: partX + toPartScreenX(intermediateCeilingFunctions.getSize(plan)),
      screenY: !!lid
        ? lid.screenY + lid.screenHeight + getLidToIntermediateCeilingOrVaultSpacing()
        : topMeasurements.screenY + topMeasurements.screenHeight,
      screenWidth: toScreenWidth(intermediateCeilingFunctions.getSize(plan)),
      screenHeight: intermediateCeilingFunctions.getHeight(plan) * scale,
      scale: scale
    }
    : null
  const vault = !!plan.vault
    ? {
      screenX: partX + toPartScreenX(vaultSize),
      screenY: !!intermediateCeiling
        ? intermediateCeiling.screenY + intermediateCeiling.screenHeight + getIntermediateCeilingToVaultSpacing()
        : !!lid
          ? lid.screenY + lid.screenHeight + getLidToIntermediateCeilingOrVaultSpacing()
          : overallY,
      screenWidth: toScreenWidth(vaultSize),
      screenHeight: vaultSize.height * scale,
      scale: scale
    }
    : null
  const floor = !!plan.floor
    ? {
      screenX: partX + toPartScreenX(floorSize),
      screenY: !!vault ? vault.screenY + vault.screenHeight
        : intermediateCeiling ? intermediateCeiling.screenY + intermediateCeiling.screenHeight
          : !!lid ? lid.screenY + lid.screenHeight
            : overallY,
      screenWidth: toScreenWidth(floorSize),
      screenHeight: (floorSize.height) * scale,
      scale: scale
    }
    : null
  const partsBottom = floor ? floor.screenY + floor.screenHeight
    : vault ? vault.screenY + vault.screenHeight
      : intermediateCeiling ? intermediateCeiling.screenY + intermediateCeiling.screenHeight
        : lid ? lid.screenY + lid.screenHeight
          : overallY
  const verticalMeasurements = {
    screenX: verticalMeasurementsX,
    screenY: overallY,
    screenWidth: verticalMeasurementsWidth,
    screenHeight: partsBottom - overallY,
    scale: scale
  }
  // noinspection JSSuspiciousNameCombination
  const bottomMeasurements = {
    screenX: verticalMeasurementsX + verticalMeasurementsWidth,
    screenY: partsBottom,
    screenWidth: toScreenWidth(overallSize),
    screenHeight: verticalMeasurementsWidth,
    scale: scale
  }
  const overall = {
    screenX: overallX,
    screenY: overallY,
    screenWidth: clientSize.width - 4,
    screenHeight: bottomMeasurements.screenY
      + (hasHorizontalMeasurements() ? bottomMeasurements.screenHeight : 0)
      + 2
  }

  return {overall, topMeasurements, lid, intermediateCeiling, vault, floor, bottomMeasurements, verticalMeasurements}
}
