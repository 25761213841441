import {PLAN_ITEM_DRAGGING_OPACITY, PLAN_ITEM_OPACITY} from "../../../constants";

export const setOpacity = (element, action) => {
  switch (action) {
    case "resizing":
      element.find('.fill').opacity(0)
      element.find('.position').opacity(0)
      element.find('.material').opacity(0)
      break

    case "dragging":
      element.opacity(PLAN_ITEM_DRAGGING_OPACITY)
      element.find('.fill').opacity(0)
      element.find('.position').opacity(0)
      element.find('.dimension').opacity(0)
      element.find('.material').opacity(0)
      break

    default:
      element.opacity(PLAN_ITEM_OPACITY)
      element.find('.fill').opacity(1)
      element.find('.position').opacity(1)
      element.find('.dimension').opacity(1)
      element.find('.material').opacity(1)
      break

  }
}
