import React from "react"
import {Line} from "react-konva"
import {getCenteredGridLinePositions, getGridLinePositions} from "./gridLinePositions"
import {VISIBLE_GRID_SIZE} from "../../../constants"
import {toScreenPosition} from "../realWorldTransform"

const gridStrokeWidth = 0.25
const gridStrokeColor = "black"

const createLine = (key, pt1, pt2) => {
  return (
    <Line
      key={key + "_" + pt1.x + "_" + pt1.y + "_" + pt2.x + "_" + pt2.y}
      points={[pt1.x, pt1.y, pt2.x, pt2.y]}
      strokeWidth={gridStrokeWidth}
      stroke={gridStrokeColor}
      lineCap="round"
    />
  )
}

export const createGrid = (configAreaLayout, transform, realWorldXPositions, realWorldYPositions) => {
  const {screenX, screenY, realWidth, realHeight} = configAreaLayout

  const verticalLines = realWorldXPositions.map(xPos => {
      const pt1 = toScreenPosition({x: xPos, y: 0}, transform, {x: screenX, y: screenY})
      const pt2 = toScreenPosition({x: xPos, y: realHeight}, transform, {x: screenX, y: screenY})

      return createLine("vertical_grid_line", pt1, pt2)
    })
  const horizontalLines = realWorldYPositions.map(yPos => {
      const pt1 = toScreenPosition({x: 0, y: yPos}, transform, {x: screenX, y: screenY})
      const pt2 = toScreenPosition({x: realWidth, y: yPos}, transform, {x: screenX, y: screenY})

      return createLine("horizontal_grid_line", pt1, pt2)
    })

  return [verticalLines, horizontalLines]
}

export const createBottomAlignedGrid = (configAreaLayout, transform) => {
  const {realWidth, realHeight} = configAreaLayout
  const realWorldXPositions = getCenteredGridLinePositions(realWidth, VISIBLE_GRID_SIZE)
  const realWorldYPositions = getGridLinePositions(realHeight, VISIBLE_GRID_SIZE)

  return createGrid(configAreaLayout, transform, realWorldXPositions, realWorldYPositions)
}

export const createVerticallyCenteredGrid = (configAreaLayout, transform) => {
  const {realWidth, realHeight} = configAreaLayout
  const realWorldXPositions = getCenteredGridLinePositions(realWidth, VISIBLE_GRID_SIZE)
  const realWorldYPositions = getCenteredGridLinePositions(realHeight, VISIBLE_GRID_SIZE)

  return createGrid(configAreaLayout, transform, realWorldXPositions, realWorldYPositions)
}
