import {getGridLinePositions} from './Configuration/gridLinePositions.js'
import {cRailLengths, SNAP_GRID_SIZE} from '../../constants'

export const snap = (pos, availablePos) => {
  let best
  let bestOffset = Number.MAX_VALUE

  for (let i = 0; i < availablePos.length; i++) {
    const current = availablePos[i]
    const offset = Math.abs(pos - current)

    if (best === undefined || offset < bestOffset) {
      best = current
      bestOffset = offset
    }
  }
  return Math.round(10 * best) / 10
}

export const snapCircleToGrid = (pos, circleRadius, gridWidth, gridHeight) => {
  const verticalGridLinePositions = getGridLinePositions(gridWidth, SNAP_GRID_SIZE)
    .filter(gridPos => gridPos - circleRadius >= 0 && gridPos + circleRadius <= gridWidth)
  const horizontalGridLinePositions = getGridLinePositions(gridHeight, SNAP_GRID_SIZE)
    .filter(gridPos => gridPos - circleRadius >= 0 && gridPos + circleRadius <= gridHeight)

  return {
    x: snap(pos.x, verticalGridLinePositions),
    y: snap(pos.y, horizontalGridLinePositions)
  }
}


export const snapResizeCRail = (cRail, gridWidth, gridHeight, direction) => {
  switch (direction) {
    case 'top': {
      const lengths = cRailLengths.filter(l => l <= gridHeight - cRail.y + SNAP_GRID_SIZE / 10)
      const length = snap(cRail.length, lengths)
      const x = snap(cRail.x, getGridLinePositions(gridWidth, SNAP_GRID_SIZE, true))
      const y = snap(
        cRail.y,
        getGridLinePositions(gridHeight, SNAP_GRID_SIZE, true)
      )

      return {...cRail, x, y, length}
    }

    case 'bottom': {
      const lengths = cRailLengths.filter(l => l <= cRail.y + cRail.length + SNAP_GRID_SIZE / 10)
      const length = snap(cRail.length, lengths)
      const x = snap(cRail.x, getGridLinePositions(gridWidth, SNAP_GRID_SIZE, true))
      const y = snap(
        cRail.y + cRail.length - length,
        getGridLinePositions(gridHeight, SNAP_GRID_SIZE, true)
      )

      return {...cRail, x, y, length}
    }

    case 'left': {
      const lengths = cRailLengths.filter(l => l <= cRail.x + cRail.length + SNAP_GRID_SIZE / 10)
      const length = snap(cRail.length, lengths)
      const x = snap(
        cRail.x + cRail.length - length,
        getGridLinePositions(gridWidth, SNAP_GRID_SIZE, true)
      )
      const y = snap(cRail.y, getGridLinePositions(gridHeight, SNAP_GRID_SIZE, true))

      return {...cRail, x, y, length}
    }

    case 'right': {
      const lengths = cRailLengths.filter(l => l <= gridWidth - cRail.x + SNAP_GRID_SIZE / 10)
      const length = snap(cRail.length, lengths)
      const x = snap(
        cRail.x,
        getGridLinePositions(gridWidth, SNAP_GRID_SIZE, true)
      )
      const y = snap(cRail.y, getGridLinePositions(gridHeight, SNAP_GRID_SIZE, true))

      return {...cRail, x, y, length}
    }

    default:
      return cRail
  }
}

export const snapNoResizeCRail = (cRail, gridWidth, gridHeight) => {
  const halfCRailWidth = 1.9
  const verticalGridLinePositions = getGridLinePositions(gridWidth, SNAP_GRID_SIZE, true)
    .filter(p => {
      switch (cRail.orientation) {
        case 'horizontal':
          return p >= 0 && p <= gridWidth - cRail.length
        case 'vertical':
          return p >= halfCRailWidth && p <= gridWidth - halfCRailWidth
        default:
          return true;
      }
    })
  const horizontalGridLinePositions = [
    ...getGridLinePositions(gridHeight, SNAP_GRID_SIZE, true),
    ...(cRail.orientation === 'vertical' ? [gridHeight - cRail.length] : [])
  ]
    .filter(p => {
      switch (cRail.orientation) {
        case 'horizontal':
          return p >= halfCRailWidth && p <= gridHeight - halfCRailWidth
        case 'vertical':
          return p >= 0 && p <= gridHeight - cRail.length
        default:
          return true;
      }
    })

  return {
    ...cRail,
    x: snap(cRail.x, verticalGridLinePositions),
    y: snap(cRail.y, horizontalGridLinePositions)
  }
}
