import React, { useEffect, useState } from "react"
import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
  useTheme
} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import { useSelector, useDispatch } from "react-redux"
import { deliveryActions } from "../../../actions"

import { orangeBackgroundColor, orangeButtonStyle } from "../../../constants"
import { constructionSiteApiActions } from "../../../actions"
import InfoIcon from "@mui/icons-material/Info"
import { Maps } from "./Maps"
import { CityItem } from "../City"

const useStyles = theme => {
  return makeStyles({
    plan: {
      background: orangeBackgroundColor,
      padding: theme.spacing(2)
    },
    italic: {
      fontStyle: "italic"
    },
    orangeButton: orangeButtonStyle
  })()
}

const TitleItem = () => {
  return (
    <React.Fragment>
      <Typography component="h1" variant="h4">
        Baustelle/Lieferung
      </Typography>
      <hr />
    </React.Fragment>
  )
}

const PresetSelectGridItem = () => {
  const dispatch = useDispatch()
  const constructionSites = useSelector(state => state.constructionSites)

  const toOptions = constructionSites =>
    Array.isArray(constructionSites)
      ? constructionSites.map(c => {
          return {
            label: `${c.description}, ${c.street}, ${c.zip} ${c.city}`,
            value: c.projectId
          }
        })
      : []

  const handleInputChange = (event, value) => {
    dispatch(constructionSiteApiActions.readConstructionSites(5, value))
  }

  const handleChange = (event, value) => {
    if (!value) {
      return
    }
    const site = constructionSites.find(cs => cs.projectId === value.value)

    if (!!site) {
      dispatch(deliveryActions.setDescription(site.description))
      dispatch(deliveryActions.setStreet(site.street))
      dispatch(deliveryActions.setZip(site.zip))
      dispatch(deliveryActions.setCity(site.city))
      dispatch(deliveryActions.setNumber(site.number))
      dispatch(deliveryActions.setPerson(site.person))
      dispatch(deliveryActions.setPhone(site.phone))
      dispatch(deliveryActions.setCommission(site.commission))
      dispatch(deliveryActions.setUsersOrderNumber(site.usersOrderNumber))
      dispatch(deliveryActions.setRemarks(site.remarks))
      dispatch(deliveryActions.setCoordinates(site.latitude, site.longitude))
    }
  }

  useEffect(() => {
    dispatch(constructionSiteApiActions.readConstructionSites())
  }, [dispatch])

  return (
    <Grid item xs={12}>
      <FormControl fullWidth variant="outlined">
        <Autocomplete
          options={toOptions(constructionSites)}
          getOptionLabel={option => option.label.toString()}
          renderInput={params => (
            <TextField
              {...params}
              label="Suchbegriff eingeben..."
              variant="outlined"
              fullWidth
            />
          )}
          onChange={handleChange}
          onInputChange={handleInputChange}
        />
      </FormControl>
    </Grid>
  )
}

const LoginForPresetsTextGridItem = () => {
  return (
    <Grid item xs={12}>
      <Grid direction={"row"} container alignItems={"center"}>
        <Grid item>
          <InfoIcon style={{ marginRight: "1em" }} />
        </Grid>
        <Grid item xs={10}>
          <Typography component="p" variant="body1">
            Sie müssen angemeldet sein um Angaben aus bereits erfassten
            Baustellen zu übernehmen.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

const PresetItem = () => {
  const authentication = useSelector(state => state.authentication)

  return (
    <Grid container spacing={2}>
      {!!authentication.loggedIn && <PresetSelectGridItem />}
      {!authentication.loggedIn && <LoginForPresetsTextGridItem />}
    </Grid>
  )
}

const DescriptionItem = () => {
  const dispatch = useDispatch()
  const description = useSelector(state => state.project.delivery.description)

  return (
    <TextField
      margin="normal"
      variant="outlined"
      fullWidth
      id="description"
      label="Baustellenbezeichnung"
      name="description"
      value={description}
      error={!description}
      helperText={
        !description ? "Baustellenbezeichnung ist erforderlich" : null
      }
      onChange={e => {
        dispatch(deliveryActions.setDescription(e.target.value))
      }}
    />
  )
}

const StreetItem = () => {
  const dispatch = useDispatch()
  const street = useSelector(state => state.project.delivery.street)

  return (
    <TextField
      margin="normal"
      variant="outlined"
      fullWidth
      id="street"
      label="Strasse und Nummer"
      name="street"
      value={street}
      error={!street}
      helperText={!street ? "Strasse ist erforderlich" : null}
      onChange={e => {
        dispatch(deliveryActions.setStreet(e.target.value))
      }}
    />
  )
}

const NumberItem = () => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const dispatch = useDispatch()
  const number = useSelector(state => state.project.delivery.number)

  return (
    <TextField
      margin="normal"
      variant="outlined"
      fullWidth
      className={classes.italic}
      id="number"
      label="Baustellennummer"
      name="number"
      value={number}
      onChange={e => {
        dispatch(deliveryActions.setNumber(e.target.value))
      }}
    />
  )
}

const PersonItem = () => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const dispatch = useDispatch()
  const person = useSelector(state => state.project.delivery.person)

  return (
    <TextField
      margin="normal"
      variant="outlined"
      fullWidth
      className={classes.italic}
      id="person"
      label="Name Kontaktperson auf Baustelle"
      name="person"
      value={person}
      onChange={e => {
        dispatch(deliveryActions.setPerson(e.target.value))
      }}
    />
  )
}

const PhoneItem = () => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const dispatch = useDispatch()
  const phone = useSelector(state => state.project.delivery.phone)

  return (
    <TextField
      margin="normal"
      variant="outlined"
      fullWidth
      className={classes.italic}
      id="phone"
      label="Telefon Kontaktperson auf Baustelle"
      name="phone"
      value={phone}
      onChange={e => {
        dispatch(deliveryActions.setPhone(e.target.value))
      }}
    />
  )
}

const CommissionItem = () => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const dispatch = useDispatch()
  const commission = useSelector(state => state.project.delivery.commission)

  return (
    <TextField
      margin="normal"
      variant="outlined"
      fullWidth
      className={classes.italic}
      id="commission"
      label="Kommission"
      name="commission"
      value={commission}
      onChange={e => {
        dispatch(deliveryActions.setCommission(e.target.value))
      }}
    />
  )
}

const UsersOrderNumberItem = () => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const dispatch = useDispatch()
  const usersOrderNumber = useSelector(state => state.project.delivery.usersOrderNumber)

  return (
    <TextField
      margin="normal"
      variant="outlined"
      fullWidth
      className={classes.italic}
      id="usersOrderNumber"
      label="Ihre Bestell-/Auftragsnummer"
      name="commission"
      value={usersOrderNumber}
      onChange={e => {
        dispatch(deliveryActions.setUsersOrderNumber(e.target.value))
      }}
    />
  )
}

const RemarksItem = () => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const dispatch = useDispatch()
  const remarks = useSelector(state => state.project.delivery.remarks)

  return (
    <TextField
      margin="normal"
      variant="outlined"
      multiline
      minRows={7}
      fullWidth
      className={classes.italic}
      id="remarks"
      label="Bemerkungen"
      name="remarks"
      value={remarks}
      onChange={e => {
        dispatch(deliveryActions.setRemarks(e.target.value))
      }}
    />
  )
}

const MapItem = () => {
  const street = useSelector(state => state.project.delivery.street)
  const zip = useSelector(state => state.project.delivery.zip)
  const city = useSelector(state => state.project.delivery.city)

  return (
    <Grid item xs={12}>
      <Maps address={street + ", " + zip + " " + city + ", Schweiz"}></Maps>
    </Grid>
  )
}

export const ConstructionSiteSection = () => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const [showMap, setShowMap] = useState(false)
  const dispatch = useDispatch()
  const street = useSelector(state => state.project.delivery.street)
  const zip = useSelector(state => state.project.delivery.zip)
  const city = useSelector(state => state.project.delivery.city)
  const latitude = useSelector(state => state.project.delivery.latitude)
  const longitude = useSelector(state => state.project.delivery.longitude)

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <TitleItem />
      </Grid>
      <Grid item xs={6}>
        <Grid container>
          <Grid item xs={12} key={"upper_text"}>
            <Typography component="p" variant="body1">
              Wählen Sie eine bereits erfasste Baustelle aus, um die Angaben zu
              übernehmen.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ marginBottom: "5.5rem", marginTop: "1rem" }}
          >
            <PresetItem />
          </Grid>

          <Grid item xs={12}>
            <DescriptionItem />
          </Grid>
          <Grid item xs={12}>
            <StreetItem />
          </Grid>
          <Grid item xs={12}>
            <CityItem
              setZipAction={deliveryActions.setZip} setCityAction={deliveryActions.setCity}
              selectZip={state => state.project.delivery.zip} selectCity={state => state.project.delivery.city}
            />
          </Grid>

          {!showMap && street && zip && city && (
            <Grid item xs={12} marginTop={"0.7rem"}>
              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography>
                  Optional: Markieren Sie den genauen Abladeort.
                </Typography>
                <Button
                  className={classes.orangeButton}
                  color="primary"
                  variant="contained"
                  onClick={() => setShowMap(true)}
                >
                  Karte einblenden
                </Button>
              </Grid>
            </Grid>
          )}
          {showMap && (latitude || longitude) && (
            <Grid item xs={12} marginTop={"0.7rem"}>
              <Grid container justifyContent={"flex-end"} alignItems={"center"}>
                <Button
                  className={classes.orangeButton}
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    dispatch(deliveryActions.unsetCoordinates())
                    setShowMap(false)
                  }}
                >
                  Markierung auf Karte löschen
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container>
          <Grid item xs={12} key={"upper_text"}>
            <Typography component="p" variant="body1">
              Optionale Eingabefelder
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <NumberItem />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <PersonItem />
              </Grid>
              <Grid item xs={6}>
                <PhoneItem />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <CommissionItem />
              </Grid>
              <Grid item xs={6}>
                <UsersOrderNumberItem />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <RemarksItem />
          </Grid>
        </Grid>
      </Grid>

      {showMap && (
        <Grid item xs={12}>
          <MapItem />
        </Grid>
      )}
    </Grid>
  )
}
