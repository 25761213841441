import {vaultFunctions} from "../../domain/vault";
import {intermediateCeilingFunctions} from "../../domain/intermediateCeiling";

const getMeasurementPositions = plan => {
  const getTop = () => intermediateCeilingFunctions.getTop(plan)
  const vaultOuterCorners = () => {
    return [
      {x: vaultFunctions.getLeft(plan), y: vaultFunctions.getFront(plan), z: getTop()},
      {x: vaultFunctions.getRight(plan), y: vaultFunctions.getFront(plan), z: getTop()},
      {x: vaultFunctions.getRight(plan), y: vaultFunctions.getBack(plan), z: getTop()},
      {x: vaultFunctions.getLeft(plan), y: vaultFunctions.getBack(plan), z: getTop()},
    ]
  }
  const corners = rect => {
    return [
      {x: rect.x, y: rect.y, z: getTop()},
      {x: rect.x, y: rect.y + rect.height, z: getTop()},
      {x: rect.x + rect.width, y: rect.y, z: getTop()},
      {x: rect.x + rect.width, y: rect.y + rect.height, z: getTop()}
    ]
  }

  return [
    ...vaultOuterCorners(),
    ...plan.intermediateCeiling.blockOuts.reduce((acc, curr) => [...acc, ...corners(curr)], []),
    ...plan.intermediateCeiling.circularBlockOuts.reduce(
      (acc, curr) => [...acc, {x: curr.x, y: curr.y, z: getTop()}],
      []
    )
  ]
}

const getExtensionLineLimits = plan => {
  return [
    {
      from: vaultFunctions.getSize(plan),
      to: {x: vaultFunctions.getLeft(plan), y: vaultFunctions.getBack(plan)}
    },
    {
      from: vaultFunctions.getSize(plan),
      to: {x: vaultFunctions.getRight(plan), y: vaultFunctions.getFront(plan)}
    }
  ]
}

export const getGroundViewLeftMeasurementPositions = plan => {
  const filterRelevant = positions => {
    return [
      ...positions.reduce(
        (acc, curr) => {
          if (!acc.has(curr.y) || acc.get(curr.y).x > curr.x) {
            acc.set(curr.y, curr)
          }
          return acc
        },
        new Map()
      ).values()
    ]
  }
  const applyLimits = (positions, limits) => {
    const getRelevantLimits = y => limits.filter(l =>
      l.from.x === l.to.x
      && l.from.y <= y && l.to.y >= y
    )
    const getLimitedX = (x, y) => Math.min(x, ...getRelevantLimits(y).map(l => l.from.x))

    return positions.map(p => ({...p, x: getLimitedX(p.x, p.y)}))
  }

  return applyLimits(
    filterRelevant(
      getMeasurementPositions(plan)
    ),
    getExtensionLineLimits(plan)
  )
}
export const getGroundViewBottomMeasurementPositions = plan => {
  const filterRelevant = positions => {
    return [
      ...positions.reduce(
        (acc, curr) => {
          if (!acc.has(curr.x) || acc.get(curr.x).y > curr.y) {
            acc.set(curr.x, curr)
          }
          return acc
        },
        new Map()
      ).values()
    ]
  }
  const applyLimits = (positions, limits) => {
    const getRelevantLimits = x => limits.filter(l =>
      l.from.y === l.to.y
      && l.from.x <= x && l.to.x >= x
    )
    const getLimitedY = (x, y) => Math.min(y, ...getRelevantLimits(x).map(l => l.from.y))

    return positions.map(p => ({...p, y: getLimitedY(p.x, p.y)}))
  }

  return applyLimits(
    filterRelevant(
      getMeasurementPositions(plan)
    ),
    getExtensionLineLimits(plan)
  )
}
export const getElevationViewTopMeasurementPositions = (plan, side) => {
  const filterRelevant = positions => {
    const getHorizontalAxisPosition = pos => side === 'front' || side === 'back' ? pos.x : pos.y
    return [
      ...positions.reduce(
        (acc, curr) => {
          if (!acc.has(getHorizontalAxisPosition(curr)) || acc.get(getHorizontalAxisPosition(curr)).z < curr.z) {
            acc.set(getHorizontalAxisPosition(curr), curr)
          }
          return acc
        },
        new Map()
      ).values()
    ]
  }
  const applyLimits = (positions, limits) => {
    const getHorizontalAxisPosition = pos => side === 'front' || side === 'back' ? pos.x : pos.y

    const getRelevantLimits = pos => limits.filter(l =>
      Number.isFinite(l.from.z)
      && l.from.z === l.to.z
      && getHorizontalAxisPosition(l.from) <= getHorizontalAxisPosition(pos)
      && getHorizontalAxisPosition(l.to) >= getHorizontalAxisPosition(pos)
    )
    const getLimitedZ = pos => Math.max(pos.z, ...getRelevantLimits(pos).map(l => l.from.z))

    return positions.map(p => ({...p, z: getLimitedZ(p)}))
  }

  return applyLimits(
    filterRelevant(
      getMeasurementPositions(plan)
    ),
    getExtensionLineLimits(plan)
  )
}
