import jwt from 'jsonwebtoken'
import {authenticationActionTypes as actionTypes, userApiActionTypes} from '../constants'
import {authenticationService} from "../services"
import {userApiActions} from "."
import {isLoginRequired, shouldExtendLogin} from "../helpers/authHelper"

const register = user => {
  const request = user => {
    return {type: actionTypes.REGISTRATION_REQUEST, user}
  }
  const success = user => {
    return {type: actionTypes.REGISTRATION_SUCCESS, user}
  }
  const failure = error => {
    return {type: actionTypes.REGISTRATION_FAILURE, error}
  }

  return dispatch => {
    dispatch(request({user}))
    authenticationService.register(user)
      .then(
        () => dispatch(success(user)),
        error => dispatch(failure(error))
      )
  }
}

const verifyEmail = verificationCode => {
  const request = verificationCode => {
    return {type: actionTypes.EMAIL_VERIFICATION_REQUEST, verificationCode}
  }
  const success = verificationCode => {
    return {type: actionTypes.EMAIL_VERIFICATION_SUCCESS, verificationCode}
  }
  const failure = error => {
    return {type: actionTypes.EMAIL_VERIFICATION_FAILURE, error}
  }

  return dispatch => {
    dispatch(request({verificationCode}))
    authenticationService.verifyEmail(verificationCode)
      .then(
        () => {
          dispatch(success(verificationCode))
        },
        error => {
          dispatch(failure(error))
        }
      )
  }
}

const login = (email, password, navigateTo, history) => {
  const request = email => {
    return {type: actionTypes.LOGIN_REQUEST, email}
  }
  const success = jwtPayload => {
    return {type: actionTypes.LOGIN_SUCCESS, jwtPayload}
  }
  const failure = error => {
    return {type: actionTypes.LOGIN_FAILURE, error}
  }

  return dispatch => {
    dispatch(request({email}))
    authenticationService.login(email, password)
      .then(
        response => {
          const payload = jwt.decode(response.jwt)

          dispatch(success(payload))
          dispatch(userApiActions.read())
          history.replace(navigateTo)
        },
        error => {
          dispatch(failure(error))
        }
      )
  }
}

const extendLoginIfRequired = () => {
  const request = () => {
    return {type: actionTypes.EXTEND_LOGIN_REQUEST}
  }
  const success = jwtPayload => {
    return {type: actionTypes.EXTEND_LOGIN_SUCCESS, jwtPayload}
  }
  const failure = error => {
    return {type: actionTypes.EXTEND_LOGIN_FAILURE, error}
  }

  return (dispatch, getState) => {
    const auth = getState().authentication

    if (auth.loggedIn && isLoginRequired(auth)) {
      dispatch(logout())
      return
    }
    if (!shouldExtendLogin(auth)) {
      return
    }
    dispatch(request())
    authenticationService.extendLogin()
      .then(
        response => {
          const payload = jwt.decode(response.jwt)

          dispatch(success(payload))
        },
        error => {
          dispatch(failure(error))
        }
      )
  }
}

const logout = () => {
  authenticationService.logout()
  return dispatch => {
    dispatch({type: actionTypes.LOGOUT})
    dispatch({type: userApiActionTypes.reset})
  }
}

const resetPassword = email => {
  const request = email => {
    return {type: actionTypes.RESET_PASSWORD_REQUEST, email}
  }
  const success = email => {
    return {type: actionTypes.RESET_PASSWORD_SUCCESS, email}
  }
  const failure = error => {
    return {type: actionTypes.RESET_PASSWORD_FAILURE, error}
  }

  return dispatch => {
    dispatch(request({email}))
    authenticationService.resetPassword(email)
      .then(
        () => {
          dispatch(success(email))
        },
        error => {
          dispatch(failure(error))
        }
      )
  }
}

const changePassword = (resetCode, password) => {
  const request = resetCode => {
    return {type: actionTypes.CHANGE_PASSWORD_REQUEST, resetCode}
  }
  const success = resetCode => {
    return {type: actionTypes.CHANGE_PASSWORD_SUCCESS, resetCode}
  }
  const failure = error => {
    return {type: actionTypes.CHANGE_PASSWORD_FAILURE, error}
  }

  return dispatch => {
    dispatch(request({resetCode}))
    authenticationService.changePassword(resetCode, password)
      .then(
        () => {
          dispatch(success(resetCode))
        },
        error => {
          dispatch(failure(error))
        }
      )
  }
}

export const authenticationActions = {
  register,
  verifyEmail,
  login,
  extendLoginIfRequired,
  logout,
  resetPassword,
  changePassword
}

