import {planImageActionTypes as actionTypes} from "../constants";

const setLidGroundViewImage = data => {
  return { type: actionTypes.setLidGroundViewImage, data }
}

const setIntermediateCeilingGroundViewImage = data => {
  return { type: actionTypes.setIntermediateCeilingGroundViewImage, data }
}

const setFloorGroundViewImage = data => {
  return { type: actionTypes.setFloorGroundViewImage, data }
}

const setSideAElevationViewImage = data => {
  return { type: actionTypes.setSideAElevationViewImage, data }
}

const setSideBElevationViewImage = data => {
  return { type: actionTypes.setSideBElevationViewImage, data }
}

const setSideCElevationViewImage = data => {
  return { type: actionTypes.setSideCElevationViewImage, data }
}

const setSideDElevationViewImage = data => {
  return { type: actionTypes.setSideDElevationViewImage, data }
}

export const planImageActions = {
  setLidGroundViewImage,
  setIntermediateCeilingGroundViewImage,
  setFloorGroundViewImage,
  setSideAElevationViewImage,
  setSideBElevationViewImage,
  setSideCElevationViewImage,
  setSideDElevationViewImage
}
