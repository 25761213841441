import React from "react";

export const LiftedCollarIcon = () => {
  return (
    <svg width="150" transform="translate(5 -20)" version="1.1" viewBox="0 0 105.83 66.146"
         xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(1.735 -342.98)" fill="none" stroke="#000">
        <path d="m101.45 369.44h-60.854v-23.812h60.854" strokeLinecap="round" strokeLinejoin="round"
              strokeWidth="1.0762"/>
        <g>
          <path d="m0.91086 406.48h63.5l2e-6 -31.75h-29.104v-29.104h-34.396z" strokeLinejoin="round"
                strokeWidth="1.0762"/>
          <g strokeWidth=".26458px">
            <path d="m48.536 406.48 15.875-15.875"/>
            <path d="m40.598 406.48 23.812-23.812"/>
            <path d="m56.473 406.48 7.9375-7.9375"/>
            <path d="m32.661 406.48 31.75-31.75"/>
            <path d="m24.723 406.48 31.75-31.75"/>
            <path d="m16.786 406.48 31.75-31.75"/>
            <path d="m8.8484 406.48 31.75-31.75"/>
            <path d="m0.91086 406.48 34.396-34.396"/>
            <path d="m0.91086 398.54 34.396-34.396"/>
            <path d="m0.91086 390.6 34.396-34.396"/>
            <path d="m0.91086 382.67 34.396-34.396"/>
            <path d="m0.91086 374.73 29.104-29.104"/>
            <path d="m0.91086 366.79 21.167-21.167"/>
            <path d="m0.91086 358.85 13.229-13.229"/>
            <path d="m0.91086 350.92 5.2917-5.2917"/>
          </g>
        </g>
        <g strokeWidth=".26458px">
          <path d="m88.223 369.44 13.229-13.229"/>
          <path d="m80.286 369.44 21.167-21.167"/>
          <path d="m96.161 369.44 5.2917-5.2917"/>
          <path d="m72.348 369.44 23.813-23.812"/>
          <path d="m64.411 369.44 23.812-23.812"/>
          <path d="m56.473 369.44 23.813-23.813"/>
          <path d="m48.536 369.44 23.812-23.813"/>
          <path d="m40.598 369.44 23.812-23.813"/>
          <path d="m40.598 361.5 15.875-15.875"/>
          <path d="m40.598 353.56 7.9375-7.9375"/>
        </g>
        <g transform="translate(-134.94 -29.104)">
          <g strokeWidth=".26458px">
            <path d="m175.54 380.02-5.2917-5.2917"/>
            <path d="m175.54 387.96-5.2917-5.2917"/>
            <path d="m183.47 403.83-5.2917-5.2917"/>
            <path d="m175.54 403.83-5.2917-5.2917"/>
          </g>
          <path d="m170.24 403.83h29.104v-5.2917h-23.812v-23.812h-5.2917z" strokeLinejoin="round"
                strokeWidth="1.0762"/>
          <path d="m199.35 403.83-5.2917-5.2917" strokeWidth=".26458px"/>
          <path d="m191.41 403.83-5.2917-5.2917" strokeWidth=".26458px"/>
          <path d="m170.24 403.83h29.104v-5.2917h-23.812v-23.812h-5.2917z" strokeLinejoin="round"
                strokeWidth="1.0762"/>
          <path d="m175.54 395.9-5.2916-5.2916" strokeWidth=".26458px"/>
        </g>
      </g>
    </svg>
  )
}

export const BeveledCollarIcon = () => {
  return (
    <svg width="150" transform="translate(5 -20)" version="1.1" viewBox="0 0 105.83 66.146"
         xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(1.735 -342.98)" fill="none" stroke="#000">
        <g>
          <path d="m0.91086 406.48h63.5v-31.75h-29.104l1e-6 -18.521-34.396 19.05z" strokeLinejoin="round"
                strokeWidth="1.0762"/>
          <g strokeWidth=".26458px">
            <path d="m48.536 406.48 15.875-15.875"/>
            <path d="m40.598 406.48 23.812-23.812"/>
            <path d="m56.473 406.48 7.9375-7.9375"/>
            <path d="m32.661 406.48 31.75-31.75"/>
            <path d="m24.723 406.48 31.75-31.75"/>
            <path d="m16.786 406.48 31.75-31.75"/>
            <path d="m8.8484 406.48 31.75-31.75"/>
            <path d="m0.91086 406.48 34.396-34.396"/>
            <path d="m0.91086 398.54 34.396-34.396"/>
            <path d="m0.91086 390.6 34.396-34.396"/>
            <path d="m0.91086 382.67 15.252-15.252"/>
          </g>
        </g>
        <g transform="translate(-134.94 -29.104)">
          <g strokeWidth=".26458px">
            <path d="m175.54 380.02-5.2917-5.2917"/>
            <path d="m175.54 387.96-5.2917-5.2917"/>
            <path d="m183.47 403.83-5.2917-5.2917"/>
            <path d="m175.54 403.83-5.2917-5.2917"/>
          </g>
          <path d="m170.24 403.83h29.104v-5.2917h-23.812v-23.812h-5.2917z" strokeLinejoin="round"
                strokeWidth="1.0762"/>
          <path d="m199.35 403.83-5.2917-5.2917" strokeWidth=".26458px"/>
          <path d="m191.41 403.83-5.2917-5.2917" strokeWidth=".26458px"/>
          <path d="m170.24 403.83h29.104v-5.2917h-23.812v-23.812h-5.2917z" strokeLinejoin="round"
                strokeWidth="1.0762"/>
          <path d="m175.54 395.9-5.2916-5.2916" strokeWidth=".26458px"/>
        </g>
        <path d="m101.45 369.44h-60.854v-23.812h60.854" strokeLinecap="round" strokeLinejoin="round"
              strokeWidth="1.0762"/>
        <g strokeWidth=".26458px">
          <path transform="translate(0 -1.1258e-5)" d="m88.223 369.44 13.229-13.229"/>
          <path transform="translate(0 -1.1258e-5)" d="m80.286 369.44 21.167-21.167"/>
          <path transform="translate(0 -1.1258e-5)" d="m96.161 369.44 5.2917-5.2917"/>
          <path transform="translate(0 -1.1258e-5)" d="m72.348 369.44 23.813-23.812"/>
          <path transform="translate(0 -1.1258e-5)" d="m64.411 369.44 23.812-23.812"/>
          <path transform="translate(0 -1.1258e-5)" d="m56.473 369.44 23.813-23.813"/>
          <path transform="translate(0 -1.1258e-5)" d="m48.536 369.44 23.812-23.813"/>
          <path transform="translate(0 -1.1258e-5)" d="m40.598 369.44 23.812-23.813"/>
          <path transform="translate(0 -1.1258e-5)" d="m40.598 361.5 15.875-15.875"/>
          <path transform="translate(0 -1.1258e-5)" d="m40.598 353.56 7.9375-7.9375"/>
        </g>
      </g>
    </svg>
  )
}

export const PavingReadyCollarIcon = () => {
  return (
    <svg width="150" transform="translate(5 -20)" version="1.1" viewBox="0 0 105.83 66.146"
         xmlns="http://www.w3.org/2000/svg">
      <g fill="none" stroke="#000">
        <g transform="translate(-133.2 -372.08)">
          <g strokeWidth=".26458px">
            <path d="m175.54 380.02-5.2917-5.2917"/>
            <path d="m175.54 387.96-5.2917-5.2917"/>
            <path d="m183.47 403.83-5.2917-5.2917"/>
            <path d="m175.54 403.83-5.2917-5.2917"/>
          </g>
          <path d="m170.24 403.83h29.104v-5.2917h-23.812v-23.812h-5.2917z" strokeLinejoin="round"
                strokeWidth="1.0762"/>
          <path d="m199.35 403.83-5.2917-5.2917" strokeWidth=".26458px"/>
          <path d="m191.41 403.83-5.2917-5.2917" strokeWidth=".26458px"/>
          <path d="m170.24 403.83h29.104v-5.2917h-23.812v-23.812h-5.2917z" strokeLinejoin="round"
                strokeWidth="1.0762"/>
          <path d="m175.54 395.9-5.2916-5.2916" strokeWidth=".26458px"/>
        </g>
        <path d="m103.19 34.396h-26.458l-10.583-7.9375h-23.812v-10.583h60.854" strokeLinecap="round"
              strokeLinejoin="round" strokeWidth="1.0762"/>
        <g>
          <g strokeWidth=".26458px">
            <path d="m82.021 34.396 18.521-18.521"/>
            <path d="m75.217 33.262 17.387-17.387"/>
            <path d="m89.958 34.396 13.229-13.229"/>
            <path d="m70.682 29.86 13.985-13.985"/>
            <path d="m66.146 26.458 10.583-10.583"/>
            <path d="m58.208 26.458 10.583-10.583"/>
            <path d="m50.271 26.458 10.583-10.583"/>
            <path d="m42.333 26.458 10.583-10.583"/>
            <path d="m42.333 18.521 2.6458-2.6458"/>
          </g>
          <path d="m2.6458 63.5h63.5l2e-6 -31.75h-29.104v-15.875h-34.396z" strokeLinejoin="round"
                strokeWidth="1.0762"/>
          <g strokeWidth=".26458px">
            <path d="m50.271 63.5 15.875-15.875"/>
            <path d="m42.333 63.5 23.812-23.812"/>
            <path d="m58.208 63.5 7.9375-7.9375"/>
            <path d="m34.396 63.5 31.75-31.75"/>
            <path d="m26.458 63.5 31.75-31.75"/>
            <path d="m18.521 63.5 31.75-31.75"/>
            <path d="m10.583 63.5 31.75-31.75"/>
            <path d="m2.6458 63.5 34.396-34.396"/>
            <path d="m2.6458 55.562 34.396-34.396"/>
            <path d="m2.6458 47.625 31.75-31.75"/>
            <path d="m2.6458 39.687 23.812-23.812"/>
            <path d="m2.6458 31.75 15.875-15.875"/>
            <path d="m2.6458 23.812 7.9375-7.9375"/>
          </g>
        </g>
        <path d="m42.333 2.6458h60.854" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.0762"/>
        <path d="m97.896 34.396 5.2917-5.2917" strokeWidth=".26458px"/>
      </g>
    </svg>
  )
}

export const WithoutCollarIcon = () => {
  return (
    <svg width="150" transform="translate(-25 -20)" version="1.1" viewBox="0 0 105.83 66.146"
         xmlns="http://www.w3.org/2000/svg">
      <g fill="none" stroke="#000">
        <g transform="translate(-133.2 -372.08)">
          <g strokeWidth=".26458px">
            <path d="m175.54 380.02-5.2917-5.2917"/>
            <path d="m175.54 387.96-5.2917-5.2917"/>
            <path d="m183.47 403.83-5.2917-5.2917"/>
            <path d="m175.54 403.83-5.2917-5.2917"/>
          </g>
          <path d="m170.24 403.83h29.104v-5.2917h-23.812v-23.812h-5.2917z" strokeLinejoin="round"
                strokeWidth="1.0762"/>
          <path d="m199.35 403.83-5.2917-5.2917" strokeWidth=".26458px"/>
          <path d="m191.41 403.83-5.2917-5.2917" strokeWidth=".26458px"/>
          <path d="m170.24 403.83h29.104v-5.2917h-23.812v-23.812h-5.2917z" strokeLinejoin="round"
                strokeWidth="1.0762"/>
          <path d="m175.54 395.9-5.2916-5.2916" strokeWidth=".26458px"/>
        </g>
        <g transform="translate(1.735 -342.98)">
          <path d="m101.45 369.44h-60.854v-23.812h60.854" strokeLinecap="round" strokeLinejoin="round"
                strokeWidth="1.0762"/>
          <g strokeWidth=".26458px">
            <path transform="translate(0 -1.1258e-5)" d="m88.223 369.44 13.229-13.229"/>
            <path transform="translate(0 -1.1258e-5)" d="m80.286 369.44 21.167-21.167"/>
            <path transform="translate(0 -1.1258e-5)" d="m96.161 369.44 5.2917-5.2917"/>
            <path transform="translate(0 -1.1258e-5)" d="m72.348 369.44 23.813-23.812"/>
            <path transform="translate(0 -1.1258e-5)" d="m64.411 369.44 23.812-23.812"/>
            <path transform="translate(0 -1.1258e-5)" d="m56.473 369.44 23.813-23.813"/>
            <path transform="translate(0 -1.1258e-5)" d="m48.536 369.44 23.812-23.813"/>
            <path transform="translate(0 -1.1258e-5)" d="m40.598 369.44 23.812-23.813"/>
            <path transform="translate(0 -1.1258e-5)" d="m40.598 361.5 15.875-15.875"/>
            <path transform="translate(0 -1.1258e-5)" d="m40.598 353.56 7.9375-7.9375"/>
          </g>
        </g>
      </g>
    </svg>
  )
}
