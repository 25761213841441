import React from "react"

import {Circle, Text} from "react-konva"

import {FONT_SIZE} from "../../../constants"

export const renderGroupElements = (screenCircle, sleeve, fillColor = '#eeeeee') => {
  const {radius} = screenCircle;
  return (
    <React.Fragment>
      <Circle
        radius={radius}
        fill={fillColor}
        stroke="black"
        strokeWidth={1}
        shadowColor="black"
        shadowBlur={8}
        shadowOpacity={0}
      />
      <Circle
        radius={0.8 * radius}
        stroke="black"
        strokeWidth={1}
      />
      {
        !!sleeve.x && sleeve.y &&
        <Text
          name="dimension"
          x={-radius}
          y={-radius}
          width={2 * radius}
          height={2 * radius}
          fontSize={FONT_SIZE}
          align="center"
          verticalAlign="middle"
          text={sleeve.colloquialism}
          fill="black"
        />
      }
    </React.Fragment>
  )
}
