import React from "react";

import { Grid, Paper, Typography } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import {orangeBackgroundColor} from "../../../constants";
import {FloorPlan} from "../../Plan/Configuration/Floor/FloorPlan";

const useStyles = makeStyles(() => ({
  plan: {
    background: orangeBackgroundColor,
    padding: '1em 2em 2em 2em'
  }
}));

const TextGridItem = () => {
  return (
    <Grid item xs={12} key={'upper_text'}>
      <Typography component="p" variant="body2" align={'center'} style={{fontSize: 0.92 + 'rem'}}>
        Positionieren Sie die Abflüsse per Drag & Drop auf dem Boden. Mit einem Klick entfernen Sie das Element.
      </Typography>
    </Grid>
  )
}

export const FloorStep = () => {
  const classes = useStyles();

  return (
    <Paper square={true} className={classes.plan} elevation={10}>
      <Grid container spacing={1}>
        <TextGridItem/>
        <Grid item xs={12}>
          <FloorPlan
            isToolbarVisible={true}
          />
        </Grid>
      </Grid>
    </Paper>
  )
};
