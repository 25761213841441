import {planActionTypes as actionTypes} from "../constants";

const setVault = () => {
  return {
    type: actionTypes.setVault
  };
};

const unsetVault = () => {
  return {
    type: actionTypes.unsetVault
  };
};

const setVaultHeight = height => {
  return {
    type: actionTypes.setVaultHeight,
    data: height
  };
};

const addSleeve = (wallIndex, sleeve) => {
  return {
    type: actionTypes.addSleeve,
    data: {wallIndex, sleeve}
  };
};

const removeSleeve = (wallIndex, id) => {
  return {
    type: actionTypes.removeSleeve,
    data: {wallIndex, id}
  };
};

const moveSleeve = (wallIndex, id, x, y) => {
  return {
    type: actionTypes.moveSleeve,
    data: {wallIndex, id, x, y}
  };
};

const addBlockOut = (wallIndex, blockOut) => {
  return {
    type: actionTypes.addBlockOut,
    data: {wallIndex, blockOut}
  };
};

const removeBlockOut = (wallIndex, id) => {
  return {
    type: actionTypes.removeBlockOut,
    data: {wallIndex, id}
  };
};

const moveBlockOut = (wallIndex, id, x, y) => {
  return {
    type: actionTypes.moveBlockOut,
    data: {wallIndex, id, x, y}
  };
};

const resizeBlockOut = (wallIndex, id, width, height) => {
  return {
    type: actionTypes.resizeBlockOut,
    data: {wallIndex, id, width, height}
  };
};

const addCRail = (wallIndex, cRail) => {
  return {
    type: actionTypes.addCRail,
    data: {wallIndex, cRail}
  };
};

const removeCRail = (wallIndex, id) => {
  return {
    type: actionTypes.removeCRail,
    data: {wallIndex, id}
  };
};

const moveCRail = (wallIndex, id, x, y) => {
  return {
    type: actionTypes.moveCRail,
    data: {wallIndex, id, x, y}
  };
};

const resizeCRail = (wallIndex, id, length) => {
  return {
    type: actionTypes.resizeCRail,
    data: {wallIndex, id, length}
  };
};

const addGrounding = (wallIndex, grounding) => {
  return {
    type: actionTypes.addGrounding,
    data: {wallIndex, grounding}
  };
};

const removeGrounding = (wallIndex, id) => {
  return {
    type: actionTypes.removeGrounding,
    data: {wallIndex, id}
  };
};

const moveGrounding = (wallIndex, id, x, y) => {
  return {
    type: actionTypes.moveGrounding,
    data: {wallIndex, id, x, y}
  };
};

const addWallDrainage = (wallIndex, drainage) => {
  return {
    type: actionTypes.addWallDrainage,
    data: {wallIndex, drainage}
  };
};

const removeWallDrainage = (wallIndex, id) => {
  return {
    type: actionTypes.removeWallDrainage,
    data: {wallIndex, id}
  };
};

const moveWallDrainage = (wallIndex, id, x, y) => {
  return {
    type: actionTypes.moveWallDrainage,
    data: {wallIndex, id, x, y}
  };
};

const mirrorFromOppositeSide = wallIndex => {
  return {
    type: actionTypes.mirrorFromOppositeSide,
    data: {wallIndex}
  };
};

export const vaultActions = {
  setVault,
  unsetVault,
  setVaultHeight,
  addSleeve,
  removeSleeve,
  moveSleeve,
  addBlockOut,
  removeBlockOut,
  moveBlockOut,
  resizeBlockOut,
  addCRail,
  removeCRail,
  moveCRail,
  resizeCRail,
  addGrounding,
  removeGrounding,
  moveGrounding,
  addWallDrainage,
  removeWallDrainage,
  moveWallDrainage,
  mirrorFromOppositeSide
};

