export const billingActionTypes = {
  setBilling: 'SET_BILLING',
  unsetBilling: 'UNSET_BILLING',
  setName: 'SET_BILLING_NAME',
  setEmail: 'SET_BILLING_EMAIL',
  setPhone: 'SET_BILLING_PHONE',
  setCompany: 'SET_BILLING_COMPANY',
  setStreet: 'SET_BILLING_STREET',
  setZip: 'SET_BILLING_ZIP',
  setCity: 'SET_BILLING_CITY'
}
