import { apiUrls } from "../constants"
import { armouringDataService } from "../services"

const createArmouringDocuments = async (planId, projectId) => {
  const data = await armouringDataService
    .readArmouringRelevantData(planId, projectId)
  const response = await fetch(apiUrls.armouringDocumentsEndpoint, {
    method: "POST",
    headers: new Headers({ "content-type": "application/json" }),
    body: JSON.stringify(data)
  })
  if (!response.ok) {
    const error = (data && data.message) || response.statusText;

    return Promise.reject(error);
  }
  const blob = await response.blob()
  const blobUrl = window.URL.createObjectURL(new Blob([blob]))
  const fileName = !!projectId ? `Armierung_ProjId_${projectId}.zip` : `Armierung_PlanId_${planId}.zip`
  const aTag = document.createElement("a")
  aTag.href = blobUrl
  aTag.setAttribute("download", fileName)
  document.body.appendChild(aTag)
  aTag.click()
  aTag.remove()
  
}

export const armouringDocumentsService = {
  createArmouringDocuments
}
