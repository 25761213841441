import {deliveryActionTypes as actionTypes} from "../constants"

const setDescription = description => {
  return {
    type: actionTypes.setDescription,
    data: description
  }
}

const setDeliveryType = deliveryType => {
  return {
    type: actionTypes.setDeliveryType,
    data: deliveryType
  }
}

const setDesiredSchedule = desiredSchedule => {
  return {
    type: actionTypes.setDesiredSchedule,
    data: desiredSchedule
  }
}

const setStreet = street => {
  return {
    type: actionTypes.setStreet,
    data: street
  }
}

const setZip = zip => {
    return {
      type: actionTypes.setZip,
      data: zip
    }
  }

const setCity = city => {
  return {
    type: actionTypes.setCity,
    data: city
  }
}

const setCoordinates = (lat, lng) => {
  return {
    type: actionTypes.setCoordinates,
    data: {lat, lng}
  }
}

const unsetCoordinates = () => {
  return {
    type: actionTypes.unsetCoordinates
  }
}

const setNumber = number => {
  return {
    type: actionTypes.setNumber,
    data: number
  }
}

const setPerson = person => {
  return {
    type: actionTypes.setPerson,
    data: person
  }
}

const setPhone = phone => {
  return {
    type: actionTypes.setPhone,
    data: phone
  }
}

const setCommission = commission => {
  return {
    type: actionTypes.setCommission,
    data: commission
  }
}

const setUsersOrderNumber = usersOrderNumber => {
  return {
    type: actionTypes.setUsersOrderNumber,
    data: usersOrderNumber
  }
}

const setRemarks = remarks => {
  return {
    type: actionTypes.setRemarks,
    data: remarks
  }
}

export const deliveryActions = {
  setDescription,
  setDeliveryType,
  setDesiredSchedule,
  setStreet,
  setZip,
  setCity,
  setCoordinates,
  unsetCoordinates,
  setNumber,
  setPerson,
  setPhone,
  setCommission,
  setUsersOrderNumber,
  setRemarks
}
