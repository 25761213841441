import {planApiActionTypes as actionTypes} from "../constants"

const initialState = []

export const plansReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.resetPlans:
      return initialState

    case actionTypes.readPlansRequest:
      return {fetching: true}

    case actionTypes.readPlansFailure:
      return {error: action.error}

    case actionTypes.readPlansSuccess:
      return Array.isArray(action.data) ? [...action.data] : []

    case actionTypes.deletePlanSuccess:
      return state.filter(p => p.id !== action.data)

    case actionTypes.deletePlanFailure:
      return {
        error: {
          action: 'deletePlan',
          message: !!action.error ? action.error : ''
        }
      }

    case actionTypes.setOwnerSuccess:
      return Array.isArray(state)
        ? state.map(p => p.id === action.data.id ? action.data : p)
        : state

    case actionTypes.setOwnerFailure:
      return {
        error: {
          action: 'setPlanOwner',
          message: !!action.error ? action.error : ''
        }
      }

    default:
      return state
  }
}
