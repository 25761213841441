export const projectApiActionTypes = {
  resetProjects: 'RESET_PROJECTS',

  createRequest: 'CREATE_PROJECT_REQUEST',
  createSuccess: 'CREATE_PROJECT_SUCCESS',
  createFailure: 'CREATE_PROJECT_FAILURE',

  setStateRequest: 'SET_PROJECT_STATE_REQUEST',
  setStateSuccess: 'SET_PROJECT_STATE_SUCCESS',
  setStateFailure: 'SET_PROJECT_STATE_FAILURE',

  readProjectsRequest: 'READ_PROJECTS_REQUEST',
  readProjectsSuccess: 'READ_PROJECTS_SUCCESS',
  readProjectsFailure: 'READ_PROJECTS_FAILURE',

  readProjectDetailsRequest: 'READ_PROJECT_DETAILS_REQUEST',
  readProjectDetailsSuccess: 'READ_PROJECT_DETAILS_SUCCESS',
  readProjectDetailsFailure: 'READ_PROJECT_DETAILS_FAILURE'
}
