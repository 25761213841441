import {lidActions} from "../../actions";
import {getGridLinePositions} from "../Plan/Configuration/gridLinePositions";
import {SNAP_GRID_SIZE} from "../../constants";
import {getClosureSizes} from "./manhole";
import {snap} from "../Plan/snap";
import {
  getDefaultManholeMaxOverlap,
  getManholeMaxOverlap,
  getSubFloorManholeMaxOverlap,
  getSubFloorManholeMinOverlap
} from "./lid";
import {createLidAndIntermediateCeilingBlockOutDomainFunctions} from "./lidAndIntermediateCeilingBlockOutDomainFunctions";
import {
  addCircularElement, circularElementResizeBoundFunc, moveCircularElement,
  previewAddCircularElement,
  previewMoveCircularElement, previewResizeCircularElement, resizeCircularElement
} from "./circularElementDomainFunctions";

export const createLidDomainFunctions = (dispatch, plan) => {
  const hasManhole = () => plan.lid.manholes.length > 0
  const snapManholePosition = manhole => {
    const getDefaultManholeYSnapPositions = () => {
      return [
        -getDefaultManholeMaxOverlap(plan),
        0,
        ...getGridLinePositions(plan.width - manhole.height, SNAP_GRID_SIZE, true),
        (plan.width - manhole.height),
        (plan.width - manhole.height) + getDefaultManholeMaxOverlap(),
        (plan.width - manhole.height) / 2
      ].filter(gridPos =>
        gridPos <= plan.width - manhole.height + getDefaultManholeMaxOverlap()
        && gridPos >= -getDefaultManholeMaxOverlap()
      )
    }
    const getSubFloorManholeYSnapPositions = () => {
      return manhole.orientation === 'vertical'
        ? plan.width <= manhole.height - 2 * getSubFloorManholeMinOverlap() ? [plan.width / 2 - manhole.height / 2] : []
        : [
          ...(
            getGridLinePositions(
              plan.width + 2 * getSubFloorManholeMaxOverlap(plan) - manhole.height,
              SNAP_GRID_SIZE,
              true
            ).map(p => p - getSubFloorManholeMaxOverlap(plan))
          ),
          (plan.width + getSubFloorManholeMaxOverlap(plan) - manhole.height),
          (plan.width - manhole.height) / 2
        ].filter(gridPos => gridPos <= plan.width + getSubFloorManholeMaxOverlap(plan) - manhole.height)
    }
    const getSubFloorManholeOnIntermediateCeilingYSnapPositions = () => {
      return manhole.orientation === 'vertical'
        ? [
          ...(
            getGridLinePositions(
              plan.width + 2 * getSubFloorManholeMaxOverlap(plan),
              SNAP_GRID_SIZE,
              true
            ).map(p => p - getSubFloorManholeMaxOverlap(plan) - manhole.height / 2)
          )
        ]
        : [
          ...(
            getGridLinePositions(
              plan.width + 2 * getSubFloorManholeMaxOverlap(plan) - manhole.height,
              SNAP_GRID_SIZE,
              true
            ).map(p => p - getSubFloorManholeMaxOverlap(plan))
          ),
          (plan.width + getSubFloorManholeMaxOverlap(plan) - manhole.height),
          (plan.width - manhole.height) / 2
        ]
    }
    const getManholeYSnapPositions = () => {
      return plan.lid.type === 'subfloor'
        ? !!plan.intermediateCeiling
          ? getSubFloorManholeOnIntermediateCeilingYSnapPositions()
          : getSubFloorManholeYSnapPositions()
        : getDefaultManholeYSnapPositions()
    }
    const getDefaultManholeXSnapPositions = () => {
      return [
        -getDefaultManholeMaxOverlap(),
        0,
        ...getGridLinePositions(plan.length - manhole.width, SNAP_GRID_SIZE, true),
        (plan.length - manhole.width),
        (plan.length - manhole.width) + getDefaultManholeMaxOverlap(),
        (plan.length - manhole.width) / 2
      ].filter(gridPos =>
        gridPos <= plan.length - manhole.width + getDefaultManholeMaxOverlap()
        && gridPos >= -getDefaultManholeMaxOverlap()
      )
    }
    const getSubFloorManholeXSnapPositions = () => {
      return manhole.orientation === 'vertical'
        ? [
          ...(
            getGridLinePositions(
              plan.length + 2 * getSubFloorManholeMaxOverlap(plan) - manhole.width,
              SNAP_GRID_SIZE,
              true
            ).map(p => p - getSubFloorManholeMaxOverlap(plan))
          ),
          (plan.length + getSubFloorManholeMaxOverlap(plan) - manhole.width),
          (plan.length - manhole.width) / 2
        ].filter(gridPos => gridPos <= plan.length + getSubFloorManholeMaxOverlap(plan) - manhole.width)
        : plan.length <= manhole.width - 2 * getSubFloorManholeMinOverlap() ? [plan.length / 2 - manhole.width / 2] : []
    }
    const getSubFloorManholeOnIntermediateCeilingXSnapPositions = () => {
      return manhole.orientation === 'vertical'
        ? [
          ...(
            getGridLinePositions(
              plan.length + 2 * getSubFloorManholeMaxOverlap(plan) - manhole.width,
              SNAP_GRID_SIZE,
              true
            ).map(p => p - getSubFloorManholeMaxOverlap(plan))
          ),
          (plan.length + getSubFloorManholeMaxOverlap(plan) - manhole.width),
          (plan.length - manhole.width) / 2
        ]
        : [
          ...(
            getGridLinePositions(
              plan.length + 2 * getSubFloorManholeMaxOverlap(plan),
              SNAP_GRID_SIZE,
              true
            ).map(p => p - getSubFloorManholeMaxOverlap(plan) - manhole.width / 2)
          )
        ]
    }
    const getManholeXSnapPositions = () => {
      return plan.lid.type === 'subfloor'
        ? !!plan.intermediateCeiling
          ? getSubFloorManholeOnIntermediateCeilingXSnapPositions()
          : getSubFloorManholeXSnapPositions()
        : getDefaultManholeXSnapPositions()
    }

    return {
      ...manhole,
      x: snap(manhole.x, getManholeXSnapPositions()),
      y: snap(manhole.y, getManholeYSnapPositions())
    }
  }
  const snapManholeSize = (manhole, direction) => {
    const stepSize = (() => {
      const getSizes = () => getClosureSizes(manhole.type)

      return (manhole.orientation === 'vertical' && direction === 'right')
      || (manhole.orientation === 'horizontal' && direction === 'top')
        ? {size: getSizes().width, spacing: 0}
        : {size: getSizes().height, spacing: getSizes().rowSpacing}
    })()
    const lengths = (() => {
      const maxLength = direction === 'right'
        ? plan.length - manhole.x + getManholeMaxOverlap(plan)
        : plan.width - manhole.y + getManholeMaxOverlap(plan)
      const minLength = stepSize.size
      const count = (maxLength - minLength) / (stepSize.size + stepSize.spacing) + 1
      return Array.from({length: count}, (v, i) => stepSize.size + i * (stepSize.size + stepSize.spacing))
    })
    const currentLength = direction === 'right' ? manhole.width : manhole.height
    const snappedLength = snap(currentLength, lengths())

    return direction === 'right'
      ? {...manhole, width: snappedLength}
      : {...manhole, height: snappedLength}
  }
  const dragBoundFunc = (manhole, pos) => {
    const defaultDragBoundFunc = (manhole, pos) => {
      return {
        x: Math.max(
          -getDefaultManholeMaxOverlap(),
          Math.min(pos.x, plan.length - manhole.width + getDefaultManholeMaxOverlap())
        ),
        y: Math.max(
          -getDefaultManholeMaxOverlap(),
          Math.min(pos.y, plan.width - manhole.height + getDefaultManholeMaxOverlap())
        )
      }
    }
    const subFloorDragBoundFunc = (manhole, pos) => {
      return manhole.orientation === 'vertical'
        ? {
          x: Math.max(
            -getSubFloorManholeMaxOverlap(plan),
            Math.min(pos.x, plan.length + getSubFloorManholeMaxOverlap(plan) - manhole.width)
          ),
          y: plan.width / 2 - manhole.height / 2
        }
        : {
          x: plan.length / 2 - manhole.width / 2,
          y: Math.max(
            -getSubFloorManholeMaxOverlap(plan),
            Math.min(pos.y, plan.width + getSubFloorManholeMaxOverlap(plan) - manhole.height)
          )
        }
    }
    const subFloorOnIntermediateCeilingDragBoundFunc = (manhole, pos) => {
      return manhole.orientation === 'vertical'
        ? {
          x: Math.max(
            -getSubFloorManholeMaxOverlap(plan),
            Math.min(pos.x, plan.length + getSubFloorManholeMaxOverlap(plan) - manhole.width)
          ),
          y: Math.max(
            -getSubFloorManholeMaxOverlap(plan) - manhole.height / 2,
            Math.min(pos.y, plan.width + getSubFloorManholeMaxOverlap(plan) - manhole.height / 2)
          )
        }
        : {
          x: Math.max(
            -getSubFloorManholeMaxOverlap(plan) - manhole.width / 2,
            Math.min(pos.x, plan.length + getSubFloorManholeMaxOverlap(plan) - manhole.width / 2)
          ),
          y: Math.max(
            -getSubFloorManholeMaxOverlap(plan),
            Math.min(pos.y, plan.width + getSubFloorManholeMaxOverlap(plan) - manhole.height)
          )
        }
    }

    return plan.lid.type === 'subfloor'
      ? !!plan.intermediateCeiling
        ? subFloorOnIntermediateCeilingDragBoundFunc(manhole, pos)
        : subFloorDragBoundFunc(manhole, pos)
      : defaultDragBoundFunc(manhole, pos)
  }
  const resizeBoundFunc = (manhole, pos) => {
    const defaultResizeBoundFunc = (manhole, pos) => {
      const getMaxOverlap = () => getDefaultManholeMaxOverlap()

      return {
        x: Math.max(-getMaxOverlap(), Math.min(pos.x, plan.length + getMaxOverlap())),
        y: Math.max(-getMaxOverlap(), Math.min(pos.y, plan.width + getMaxOverlap()))
      }
    }
    const subFloorResizeBoundFunc = (manhole, pos) => {
      return manhole.orientation === 'vertical'
        ? {
          x: Math.max(
            -getSubFloorManholeMaxOverlap(plan),
            Math.min(pos.x, plan.length + getSubFloorManholeMaxOverlap(plan))
          ),
          y: pos.y
        }
        : {
          x: pos.x,
          y: Math.max(
            -getSubFloorManholeMaxOverlap(plan),
            Math.min(pos.y, plan.width + getSubFloorManholeMaxOverlap(plan))
          )
        }
    }

    return plan.lid.type === 'subfloor'
      ? subFloorResizeBoundFunc(manhole, pos)
      : defaultResizeBoundFunc(manhole, pos)
  }

  return {
    manholeFunctions: {
      previewAdd: item => snapManholePosition(item),
      add: item => {
        const snapped = snapManholePosition(item)

        if (!hasManhole() && Number.isFinite(snapped.x) && Number.isFinite(snapped.y)) {
          dispatch(lidActions.addManhole(snapped))
        }
        return snapped
      },
      previewMove: item => snapManholePosition(item),
      move: item => {
        const snapped = snapManholePosition(item)

        dispatch(lidActions.moveManhole(item.id, snapped.x, snapped.y))
        return snapped
      },
      remove: id => dispatch(lidActions.removeManhole(id)),
      previewResize: (item, direction) => snapManholeSize(item, direction),
      resize: (manhole, direction) => {
        const snapped = snapManholeSize(manhole, direction)

        dispatch(lidActions.resizeManhole(manhole.id, snapped.width, snapped.height))
        return snapped
      },
      dragBoundFunc: dragBoundFunc,
      resizeBoundFunc: resizeBoundFunc
    },
    blockOutFunctions: createLidAndIntermediateCeilingBlockOutDomainFunctions(dispatch, plan, lidActions),
    circularBlockOutFunctions : {
      previewAdd: item => previewAddCircularElement(plan.length, plan.width, item),
      add: item => addCircularElement(
        item => dispatch(lidActions.addCircularBlockOut(item)),
        plan.length,
        plan.width,
        item
      ),
      previewMove: item => previewMoveCircularElement(plan.length, plan.width, item),
      move: item => moveCircularElement(
        (id, x, y) => dispatch(lidActions.moveCircularBlockOut(id, x, y)),
        plan.length,
        plan.width,
        item
      ),
      remove: id => dispatch(lidActions.removeCircularBlockOut(id)),
      previewResize: (item) => previewResizeCircularElement(plan.length, plan.width, item),
      resize: item => resizeCircularElement(
        (id, diameter) => dispatch(lidActions.resizeCircularBlockOut(id, diameter)),
        plan.length,
          plan.width,
          item
      ),
      resizeBoundFunc: (blockOut, pos) => circularElementResizeBoundFunc(pos, plan.length, plan.width, blockOut)
    }
  }

}
