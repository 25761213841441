import {
  getSubFloorManholeMinOverlap,
  getVaultClearMeasureBack,
  getVaultClearMeasureFront,
  getVaultClearMeasureLeft,
  getVaultClearMeasureRight
} from "./lid";

export const intermediateCeilingFunctions = {
  getSize: plan => {
    if (!plan.intermediateCeiling) {
      return {width: 0, length: 0, height: 0, x: 0, y: 0}
    }
    return {
      width: plan.width + 2 * plan.wallWidth,
      length: plan.length + 2 * plan.wallWidth,
      height: 25,
      x: -plan.wallWidth,
      y: -plan.wallWidth
    }
  },
  getBottom: () => 0,
  getHeight: plan => intermediateCeilingFunctions.getSize(plan).height,
  getTop: plan => intermediateCeilingFunctions.getBottom() + intermediateCeilingFunctions.getHeight(plan),
  getLeft: plan => intermediateCeilingFunctions.getSize(plan).x,
  getLength: plan => intermediateCeilingFunctions.getSize(plan).length,
  getRight: plan => intermediateCeilingFunctions.getLeft(plan) + intermediateCeilingFunctions.getLength(plan),
  getFront: plan => intermediateCeilingFunctions.getSize(plan).y,
  getWidth: plan => intermediateCeilingFunctions.getSize(plan).width,
  getBack: plan => intermediateCeilingFunctions.getFront(plan) + intermediateCeilingFunctions.getWidth(plan)
}

export const getManholeClearMeasure = (plan, manhole) => {
  const getXWithinVaultClearMeasure = x => {
    return Math.max(
      getVaultClearMeasureLeft(),
      Math.min(getVaultClearMeasureRight(plan), x)
    )
  }
  const getYWithinVaultClearMeasure = y => {
    return Math.max(
      getVaultClearMeasureFront(),
      Math.min(getVaultClearMeasureBack(plan), y)
    )
  }
  const getRectWithinVaultClearMeasure = rect => {
    const getLeft = () => getXWithinVaultClearMeasure(rect.x)
    const getRight = () => getXWithinVaultClearMeasure(rect.x + rect.width)
    const getBottom = () => getYWithinVaultClearMeasure(rect.y)
    const getTop = () => getYWithinVaultClearMeasure(rect.y + rect.height)

    return {
      x: getLeft(),
      y: getBottom(),
      width: getRight() - getLeft(),
      height: getTop() - getBottom()
    }
  }
  const getSubFloorManholeClearMeasure = (plan, manhole) => {
    const getMinOverlapApplied = manhole => {
      return manhole.orientation === 'vertical'
        ? {
          x: manhole.x,
          y: manhole.y + getSubFloorManholeMinOverlap(),
          width: manhole.width,
          height: manhole.height - 2 * getSubFloorManholeMinOverlap()
        }
        : {
          x: manhole.x + getSubFloorManholeMinOverlap(),
          y: manhole.y,
          width: manhole.width - 2 * getSubFloorManholeMinOverlap(),
          height: manhole.height
        }
    }

    return getRectWithinVaultClearMeasure(getMinOverlapApplied(manhole))
  }

  return plan.lid.type === 'subfloor'
    ? getSubFloorManholeClearMeasure(plan, manhole)
    : getRectWithinVaultClearMeasure(manhole)
}

export const intermediateCeilingManholeClearMeasureFunctions = {
  getLeft: (plan, manhole) => getManholeClearMeasure(plan, manhole).x,
  getRight: (plan, manhole) => getManholeClearMeasure(plan, manhole).x + getManholeClearMeasure(plan, manhole).width,
  getFront: (plan, manhole) => getManholeClearMeasure(plan, manhole).y + getManholeClearMeasure(plan, manhole).height,
  getBack: (plan, manhole) => getManholeClearMeasure(plan, manhole).y
}

