import React from "react"

import {useTheme, Paper} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Grid from "@mui/material/Grid"

import {orangeBackgroundColor} from "../../../constants"
import {DeliveryTypeSection} from "./DeliveryTypeSection"
import {QuantitySection} from "./QuantitySection"
import {DeadlineSection} from "./DeadlineSection"
import {ConstructionSiteSection} from "./ConstructionSiteSection"

const useStyles = theme => {
  return makeStyles({
    plan: {
      background: orangeBackgroundColor,
      padding: theme.spacing(6)
    },
    italic: {
      fontStyle: 'italic',
    }
  })()
}

export const OrderStep = () => {
  const theme = useTheme()
  const classes = useStyles(theme)

  return (
    <Paper square={true} className={classes.plan}>

      <Grid container spacing={6}>
        <Grid item xs={6}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <QuantitySection/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <DeliveryTypeSection/>
            </Grid>
            <Grid item xs={12}>
              <DeadlineSection/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid>
        <ConstructionSiteSection/>
      </Grid>
    </Paper>
  )
}
