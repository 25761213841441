import React, {useEffect, useRef} from "react";

import {useDispatch, useSelector} from "react-redux";
import {Group, Layer, Stage} from "react-konva";

import {AutoResizeDiv} from "../AutoResizeDiv";
import {createFloorGroundView} from "./Floor/floorGroundView";
import {createGroundViewLayout} from "./layoutFunctions";
import {getFloorSize} from "../../domain/floor";
import {getOverallSize} from "../../domain/composition";
import {
  createDimensioning,
  createMeasurementStopChainFromMeasurements,
  createMeasurementStopChainFromPositions
} from "../dimensioning";
import {planImageActions} from "../../../actions/planImageActions";

const GroundView = props => {
  const {clientSize} = props
  const plan = useSelector(state => state.plan)
  const dispatch = useDispatch()
  const theStage = useRef(null)

  useEffect(() => {
      if (!theStage.current) {
        return
      }
      dispatch(planImageActions.setFloorGroundViewImage(theStage.current.toDataURL({pixelRatio: 2})))
    },
    [clientSize, dispatch]
  )
  const layout = createGroundViewLayout(clientSize, plan, getFloorSize(plan))
  const getFloorWidth = () => getFloorSize(plan).length
  const getFloorHeight = () => getFloorSize(plan).width
  const getVerticalMeasurementsToPartDistance = () => getFloorSize(plan).x - getOverallSize(plan).x
  const getWallWidth = () => plan.wallWidth
  const getClearMeasureWidth = () => plan.length
  const getClearMeasureHeight = () => plan.width

  const getHorizontalMeasurementStops = () => {
    const getOuterMeasurements = () => createMeasurementStopChainFromMeasurements(
      [getFloorWidth()],
      getVerticalMeasurementsToPartDistance()
    )
    const getInnerMeasurements = () => {
      const getXPositions = () => [
        0,
        getWallWidth(),
        getWallWidth() + getClearMeasureWidth(),
        getWallWidth() + getClearMeasureWidth() + getWallWidth(),
        ...plan.floor.drainages.map(d => d.x + getWallWidth())
      ].map(p => p + getVerticalMeasurementsToPartDistance())

      return createMeasurementStopChainFromPositions(getXPositions().map(x => ({x, y:0})))
    }
    return [getInnerMeasurements(), getOuterMeasurements()]
  }

  const getVerticalMeasurementStops = () => {
    const getY = () => -getVerticalMeasurementsToPartDistance()
    const getOuterMeasurements = () => createMeasurementStopChainFromMeasurements(
      [getFloorHeight()],
      0,
      getY()
    )
    const getInnerMeasurements = () => {
      const getYPositions = () => [
        0,
        getWallWidth(),
        getWallWidth() + getClearMeasureHeight(),
        getWallWidth() + getClearMeasureHeight() + getWallWidth(),
        ...plan.floor.drainages.map(d => d.y + getWallWidth())
      ]

      return createMeasurementStopChainFromPositions(getYPositions().map(x => ({x, y: getY()})))
    }

    return [getInnerMeasurements(), getOuterMeasurements()]
  }

  return (
    <React.Fragment>
      {!isNaN(layout.verticalMeasurements.screenHeight) &&
      <Stage
        ref={theStage}
        name={"stage"}
        width={clientSize.width}
        height={layout.overall.screenHeight}
      >
        <Layer>
          {
            <Group x={layout.part.screenX} y={layout.part.screenY}>
              {createFloorGroundView(plan, layout.part.scale)}
            </Group>
          }
          <Group
            x={layout.horizontalMeasurements.screenX}
            y={layout.horizontalMeasurements.screenY}
          >
            {
              createDimensioning(
                getHorizontalMeasurementStops(),
                'below',
                {
                  width: layout.horizontalMeasurements.screenWidth,
                  height: layout.horizontalMeasurements.screenHeight
                },
                layout.verticalMeasurements.scale
              )
            }
          </Group>
          <Group
            x={layout.verticalMeasurements.screenX + layout.verticalMeasurements.screenWidth}
            y={layout.verticalMeasurements.screenY + layout.verticalMeasurements.screenHeight}
            rotation={-90}
          >
            {
              createDimensioning(
                getVerticalMeasurementStops(),
                'above',
                {width: layout.verticalMeasurements.screenHeight, height: layout.verticalMeasurements.screenWidth},
                layout.verticalMeasurements.scale
              )
            }
          </Group>
        </Layer>
      </Stage>
      }
    </React.Fragment>
  )
}

export const FloorGroundView = props => {
  return (
    <AutoResizeDiv>
      <GroundView {...props} />
    </AutoResizeDiv>
  )
}
