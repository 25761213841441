import React from "react"

import {Group} from "react-konva"

import {FONT_SIZE} from "../../../../constants"
import {idleDragPreviewOpacity} from "./toolbarConstants";
import {toRealPosition, toScreenPosition} from "../../realWorldTransform"
import {setOpacity} from "../../Element/elementFunctions"
import {hideCrossHairs, updateCrossHairs} from "../../Element/circularElementCrossHairs"
import {renderDragStarterRect, renderToolbarIcon, renderToolbarItemLabel} from "./toolbarFunctions";

const renderCircularElementToolbarDragPreview = (
  toolbarItemRect,
  dragPreviewCircle,
  configAreaLayout,
  element,
  onDragMove,
  onDragEnd,
  renderGroupElementsFunction
) => {
  const handleDragStart = e => {
    setOpacity(e.target, "dragging")
  }
  const handleDragMove = e => {
    const group = e.target
    const circle = {...dragPreviewCircle, x: group.x(), y: group.y()}
    const dragMoveResult = onDragMove(circle)
    const stage = group.getStage()

    updateCrossHairs(
      dragMoveResult.element,
      configAreaLayout,
      configAreaLayout.transform,
      stage,
      dragMoveResult.screenCircle.x,
      dragMoveResult.screenCircle.y
    )
  }
  const handleDragEnd = e => {
    const group = e.target
    const circle = {...dragPreviewCircle, x: group.x(), y: group.y()}

    onDragEnd(circle)
    hideCrossHairs(group.getStage())
    setOpacity(group)
    e.target.to({
      opacity: idleDragPreviewOpacity,
      duration: 0,
      x: e.target.attrs.origin.x,
      y: e.target.attrs.origin.y,
    })
  }

  return (
    <Group
      x={dragPreviewCircle.x}
      y={dragPreviewCircle.y}
      opacity={idleDragPreviewOpacity}
      onDragStart={handleDragStart}
      onDragMove={handleDragMove}
      onDragEnd={handleDragEnd}
      origin={{x: dragPreviewCircle.x, y: dragPreviewCircle.y}}
    >
      <Group listening={false}>
        {renderGroupElementsFunction(dragPreviewCircle, element)}
      </Group>
      {
        renderDragStarterRect({
          x: toolbarItemRect.x - dragPreviewCircle.x,
          y: toolbarItemRect.y - dragPreviewCircle.y,
          width: toolbarItemRect.width,
          height: toolbarItemRect.height - FONT_SIZE
        })
      }
    </Group>
  )
}

export const renderCircularElementToolbarItem = (
  key,
  element,
  x,
  y,
  width,
  height,
  iconScale,
  configAreaLayout,
  domainFunctions,
  renderGroupElementsFunction
) => {
  const toolbarItemRect = {x, y, width, height}
  const labelHeight = 2 * FONT_SIZE + 10
  const iconRadius = element.outerDiameter / 2 * iconScale
  const iconCenter = {
    x: x + width / 2,
    y: y + labelHeight + iconRadius
  }
  const iconScreenCircle = {
    ...iconCenter,
    radius: iconRadius
  }
  const dragPreviewScreenCircle = {
    ...iconCenter,
    radius: element.outerDiameter / 2 * configAreaLayout.scale
  }
  const screenZero = {x: configAreaLayout.screenX, y: configAreaLayout.screenY}
  const onDragMove = screenCircle => {
    const realPosition = toRealPosition(screenCircle, configAreaLayout.transform, screenZero)
    const addedElement = domainFunctions.previewAdd({...element, x: realPosition.x, y: realPosition.y})
    const newScreenPosition = toScreenPosition(addedElement, configAreaLayout.transform, screenZero)
    const newScreenCircle = {...screenCircle, ...newScreenPosition}

    return {
      screenCircle: newScreenCircle,
      element: addedElement
    }
  }
  const onDragEnd = screenPosition => {
    const realPosition = toRealPosition(screenPosition, configAreaLayout.transform, screenZero)
    const addedItem = domainFunctions.add({...element, x: realPosition.x, y: realPosition.y})
    const newScreenPosition = toScreenPosition(addedItem, configAreaLayout.transform, screenZero)
    const newScreenCircle = {...dragPreviewScreenCircle, ...newScreenPosition}

    return {
      screenCircle: newScreenCircle,
      element: addedItem
    }
  }

  return (
    <React.Fragment key={key}>
      {/*<Rect x={toolbarItemRect.x} y={toolbarItemRect.y} width={toolbarItemRect.width} height={toolbarItemRect.height} fill={'blue'} strokeWidth={1} stroke={'black'}/>*/}
      {renderToolbarItemLabel(x, y, width, element.colloquialism, element.name)}
      {renderToolbarIcon(iconScreenCircle, element, renderGroupElementsFunction)}
      {renderCircularElementToolbarDragPreview(
        toolbarItemRect,
        dragPreviewScreenCircle,
        configAreaLayout,
        element,
        onDragMove,
        onDragEnd,
        renderGroupElementsFunction
      )}
    </React.Fragment>
  )
}
