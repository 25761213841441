import { authenticationActionTypes as actionTypes } from '../constants';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user && user.jwt ? { loggedIn: true, user } : {};

export const authenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REGISTRATION_REQUEST: return { registering: true, user: action.user };
    case actionTypes.REGISTRATION_SUCCESS: return { registered: true, user: action.user };
    case actionTypes.REGISTRATION_FAILURE: return { error: action.error };
    case actionTypes.EMAIL_VERIFICATION_REQUEST: return { verifying: true, user: action.user };
    case actionTypes.EMAIL_VERIFICATION_SUCCESS: return { verified: true, user: action.user };
    case actionTypes.EMAIL_VERIFICATION_FAILURE: return { error: action.error };
    case actionTypes.LOGIN_REQUEST: return { loggingIn: true, user: action.user };
    case actionTypes.LOGIN_SUCCESS: return { loggedIn: true, jwtPayload: action.jwtPayload };
    case actionTypes.LOGIN_FAILURE: return { error: action.error };
    case actionTypes.EXTEND_LOGIN_SUCCESS: return { loggedIn: true, jwtPayload: action.jwtPayload };
    case actionTypes.LOGOUT: return {};
    case actionTypes.RESET_PASSWORD_REQUEST: return { resetting: true, user: action.user };
    case actionTypes.RESET_PASSWORD_SUCCESS: return { reset: true, user: action.user };
    case actionTypes.RESET_PASSWORD_FAILURE: return { error: action.error };
    case actionTypes.CHANGE_PASSWORD_REQUEST: return { changing: true, user: action.user };
    case actionTypes.CHANGE_PASSWORD_SUCCESS: return { changed: true, user: action.user };
    case actionTypes.CHANGE_PASSWORD_FAILURE: return { error: action.error };

    default: return state;
  }
};
