import React from "react";
import {renderLine} from "../renderFunctions";
import {getBevelWidth, getFloorSize} from "../../../domain/floor";
import {Group, Text} from "react-konva";
import {FONT_SIZE} from "../../../../constants";
import {renderGroupElements} from "../../Element/drainageRenderFactory"

export const createFloorGroundView = (plan, scale) => {
  const getWallWidth = () => plan.wallWidth * scale
  const getTop = () => 0
  const getFloorHeight = () => getFloorSize(plan).width * scale
  const getBottom = () => getTop() + getFloorHeight()
  const getLeft = () => 0
  const getWidth = () => getFloorSize(plan).length * scale
  const getRight = () => getLeft() + getWidth()
  const getClearMeasureLeft = () => getLeft() + getWallWidth()
  const getClearMeasureWidth = () => plan.length * scale
  const getClearMeasureRight = () => getClearMeasureLeft() + getClearMeasureWidth()
  const getClearMeasureTop = () => getTop() + getWallWidth()
  const getClearMeasureHeight = () => plan.width * scale
  const getClearMeasureBottom = () => getClearMeasureTop() + getClearMeasureHeight()

  const renderOutline = () => (
    renderLine([
      getLeft(), getTop(),
      getLeft(), getBottom(),
      getRight(), getBottom(),
      getRight(), getTop(),
      getLeft(), getTop()
    ])
  )

  const renderBevel = () => {
    const getInnerLeft = () => getClearMeasureLeft() + getBevelWidth() * scale
    const getInnerRight = () => getClearMeasureRight() - getBevelWidth() * scale
    const getInnerTop = () => getClearMeasureTop() + getBevelWidth() * scale
    const getInnerBottom = () => getClearMeasureBottom() - getBevelWidth() * scale

    return (
      <React.Fragment>
        {
          renderLine([
            getClearMeasureLeft(), getClearMeasureTop(),
            getClearMeasureLeft(), getClearMeasureBottom(),
            getClearMeasureRight(), getClearMeasureBottom(),
            getClearMeasureRight(), getClearMeasureTop(),
            getClearMeasureLeft(), getClearMeasureTop()
          ])
        }
        {
          renderLine([
            getInnerLeft(), getInnerTop(),
            getInnerLeft(), getInnerBottom(),
            getInnerRight(), getInnerBottom(),
            getInnerRight(), getInnerTop(),
            getInnerLeft(), getInnerTop()
          ])
        }
        {renderLine([getClearMeasureLeft(), getClearMeasureTop(), getInnerLeft(), getInnerTop()])}
        {renderLine([getClearMeasureLeft(), getClearMeasureBottom(), getInnerLeft(), getInnerBottom()])}
        {renderLine([getClearMeasureRight(), getClearMeasureBottom(), getInnerRight(), getInnerBottom()])}
        {renderLine([getClearMeasureRight(), getClearMeasureTop(), getInnerRight(), getInnerTop()])}
      </React.Fragment>
    )
  }

  const renderDrainages = () => {
    return plan.floor.drainages.map((d, i) => {
      const getRadius = () => d.diameter / 2 * scale

      return (
        <Group
          key={'drainage_' + i}
          x={d.x * scale}
          y={getClearMeasureHeight() - d.y * scale}
        >
          {
            renderGroupElements({radius: getRadius()}, d, 'transparent')
          }
          <Text
            x={-0.8 * getRadius() - 200}
            y={0.8 * getRadius()}
            width={200}
            height={FONT_SIZE}
            fontSize={FONT_SIZE}
            align="right"
            verticalAlign="top"
            text={d.x + "/" + d.y}
            fill="black"
          />
        </Group>
      )
    })
  }

  return (
    <React.Fragment>
      {renderOutline()}
      {renderBevel()}
      <Group x={getClearMeasureLeft()} y={getClearMeasureTop()}>
        {renderDrainages()}
      </Group>
    </React.Fragment>
  )
}
