import {planActionTypes as actionTypes} from "../constants"
import getElementsOutsideLimits from "../containers/domain/getElementsOutsideLimits"
import {vaultActions} from "./vaultActions";
import {lidActions} from "./lidActions";
import {intermediateCeilingActions} from "./intermediateCeilingActions";
import {floorActions} from "./floorActions";

const reset = () => {
  return {type: actionTypes.reset}
}

const setDescription = description => {
  return {
    type: actionTypes.setDescription,
    data: description
  }
}

const setLength = length => {
  return {
    type: actionTypes.setLength,
    data: length
  }
}

const setWidth = width => {
  return {
    type: actionTypes.setWidth,
    data: width
  }
}

const setWallWidth = wallWidth => {
  return {
    type: actionTypes.setWallWidth,
    data: wallWidth
  }
}

const removeElementsOutsideLimits = () => (dispatch, getState) => {
  const {lid, intermediateCeiling, vault, floor} = getElementsOutsideLimits(getState().plan)

  if (lid) {
    lid.manholes.forEach(manhole => dispatch(lidActions.removeManhole(manhole.id)))
    lid.blockOuts.forEach(blockOut => dispatch(lidActions.removeBlockOut(blockOut.id)))
  }

  if (intermediateCeiling) {
    intermediateCeiling.blockOuts
      .forEach(blockOut => dispatch(intermediateCeilingActions.removeBlockOut(blockOut.id)))
    intermediateCeiling.circularBlockOuts
      .forEach(blockOut => dispatch(intermediateCeilingActions.removeCircularBlockOut(blockOut.id)))
  }

  if (vault) {
    vault.walls.forEach((wall, i) => {
      const {sleeves, blockOuts, cRails, groundings, drainages} = wall

      sleeves.forEach(sleeve => dispatch(vaultActions.removeSleeve(i, sleeve.id)))
      blockOuts.forEach(blockOut => dispatch(vaultActions.removeBlockOut(i, blockOut.id)))
      cRails.forEach(cRail => dispatch(vaultActions.removeCRail(i, cRail.id)))
      groundings.forEach(grounding => dispatch(vaultActions.removeGrounding(i, grounding.id)))
      drainages.forEach(drainage => dispatch(vaultActions.removeWallDrainage(i, drainage.id)))
    })
  }

  if (floor) {
    floor.drainages.forEach(drainage => dispatch(floorActions.removeFloorDrainage(drainage.id)))
  }
}

export const planActions = {
  reset,
  setDescription,
  setLength,
  setWidth,
  setWallWidth,
  removeElementsOutsideLimits
}
