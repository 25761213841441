import React from "react"

import {Arrow, Circle, Group, Line, Rect, Shape, Text} from "react-konva"

import {
  getBevelHeight, getHorizontalAnchorWidth,
  getLidSize,
  getSubFloorManholeMinOverlap, getVerticalAnchorWidth
} from "../../../domain/lid"
import {renderDashedLine, renderLine} from "../renderFunctions"
import {getClosuresIntersectionPositions, getClosureSizes, getTypeLabelText} from "../../../domain/manhole"
import {anthracite, concreteGray, FONT_SIZE} from "../../../../constants"
import {getLeftToLeft, getTopToTop} from "../../../domain/composition"
import {getVaultSize} from "../../../domain/vault"
import {
  hasSupplementaryCoverBack,
  hasSupplementaryCoverFront,
  hasSupplementaryCoverLeft,
  hasSupplementaryCoverRight
} from "../../../domain/subfloorLid"
import {renderGroupElements as renderBlockOutGroupElements} from "../../Element/blockOutRenderFactory";
import {renderGroupElements as renderCircularBlockOutGroupElements} from "../../Element/circularBlockOutRenderFactory";
import {renderMeasurements} from "../../Element/rectangularElementFunctions";
import {intermediateCeilingFunctions} from "../../../domain/intermediateCeiling";

const round = val => Math.round(100 * val) / 100
export const createLidGroundView = (plan, scale) => {
  // Lid
  const getLidTop = () => !!plan.intermediateCeiling
    ? (getTopToTop(getLidSize, [getLidSize, intermediateCeilingFunctions.getSize], plan)) * scale
    : plan.lid.type === 'subfloor' && !!plan.vault
      ? (getTopToTop(getLidSize, [getLidSize, getVaultSize], plan)) * scale
      : 0
  const getLidHeight = () => getLidSize(plan).width * scale
  const getLidBottom = () => getLidTop() + getLidHeight()
  const getLidLeft = () => !!plan.intermediateCeiling
    ? getLeftToLeft(getLidSize, [getLidSize, intermediateCeilingFunctions.getSize], plan) * scale
    : plan.lid.type === 'subfloor' && !!plan.vault
      ? getLeftToLeft(getLidSize, [getLidSize, getVaultSize], plan) * scale
      : 0
  const getLidWidth = () => getLidSize(plan).length * scale
  const getLidRight = () => getLidLeft() + getLidWidth()

  // Clear measure (vault)
  const getClearMeasureLeft = () => getLidLeft() - getLidSize(plan).x * scale
  const getClearMeasureLength = () => plan.length * scale
  const getClearMeasureRight = () => getClearMeasureLeft() + getClearMeasureLength()
  const getClearMeasureFront = () => getLidTop() + (getLidSize(plan).width + getLidSize(plan).y) * scale
  const getClearMeasureWidth = () => plan.width * scale
  const getClearMeasureBack = () => getClearMeasureFront() - getClearMeasureWidth()
  // Vault
  const getVaultLeft = () => round(getClearMeasureLeft() - plan.wallWidth * scale)
  const getVaultRight = () => round(getClearMeasureRight() + plan.wallWidth * scale)
  const getVaultFront = () => round(getClearMeasureFront() + plan.wallWidth * scale)
  const getVaultBack = () => round(getClearMeasureBack() - plan.wallWidth * scale)
  // Manhole
  const hasManhole = () => plan.lid && plan.lid.manholes.length === 1
  const getManhole = () => plan.lid.manholes[0]
  const getRabbetRealWidth = () => {
    return !!getClosureSizes(getManhole().type) && !!getClosureSizes(getManhole().type).rabbetWidth
      ? getClosureSizes(getManhole().type).rabbetWidth
      : 0
  }
  const getRabbetWidth = () => getRabbetRealWidth() * scale
  const translatedManholeFunctions = () => {
    const getManholeTranslationX = () => {
      return getManhole().orientation === 'vertical'
        ? getClearMeasureLeft() + getManhole().x * scale
        : getClearMeasureLeft() + (getManhole().x + getManhole().width + getRabbetRealWidth()) * scale
    }
    const getManholeTranslationY = () => {
      return getManhole().orientation === 'vertical'
        ? getClearMeasureFront() - (getManhole().y + getManhole().height + getRabbetRealWidth()) * scale
        : getClearMeasureFront() - (getManhole().y + getManhole().height) * scale
    }
    const getManholeRotation = () => getManhole().orientation === 'horizontal' ? 90 : 0

    const getManholeLeft = () => 0
    const getManholeRealWidth = () => getManhole().orientation === 'vertical' ? getManhole().width : getManhole().height
    const getManholeWidth = () => getManholeRealWidth() * scale
    const getManholeRight = () => getManholeLeft() + getManholeWidth()
    const getManholeClearMeasureTop = () => getRabbetRealWidth() * scale
    const getManholeClearMeasureRealHeight = () => {
      return getManhole().orientation === 'vertical'
        ? getManhole().height
        : getManhole().width
    }
    const getManholeClearMeasureHeight = () => getManholeClearMeasureRealHeight() * scale
    const getManholeClearMeasureBottom = () => getManholeClearMeasureTop() + getManholeClearMeasureHeight()
    const getManholeTop = () => 0
    const getManholeRealHeight = () => getManholeClearMeasureRealHeight() + 2 * getRabbetRealWidth()
    const getManholeBottom = () => getManholeClearMeasureBottom() + getRabbetWidth()
    const getClosureRealWidth = () => getClosureSizes(getManhole().type).width
    const getClosureWidth = () => getClosureRealWidth() * scale
    const getClosureRealHeight = () => (getClosureSizes(getManhole().type).height + 2 * getRabbetRealWidth())
    const getClosureHeight = () => getClosureRealHeight() * scale
    const getVerticalClosureIntersectionsX = () => {
      return getClosuresIntersectionPositions(getManholeWidth(), getClosureSizes(getManhole().type).width * scale)
    }
    const getHorizontalClosureIntersectionsY = () => {
      return getClosuresIntersectionPositions(getManholeRealHeight(), getClosureRealHeight())
        .map(p => p * scale)
    }
    const renderHorizontalClosureIntersections = () => {
      return getHorizontalClosureIntersectionsY().map((p, i) =>
        renderLine(
          [getManholeLeft(), p, getManholeRight(), p],
          'vertical_divider_' + i
        )
      )
    }
    const renderVerticalClosureIntersections = () => {
      return getVerticalClosureIntersectionsX()
        .map((p, i) => renderLine(
          [p, getManholeTop(), p, getManholeBottom()],
          'horizontal_divider_' + i
        ))
    }

    return {
      getRabbetWidth,
      getManholeTranslationX,
      getManholeTranslationY,
      getManholeRotation,
      getManholeLeft,
      getManholeRealWidth,
      getManholeWidth,
      getManholeRight,
      getManholeClearMeasureTop,
      getManholeClearMeasureBottom,
      getManholeTop,
      getManholeBottom,
      getClosureWidth,
      getClosureHeight,
      getVerticalClosureIntersectionsX,
      getHorizontalClosureIntersectionsY,
      renderVerticalClosureIntersections,
      renderHorizontalClosureIntersections
    }
  }
  const renderVaultOutlineBehindRect = (rect, renderWithinRectOnly = false) => {
    const getRectLeft = () => rect.x
    const getRectWidth = () => rect.width
    const getRectRight = () => getRectLeft() + getRectWidth()
    const getRectTop = () => rect.y
    const getRectHeight = () => rect.height
    const getRectBottom = () => getRectTop() + getRectHeight()

    const renderLeft = () => {
      return !!rect && getRectLeft() < getVaultLeft()
        ? <React.Fragment>
          {
            getRectTop() > getVaultBack() &&
            !renderWithinRectOnly &&
            renderLine([getVaultLeft(), getVaultBack(), getVaultLeft(), getRectTop()])
          }
          {
            renderDashedLine([
              getVaultLeft(), Math.max(getRectTop(), getVaultBack()),
              getVaultLeft(), Math.min(getRectBottom(), getVaultFront())
            ])
          }
          {
            getRectBottom() < getVaultFront() &&
            !renderWithinRectOnly &&
            renderLine([getVaultLeft(), getRectBottom(), getVaultLeft(), getVaultFront()])
          }
        </React.Fragment>
        : renderWithinRectOnly ? null : renderLine([getVaultLeft(), getVaultFront(), getVaultLeft(), getVaultBack()])
    }
    const renderRight = () => {
      return !!rect && getRectRight() > getVaultRight()
        ? <React.Fragment>
          {
            getRectTop() > getVaultBack() &&
            !renderWithinRectOnly &&
            renderLine([getVaultRight(), getVaultBack(), getVaultRight(), getRectTop()])
          }
          {
            renderDashedLine([
              getVaultRight(), Math.max(getRectTop(), getVaultBack()),
              getVaultRight(), Math.min(getRectBottom(), getVaultFront())
            ])
          }
          {
            getRectBottom() < getVaultFront() &&
            !renderWithinRectOnly &&
            renderLine([getVaultRight(), getRectBottom(), getVaultRight(), getVaultFront()])
          }
        </React.Fragment>
        : renderWithinRectOnly ? null : renderLine([getVaultRight(), getVaultFront(), getVaultRight(), getVaultBack()])
    }
    const renderFront = () => {
      return !!rect && getRectBottom() > getVaultFront()
        ? <React.Fragment>
          {
            getRectLeft() > getVaultLeft() &&
            !renderWithinRectOnly &&
            renderLine([getVaultLeft(), getVaultFront(), getRectLeft(), getVaultFront()])
          }
          {
            renderDashedLine([
              Math.max(getRectLeft(), getVaultLeft()), getVaultFront(),
              Math.min(getRectRight(), getVaultRight()), getVaultFront()
            ])
          }
          {
            getRectRight() < getVaultRight() &&
            !renderWithinRectOnly &&
            renderLine([getRectRight(), getVaultFront(), getVaultRight(), getVaultFront()])
          }
        </React.Fragment>
        : renderWithinRectOnly ? null : renderLine([getVaultLeft(), getVaultFront(), getVaultRight(), getVaultFront()])
    }
    const renderBack = () => {
      return !!rect && getRectTop() < getVaultBack()
        ? <React.Fragment>
          {
            getRectLeft() > getVaultLeft() &&
            !renderWithinRectOnly &&
            renderLine([getVaultLeft(), getVaultBack(), getRectLeft(), getVaultBack()])
          }
          {
            renderDashedLine([
              Math.max(getRectLeft(), getVaultLeft()), getVaultBack(),
              Math.min(getRectRight(), getVaultRight()), getVaultBack()
            ])
          }
          {
            getRectRight() < getVaultRight() &&
            !renderWithinRectOnly &&
            renderLine([getRectRight(), getVaultBack(), getVaultRight(), getVaultBack()])
          }
        </React.Fragment>
        : renderWithinRectOnly ? null : renderLine([getVaultLeft(), getVaultBack(), getVaultRight(), getVaultBack()])
    }

    return <React.Fragment>
      {renderLeft()}
      {renderRight()}
      {renderFront()}
      {renderBack()}
    </React.Fragment>
  }
  const renderLidOutline = () => {
    return renderLine([
      getLidLeft(), getLidTop(),
      getLidLeft(), getLidBottom(),
      getLidRight(), getLidBottom(),
      getLidRight(), getLidTop(),
      getLidLeft(), getLidTop()
    ])
  }

  const renderBlockOuts = () => {
    return plan.lid.blockOuts.map((b, i) => {
      const getX = () => b.x * scale
      const getY = () => getClearMeasureWidth() - (b.y + b.height) * scale
      const getWidth = () => b.width * scale
      const getHeight = () => b.height * scale
      const getScreenRect = () => ({x: getX(), y: getY(), width: getWidth(), height: getHeight()})

      return (
        <Group x={getX()} y={getY()} key={'block_out_' + i}>
          {renderBlockOutGroupElements(getScreenRect(), b, 'transparent')}
          {renderMeasurements(getScreenRect(), b, true)}
        </Group>
      )
    })
  }


  const renderCircularBlockOuts = () => {
    return plan.lid.circularBlockOuts.map((b, i) => {
      const getRadius = () => b.outerDiameter / 2 * scale

      return (
        <Group
          key={'circular_manhole_' + i}
          x={b.x * scale}
          y={getClearMeasureWidth() - b.y * scale}
        >
          <Circle
            radius={b.outerDiameter / 2 * scale}
            stroke={'black'}
            strokeWidth={1}
          />
          {renderCircularBlockOutGroupElements({radius: b.diameter / 2 * scale}, b, "transparent")}
          <Text
            x={-0.8 * getRadius() - 200}
            y={0.8 * getRadius()}
            width={200}
            height={FONT_SIZE}
            fontSize={FONT_SIZE}
            align="right"
            verticalAlign="top"
            text={b.x + "/" + b.y}
            fill="black"
          />
        </Group>
      )
    })
  }

  const renderTypePlates = () => {
    const renderTypePlate = () => {
      const getXDistance = () => 4.5 * scale
      const getYCenter = () => translatedManholeFunctions().getClosureHeight() / 2
      const getPlateWidth = () => 140
      const getPlateHeight = () => 2 * FONT_SIZE

      const renderOutline = () => renderLine([
        0, 0,
        0, getPlateHeight(),
        getPlateWidth(), getPlateHeight(),
        getPlateWidth(), 0,
        0, 0
      ])
      const renderText = () =>
        <Text
          fontSize={FONT_SIZE}
          width={getPlateWidth()}
          align={'center'}
          y={FONT_SIZE / 2 + 1}
          text={getTypeLabelText(getManhole().type)}
        />

      return (
        <Group x={getXDistance()} y={getYCenter() + getPlateWidth() / 2} rotation={-90}>
          {renderOutline()}
          {renderText()}
        </Group>
      )
    }

    return (
      [
        translatedManholeFunctions().getManholeLeft(),
        ...translatedManholeFunctions().getVerticalClosureIntersectionsX()
      ].map(x =>
        [
          translatedManholeFunctions().getManholeTop(),
          ...translatedManholeFunctions().getHorizontalClosureIntersectionsY()
        ].map(y =>
          <Group x={x} y={y} key={'keyhole_' + x + '_' + y}>
            {renderTypePlate()}
          </Group>
        )
      )
    )
  }
  const renderFillLabel = () => {
    const getLabelWidth = () => plan.lid.fill === 'partialfill' || plan.lid.fill === 'without' ? 230 : 100

    const getText =
      () => plan.lid.fill === 'partialfill' ? 'Pflästerung, Teilfüllung (Beton)'
        : plan.lid.fill === 'without' ? 'Pflästerung, ohne Betonfüllung'
          : plan.lid.fill === 'concretegray' ? 'Betongrau'
            : plan.lid.fill === 'anthracite' ? 'Anthrazit'
              : ''
    const getColor = () => plan.lid.fill === 'concretegray' ? concreteGray
      : plan.lid.fill === 'anthracite' ? anthracite
        : ''

    const renderIcon = () => {
      const getSideLength = () => 1.5 * FONT_SIZE
      const getTop = () => -getSideLength() / 2
      const getBottom = () => getSideLength() / 2
      const getLeft = () => 0
      const getRight = () => getLeft() + getSideLength()

      const renderSolidColorIcon = () =>
        <Rect
          y={getTop()}
          name="fill"
          width={getSideLength()}
          height={getSideLength()}
          fill={getColor()}
        />

      const renderPavingIcon = () => {
        const getLineCount = () => 3
        const getLineDistance = () => getSideLength() / (getLineCount() + 1)
        const getLinePosition = i => i * getLineDistance()
        const renderLine = (points, key) => <Line key={key} points={points} stroke={'black'} strokeWidth={0.3}/>

        return Array(3).fill(null).reduce(
          (total, _, i) =>
            [...total,
              renderLine(
                [getLeft() + getLinePosition(i + 1), getTop(), getLeft() + getLinePosition(i + 1), getBottom()],
                'horizontal_paving_' + i
              ),
              renderLine(
                [getLeft(), getTop() + getLinePosition(i + 1), getRight(), getTop() + getLinePosition(i + 1)],
                'vertical_paving_' + i
              ),
            ],
          []
        )
      }

      return plan.lid.fill === 'partialfill' || plan.lid.fill === 'without' ? renderPavingIcon() : renderSolidColorIcon()
    }
    const renderText = () => {
      return (
        <Text
          fontSize={FONT_SIZE}
          width={getLabelWidth()}
          align={'left'}
          x={2 * FONT_SIZE}
          y={-FONT_SIZE / 2 + 1}
          text={getText()}
        />
      )
    }

    return (
      <Group y={getLabelWidth() / 2} rotation={-90}>
        {renderIcon()}
        {renderText()}
      </Group>
    )
  }
  const renderDirections = () => {
    const rectToVertical = () => {
      return {
        width: getClosureSizes(getManhole().type).width * scale,
        height: (getClosureSizes(getManhole().type).height + 2 * getClosureSizes(getManhole().type).rabbetWidth) * scale
      }
    }
    const rotation = () => getManhole().orientation === 'vertical' ? 0 : 180
    const openingDirectionY = () => rectToVertical().height * 0.1
    const drivingDirectionY = () => rectToVertical().height * 0.15
    const labelWidth = () => 110
    const labelLeft = () => (rectToVertical().width - labelWidth()) / 2
    const labelRight = () => labelLeft() + labelWidth()

    const renderArrows = () => {
      const scale = () => {
        return (getManhole().orientation === 'vertical' && getManhole().openingDirection === 'right')
        || (getManhole().orientation === 'horizontal' && getManhole().openingDirection === 'up')
          ? 1
          : -1
      }
      const offsetX = () => {
        return getManhole().orientation === 'vertical'
          ? getManhole().openingDirection === 'right'
            ? 0
            : rectToVertical().width
          : getManhole().openingDirection === 'down'
            ? 0
            : rectToVertical().width
      }
      const offsetY = () => {
        return getManhole().orientation === 'vertical'
          ? 0
          : rectToVertical().height
      }
      const renderOpeningDirectionArrow = () => {
        const arrowLeft = () => labelRight() + 10
        const arrowRight = () => rectToVertical().width * 0.9

        return (
          <Arrow
            points={[arrowLeft(), openingDirectionY(), arrowRight(), openingDirectionY()]}
            strokeWidth={1}
            stroke={'black'}
            fill={"black"}
            pointerWidth={5}
          />
        )
      }
      const renderDrivingDirectionArrow = () => {
        const arrowLeft = () => rectToVertical().width * 0.1
        const arrowRight = () => labelLeft()

        return (
          <Arrow
            points={[arrowRight(), drivingDirectionY(), arrowLeft(), drivingDirectionY()]}
            strokeWidth={3}
            stroke={'black'}
            fill={"black"}
            pointerWidth={7}
          />
        )
      }

      return (
        <React.Fragment>
          <Group scaleX={scale()} rotation={rotation()} offsetX={offsetX()} offsetY={offsetY()} name={'fill'}>
            {renderOpeningDirectionArrow()}
            {renderDrivingDirectionArrow()}
          </Group>
        </React.Fragment>
      )
    }
    const renderLabels = () => {
      const offsetX = () => {
        return getManhole().orientation === 'vertical'
          ? 0
          : rectToVertical().width
      }
      const offsetY = () => {
        return getManhole().orientation === 'vertical'
          ? 0
          : rectToVertical().height
      }
      const renderOpeningDirectionLabel = () => {
        const labelTop = () => openingDirectionY() - FONT_SIZE / 2

        return (
          <Text
            name="fill"
            x={labelLeft()}
            y={labelTop()}
            width={labelWidth()}
            height={FONT_SIZE}
            fontSize={FONT_SIZE}
            align="center"
            verticalAlign="bottom"
            text={"Öffnungsrichtung"}
            fill="black"
          />
        )
      }
      const renderDrivingDirectionLabel = () => {
        const labelTop = () => drivingDirectionY() - FONT_SIZE / 2

        return (
          <Text
            name="fill"
            x={labelLeft()}
            y={labelTop()}
            width={labelWidth()}
            height={FONT_SIZE}
            fontSize={FONT_SIZE}
            align="center"
            verticalAlign="bottom"
            text={"Fahrtrichtung"}
            fill="black"
          />
        )
      }
      return (
        <Group rotation={rotation()} offsetX={offsetX()} offsetY={offsetY()} name={'fill'}>
          {renderOpeningDirectionLabel()}
          {renderDrivingDirectionLabel()}
        </Group>
      )
    }

    return (
      <React.Fragment>
        {renderArrows()}
        {renderLabels()}
      </React.Fragment>
    )
  }

  const renderA15B125D400E600 = () => {
    const renderManhole = () => {
      const renderManholeOutline = () => renderLine([
        translatedManholeFunctions().getManholeLeft(), translatedManholeFunctions().getManholeTop(),
        translatedManholeFunctions().getManholeLeft(), translatedManholeFunctions().getManholeBottom(),
        translatedManholeFunctions().getManholeRight(), translatedManholeFunctions().getManholeBottom(),
        translatedManholeFunctions().getManholeRight(), translatedManholeFunctions().getManholeTop(),
        translatedManholeFunctions().getManholeLeft(), translatedManholeFunctions().getManholeTop()
      ])
      const getGirderWidth = () => 18 * scale
      const renderRabbetLines = () => {
        return (
          <React.Fragment>
            {
              renderDashedLine([
                0,
                translatedManholeFunctions().getManholeClearMeasureTop(),
                translatedManholeFunctions().getManholeWidth(),
                translatedManholeFunctions().getManholeClearMeasureTop()
              ])
            }
            {
              renderDashedLine([
                  0,
                  translatedManholeFunctions().getManholeClearMeasureBottom(),
                  translatedManholeFunctions().getManholeWidth(),
                  translatedManholeFunctions().getManholeClearMeasureBottom()
                ]
              )}
          </React.Fragment>
        )
      }
      const renderA15Keyholes = () => {
        const getHorizontalKeyholeSpacing = () => 12.5 * scale
        const getVerticalKeyholeSpacing = () => 15 * scale

        const renderKeyhole = (x, y) => {
          const getLength = () => 3 * scale
          const getWidth = () => 1.5 * scale
          const getRightCenterX = () => (getLength() - getWidth()) / 2
          const getLeftCenterX = () => -getRightCenterX()
          const getRadius = () => getWidth() / 2

          return (
            <Group x={x} y={y}>
              <Shape
                sceneFunc={(context, shape) => {
                  context.beginPath()
                  context.arc(getLeftCenterX(), 0, getRadius(), Math.PI / 2, -Math.PI / 2, false)
                  context.arc(getRightCenterX(), 0, getRadius(), -Math.PI / 2, Math.PI / 2, false)
                  context.closePath()
                  context.fillStrokeShape(shape)
                }}
                stroke="black"
                strokeWidth={1}
              />
            </Group>
          )
        }

        return (
          [
            translatedManholeFunctions().getManholeLeft(),
            ...translatedManholeFunctions().getVerticalClosureIntersectionsX()
          ].map(x =>
            [
              translatedManholeFunctions().getManholeTop(),
              ...translatedManholeFunctions().getHorizontalClosureIntersectionsY()
            ].map(y =>
              <Group x={x} y={y} key={'keyhole_' + x + '_' + y}>
                {renderKeyhole(
                  getHorizontalKeyholeSpacing(),
                  getVerticalKeyholeSpacing()
                )}
                {renderKeyhole(
                  getHorizontalKeyholeSpacing(),
                  translatedManholeFunctions().getClosureHeight() - getVerticalKeyholeSpacing()
                )}
              </Group>
            )
          )
        )
      }
      const renderB125Girder = () => {
        return translatedManholeFunctions().getHorizontalClosureIntersectionsY()
          .map((p, i) => (
            <React.Fragment key={'girder_' + i}>
              {
                renderDashedLine(
                  [
                    translatedManholeFunctions().getManholeLeft(), p - getGirderWidth() / 2,
                    translatedManholeFunctions().getManholeRight(), p - getGirderWidth() / 2
                  ]
                )
              }
              {
                renderDashedLine(
                  [
                    translatedManholeFunctions().getManholeLeft(), p + getGirderWidth() / 2,
                    translatedManholeFunctions().getManholeRight(), p + getGirderWidth() / 2
                  ]
                )
              }
            </React.Fragment>
          ))
      }
      const renderB125Keyholes = () => {
        const getHorizontalKeyholeSpacing = () => 5 * scale
        const getVerticalKeyholeSpacing = () => 6 * scale

        const renderKeyhole = (x, y) => {
          const getKeyholeRadius = () => 2 * scale
          const getLineYDistance = () => 0.5 * scale
          const getLineXDistance = () => Math.sqrt(Math.pow(getKeyholeRadius(), 2) - Math.pow(getLineYDistance(), 2))

          return (
            <Group x={x} y={y}>
              <Circle radius={getKeyholeRadius()} stroke={'black'} strokeWidth={1}/>
              {renderLine([-getLineXDistance(), -getLineYDistance(), getLineXDistance(), -getLineYDistance()])}
              {renderLine([-getLineXDistance(), getLineYDistance(), getLineXDistance(), getLineYDistance()])}
            </Group>
          )
        }

        return (
          [
            translatedManholeFunctions().getManholeLeft(),
            ...translatedManholeFunctions().getVerticalClosureIntersectionsX()
          ].map(x =>
            [
              translatedManholeFunctions().getManholeTop(),
              ...translatedManholeFunctions().getHorizontalClosureIntersectionsY()
            ].map(y =>
              <Group x={x} y={y} key={'keyhole_' + x + '_' + y}>
                {renderKeyhole(
                  getHorizontalKeyholeSpacing(),
                  getVerticalKeyholeSpacing()
                )}
                {renderKeyhole(
                  translatedManholeFunctions().getClosureWidth() - getHorizontalKeyholeSpacing(),
                  getVerticalKeyholeSpacing()
                )}
                {renderKeyhole(
                  getHorizontalKeyholeSpacing(),
                  translatedManholeFunctions().getClosureHeight() - getVerticalKeyholeSpacing()
                )}
                {renderKeyhole(
                  translatedManholeFunctions().getClosureWidth() - getHorizontalKeyholeSpacing(),
                  translatedManholeFunctions().getClosureHeight() - getVerticalKeyholeSpacing()
                )}
              </Group>
            )
          )
        )
      }
      const renderB125ScrewHoles = () => {
        const renderScrewHole = (x, y) => {
          const getRadius = () => 1.5 * scale
          const getInnerRadius = () => 1 * scale

          return (
            <Group x={x} y={y}>
              <Circle radius={getRadius()} stroke={'black'} strokeWidth={1}/>
              <Circle radius={getInnerRadius()} stroke={'black'} strokeWidth={1}/>
            </Group>
          )
        }

        const renderRabbetScrewHoles = () => {
          const getYDistance = () => 1.75 * scale
          const getCenter = () => translatedManholeFunctions().getClosureWidth() / 2

          return (
            [
              translatedManholeFunctions().getManholeLeft(),
              ...translatedManholeFunctions().getVerticalClosureIntersectionsX()
            ].map((x, i) => (
              <React.Fragment key={'rabbet_screw_hole_' + i}>
                {renderScrewHole(x + getCenter(), translatedManholeFunctions().getManholeTop() + getYDistance())}
                {renderScrewHole(x + getCenter(), translatedManholeFunctions().getManholeBottom() - getYDistance())}
              </React.Fragment>
            ))
          )
        }

        const renderGirderScrewHoles = () => {
          const getYDistance = () => 4.5 * scale
          const getXCenter = () => translatedManholeFunctions().getClosureWidth() / 2

          return (
            [
              translatedManholeFunctions().getManholeLeft(),
              ...translatedManholeFunctions().getVerticalClosureIntersectionsX()
            ].map(x =>
              translatedManholeFunctions().getHorizontalClosureIntersectionsY().map(y => (
                <React.Fragment key={'girder_screw_hole_' + x + '_' + y}>
                  {renderScrewHole(x + getXCenter(), y + getYDistance())}
                  {renderScrewHole(x + getXCenter(), y - getYDistance())}
                </React.Fragment>
              ))
            )
          )
        }

        return (
          <React.Fragment>
            {renderRabbetScrewHoles()}
            {renderGirderScrewHoles()}
          </React.Fragment>
        )
      }
      const renderD400E600Keyholes = () => {
        const getKeyholeWidth = () => 10 * scale
        const getKeyholeHeight = () => 5 * scale

        const renderKeyhole = (x, y) => {
          const getInnerRectWidth = () => 3 * scale
          const getInnerRectHeight = () => 1.5 * scale
          const getInnerRectX = () => (getKeyholeWidth() - getInnerRectWidth()) / 2
          const getInnerRectY = () => (getKeyholeHeight() - getInnerRectHeight()) / 2

          return (
            <Group x={x} y={y}>
              <Rect width={getKeyholeWidth()} height={getKeyholeHeight()} stroke={'black'} strokeWidth={1}/>
              <Rect width={getInnerRectWidth()} height={getInnerRectHeight()} stroke={'black'} strokeWidth={1}
                    x={getInnerRectX()} y={getInnerRectY()}/>
            </Group>
          )
        }

        return (
          [
            translatedManholeFunctions().getManholeLeft(),
            ...translatedManholeFunctions().getVerticalClosureIntersectionsX()
          ].map(x =>
            [
              translatedManholeFunctions().getManholeTop(),
              ...translatedManholeFunctions().getHorizontalClosureIntersectionsY()
            ].map(y =>
              <Group x={x} y={y} key={'keyhole_' + x + '_' + y}>
                {renderKeyhole(0, 0)}
                {renderKeyhole(translatedManholeFunctions().getClosureWidth() - getKeyholeWidth(), 0)}
                {renderKeyhole(0, translatedManholeFunctions().getClosureHeight() - getKeyholeHeight())}
                {
                  renderKeyhole(
                    translatedManholeFunctions().getClosureWidth() - getKeyholeWidth(),
                    translatedManholeFunctions().getClosureHeight() - getKeyholeHeight()
                  )
                }
              </Group>
            )
          )
        )
      }
      const renderFillLabels = () => {
        return [
          translatedManholeFunctions().getManholeLeft(),
          ...translatedManholeFunctions().getVerticalClosureIntersectionsX()
        ].map(x =>
          [
            translatedManholeFunctions().getManholeTop(),
            ...translatedManholeFunctions().getHorizontalClosureIntersectionsY()
          ].map(y =>
            <Group
              x={x + 19 * scale}
              y={y + translatedManholeFunctions().getClosureHeight() / 2}
              key={'fill_label_' + x + '_' + y}
            >
              {renderFillLabel()}
            </Group>
          )
        )
      }

      return (
        <Group x={translatedManholeFunctions().getManholeTranslationX()}
               y={translatedManholeFunctions().getManholeTranslationY()}
               rotation={translatedManholeFunctions().getManholeRotation()}>
          {renderManholeOutline()}
          {renderRabbetLines()}
          {translatedManholeFunctions().renderHorizontalClosureIntersections()}
          {translatedManholeFunctions().renderVerticalClosureIntersections()}
          {plan.lid.type === 'A15' && renderA15Keyholes()}
          {plan.lid.type === 'B125' && renderB125Girder()}
          {plan.lid.type === 'B125' && renderB125Keyholes()}
          {plan.lid.type === 'B125' && renderB125ScrewHoles()}
          {(plan.lid.type === 'D400' || plan.lid.type === 'E600') && renderD400E600Keyholes()}
          {(plan.lid.type === 'D400' || plan.lid.type === 'E600') && renderDirections()}
          {renderTypePlates()}
          {renderFillLabels()}
        </Group>
      )
    }
    const renderNoCollarLid = () => {
      const getHorizontalFrameWidth = () => {
        return plan.lid.type === 'A15' ? 5.5 * scale
          : plan.lid.type === 'B125' ? 4 * scale
            : plan.lid.type === "D400" ? 7.1 * scale
              : plan.lid.type === "E600" ? 7.1 * scale
                : 0
      }
      const getVerticalFrameWidth = () => {
        return plan.lid.type === 'A15' ? 0.5 * scale
          : plan.lid.type === 'B125' ? 0.5 * scale
            : plan.lid.type === "D400" ? 5.6 * scale
              : plan.lid.type === "E600" ? 5.6 * scale
                : 0
      }
      const getFrameLeft = () => translatedManholeFunctions().getManholeLeft() - getHorizontalFrameWidth()
      const getFrameRight = () => translatedManholeFunctions().getManholeRight() + getHorizontalFrameWidth()
      const getFrameBack = () => translatedManholeFunctions().getManholeTop() - getVerticalFrameWidth()
      const getFrameFront = () => translatedManholeFunctions().getManholeBottom() + getVerticalFrameWidth()

      const renderFrameOutline = () => renderLine([
        getFrameLeft(), getFrameBack(),
        getFrameLeft(), getFrameFront(),
        getFrameRight(), getFrameFront(),
        getFrameRight(), getFrameBack(),
        getFrameLeft(), getFrameBack()
      ])
      const renderA15 = () => {
        const getFrameWallWidth = () => 0.5 * scale
        const getLeftHorizontalLinePosition = () => translatedManholeFunctions().getManholeLeft() - getFrameWallWidth()
        const getRightHorizontalLinePosition = () => translatedManholeFunctions().getManholeRight() + getFrameWallWidth()

        const renderVerticalLines = () => {
          return (
            <React.Fragment>
              {
                renderLine([
                  getLeftHorizontalLinePosition(), getFrameFront(),
                  getLeftHorizontalLinePosition(), getFrameBack()
                ])
              }
              {
                renderLine([
                  getRightHorizontalLinePosition(), getFrameFront(),
                  getRightHorizontalLinePosition(), getFrameBack()
                ])
              }
            </React.Fragment>
          )
        }
        const renderAnchors = () => {
          const getAnchorLength = () => 2 * scale
          const getAnchorWidth = () => 1.5 * scale
          const getManholeLength = () =>
            getManhole().orientation === 'vertical' ? getManhole().width : getManhole().height
          const getClosureWidth = () => getClosureSizes(getManhole().type).width
          const getClosuresCount = () => Math.round(getManholeLength() / getClosureWidth())
          const getAnchorXPositionCount = () => Math.ceil(getClosuresCount() / 2)
          const getAnchorXPositionsDistance = () => getManholeLength() / getAnchorXPositionCount() * scale
          const getAnchorXPositions = () => {
            return Array(getAnchorXPositionCount()).fill(null).map((curr, i) => {
              return (0.5 + i) * getAnchorXPositionsDistance() + translatedManholeFunctions().getManholeLeft()
            })
          }

          return getAnchorXPositions().map(
            (p, i) => {
              return (
                <React.Fragment key={'lid_ground_view_anchor_' + i}>
                  {
                    renderLine([
                      p, getFrameFront(),
                      p, getFrameFront() + getAnchorLength(),
                      p - getAnchorWidth(), getFrameFront() + getAnchorLength(),
                      p - getAnchorWidth(), getFrameFront()
                    ])
                  }
                  {
                    renderLine([
                      p, getFrameBack(),
                      p, getFrameBack() - getAnchorLength(),
                      p + getAnchorWidth(), getFrameBack() - getAnchorLength(),
                      p + getAnchorWidth(), getFrameBack()
                    ])
                  }
                </React.Fragment>
              )
            }
          )
        }

        return (
          <React.Fragment>
            {renderVerticalLines()}
            {renderAnchors()}
          </React.Fragment>
        )
      }
      const renderB125 = () => {
        const getFrameWallWidth = () => 0.5 * scale
        const getLeftHorizontalLinePosition = () => translatedManholeFunctions().getManholeLeft() - getFrameWallWidth()
        const getRightHorizontalLinePosition = () => translatedManholeFunctions().getManholeRight() + getFrameWallWidth()

        const renderVerticalLines = () => {
          return (
            <React.Fragment>
              {
                renderLine([
                  getLeftHorizontalLinePosition(), getFrameFront(),
                  getLeftHorizontalLinePosition(), getFrameBack()
                ])
              }
              {
                renderLine([
                  getRightHorizontalLinePosition(), getFrameFront(),
                  getRightHorizontalLinePosition(), getFrameBack()
                ])
              }
            </React.Fragment>
          )
        }
        const renderAnchors = () => {
          const getManholeLength = () =>
            getManhole().orientation === 'vertical' ? getManhole().width : getManhole().height
          const getClosureWidth = () => getClosureSizes(getManhole().type).width
          const getClosuresCount = () => Math.round(getManholeLength() / getClosureWidth())
          const getAnchorXPositions = () => {
            return Array(getClosuresCount()).fill(null).map((curr, i) => {
              return (0.5 + i) * getClosureWidth() * scale + translatedManholeFunctions().getManholeLeft()
            })
          }

          return getAnchorXPositions().map(
            (p, i) => {
              return (
                <React.Fragment key={'anchor_' + i}>
                  {
                    renderLine([
                      p - 1 * scale, getFrameFront(),
                      p - 1 * scale, getFrameFront() + 3.2 * scale,
                      p, getFrameFront() + 3.2 * scale,
                      p, getFrameFront() + 2 * scale,
                      p + 1 * scale, getFrameFront() + 2 * scale,
                      p + 1 * scale, getFrameFront()
                    ])
                  }
                  {
                    renderLine([
                      p + 1 * scale, getFrameBack(),
                      p + 1 * scale, getFrameBack() - 3.2 * scale,
                      p, getFrameBack() - 3.2 * scale,
                      p, getFrameBack() - 2 * scale,
                      p - 1 * scale, getFrameBack() - 2 * scale,
                      p - 1 * scale, getFrameBack()
                    ])
                  }
                </React.Fragment>
              )
            }
          )
        }

        return (
          <React.Fragment>
            {renderVerticalLines()}
            {renderAnchors()}
          </React.Fragment>
        )
      }
      const renderD400E600 = () => {
        const renderSupportsAcrossClosures = () => {
          const getManholeLength = () =>
            getManhole().orientation === 'vertical' ? getManhole().width : getManhole().height
          const getClosureWidth = () => getClosureSizes(getManhole().type).width
          const getClosuresCount = () => Math.round(getManholeLength() / getClosureWidth())
          const getSupportXPositions = () => {
            return Array(getClosuresCount() + 1).fill(null).map((curr, i) => {
              return i * getClosureWidth() * scale + translatedManholeFunctions().getManholeLeft()
            })
          }

          return getSupportXPositions().map(
            (p, i) => {
              return (
                <React.Fragment key={'across_support_' + i}>
                  {
                    renderLine([
                      p, translatedManholeFunctions().getManholeTop(),
                      p, getFrameBack()
                    ])
                  }
                  {
                    renderLine([
                      p, translatedManholeFunctions().getManholeBottom(),
                      p, getFrameFront()
                    ])
                  }

                </React.Fragment>
              )
            }
          )
        }
        const renderSupportsAlongClosures = () => {
          const getManholeWidth = () =>
            getManhole().orientation === 'horizontal' ? getManhole().width : getManhole().height
          const getSupportDistance = () => getManholeWidth() / 5
          const getSupportYPositions = () => {
            return Array(6).fill(null).map((curr, i) => {
              return i * getSupportDistance() * scale + translatedManholeFunctions().getManholeClearMeasureTop()
            })
          }

          return getSupportYPositions().map(
            (p, i) => {
              return (
                <React.Fragment key={'across_support_' + i}>
                  {
                    renderLine([
                      translatedManholeFunctions().getManholeLeft(), p,
                      getFrameLeft(), p
                    ])
                  }
                  {
                    renderLine([
                      translatedManholeFunctions().getManholeRight(), p,
                      getFrameRight(), p
                    ])
                  }
                </React.Fragment>
              )
            }
          )
        }

        return (
          <React.Fragment>
            {renderSupportsAcrossClosures()}
            {renderSupportsAlongClosures()}
          </React.Fragment>
        )
      }

      return (
        <Group x={translatedManholeFunctions().getManholeTranslationX()}
               y={translatedManholeFunctions().getManholeTranslationY()}
               rotation={translatedManholeFunctions().getManholeRotation()}>
          {renderFrameOutline()}
          {plan.lid.type === 'A15' && renderA15()}
          {plan.lid.type === 'B125' && renderB125()}
          {(plan.lid.type === 'D400' || plan.lid.type === 'E600') && renderD400E600()}
        </Group>
      )
    }
    const renderBevelLines = () => {
      const getManhole = () => plan.lid.manholes[0]
      const getRabbetWidth = () => getClosureSizes(getManhole().type)
        ? getClosureSizes(getManhole().type).rabbetWidth * scale
        : 0
      const getManholeBottom = () =>
        getClearMeasureFront()
        - getManhole().y * scale
        + (getManhole().orientation === 'vertical' ? getRabbetWidth() : 0)
      const getManholeTop = () =>
        getManholeBottom()
        - getManhole().height * scale
        - 2 * (getManhole().orientation === 'vertical' ? getRabbetWidth() : 0)
      const getManholeLeft = () =>
        getClearMeasureLeft()
        + getManhole().x * scale
        - (getManhole().orientation === 'horizontal' ? getRabbetWidth() : 0)
      const getManholeRight = () =>
        getManholeLeft()
        + getManhole().width * scale
        + 2 * (getManhole().orientation === 'horizontal' ? getRabbetWidth() : 0)

      return (
        <React.Fragment>
          {renderLine([getLidLeft(), getLidTop(), getManholeLeft(), getManholeTop()])}
          {renderLine([getLidRight(), getLidTop(), getManholeRight(), getManholeTop()])}
          {renderLine([getLidRight(), getLidBottom(), getManholeRight(), getManholeBottom()])}
          {renderLine([getLidLeft(), getLidBottom(), getManholeLeft(), getManholeBottom()])}
        </React.Fragment>
      )
    }
    const renderIntermediateCeilingsClearMeasure = () => {
      const getManholeLeft = () => getClearMeasureLeft() + getManhole().x * scale
      const getManholeWidth = () => getManhole().width * scale
      const getManholeRight = () => getManholeLeft() + getManholeWidth()
      const getManholeBottom = () => getClearMeasureFront() - getManhole().y * scale
      const getManholeHeight = () => getManhole().height * scale
      const getManholeTop = () => getManholeBottom() - getManholeHeight()


      const getXWithinClearMeasure = x => {
        return Math.min(getClearMeasureRight(), Math.max(getClearMeasureLeft(), x))
      }
      const getYWithinClearMeasure = y => {
        return Math.max(getClearMeasureBack(), Math.min(getClearMeasureFront(), y))
      }

      return getManhole().orientation === 'vertical'
        ? <React.Fragment>
          {
            getManholeTop() < getClearMeasureBack() &&
            renderDashedLine([
              getXWithinClearMeasure(getManholeLeft()), getYWithinClearMeasure(getManholeTop()),
              getXWithinClearMeasure(getManholeRight()), getYWithinClearMeasure(getManholeTop())
            ])
          }
          {
            getManholeBottom() > getClearMeasureFront() &&
            renderDashedLine([
              getXWithinClearMeasure(getManholeLeft()), getYWithinClearMeasure(getManholeBottom()),
              getXWithinClearMeasure(getManholeRight()), getYWithinClearMeasure(getManholeBottom())
            ])
          }
          {
            getManholeLeft() < getClearMeasureLeft() &&
            renderDashedLine([
              getXWithinClearMeasure(getManholeLeft()), getYWithinClearMeasure(getManholeTop()),
              getXWithinClearMeasure(getManholeLeft()), getYWithinClearMeasure(getManholeBottom()),
            ])
          }
          {
            getManholeRight() > getClearMeasureRight() &&
            renderDashedLine([
              getXWithinClearMeasure(getManholeRight()), getYWithinClearMeasure(getManholeTop()),
              getXWithinClearMeasure(getManholeRight()), getYWithinClearMeasure(getManholeBottom()),
            ])
          }
        </React.Fragment>
        : <React.Fragment>
          {
            getManholeLeft() < getClearMeasureLeft() &&
            renderDashedLine([
              getXWithinClearMeasure(getManholeLeft()), getYWithinClearMeasure(getManholeTop()),
              getXWithinClearMeasure(getManholeLeft()), getYWithinClearMeasure(getManholeBottom()),
            ])
          }
          {
            getManholeRight() > getClearMeasureRight() &&
            renderDashedLine([
              getXWithinClearMeasure(getManholeRight()), getYWithinClearMeasure(getManholeTop()),
              getXWithinClearMeasure(getManholeRight()), getYWithinClearMeasure(getManholeBottom()),
            ])
          }
          {
            getManholeTop() < getClearMeasureBack() &&
            renderDashedLine([
              getXWithinClearMeasure(getManholeLeft()), getYWithinClearMeasure(getManholeTop()),
              getXWithinClearMeasure(getManholeRight()), getYWithinClearMeasure(getManholeTop()),
            ])
          }
          {
            getManholeBottom() > getClearMeasureFront() &&
            renderDashedLine([
              getXWithinClearMeasure(getManholeLeft()), getYWithinClearMeasure(getManholeBottom()),
              getXWithinClearMeasure(getManholeRight()), getYWithinClearMeasure(getManholeBottom()),
            ])
          }

        </React.Fragment>
    }

    return (
      <React.Fragment>
        {plan.lid.collar !== 'without' && renderLidOutline()}
        {plan.lid.collar === 'without' && renderNoCollarLid()}
        {
          !!plan.intermediateCeiling &&
          renderVaultOutlineBehindRect(
            {
              x: getLidLeft() + getHorizontalAnchorWidth(plan) * scale,
              y: getLidTop() + getVerticalAnchorWidth(plan) * scale,
              width: getLidWidth()+ getHorizontalAnchorWidth(plan) * scale * 2,
              height: getLidHeight() + getVerticalAnchorWidth(plan) * scale * 2
            }
          )
        }
        {hasManhole() && renderManhole()}
        {hasManhole() && getBevelHeight(plan) > 0 && renderBevelLines()}
        {
          plan.lid.collar === 'lifted' &&
          <Group x={(getClearMeasureLeft() - getLidLeft()) / 2} y={getLidHeight() / 2}>
            {renderFillLabel()}
          </Group>
        }
        <Group x={getClearMeasureLeft()} y={getClearMeasureBack()}>
          {renderBlockOuts()}
          {renderCircularBlockOuts()}
        </Group>
        {!!hasManhole() && !!plan.intermediateCeiling && renderIntermediateCeilingsClearMeasure()}
      </React.Fragment>
    )
  }

  const renderSubFloor = () => {
    const getManholeLeft = () => round(getClearMeasureLeft() + getManhole().x * scale)
    const getManholeWidth = () => round(getManhole().width * scale)
    const getManholeRight = () => round(getManholeLeft() + getManholeWidth())
    const getManholeBottom = () => round(getClearMeasureFront() - getManhole().y * scale)
    const getManholeHeight = () => round(getManhole().height * scale)
    const getManholeTop = () => round(getManholeBottom() - getManholeHeight())

    const renderVaultClearMeasure = () => renderDashedLine([
      getClearMeasureLeft(), getClearMeasureFront(),
      getClearMeasureLeft(), getClearMeasureBack(),
      getClearMeasureRight(), getClearMeasureBack(),
      getClearMeasureRight(), getClearMeasureFront(),
      getClearMeasureLeft(), getClearMeasureFront()
    ])
    const renderManhole = () => {
      const renderHoldingBrackets = () => {
        const getHorizontalSpacing = () => 13.5 * scale
        const getVerticalSpacing = () => 8.5 * scale
        const renderBracket = (x, y) => {
          const getDiameter = () => getClosureSizes(getManhole().type).bracketDiameter * scale
          const getWidth = () => getClosureSizes(getManhole().type).bracketWidth * scale // center to center distance
          const getRightCenterX = () => getWidth() / 2
          const getLeftCenterX = () => -getRightCenterX()
          const getRadius = () => getDiameter() / 2

          return (
            <Group x={x} y={y}>
              <Shape
                sceneFunc={(context, shape) => {
                  context.beginPath()
                  context.arc(getLeftCenterX(), 0, getRadius(), Math.PI / 2, -Math.PI / 2, false)
                  context.arc(getRightCenterX(), 0, getRadius(), -Math.PI / 2, Math.PI / 2, false)
                  context.closePath()
                  context.fillStrokeShape(shape)
                }}
                stroke="black"
                fill="black"
                strokeWidth={1}
              />
            </Group>
          )
        }

        return (
          [
            translatedManholeFunctions().getManholeLeft(),
            ...translatedManholeFunctions().getVerticalClosureIntersectionsX()
          ].map(x =>
            [
              translatedManholeFunctions().getManholeTop(),
              ...translatedManholeFunctions().getHorizontalClosureIntersectionsY()
            ].map(y =>
              <Group x={x} y={y} key={'bracket_' + x + '_' + y}>
                {renderBracket(getHorizontalSpacing(), getVerticalSpacing())}
                {
                  renderBracket(
                    getHorizontalSpacing(),
                    translatedManholeFunctions().getClosureHeight() - getVerticalSpacing()
                  )
                }
              </Group>
            )
          )
        )
      }
      const renderManholeOutline = () => renderLine([
        translatedManholeFunctions().getManholeLeft(), translatedManholeFunctions().getManholeTop(),
        translatedManholeFunctions().getManholeLeft(), translatedManholeFunctions().getManholeBottom(),
        translatedManholeFunctions().getManholeRight(), translatedManholeFunctions().getManholeBottom(),
        translatedManholeFunctions().getManholeRight(), translatedManholeFunctions().getManholeTop(),
        translatedManholeFunctions().getManholeLeft(), translatedManholeFunctions().getManholeTop()
      ])

      return (
        <Group x={translatedManholeFunctions().getManholeTranslationX()}
               y={translatedManholeFunctions().getManholeTranslationY()}
               rotation={translatedManholeFunctions().getManholeRotation()}>
          {renderManholeOutline()}
          {translatedManholeFunctions().renderVerticalClosureIntersections()}
          {renderHoldingBrackets()}
          {renderTypePlates()}
        </Group>
      )
    }
    const renderSupplementaryCovers = () => {
      const getManholeLeft = () => getClearMeasureLeft() + getManhole().x * scale
      const getManholeWidth = () => getManhole().width * scale
      const getManholeRight = () => getManholeLeft() + getManholeWidth()
      const getManholeBottom = () => getClearMeasureFront() - getManhole().y * scale
      const getManholeHeight = () => getManhole().height * scale
      const getManholeTop = () => getManholeBottom() - getManholeHeight()

      const renderLeftSupplementaryCover = () => renderLine(
        [
          getVaultLeft(), getVaultBack(),
          getManholeLeft(), getVaultBack(),
          getManholeLeft(), getVaultFront(),
          getVaultLeft(), getVaultFront(),
          getVaultLeft(), getVaultBack()
        ]
      )
      const renderRightSupplementaryCover = () => renderLine(
        [
          getManholeRight(), getVaultBack(),
          getVaultRight(), getVaultBack(),
          getVaultRight(), getVaultFront(),
          getManholeRight(), getVaultFront(),
          getManholeRight(), getVaultBack()
        ]
      )
      const renderBackSupplementaryCover = () => renderLine(
        [
          getVaultLeft(), getVaultBack(),
          getVaultRight(), getVaultBack(),
          getVaultRight(), getManholeTop(),
          getVaultLeft(), getManholeTop(),
          getVaultLeft(), getVaultBack()
        ]
      )
      const renderFrontSupplementaryCover = () => renderLine(
        [
          getVaultLeft(), getManholeBottom(),
          getVaultRight(), getManholeBottom(),
          getVaultRight(), getVaultFront(),
          getVaultLeft(), getVaultFront(),
          getVaultLeft(), getManholeBottom()
        ]
      )

      return (
        <React.Fragment>
          {hasSupplementaryCoverLeft(plan) && renderLeftSupplementaryCover()}
          {hasSupplementaryCoverRight(plan) && renderRightSupplementaryCover()}
          {hasSupplementaryCoverBack(plan) && renderBackSupplementaryCover()}
          {hasSupplementaryCoverFront(plan) && renderFrontSupplementaryCover()}
        </React.Fragment>
      )
    }
    const renderIntermediateCeilingsClearMeasure = () => {
      const getMinOverlap = () => getSubFloorManholeMinOverlap() * scale
      const getXWithinClearMeasure = x => {
        return Math.min(getClearMeasureRight(), Math.max(getClearMeasureLeft(), x))
      }
      const getYWithinClearMeasure = y => {
        return Math.max(getClearMeasureBack(), Math.min(getClearMeasureFront(), y))
      }
      return getManhole().orientation === 'vertical'
        ? <React.Fragment>
          {
            renderDashedLine([
              getXWithinClearMeasure(getManholeLeft()), getYWithinClearMeasure(getManholeTop() + getMinOverlap()),
              getXWithinClearMeasure(getManholeRight()), getYWithinClearMeasure(getManholeTop() + getMinOverlap())
            ])
          }
          {
            renderDashedLine([
              getXWithinClearMeasure(getManholeLeft()), getYWithinClearMeasure(getManholeBottom() - getMinOverlap()),
              getXWithinClearMeasure(getManholeRight()), getYWithinClearMeasure(getManholeBottom() - getMinOverlap())
            ])
          }
          {
            getManholeLeft() < getClearMeasureLeft() &&
            renderDashedLine([
              getXWithinClearMeasure(getManholeLeft()), getYWithinClearMeasure(getManholeTop() + getMinOverlap()),
              getXWithinClearMeasure(getManholeLeft()), getYWithinClearMeasure(getManholeBottom() - getMinOverlap()),
            ])
          }
          {
            getManholeRight() > getClearMeasureRight() &&
            renderDashedLine([
              getXWithinClearMeasure(getManholeRight()), getYWithinClearMeasure(getManholeTop() + getMinOverlap()),
              getXWithinClearMeasure(getManholeRight()), getYWithinClearMeasure(getManholeBottom() - getMinOverlap()),
            ])
          }
        </React.Fragment>
        : <React.Fragment>
          {
            renderDashedLine([
              getXWithinClearMeasure(getManholeLeft() + getMinOverlap()), getYWithinClearMeasure(getManholeTop()),
              getXWithinClearMeasure(getManholeLeft() + getMinOverlap()), getYWithinClearMeasure(getManholeBottom()),
            ])
          }
          {
            renderDashedLine([
              getXWithinClearMeasure(getManholeRight() - getMinOverlap()), getYWithinClearMeasure(getManholeTop()),
              getXWithinClearMeasure(getManholeRight() - getMinOverlap()), getYWithinClearMeasure(getManholeBottom()),
            ])
          }
          {
            getManholeTop() < getClearMeasureBack() &&
            renderDashedLine([
              getXWithinClearMeasure(getManholeLeft() + getMinOverlap()), getYWithinClearMeasure(getManholeTop()),
              getXWithinClearMeasure(getManholeRight() - getMinOverlap()), getYWithinClearMeasure(getManholeTop()),
            ])
          }
          {
            getManholeBottom() > getClearMeasureFront() &&
            renderDashedLine([
              getXWithinClearMeasure(getManholeLeft() + getMinOverlap()), getYWithinClearMeasure(getManholeBottom()),
              getXWithinClearMeasure(getManholeRight() - getMinOverlap()), getYWithinClearMeasure(getManholeBottom()),
            ])
          }

        </React.Fragment>
    }

    return (
      <React.Fragment>
        {!plan.intermediateCeiling && renderVaultClearMeasure()}
        {
          !!hasManhole()
            ? renderVaultOutlineBehindRect(
              {
                x: getManholeLeft(),
                y: getManholeTop(),
                width: getManholeWidth(),
                height: getManholeHeight()
              },
              !plan.vault && !plan.intermediateCeiling
            )
            : renderLidOutline()
        }
        {!!hasManhole() && renderManhole()}
        {!!hasManhole() && renderSupplementaryCovers()}
        {!!hasManhole() && !!plan.intermediateCeiling && renderIntermediateCeilingsClearMeasure()}
      </React.Fragment>
    )
  }

  return plan.lid.type === 'A15' || plan.lid.type === 'B125' || plan.lid.type === 'D400' || plan.lid.type === 'E600'
    ? renderA15B125D400E600()
    : plan.lid.type === 'subfloor'
      ? renderSubFloor()
      : null
}
