import React, {useState, useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useParams, useHistory} from "react-router-dom"
import {makeStyles} from "@mui/styles"
import {
  CircularProgress,
  TextField,
  Container,
  Divider,
  Grid,
  IconButton,
  Paper,
  Button,
  Typography,
  useTheme, Snackbar, Alert
} from "@mui/material"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"

import {grayBackgroundColor, orangeButtonStyle} from "../../constants"
import {primary_orange} from "../../constants"
import {isLoginRequired} from "../../helpers/authHelper"
import {authenticationActions, projectApiActions} from "../../actions"
import {AlertDialog} from "../AlertDialog"
import {Header} from "../Header"
import {mapUrl} from "../../helpers/mapsHelper"
import {CustomerPanel} from "../Plans/CustomerPanel"
import {PlanPanel} from "../Plans/PlanPanel"
import {BillingPanel} from "./BillingPanel";

const useStyles = theme => {
  return makeStyles({
    input: {
      height: 22,
      marginLeft: 0,
      marginRight: 5,
      fontSize: 13
    },
    divider: {
      height: 20,
      marginTop: 8
    },
    plan: {
      background: grayBackgroundColor,
      padding: theme.spacing(6)
    },
    panel: {
      background: "white",
      padding: theme.spacing(4)
    },
    orangeButton: orangeButtonStyle,
    clickableTypography: {
      cursor: "pointer"
    }
  })()
}

export const ProjectDetails = () => {
  const {id} = useParams()
  const history = useHistory()
  const isAdmin = useSelector(state => state.user.isAdmin)
  const apiRequestState = useSelector(state => state.apiRequestState)

  const theme = useTheme()
  const classes = useStyles(theme)
  const projectDetails = useSelector(state => state.projectDetails)
  const dispatch = useDispatch()
  const [readErrorAlertOpen, setReadErrorAlertOpen] = useState(true)
  const [setStateErrorAlertOpen, setSetStateErrorAlertOpen] = useState(true)
  const [snackBar, setSnackBar] = React.useState("");

  useEffect(
    () => dispatch(projectApiActions.readProjectDetails(id)),
    [dispatch, id]
  )

  const hasReadPlanError = () =>
    !!apiRequestState &&
    apiRequestState.request === "read_plan" &&
    apiRequestState.state === "failed"

  const hasSetStateError = () =>
    !!apiRequestState &&
    apiRequestState.request === "set_project_state" &&
    apiRequestState.state === "failed"

  const hasSetOwnerError = () =>
    !!apiRequestState &&
    apiRequestState.request === "set_plan_owner" &&
    apiRequestState.state === "failed"

  const contact = () => [projectDetails.project.delivery.person, projectDetails.project.delivery.phone]
    .filter(e => e !== null && e.length > 0)
    .join(", ")

  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBar("");
  }

  const DeliveryPanel = () => {
    return (
      <Paper cursor="pointer" square={true} className={classes.panel}>
        <Snackbar
          open={snackBar.length > 0}
          autoHideDuration={3000}
          anchorOrigin={{vertical: 'top', horizontal: 'right'}}
          onClose={handleSnackBarClose}
        >
          <Alert severity="info" sx={{width: '100%'}}>
            {snackBar}
          </Alert>
        </Snackbar>
        <Typography component="h1" variant="h5">
          Lieferadresse
        </Typography>
        <Divider style={{background: "black"}}/>
        <Typography
          mt={theme.spacing(4)}
          className={classes.clickableTypography}
          component="p"
          variant="body2"
          onClick={_ => {
            navigator.clipboard.writeText(
              `${projectDetails.project.delivery.street}\n${projectDetails.project.delivery.zip} ${projectDetails.project.delivery.city}`
            )
            setSnackBar("Adresse in Zwischenablage kopiert")
          }}
        >
          {projectDetails.project.delivery.street}
        </Typography>
        <Typography
          component="p"
          className={classes.clickableTypography}
          variant="body2"
          cursor='hand'
          onClick={_ => {
            navigator.clipboard.writeText(
              `${projectDetails.project.delivery.street}\n${projectDetails.project.delivery.zip} ${projectDetails.project.delivery.city}`
            )
            setSnackBar("Adresse in Zwischenablage kopiert")
          }}
        >
          {projectDetails.project.delivery.zip} {projectDetails.project.delivery.city}
        </Typography>
        <a
          target="_blank"
          rel="noreferrer"
          href={mapUrl(projectDetails.project.delivery)}
          style={{color: primary_orange, textDecoration: "none"}}
        >
          <Typography mt={theme.spacing(2)} component="p" variant="body2">
            Karte
          </Typography>
        </a>
        {!!projectDetails.project.delivery.latitude &&
          !!projectDetails.project.delivery.longitude && (
            <Typography component="p" variant="body2">
              {projectDetails.project.delivery.latitude}/
              {projectDetails.project.delivery.longitude}
            </Typography>
          )}

        <Grid cursor="pointer" container mt={theme.spacing(3)} direction="row">
          <Grid item xs={5}>
            <Typography component="p" variant="body2">
              Baustellennummer
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography component="p" variant="body2" fontWeight="800">
              {projectDetails.project.delivery.number}
            </Typography>
          </Grid>
        </Grid>

        <Grid container mt={theme.spacing(1)} direction="row">
          <Grid item xs={5}>
            <Typography component="p" variant="body2">
              Lieferart
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography component="p" variant="body2" fontWeight="800">
              {projectDetails.project.delivery.deliveryType === "delivery"
                ? "Lieferung"
                : projectDetails.project.delivery.deliveryType === "pickupFactory"
                  ? "Abholung ab Werk"
                  : ""}
            </Typography>
          </Grid>
        </Grid>

        <Grid container mt={theme.spacing(1)} direction="row">
          <Grid item xs={5}>
            <Typography component="p" variant="body2">
              Kontaktperson
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography
              component="p"
              className={classes.clickableTypography}
              variant="body2"
              fontWeight="800"
              onClick={_ => {
                navigator.clipboard.writeText(contact())
                setSnackBar("Kontaktperson in Zwischenablage kopiert")
              }}
            >
              {contact()}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    )
  }

  const RemarksPanel = () => {
    return (
      <Paper square={true} className={classes.panel}>
        <Typography component="h1" variant="h5">
          Bemerkungen
        </Typography>
        <Divider style={{background: "black"}}/>
        <Typography
          mt={theme.spacing(4)}
          className={classes.clickableTypography}
          component="p"
          variant="body2"
          onClick={_ => {
            navigator.clipboard.writeText(projectDetails.project.delivery.remarks)
            setSnackBar("Bemerkungen in Zwischenablage kopiert")
          }}
        >
          {projectDetails.project.delivery.remarks}
        </Typography>
      </Paper>
    )
  }

  const StatusPanel = () => {
    const dispatch = useDispatch()
    const authentication = useSelector(state => state.authentication)
    const [mmOfferNumber, setMmOfferNumber] = useState("")
    const [mmOrderNumber, setMmOrderNumber] = useState("")

    const showOfferCreatedButton = () =>
      projectDetails.project.requestStatus === "Offerte angefordert" && isAdmin
    const showOrderButton = () =>
      projectDetails.project.requestStatus === "Offerte angefordert" ||
      projectDetails.project.requestStatus === "Angebot erstellt"
    const showConfirmButton = () =>
      (projectDetails.project.requestStatus === "Offerte angefordert" ||
        projectDetails.project.requestStatus === "Angebot erstellt" ||
        projectDetails.project.requestStatus === "Bestellt") &&
      isAdmin
    const showShipButton = () =>
      (projectDetails.project.requestStatus === "Offerte angefordert" ||
        projectDetails.project.requestStatus === "Angebot erstellt" ||
        projectDetails.project.requestStatus === "Bestellt" ||
        projectDetails.project.requestStatus === "Auftrag bestätigt") &&
      isAdmin
    const showCancelButton = () =>
      projectDetails.project.requestStatus === "Offerte angefordert" ||
      projectDetails.project.requestStatus === "Angebot erstellt" ||
      projectDetails.project.requestStatus === "Bestellt" ||
      (projectDetails.project.requestStatus === "Auftrag bestätigt" && isAdmin)

    const setStateButtonOnClick = state => {
      if (isLoginRequired(authentication)) {
        history.push("/login?navigateTo=/projects")
        return
      }
      dispatch(authenticationActions.extendLoginIfRequired())
      dispatch(
        projectApiActions.setProjectState(
          projectDetails.project.id,
          state,
          mmOfferNumber,
          mmOrderNumber
        )
      )
    }

    return (
      <Paper square={true} className={classes.panel}>
        <Typography component="h1" variant="h5">
          Status
        </Typography>
        <Divider style={{background: "black"}}/>

        <Grid container mt={theme.spacing(4)} direction="row">
          <Grid item xs={5}>
            <Typography component="p" variant="body2">
              Wunschtermin
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography component="p" variant="body2" fontWeight="800">
              {new Date(
                projectDetails.project.delivery.desiredSchedule
              ).toLocaleDateString()}
              {!isAdmin && (
                <Typography display="block" component="p" variant="body2">
                  Definitiver Termin in der
                  <br/> Auftragsbestätigung
                </Typography>
              )}
            </Typography>
          </Grid>
        </Grid>

        <Grid container mt={theme.spacing(1)} direction="row">
          <Grid item xs={5}>
            <Typography component="p" variant="body2">
              Aktueller Status
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography component="p" variant="body2" fontWeight="800">
              {projectDetails.project.requestStatus}
            </Typography>
          </Grid>
        </Grid>

        {!!projectDetails.project.ourOfferNumber && (
          <Grid container mt={theme.spacing(1)} direction="row">
            <Grid item xs={5}>
              <Typography component="p" variant="body2">
                Offertnummer
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography component="p" variant="body2" fontWeight="800">
                {projectDetails.project.ourOfferNumber}
              </Typography>
            </Grid>
          </Grid>
        )}

        {!!projectDetails.project.ourOrderNumber && (
          <Grid container mt={theme.spacing(1)} direction="row">
            <Grid item xs={5}>
              <Typography component="p" variant="body2">
                Auftragsnummer
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography component="p" variant="body2" fontWeight="800">
                {projectDetails.project.ourOrderNumber}
              </Typography>
            </Grid>
          </Grid>
        )}

        {!isAdmin && (
          <Grid container direction="row">
            <Grid item xs={5}></Grid>
            <Grid item xs={7}>
              <Grid mt="1px" container direction="row" spacing={3}>
                {showOrderButton() && (
                  <Grid item>
                    <Button
                      className={classes.orangeButton}
                      variant="contained"
                      color="primary"
                      onClick={() => setStateButtonOnClick("ORDER")}
                    >
                      Bestellen
                    </Button>
                  </Grid>
                )}
                {showCancelButton() && (
                  <Grid item>
                    <Button
                      variant="outlined"
                      onClick={() => setStateButtonOnClick("CANCEL")}
                    >
                      Stornieren
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        )}

        {isAdmin && (
          <Grid item mt={theme.spacing(3)}>
            {showOfferCreatedButton() && (
              <Grid container direction="row">
                <Grid item xs={5}>
                  <TextField
                    InputProps={{className: classes.input}}
                    InputLabelProps={{shrink: true}}
                    size={"small"}
                    margin="dense"
                    variant="outlined"
                    placeholder={"Offertnummer"}
                    value={mmOfferNumber}
                    onChange={e => {
                      setMmOfferNumber(e.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={7}>
                  <Button
                    color="primary"
                    onClick={() => setStateButtonOnClick("OFFER_CREATE")}
                    disabled={mmOfferNumber.length < 1}
                  >
                    Angebot erstellt
                  </Button>
                </Grid>
              </Grid>
            )}

            {showOrderButton() && (
              <Grid container direction="row">
                <Grid item xs={5}></Grid>
                <Grid item xs={7}>
                  <Button
                    color="primary"
                    onClick={() => setStateButtonOnClick("ORDER")}
                  >
                    Bestellen
                  </Button>
                </Grid>
              </Grid>
            )}

            {showConfirmButton() && (
              <Grid container direction="row">
                <Grid item xs={5}>
                  <TextField
                    InputProps={{className: classes.input}}
                    InputLabelProps={{shrink: true}}
                    size={"small"}
                    margin="dense"
                    variant="outlined"
                    placeholder={"Auftragsnummer"}
                    value={mmOrderNumber}
                    onChange={e => {
                      setMmOrderNumber(e.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={7}>
                  <Button
                    color="primary"
                    onClick={() => setStateButtonOnClick("ORDER_CONFIRM")}
                    disabled={mmOrderNumber.length < 1}
                  >
                    Auftrag bestätigen
                  </Button>
                </Grid>
              </Grid>
            )}

            {showShipButton() && (
              <Grid container direction="row">
                <Grid item xs={5}></Grid>
                <Grid item xs={7}>
                  <Button
                    color="primary"
                    onClick={() => setStateButtonOnClick("COMPLETE")}
                  >
                    Liefern
                  </Button>
                </Grid>
              </Grid>
            )}

            {showCancelButton() && (
              <Grid container direction="row">
                <Grid item xs={5}></Grid>
                <Grid item xs={7}>
                  <Button
                    style={{color: "red"}}
                    onClick={() => setStateButtonOnClick("CANCEL")}
                  >
                    Stornieren
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </Paper>
    )
  }

  return (
    <Container
      maxWidth="lg"
      style={{paddingBottom: theme.spacing(4), minWidth: "1200px"}}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Header/>
        </Grid>

        <Grid item xs={12}>
          <Paper square={true} className={classes.plan}>
            {projectDetails.fetching ? (
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <CircularProgress style={{justifySelf: "center"}}/>
                </Grid>
              </Grid>
            ) : hasReadPlanError() ? (
              <AlertDialog
                open={readErrorAlertOpen}
                setOpen={setReadErrorAlertOpen}
                title="Als Vorlage öffnen ist fehlgeschlagen"
                onConfirm={() => {
                  setReadErrorAlertOpen(true)
                  dispatch(projectApiActions.readProjectDetails(id))
                }}
              >
                <Typography component="p" variant="body2">
                  Der Plan konnte leider nicht als Vorlage geöffnet werden.
                  Bitte versuchen Sie es in Kürze nochmals oder nehmen mit uns
                  Kontakt auf.
                </Typography>
              </AlertDialog>
            ) : hasSetStateError() ? (
              <AlertDialog
                open={setStateErrorAlertOpen}
                setOpen={setSetStateErrorAlertOpen}
                title="Status Ändern ist fehlgeschlagen"
                onConfirm={() => {
                  setSetStateErrorAlertOpen(true)
                  dispatch(projectApiActions.readProjectDetails(id))
                }}
              >
                <Typography component="p" variant="body2">
                  Der Status konnte leider nicht geändert werden. Bitte
                  versuchen Sie es in Kürze nochmals oder nehmen mit uns Kontakt
                  auf.
                </Typography>
              </AlertDialog>
            ) : hasSetOwnerError() ? (
              <AlertDialog
                open={setStateErrorAlertOpen}
                setOpen={setSetStateErrorAlertOpen}
                title="Eigentümer Ändern ist fehlgeschlagen"
                onConfirm={() => {
                  setSetStateErrorAlertOpen(true)
                  dispatch(projectApiActions.readProjectDetails(id))
                }}
              >
                <Typography component="p" variant="body2">
                  Der Eigentümer konnte leider nicht geändert werden. Bitte
                  versuchen Sie es in Kürze nochmals oder nehmen mit uns Kontakt
                  auf.
                </Typography>
              </AlertDialog>
            ) : projectDetails.error ? (
              projectDetails.error && (
                <Typography
                  component="p"
                  variant="body2"
                  color="error"
                  style={{
                    marginTop: theme.spacing(2)
                  }}
                >
                  {projectDetails.error.toString()}
                </Typography>
              )
            ) : (
              !!projectDetails &&
              !!projectDetails.project &&
              !!projectDetails.plan &&
              !!projectDetails.customer &&
              !!projectDetails.creator && (
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Grid
                      container
                      direction="row"
                      alignItems="flex-start"
                      spacing={2}
                    >
                      <Grid item>
                        <IconButton
                          style={{
                            background: "black",
                            width: "35px",
                            height: "35px"
                          }}
                          onClick={() => {
                            history.push("/projects")
                          }}
                        >
                          <ArrowBackIosIcon
                            style={{color: "white", marginLeft: "6px"}}
                          />
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <Typography mt="2px" component="h1" variant="h4">
                          Projekt {id}
                        </Typography>
                        <Typography
                          mt={theme.spacing(1)}
                          component="h1"
                          variant="h5"
                        >
                          {projectDetails.project.description}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container direction="row" spacing={3}>
                      <Grid item xs={6}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <CustomerPanel
                              customer={projectDetails.customer}
                              project={projectDetails.project}
                              emailSubject={`Projekt ${id}`}
                            ></CustomerPanel>
                          </Grid>
                          <Grid item xs={12}>
                            <BillingPanel
                              billing={projectDetails.project.billing}
                              emailSubject={`Projekt ${id}`}
                            ></BillingPanel>
                          </Grid>
                          <Grid item xs={12}>
                            <DeliveryPanel></DeliveryPanel>
                          </Grid>
                          <Grid item xs={12}>
                            <RemarksPanel></RemarksPanel>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={6}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <StatusPanel></StatusPanel>
                          </Grid>
                          <Grid item xs={12}>
                            <PlanPanel
                              plan={projectDetails.plan}
                              project={projectDetails.project}
                              creator={projectDetails.creator}
                              customer={projectDetails.customer}
                            ></PlanPanel>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )
            )}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}
