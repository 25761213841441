import { Button, ButtonGroup, Grid, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react"
import {useDispatch, useSelector} from "react-redux"
import {deliveryActions} from "../../../actions"
import {grayButtonStyle, orangeButtonStyle} from "../../../constants"

const useStyles = () => {
  return makeStyles({
    orangeButton: orangeButtonStyle,
    grayButton: grayButtonStyle,
  })()
}

const TitleGridItem = () => {
  return (
    <Grid item xs={12}>
      <Typography component="h1" variant="h4">
        Lieferart
      </Typography>
      < hr/>
    </Grid>
  )
}

const DeliveryTypeGridItem = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const deliveryType = useSelector(state => state.project.delivery.deliveryType)

  return (
    <Grid item xs={12}>
      <ButtonGroup
        fullWidth
        variant="contained"
      >
        <Button
          className={deliveryType === "delivery" ? classes.orangeButton : classes.grayButton}
          color={deliveryType === "delivery" ? "primary" : "inherit"}
          onClick={() => {
            dispatch(deliveryActions.setDeliveryType("delivery"))
          }}
        >
          Lieferung
        </Button>
        <Button
          className={deliveryType === "pickupFactory" ? classes.orangeButton : classes.grayButton}
          color={deliveryType === "pickupFactory" ? "primary" : "inherit"}
          onClick={() => {
            dispatch(deliveryActions.setDeliveryType("pickupFactory"))
          }}
        >
          Abholung ab Werk
        </Button>
      </ButtonGroup>
    </Grid>
  )
}

export const DeliveryTypeSection = () => {
  return (
    <Grid container spacing={4}>
      <TitleGridItem/>
      <DeliveryTypeGridItem/>
    </Grid>
  )
}
