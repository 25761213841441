import {Line} from "react-konva";
import React from "react";

const stroke = 'black'
const strokeWidth = 1
export const dash = [8, 5]
export const renderLine = (points, key) => !key
  ? <Line points={points} stroke={stroke} strokeWidth={strokeWidth}/>
  : <Line key={key} points={points} stroke={stroke} strokeWidth={strokeWidth}/>
export const renderDashedLine = (points, key) => !key
  ? <Line points={points} stroke={stroke} strokeWidth={strokeWidth} dash={dash}/>
  : <Line key={key} points={points} stroke={stroke} strokeWidth={strokeWidth} dash={dash}/>

