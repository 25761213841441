import { lidIsFullyCoveredByManhole } from "../containers/domain/lid"

export const validate = plan => {
  const validateD400E600 = () => {
    const hasOneManhole = () => plan.lid.manholes.length === 1
    const manhole = () => (hasOneManhole() ? plan.lid.manholes[0] : null)
    const isFullyCoveredByManhole = () => lidIsFullyCoveredByManhole(plan)
    const couldBeCoverredByMove = () =>
      manhole().orientation === "vertical"
        ? plan.length <= manhole().width && plan.width <= manhole().height
        : plan.width <= manhole().height && plan.length <= manhole().width
    const couldBeCoverredByReasonableResize = () =>
      manhole().orientation === "vertical"
        ? plan.length <= manhole().width + 52 && plan.width <= manhole().height + 10
        : plan.width <= manhole().height + 52 && plan.length <= manhole().width + 10

    return !hasOneManhole() || isFullyCoveredByManhole()
      ? []
      : couldBeCoverredByMove()
      ? [
          {
            message:
              "Optimierungsvorschlag: Damit eine Standardabdeckung verwendet werden kann, muss das Lichtmass des" +
              " Kabelschachtes komplett durch die Einstiegsöffnung abgedeckt sein." +
              "\nVerschieben Sie die Position der Einstiegsöffnung.",
            severity: "info"
          }
        ]
        : couldBeCoverredByReasonableResize()
        ?[
          {
            message:
              "Optimierungsvorschlag: Damit eine Standardabdeckung verwendet werden kann, muss das Lichtmass des" +
              " Kabelschachtes komplett durch die Einstiegsöffnung abgedeckt sein." +
              "\nÄndern Sie ggf. die Abmessung (Länge/Breite im Licht) oder die Grösse der Einstiegsöffnung" +
              " (allenfalls Wandüberlappung erforderlich).",
            severity: "info",
            resolveLocation: "/step/1",
            resolveLocationText: "Zu Schritt 1"
          }
        ]
        : []
  }

  return [
    ...(plan.lid && (plan.lid.type === "D400" || plan.lid.type === "E600")
      ? validateD400E600()
      : [])
  ]
}
