export const getQuantityError = (quantity, elementName, plan, project) => {
  return !Number.isFinite(quantity)
  ? "Menge muss numerisch und ganzzahlig sein"
  : quantity < 0 || quantity > 1000
      ? "Menge muss zwischen 0 und 1000 liegen"
      : !projectHasElements(elementName, plan, project) && quantity < 1
        ? "Menge von mindestens einem Element muss mindestens 1 sein"
        : ''
}
export const isQuantityValid = (quantity, hasOtherElements) => {
  return getQuantityError(quantity, hasOtherElements).length < 1
}

const projectHasElements = (except, plan, project) => {
  const hasVault = () => !!plan.vault && project.vaultQuantity
  const hasLid = () => !!plan.lid && project.lidQuantity
  const hasIntermediateCeiling = () => !!plan.intermediateCeiling && project.intermediateCeilingQuantity
  const hasFloor = () => !!plan.floor && project.floorQuantity

  return  except === 'vault' ? hasLid() || hasIntermediateCeiling() || hasFloor()
    : except === 'lid' ? hasVault() || hasIntermediateCeiling() || hasFloor()
      : except === 'intermediateCeiling' ? hasVault() || hasLid() || hasFloor()
        : except === 'floor' ? hasVault() || hasLid() || hasIntermediateCeiling()
          : hasVault() || hasLid() || hasIntermediateCeiling() || hasFloor()
}

export const getMinDeadline = () => {
  const date = new Date()

  date.setDate(date.getDate() + 1)
  return date.toISOString().slice(0, 10)
}

export const getDeadlineError = deadline => {
  return deadline < getMinDeadline()
    ? "Wunschtermin muss in der Zukunft liegen"
    : ''
}

export const isDeadlineValid = deadline => {
  return getDeadlineError(deadline).length < 1
}

export const isProjectValid = (plan, project) => {
  return projectHasElements('', plan, project)
    && isDeadlineValid(project.delivery.desiredSchedule)
    && project.delivery.description
    && project.delivery.street
    && project.delivery.zip
    && project.delivery.city
}
