import {planActionTypes as actionTypes} from "../constants"

const setIntermediateCeiling = () => {
  return {
    type: actionTypes.setIntermediateCeiling
  }
}

const unsetIntermediateCeiling = () => {
  return {
    type: actionTypes.unsetIntermediateCeiling
  }
}

const addBlockOut = blockOut => {
  return {
    type: actionTypes.addIntermediateCeilingBlockOut,
    data: blockOut
  }
}

const removeBlockOut = id => {
  return {
    type: actionTypes.removeIntermediateCeilingBlockOut,
    data: id
  }
}

const moveBlockOut = (id, x, y) => {
  return {
    type: actionTypes.moveIntermediateCeilingBlockOut,
    data: {id, x, y}
  }
}

const resizeBlockOut = (id, width, height) => {
  return {
    type: actionTypes.resizeIntermediateCeilingBlockOut,
    data: {id, width, height}
  }
}

const addCircularBlockOut = blockOut => {
  return {
    type: actionTypes.addCircularIntermediateCeilingBlockOut,
    data: blockOut
  }
}

const removeCircularBlockOut = id => {
  return {
    type: actionTypes.removeCircularIntermediateCeilingBlockOut,
    data: id
  }
}

const moveCircularBlockOut = (id, x, y) => {
  return {
    type: actionTypes.moveCircularIntermediateCeilingBlockOut,
    data: {id, x, y}
  }
}

const resizeCircularBlockOut = (id, diameter) => {
  return {
    type: actionTypes.resizeCircularIntermediateCeilingBlockOut,
    data: {id, diameter}
  }
}

export const intermediateCeilingActions = {
  setIntermediateCeiling,
  unsetIntermediateCeiling,
  addBlockOut,
  removeBlockOut,
  moveBlockOut,
  resizeBlockOut,
  addCircularBlockOut,
  removeCircularBlockOut,
  moveCircularBlockOut,
  resizeCircularBlockOut
}
