import React from "react"

import {Group} from "react-konva"

import {FONT_SIZE} from "../../../../constants"
import {idleDragPreviewOpacity} from "./toolbarConstants";
import {toRealRect, toScreenRect} from "../../realWorldTransform"
import {setOpacity} from "../../Element/elementFunctions"
import {hideCrossHairs, updateCrossHairs} from "../../Element/rectangularElementCrossHairs"
import {renderToolbarIcon, renderToolbarItemLabel} from "./toolbarFunctions";
import {renderDragStarterRect} from "./toolbarFunctions";

export const renderRectangularElementToolbarDragPreview = (
  toolbarItemRect,
  dragPreviewRect,
  element,
  configAreaLayout,
  onDragMove,
  onDragEnd,
  renderGroupElementsFunction
) => {
  const handleDragStart = e => {
    setOpacity(e.target, "dragging")
  }
  const handleDragMove = e => {
    const group = e.target
    const rect = {...dragPreviewRect, x: group.x(), y: group.y()}
    const dragMoveResult = onDragMove(rect)
    const stage = group.getStage()

    updateCrossHairs(configAreaLayout, stage, dragMoveResult.screenRect, dragMoveResult.element)
  }
  const handleDragEnd = e => {
    const group = e.target
    const rect = {...dragPreviewRect, x: group.x(), y: group.y()}

    onDragEnd(rect)
    hideCrossHairs(group.getStage())
    setOpacity(group)
    e.target.to({
      opacity: idleDragPreviewOpacity,
      duration: 0,
      x: e.target.attrs.origin.x,
      y: e.target.attrs.origin.y,
    })
  }

  return (
    <Group
      x={dragPreviewRect.x}
      y={dragPreviewRect.y}
      opacity={idleDragPreviewOpacity}
      onDragStart={handleDragStart}
      onDragMove={handleDragMove}
      onDragEnd={handleDragEnd}
      origin={{x: dragPreviewRect.x, y: dragPreviewRect.y}}
    >
      <Group listening={false}>
        {renderGroupElementsFunction(dragPreviewRect, element)}
      </Group>
      {
        renderDragStarterRect({
          x: toolbarItemRect.x - dragPreviewRect.x,
          y: toolbarItemRect.y - dragPreviewRect.y,
          width: toolbarItemRect.width,
          height: toolbarItemRect.height - FONT_SIZE
        })
      }
    </Group>
  )
}

export const renderRectangularElementToolbarItem = (
  key,
  element,
  x,
  y,
  width,
  height,
  iconScale,
  configAreaLayout,
  domainFunctions,
  renderGroupElementsFunction
) => {
  const toolbarItemRect = {x, y, width, height}
  const symbolWidth = element.width * iconScale
  const symbolHeight = element.height * iconScale
  const symbolX = x + (width - symbolWidth) * 0.5
  const symbolY = y + 2 * FONT_SIZE + 10
  const configAreaScreenZero = {x: configAreaLayout.screenX, y: configAreaLayout.screenY}

  const dragPreviewWidth = element.width * configAreaLayout.scale
  const dragPreviewHeight = element.height * configAreaLayout.scale
  const dragPreviewScreenRect = {x: symbolX, y: symbolY, width: dragPreviewWidth, height: dragPreviewHeight}

  const onDragMove = screenRect => {
    const realRect = {
      ...toRealRect(screenRect, configAreaLayout.transform, configAreaScreenZero),
      width: element.width,
      height: element.height
    }
    const addElement = domainFunctions.previewAdd({...element, x: realRect.x, y: realRect.y})
    const newScreenRect = toScreenRect(addElement, configAreaLayout.transform, configAreaScreenZero)

    return {
      screenRect: newScreenRect,
      element: addElement
    }
  }
  const onDragEnd = screenRect => {
    const realRect = {
      ...toRealRect(screenRect, configAreaLayout.transform, configAreaScreenZero),
      width: element.width,
      height: element.height
    }
    const addElement = domainFunctions.add({...element, ...realRect})
    const newScreenRect = toScreenRect(addElement, configAreaLayout.transform, configAreaScreenZero)

    return {
      screenRect: newScreenRect,
      element: addElement
    }
  }
  const iconScreenRect = {x: symbolX, y: symbolY, width: symbolWidth, height: symbolHeight}

  return (
    <React.Fragment key={key}>
      {renderToolbarItemLabel(x, y, width, element.colloquialism, element.name)}
      {renderToolbarIcon(iconScreenRect, element, renderGroupElementsFunction)}
      {renderRectangularElementToolbarDragPreview(
        toolbarItemRect,
        dragPreviewScreenRect,
        element,
        configAreaLayout,
        onDragMove,
        onDragEnd,
        renderGroupElementsFunction
      )}
    </React.Fragment>
  )
}
