import {getNestedTransform, getRealWorldTransform} from "../realWorldTransform";

const createPlanLayout = (clientSize, innerWidth, innerHeight, outerWidth, outerHeight, isToolbarVisible = true) => {
  const measurementsAreaScreenWidth = Math.min(clientSize.width - 4, 65)
  const toolbarScreenHeight = 125
  const partScreenWidth = (clientSize.width - measurementsAreaScreenWidth - 4)

  const scale = partScreenWidth / outerWidth
  const partScreenHeight = outerHeight * scale

  const partRealWorldTransform = getRealWorldTransform(outerWidth, outerHeight, partScreenWidth)
  const configAreaRealWorldTransform = getNestedTransform(partRealWorldTransform, innerWidth, innerHeight)

  const overall = {
    screenX: 2,
    screenY: isToolbarVisible ? 0 : 12,
    screenWidth: clientSize.width - 4,
    screenHeight: isToolbarVisible
      ? toolbarScreenHeight + measurementsAreaScreenWidth + partScreenHeight + 40
      : measurementsAreaScreenWidth + partScreenHeight + 12
  }
  const toolbar = {
    screenX: overall.screenX + 5,
    screenY: overall.screenY,
    screenWidth: overall.screenWidth - 10,
    screenHeight: toolbarScreenHeight
  }
  // noinspection JSSuspiciousNameCombination
  const part = {
    screenX: overall.screenX + measurementsAreaScreenWidth,
    screenY: overall.screenY + (isToolbarVisible ? toolbar.screenHeight + 30 : 0),
    screenWidth: outerWidth * scale,
    screenHeight: outerHeight * scale,
    realWidth: outerWidth,
    realHeight: outerHeight,
    scale: scale
  }
  // noinspection JSSuspiciousNameCombination
  const configArea = {
    screenX: part.screenX + (outerWidth - innerWidth) / 2 * scale,
    screenY: part.screenY + (outerHeight - innerHeight) / 2 * scale,
    screenWidth: innerWidth * scale,
    screenHeight: innerHeight * scale,
    realWidth: innerWidth,
    realHeight: innerHeight,
    transform: configAreaRealWorldTransform,
    scale: scale
  }
  // noinspection JSSuspiciousNameCombination
  const horizontalMeasurements = {
    screenX: part.screenX,
    screenY: part.screenY + part.screenHeight,
    screenWidth: part.screenWidth,
    screenHeight: measurementsAreaScreenWidth,
    realWidth: outerWidth,
    scale: scale
  }
  // noinspection JSSuspiciousNameCombination
  const verticalMeasurements = {
    screenX: overall.screenX,
    screenY: part.screenY,
    screenWidth: measurementsAreaScreenWidth,
    screenHeight: part.screenHeight,
    realHeight: outerHeight,
    scale: scale
  }

  return {
    scale,
    configAreaRealWorldTransform,
    overall, toolbar, part, configArea, horizontalMeasurements, verticalMeasurements
  }
}

export const createElevationPlanLayout = (clientSize, plan, wallIndex, isToolbarVisible) => {
  const innerWidth = !wallIndex || wallIndex < 2 ? plan.length : plan.width
  const outerWidth = innerWidth + 2 * plan.wallWidth
  const height = !!plan.vault ? plan.vault.height : 0

  return createPlanLayout(clientSize, innerWidth, height, outerWidth, height, isToolbarVisible)
}

export const createGroundPlanLayout = (clientSize, plan) =>
  createPlanLayout(clientSize, plan.length, plan.width, plan.length + 2 * plan.wallWidth, plan.width + 2 * plan.wallWidth)
