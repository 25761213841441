import React from 'react'

import {
  Step,
  Stepper as MuiStepper,
  Grid,
  StepButton,
  StepLabel,
  StepConnector,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import {useRouteMatch, useHistory} from "react-router-dom"

import {grayBackgroundColor} from "../../constants"
import {useDispatch, useSelector} from "react-redux"
import {authenticationActions} from "../../actions"
import {StepIcon} from "./StepIcon"
import {
  CONFIGURATION_STEPS,
  getStepByRoute,
  getStepDescription,
  getStepIndex,
  isStepEnabled
} from "./configurationSteps"
import clsx from "clsx"

const Connector = withStyles({
  alternativeLabel: {
    top: 13,
  },
  line: {
    height: 2,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector)

const useCircleStepIconStyles = makeStyles({
  root: {
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    backgroundImage:
      'linear-gradient( 136deg, rgba(120, 120, 120, 0.8) 0%, rgba(120, 120, 120, 0.9) 50%, rgba(120, 120, 120, 1) 100%)',
    zIndex: 1,
    color: '#fff',
    width: 30,
    height: 30,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, rgba(239, 139, 45, 0.8) 0%, rgba(239, 139, 45, 0.9) 50%, rgba(239, 139, 45, 1) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  disabled: {
    backgroundImage:
      'linear-gradient( 136deg, rgba(204, 204, 204, 0.8) 0%, rgba(204, 204, 204, 0.9) 50%, rgba(204, 204, 204, 1) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  },
})

function CircleStepIcon(props) {
  const classes = useCircleStepIconStyles()
  const {active, disabled} = props
  const icons = CONFIGURATION_STEPS.map(current => current.id)

  return (
    <div className={clsx(classes.root, {[classes.active]: active, [classes.disabled]: disabled})}>
      {icons[String(props.icon - 1)]}
    </div>
  )
}

const useStyles = makeStyles(
  ({
    stepper: {
      padding: '12px, 0px, 2px, 0px',
      background: grayBackgroundColor,
    }
  })
)

export const Stepper = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const plan = useSelector(state => state.plan)
  const match = useRouteMatch()
  const step = getStepByRoute(match.url)
  const stepIndex = !!step ? getStepIndex(step.id) : 0

  const disabledTextStyle = {
    color: 'gray'
  }
  const enabledTextStyle = {
    color: 'black'
  }

  return (
    <MuiStepper
      alternativeLabel
      nonLinear
      elevation={1}
      activeStep={stepIndex}
      connector={<Connector/>}
      style={{padding: '12px 0px 2px 0px'}}
      className={classes.stepper}
    >
      {CONFIGURATION_STEPS.map((current, index) => {
        const stepProps = {}
        const disabled = !isStepEnabled(plan, current.id)

        return (
          <Step key={current.description} {...stepProps} disabled={disabled}>
            <StepButton
              onClick={() => {
                history.push("/step/" + (current.id))
                dispatch(authenticationActions.extendLoginIfRequired())
              }}>
              <StepLabel
                StepIconComponent={
                  (props) => (<CircleStepIcon {...props} disabled={disabled}/>)
                }
              >
                <Grid style={{marginTop: '-8px'}} container direction="column">
                  <Grid item>
                    <Typography component="p" variant="body2" style={disabled ? disabledTextStyle : enabledTextStyle}>
                      {getStepDescription(plan, current.id)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <StepIcon scale={4} step={index} disabled={disabled} isSelected={index === stepIndex}/>
                  </Grid>
                </Grid>
              </StepLabel>
            </StepButton>
          </Step>
        )
      })}
    </MuiStepper>
  )
}
