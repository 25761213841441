import React from "react";
import {Rect, Text} from "react-konva";
import {FONT_SIZE, orangeBackgroundColorOpaque, primary_orange} from "../../../../constants";

const topSpacing = 20
const bottomSpacing = 10

export const getToolbarItemY = groupBoxY => groupBoxY + topSpacing + FONT_SIZE / 2

export const getToolbarItemHeight = groupBoxHeight => groupBoxHeight - topSpacing - bottomSpacing

export const renderGroupBox = (x, y, width, height, text, textWidth) => {

  return (
    <React.Fragment>
      <Rect
        x={x}
        y={y + FONT_SIZE / 2}
        width={width}
        height={height}
        stroke={primary_orange}
        strokeWidth={2}
        fill={orangeBackgroundColorOpaque}
        cornerRadius={4}
        shadowOffsetX={3}
        shadowOffsetY={5}
        shadowBlur={8}
        shadowOpacity={0.3}
      />
      <Rect
        x={x + 10}
        y={y}
        width={textWidth}
        height={FONT_SIZE}
        fill={orangeBackgroundColorOpaque}
        cornerRadius={4}
      />
      <Text
        x={x + 15}
        y={y}
        text={text}
        fontStyle={'bold'}
        fontSize={FONT_SIZE}
      />
    </React.Fragment>
  )
}
