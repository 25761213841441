import React, {useState} from 'react';
import {Typography, Grid, Button, TextField, CircularProgress, Container} from '@mui/material';
import { useTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import {useDispatch, useSelector} from "react-redux";

import {orangeButtonStyle, dialogStyles} from "../../../constants";
import {authenticationActions} from "../../../actions";
import {isValidPassword} from "../../../validation/passwordValidation";
import * as queryString from "query-string";

const useStyles = () => {
  return makeStyles({
    orangeButton: orangeButtonStyle,
    paragraph: dialogStyles.paragraphStyle
  })();
};

const renderSuccess = (theme, classes) => {
  return (
    <Container componemt="main" maxWidth="sm">
      <Typography component="h1" variant="h4">
        Kennwort geändert
      </Typography>
      <Typography className={classes.paragraph} component="p" variant="body2">
        Ihr neues Kennwort wurde erfolgreich gespeichert.
      </Typography>
      <Typography className={classes.paragraph} component="p" variant="body2">
        Sie können dieses Browserfenster (Register) jetzt schliessen.
      </Typography>
    </Container>
  );
};

export const ChangePassword = props => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();

  // local state
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  if (isFirstRender) {
    dispatch(authenticationActions.logout());
    setIsFirstRender(false);
  }
  // redux state
  const changed = useSelector(state => state.authentication.changed);
  const changing = useSelector(state => state.authentication.changing);
  const error = useSelector(state => state.authentication.error);

  if (changed) {
    return renderSuccess(theme, classes);
  }
  return (
    <React.Fragment>
      <Typography component="h1" variant="h4">
        Kennwort ändern
      </Typography>
      <Typography className={classes.paragraph} component="p" variant="body2">
        Geben Sie Ihr neues und schwierig zu erratende Kennwort ein. Dieses sollte mindestens folgende Richtlinien
        erfüllen:
      </Typography>
      <Typography className={classes.paragraph} component="p" variant="body2">
        &nbsp;&nbsp;- 8 Zeichen<br/>
        &nbsp;&nbsp;- 1 Zahl<br/>
        &nbsp;&nbsp;- 1 Grossbuchstabe<br/>
        &nbsp;&nbsp;- 1 Kleinbuchstabe<br/>
      </Typography>
      <form
        style={{marginTop: theme.spacing(6)}}
        name="form"
        noValidate
        onSubmit={e => {
          e.preventDefault();
          setSubmitted(true);
          const code = queryString.parse(props.location.search).code;

          if (isValidPassword(password) && password === passwordConfirmation) {
            dispatch(authenticationActions.changePassword(code, password));
          }
        }}
      >
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          name="password"
          label="Kennwort"
          type="password"
          id="password"
          autoComplete="new-password"
          value={password}
          error={submitted && !isValidPassword(password)}
          helperText={
            !submitted
              ? null
              : !password
              ? "Kennwort ist erfoderlich"
              : !isValidPassword(password)
                ? "Das Kennwort erfüllt die Sicherheitsrichtlinie nicht (min. 8 Zeichen lang, 1 Zahl, 1 Gross-/Kleinbuchstabe und 1 Sonderzeichen)"
                : null
          }
          onChange={e => setPassword(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          name="passwordConfirmation"
          label="Kennwort bestätigen"
          type="password"
          id="passwordConfirmation"
          autoComplete="new-password"
          value={passwordConfirmation}
          error={submitted && (!passwordConfirmation || passwordConfirmation !== password)}
          helperText={
            !submitted
              ? null
              : !passwordConfirmation
              ? "Kennwort ist erfoderlich"
              : passwordConfirmation !== password
                ? "Kennwörter sind nicht identisch"
                : null
          }
          onChange={e => setPasswordConfirmation(e.target.value)}
        />
        {error &&
        <Typography
          component="p"
          variant="caption"
          color="error"
          style={{
            marginTop: theme.spacing(2)
          }}
        >
          {error.toString()}
        </Typography>
        }
        {changing &&
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <CircularProgress/>
          </Grid>
        </Grid>
        }
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.orangeButton}
          style={{
            margin: theme.spacing(3, 0, 2),
          }}
        >
          Neues Kennwort speichern
        </Button>
      </form>
    </React.Fragment>
  );
};

