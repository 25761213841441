import React from "react"

import {Line, Rect, Shape} from "react-konva"

const renderRectangle = (screenRect, fillColor) => {
  return (
    <Rect
      name={"background"}
      width={screenRect.width}
      height={screenRect.height}
      fill={fillColor}
      stroke="black"
      strokeWidth={1}
      shadowColor="black"
      shadowBlur={8}
      shadowOpacity={0}
    />
  )
}

const renderStandardFill = screenRect => {
  const borderWidth = 0.03 * Math.min(screenRect.width, screenRect.height)
  const innerCornerX = 0.2 * screenRect.width
  const innerCornerY = 0.3 * screenRect.height

  return (
    <Shape
      name="fill"
      sceneFunc={(context, shape) => {
        context.beginPath()
        context.moveTo(borderWidth, borderWidth)
        context.lineTo(screenRect.width - borderWidth, borderWidth)
        context.lineTo(innerCornerX, innerCornerY)
        context.lineTo(borderWidth, screenRect.height - borderWidth)
        context.closePath()
        context.fillStrokeShape(shape)
      }}
      fill="black"
    />
  )
}

const renderSagexFill = screenRect => {
  const distance = 40
  let result = []
  let length = screenRect.width + screenRect.height - distance

  while (length > 0) {
    const from = {
      x: Math.max(length - screenRect.height, 0),
      y: Math.min(screenRect.height, length)
    }
    const to = {
      x: Math.min(screenRect.width, length),
      y: Math.max(length - screenRect.width, 0)
    }
    result.push((
      <Line
        key={"line_" + from.x + "_" + from.y}
        name="fill"
        points={[from.x, from.y, to.x, to.y]}
        stroke="gray"
        strokeWidth={1}
      />
    ))
    length -= distance
  }
  return result
}

const renderFill = (screenRect, blockOut) => {
  switch (blockOut.type) {
    case "standard":
      return renderStandardFill(screenRect)

    case "sagex":
      return renderSagexFill(screenRect)

    default:
      break
  }
}

export const renderGroupElements = (screenRect, blockOut, fillColor = 'white') => {
  return (
    <React.Fragment>
      {renderRectangle(screenRect, fillColor)}
      {renderFill(screenRect, blockOut)}
    </React.Fragment>
  )
}
