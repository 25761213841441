import {Grid, Typography} from "@mui/material"
import React from "react"
import {useDispatch, useSelector} from "react-redux"
import {ImageButton} from "./ImageButton"
import {lidActions} from "../../../actions"
import {
  BeveledCollarIcon,
  LiftedCollarIcon,
  PavingReadyCollarIcon,
  WithoutCollarIcon
} from "./CollarIcons";
import a15Image from '../../../image/buttonImage/a15.jpg';
import b125Image from '../../../image/buttonImage/b125.jpg';
import d400Image from '../../../image/buttonImage/d400.jpg';
import e600Image from '../../../image/buttonImage/e600.jpg';
import subFloorImage from '../../../image/buttonImage/subfloor.jpg';
import {AnthraciteIcon, ConcreteGrayIcon} from "./FillIcons";

const TitleGridItem = () => {
  return (
    <Grid item xs={12}>
      <Typography component="h1" variant="h4">
        Abdeckung
      </Typography>
      < hr/>
    </Grid>
  )
}

const TypeGridItem = () => {
  const dispatch = useDispatch()
  const lid = useSelector(state => state.plan.lid)

  return (
    <Grid item xs={12}>
      <Typography component="p" variant="body1" style={{marginBottom: '0.5em'}}>
        Wählen Sie die Deckelklasse (Druckprüfung nach EN124) aus:
      </Typography>
      <Grid container direction={'row'} justifyContent={'flex-start'} spacing={3}>
        <Grid item>
          <ImageButton
            title={'Klasse A15'}
            image={a15Image}
            checked={lid.type === "A15"}
            onClick={() => {
              dispatch(lidActions.setLidType("A15"))
            }}
          />
        </Grid>
        <Grid item>
          <ImageButton
            title={'Klasse B125'}
            image={b125Image}
            checked={lid.type === "B125"}
            onClick={() => {
              dispatch(lidActions.setLidType("B125"))
            }}
          />
        </Grid>
        <Grid item>
          <ImageButton
            title={'Klasse D400'}
            image={d400Image}
            checked={lid.type === "D400"}
            onClick={() => {
              dispatch(lidActions.setLidType("D400"))
            }}
          />
        </Grid>
        <Grid item>
          <ImageButton
            title={'Klasse E600'}
            image={e600Image}
            checked={lid.type === "E600"}
            onClick={() => {
              dispatch(lidActions.setLidType("E600"))
            }}
          />
        </Grid>
        <Grid item>
          <ImageButton
            title={'Unterflur'}
            image={subFloorImage}
            checked={lid.type === "subfloor"}
            onClick={() => {
              dispatch(lidActions.setLidType("subfloor"))
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

const CollarGridItem = () => {
  const dispatch = useDispatch()
  const lid = useSelector(state => state.plan.lid)
  const isVisible = () => !!lid && lid.type !== 'subfloor'
  const isWithoutCollarVisible = () =>
    lid.type === 'A15' || lid.type === 'B125' || lid.type === 'D400' || lid.type === 'E600'
  const isBeveledVisible = () => lid.type === 'A15' || lid.type === 'B125' || lid.type === 'D400' || lid.type === 'E600'
  const isLiftedVisible = () => lid.type === 'A15' || lid.type === 'B125' || lid.type === 'D400' || lid.type === 'E600'
  const isPavingReadyVisible = () => lid.type === 'B125' || lid.type === 'D400' || lid.type === 'E600'

  return isVisible()
    ? (
      <Grid item xs={12}>
        <Typography component="p" variant="body1" style={{marginBottom: '0.5em'}}>
          Wählen Sie den Betonsockel aus:
        </Typography>
        <Grid container direction={'row'} justifyContent={'flex-start'} spacing={3}>
          {
            isBeveledVisible() &&
            <Grid item>
              <ImageButton
                title={'Abgeschrägt'}
                checked={lid.collar === "beveled"}
                onClick={() => {
                  dispatch(lidActions.setLidCollar("beveled"))
                }}
              >
                <BeveledCollarIcon/>
              </ImageButton>
            </Grid>
          }
          {
            isLiftedVisible() &&
            <Grid item>
              <ImageButton
                title={'Hochgezogen'}
                checked={lid.collar === "lifted"}
                onClick={() => {
                  dispatch(lidActions.setLidCollar("lifted"))
                }}
              >
                <LiftedCollarIcon/>
              </ImageButton>
            </Grid>
          }
          {
            isWithoutCollarVisible() &&
            <Grid item>
              <ImageButton
                title={'Ohne'}
                checked={lid.collar === "without"}
                onClick={() => {
                  dispatch(lidActions.setLidCollar("without"))
                }}
              >
                <WithoutCollarIcon/>
              </ImageButton>
            </Grid>
          }
          {
            isPavingReadyVisible() &&
            <Grid item>
              <ImageButton
                title={'Für Pflästerung'}
                checked={lid.collar === "pavingready"}
                onClick={() => {
                  dispatch(lidActions.setLidCollar("pavingready"))
                }}
              >
                <PavingReadyCollarIcon/>
              </ImageButton>
            </Grid>
          }
        </Grid>
      </Grid>
    )
    : null
}

const FillGridItem = () => {
  const dispatch = useDispatch()
  const lid = useSelector(state => state.plan.lid)
  const isVisible = () => !!lid && lid.type !== 'subfloor'
  const isAnthraciteVisible = () =>
    (lid.type === 'B125' || lid.type === 'D400' || lid.type === 'E600')
    && (lid.collar === 'without' || lid.collar === 'beveled' || lid.collar === 'lifted')
  const isConcreteGrayVisible = () =>
    (lid.type === 'A15' || lid.type === 'B125' || lid.type === 'D400' || lid.type === 'E600')
    && (lid.collar === 'without' || lid.collar === 'beveled' || lid.collar === 'lifted')
  const isPartialFillVisible = () => lid.type === 'B125' && lid.collar === 'pavingready'
  const isNoFillVisible = () => (lid.type === 'D400' || lid.type === 'E600') && lid.collar === 'pavingready'

  return isVisible()
    ? (
      <Grid item xs={12}>
        <Typography component="p" variant="body1" style={{marginBottom: '0.5em'}}>
          Wählen Sie die Deckelfarbe/-füllung aus:
        </Typography>
        <Grid container direction={'row'} justifyContent={'flex-start'} spacing={3}>
          {
            isAnthraciteVisible() &&
            <Grid item>
              <ImageButton
                title={'Anthrazit'}
                checked={lid.fill === "anthracite"}
                onClick={() => {
                  dispatch(lidActions.setLidFill("anthracite"))
                }}
              >
                <AnthraciteIcon/>
              </ImageButton>
            </Grid>
          }
          {
            isConcreteGrayVisible() &&
            <Grid item>
              <ImageButton
                title={'Betongrau'}
                checked={lid.fill === "concretegray"}
                onClick={() => {
                  dispatch(lidActions.setLidFill("concretegray"))
                }}
              >
                <ConcreteGrayIcon/>
              </ImageButton>
            </Grid>
          }
          {
            isPartialFillVisible() &&
            <Grid item>
              <ImageButton
                title={'Teilfüllung'}
                checked={lid.fill === "partialfill"}
              />
            </Grid>
          }
          {
            isNoFillVisible() &&
            <Grid item>
              <ImageButton
                title={'Ohne Betonfüllung'}
                checked={lid.fill === "without"}
              />
            </Grid>
          }
        </Grid>
      </Grid>
    )
    : null
}

export const LidSection = () => {
  return (
    <Grid container spacing={3}>
      <TitleGridItem/>
      <TypeGridItem/>
      <CollarGridItem/>
      <FillGridItem/>
    </Grid>
  )
}
