import React from "react"

import {Button, Grid, Typography} from "@mui/material"
import {FloorGroundView} from "../../Plan/Verification/FloorGroundView";
import {useHistory} from "react-router-dom";


const TitleGridItem = () => {
  const history = useHistory()

  return (
    <Grid item xs={12}>
      <Grid container justifyContent="space-between" alignItems="flex-end">
        <Grid item>
          <Typography component="h1" variant="h4">
            {"Boden"}
          </Typography>
        </Grid>
        <Grid item>
          <Button color="primary" onClick={() => history.push("/step/4")}>
            bearbeiten
          </Button>
        </Grid>
      </Grid>
      < hr/>
    </Grid>
  )
}

const PlanGridItem = () => {
  return (
    <Grid item xs={12}>
      <FloorGroundView/>
    </Grid>
  )
}

export const FloorGroundViewSection = () => {
  return (
    <Grid container spacing={4}>
      <TitleGridItem/>
      <PlanGridItem/>
    </Grid>
  )
}

