import {manholeDefinitions} from "../../../domain/manhole";

export const getManholeDefinitions = lidType => {
  const getDefinitions = () => manholeDefinitions.filter(d => d.applicableToLidType === lidType)

  const toManholeDefinition = d => {
    return {
      colloquialism: d.type,
      name: d.height + ' x ' + d.width + ' cm',
      type: d.type,
      width: d.width,
      height: d.height
    }
  }
  const toVerticalAndHorizontal = m => {
    return [
      {
        ...m,
        orientation: "vertical",
      },
      {
        ...m,
        orientation: "horizontal",
        width: m.height,
        height: m.width
      }
    ]
  }
  const applyOpeningDirections = m => {
    return m.type !== 'GBDC' && m.type !== 'GBE'
      ? [m]
      : m.orientation === 'vertical'
        ? [
          {...m, openingDirection: 'left'},
          {...m, openingDirection: 'right'}
        ]
        : [
          {...m, openingDirection: 'up'},
          {...m, openingDirection: 'down'}
        ]
  }

  return getDefinitions()
    .map(d => toManholeDefinition(d))
    .reduce((acc, curr) => [...acc, ...toVerticalAndHorizontal(curr)], [])
    .reduce((acc, curr) => [...acc, ...applyOpeningDirections(curr)], [])
}

export const getBlockOutDefinitions = plan => {
  return !plan.intermediateCeiling && plan.lid.type !== 'subfloor' && plan.lid.collar === 'lifted'
    ? [{
    colloquialism: "variable",
    name: "Grösse",
    type: "standard",
    width: 50,
    height: 30
  }]
    : []
}

export const getCircularBlockOutDefinitions = plan => {
  return !plan.intermediateCeiling && plan.lid.type !== 'subfloor' && plan.lid.collar === 'lifted'
    ? [{
      colloquialism: "variable",
      name: "Grösse",
      outerDiameter: 30,
      diameter: 30,
      resizable: true
    }]
    : []
}

export const getLidElements = plan => {
  return [...getManholeDefinitions(plan.lid.type), ...getBlockOutDefinitions(plan)]
}

