import React from "react"
import {Arrow, Line, Text} from "react-konva";
import {FONT_SIZE} from "../../constants";

export const createDimensioning = (dimensions, position, screenSize, scale) => {
  const getColor = () => 'black'
  const getExtensionLineStrokeWidth = () => 0.5

  const getFirstRowToPartDistance = () => 0.1 * screenSize.height
  const getRowHeight = () => (screenSize.height - getFirstRowToPartDistance()) / dimensions.length
  const getArrowPositionWithinRow = () => 0.3 * getRowHeight() // from row bottom

  const getLabelToArrowDistance = () => 1
  const getExtensionLineOverlap = () => 0.1 * screenSize.height * (position === 'above' ? -1 : 1)

  const getArrowYs = () => position === 'above'
    ? [...Array(dimensions.length).keys()].map(i =>
      -getFirstRowToPartDistance()
      - i * getRowHeight()
      - getArrowPositionWithinRow()
    )
    : [...Array(dimensions.length).keys()].map(i =>
      getFirstRowToPartDistance()
      + i * getRowHeight()
      + getRowHeight() - getArrowPositionWithinRow()
    )
  const getExtensionLines = () => dimensions
    .reduce(
      (acc, row, rowIndex) => [
        ...acc,
        ...row.reduce((acc, dim) => [
            ...acc,
            {x: dim.from.x, y: dim.from.y, row: rowIndex},
            {x: dim.to.x, y: dim.to.y, row: rowIndex}
          ],
          []
        )
      ],
      []
    )
    .reduce(
      (acc, curr) => {
        const i = acc.findIndex(item => item.x === curr.x)

        if (i > -1) {
          acc[i] = {
            ...acc[i],
            y: Math.min(acc[i].y, curr.y),
            row: Math.max(acc[i].row, curr.row)
          }
          return acc
        } else {
          return [...acc, curr]
        }
      },
      []
    )
    .map(
      dim => ({
        x: dim.x * scale,
        y1: getArrowYs()[dim.row] + getExtensionLineOverlap(),
        y2: -dim.y * scale
      })
    )

  return (
    <React.Fragment>
      {
        dimensions.map((row, i) =>
          row.map((dim, j) => {
            const getFromX = () => dim.from.x * scale
            const getToX = () => dim.to.x * scale
            const getY = () => getArrowYs()[i]

            return (
              <Arrow
                key={'arrow_' + i + '_' + j}
                points={[getFromX(), getY(), getToX(), getY()]}
                strokeWidth={0.5}
                stroke={getColor()}
                fill={getColor()}
                lineCap="round"
                pointerAtBeginning={true}
                pointerWidth={5}
              />
            )
          })
        )
      }
      {
        dimensions.map((row, i) =>
          row.map((dim, j) => {
            const getWidth = () => 100
            const getLeft = () => (dim.from.x + dim.to.x) / 2 * scale - getWidth() / 2
            const getTop = () => getArrowYs()[i] - FONT_SIZE - getLabelToArrowDistance()
            const getText = () => dim.label

            return (
              <Text
                key={'label_' + i + '_' + j}
                x={getLeft()}
                y={getTop()}
                width={getWidth()}
                height={FONT_SIZE}
                align="center"
                verticalAlign="bottom"
                fontSize={FONT_SIZE}
                text={getText()}
                fill={getColor()}
              />
            )
          })
        )
      }
      {
        getExtensionLines().map((dim, i) => {
          return (
            <Line
              key={'extension_line_' + i}
              points={[dim.x, dim.y1, dim.x, dim.y2]}
              strokeWidth={getExtensionLineStrokeWidth()}
              stroke={getColor()}
              lineCap="round"
            />
          )
        })
      }
    </React.Fragment>
  )
}

export const createMeasurementStopChainFromMeasurements = (measurements, horizontalOffset, verticalOffset) => {
  const getY = () => !!verticalOffset ? verticalOffset : 0
  const getFistX = () => !!horizontalOffset ? horizontalOffset : 0

  return measurements.reduce((acc, curr, i) => {
      const getFromX = () => i > 0 ? acc[i - 1].to.x : getFistX()
      const getToX = () => getFromX() + curr

      return [
        ...acc,
        {
          from: {x: getFromX(), y: getY()},
          to: {x: getToX(), y: getY()},
          label: Math.round(curr * 100) / 100
        }
      ]
    },
    []
  )
}

export const createMeasurementStopChainFromPositions = (positions) => {
  const filterMaxAbsYPerX = positions => positions.filter((p, i, arr) => {
    const sameX = () => arr.filter(q => q.x === p.x)
    const maxY = positions => Math.max(...positions.map(p => Math.abs(p.y)))

    return Math.abs(p.y) === maxY(sameX())
  })
  const toDistinct = positions => {
    const map = new Map();

    return positions.filter(p => {
      if (map.has(p.x)) return false;
      map.set(p.x, true)
      return true
    })
  }
  const toDistinctSorted = positions => toDistinct(filterMaxAbsYPerX(positions)).sort((a, b) => a.x - b.x)

  return toDistinctSorted(positions).reduce((acc, curr, i, arr) => {
    const getFromX = () => arr[i - 1].x
    const getFromY = () => arr[i - 1].y
    const getToX = () => curr.x
    const getToY = () => curr.y
    const getLabel = () => Math.round((getToX() - getFromX()) * 100) / 100

    return i < 1
      ? []
      : [
        ...acc,
        {
          from: {x: getFromX(), y: getFromY()},
          to: {x: getToX(), y: getToY()},
          label: getLabel()
        }
      ]
  }, [])
}

export const toMeasurementStops = (measurements, extensionLineLimits = []) => {
  const toPositions = measurements => {
    return measurements
      .reduce(
        (acc, curr) => [...acc, curr.from, curr.to]
        , []
      )
  }
  const toDistinct = positions => {
    return Object.values(
      positions.reduce(
        (acc, curr) => {
          return !acc[curr.x]
            ? {...acc, [curr.x]: curr}
            : {...acc, [curr.x]: {...acc[curr.x], y: Math.max(acc[curr.x].y, curr.y)}}
        },
        {}
      ))
  }
  const toLimitedExtensionLines = (positions, limits) => {
    const getMeasurementsYAtX = x => limits.filter(m => m.from.x <= x && m.to.x >= x)
    const getMeasurementsY = measurement => Math.min(measurement.from.y, measurement.to.y)
    const getY = (x, y) => Math.min(y, ...getMeasurementsYAtX(x).map(m => getMeasurementsY(m)))

    return positions.map(p => ({...p, y: getY(p.x, p.y)}))
  }
  const toSorted = positions => positions.sort((a, b) => a.x - b.x)
  const toStops = positions => {
    return positions.reduce(
      (acc, curr, i, arr) => {
        const getFrom = () => arr[i - 1]
        const getTo = () => curr
        const getLabel = () => getTo().x - getFrom().x

        return i < 1
          ? []
          : [
            ...acc,
            {
              from: {x: getFrom().x, y: getFrom().y},
              to: {x: getTo().x, y: getTo().y},
              label: getLabel()
            }
          ]
      },
      []
    )
  }

  return toStops(
    toSorted(
      toLimitedExtensionLines(
        toDistinct(toPositions(measurements)),
        [...measurements, ...extensionLineLimits]
      )
    )
  )
}

export const toWithOverallMeasurement = innerStops => {
  const getOverallFrom = () => innerStops[0].from
  const getOverallTo = () => innerStops[innerStops.length - 1].to
  const getOverallLabel = () => Math.round( 100 * (getOverallTo().x - getOverallFrom().x)) / 100

  return innerStops.length < 2
    ? [innerStops]
    : [innerStops, [{
      from: getOverallFrom(),
      to: getOverallTo(),
      label: getOverallLabel()
    }]]
}

