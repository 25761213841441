export const planActionTypes = {
  reset: 'RESET_PLAN',
  setDescription: "SET_PLAN_DESCRIPTION",
  setLength: 'SET_LENGTH',
  setWidth: 'SET_WIDTH',
  setWallWidth: 'SET_WALL_WIDTH',
  setVault: 'SET_VAULT',
  unsetVault: "UNSET_VAULT",
  setLid: 'SET_LID',
  unsetLid: 'UNSET_LID',
  setIntermediateCeiling: 'SET_INTERMEDIATE_CEILING',
  unsetIntermediateCeiling: 'UNSET_INTERMEDIATE_CEILING',
  setFloor: 'SET_FLOOR',
  unsetFloor: 'UNSET_FLOOR',
  setVaultHeight: 'SET_VAULT_HEIGHT',
  addSleeve: 'ADD_SLEEVE',
  removeSleeve: 'REMOVE_SLEEVE',
  moveSleeve: 'MOVE_SLEEVE',
  addBlockOut: 'ADD_BLOCK_OUT',
  removeBlockOut: 'REMOVE_BLOCK_OUT',
  moveBlockOut: 'MOVE_BLOCK_OUT',
  resizeBlockOut: 'RESIZE_BLOCK_OUT',
  addCRail: 'ADD_C_RAIL',
  removeCRail: 'REMOVE_C_RAIL',
  moveCRail: 'MOVE_C_RAIL',
  resizeCRail: 'RESIZE_C_RAIL',
  addGrounding: 'ADD_GROUNDING',
  removeGrounding: 'REMOVE_GROUNDING',
  moveGrounding: 'MOVE_GROUNDING',
  addWallDrainage: 'ADD_WALL_DRAINAGE',
  removeWallDrainage: 'REMOVE_WALL_DRAINAGE',
  moveWallDrainage: 'MOVE_WALL_DRAINAGE',
  mirrorFromOppositeSide: 'MIRROR_FROM_OPPOSITE_SIDE',
  setLidType: 'SET_LID_TYPE',
  setLidCollar: 'SET_LID_COLLAR',
  setLidFill: 'SET_LID_FILL',
  addManhole: 'ADD_MANHOLE',
  removeManhole: 'REMOVE_MANHOLE',
  moveManhole: 'MOVE_MANHOLE',
  resizeManhole: 'RESIZE_MANHOLE',
  addLidBlockOut: 'ADD_LID_BLOCK_OUT',
  removeLidBlockOut: 'REMOVE_LID_BLOCK_OUT',
  moveLidBlockOut: 'MOVE_LID_BLOCK_OUT',
  resizeLidBlockOut: 'RESIZE_LID_BLOCK_OUT',
  addCircularLidBlockOut: 'ADD_CIRCULAR_LID_BLOCK_OUT',
  removeCircularLidBlockOut: 'REMOVE_CIRCULAR_LID_BLOCK_OUT',
  moveCircularLidBlockOut: 'MOVE_CIRCULAR_LID_BLOCK_OUT',
  resizeCircularLidBlockOut: 'RESIZE_CIRCULAR_LID_BLOCK_OUT',
  addIntermediateCeilingBlockOut: 'ADD_INTERMEDIATE_CEILING_BLOCK_OUT',
  removeIntermediateCeilingBlockOut: 'REMOVE_INTERMEDIATE_CEILING_BLOCK_OUT',
  moveIntermediateCeilingBlockOut: 'MOVE_INTERMEDIATE_CEILING_BLOCK_OUT',
  resizeIntermediateCeilingBlockOut: 'RESIZE_INTERMEDIATE_CEILING_BLOCK_OUT',
  addCircularIntermediateCeilingBlockOut: 'ADD_CIRCULAR_INTERMEDIATE_CEILING_BLOCK_OUT',
  removeCircularIntermediateCeilingBlockOut: 'REMOVE_CIRCULAR_INTERMEDIATE_CEILING_BLOCK_OUT',
  moveCircularIntermediateCeilingBlockOut: 'MOVE_CIRCULAR_INTERMEDIATE_CEILING_BLOCK_OUT',
  resizeCircularIntermediateCeilingBlockOut: 'RESIZE_CIRCULAR_INTERMEDIATE_CEILING_BLOCK_OUT',
  setFloorThickness: 'SET_FLOOR_THICKNESS',
  addFloorDrainage: 'ADD_FLOOR_DRAINAGE',
  removeFloorDrainage: 'REMOVE_FLOOR_DRAINAGE',
  moveFloorDrainage: 'MOVE_FLOOR_DRAINAGE',
}
