import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams, useHistory } from "react-router-dom"
import { makeStyles } from "@mui/styles"
import {
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Paper,
  Typography,
  useTheme
} from "@mui/material"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"

import { planApiActions } from "../../actions"
import { grayBackgroundColor, orangeButtonStyle } from "../../constants"
import { AlertDialog } from "../AlertDialog"
import { Header } from "../Header"
import { CustomerPanel } from "./CustomerPanel"
import { PlanPanel } from "./PlanPanel"

const useStyles = theme => {
  return makeStyles({
    input: {
      height: 22,
      marginLeft: 0,
      marginRight: 5,
      fontSize: 13
    },
    divider: {
      height: 20,
      marginTop: 8
    },
    plan: {
      background: grayBackgroundColor,
      padding: theme.spacing(6)
    },
    panel: {
      background: "white",
      padding: theme.spacing(4)
    },
    orangeButton: orangeButtonStyle
  })()
}

export const PlanDetails = () => {
  const { id } = useParams()
  const history = useHistory()
  const apiRequestState = useSelector(state => state.apiRequestState)

  const theme = useTheme()
  const classes = useStyles(theme)
  const planDetails = useSelector(state => state.planDetails)
  const dispatch = useDispatch()
  const [readErrorAlertOpen, setReadErrorAlertOpen] = useState(true)
  const [setStateErrorAlertOpen, setSetStateErrorAlertOpen] = useState(true)
  const [deleteErrorAlertOpen, setDeleteErrorAlertOpen] = useState(true)

  useEffect(() => dispatch(planApiActions.readPlanDetails(id)), [dispatch, id])

  const hasReadPlanError = () =>
    !!apiRequestState &&
    apiRequestState.request === "read_plan" &&
    apiRequestState.state === "failed"

  const hasDeletePlanError = () =>
    !!apiRequestState &&
    apiRequestState.request === "delete_plan" &&
    apiRequestState.state === "failed"

  if (
    !!apiRequestState &&
    apiRequestState.request === "delete_plan" &&
    apiRequestState.state === "success"
  ) {
    history.push("/plans")
  }

  const hasSetOwnerError = () =>
    !!apiRequestState &&
    apiRequestState.request === "set_plan_owner" &&
    apiRequestState.state === "failed"

  return (
    <Container
      maxWidth="lg"
      style={{ paddingBottom: theme.spacing(4), minWidth: "1200px" }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Header />
        </Grid>

        <Grid item xs={12}>
          <Paper square={true} className={classes.plan}>
            {planDetails.fetching ? (
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <CircularProgress style={{ justifySelf: "center" }} />
                </Grid>
              </Grid>
            ) : hasReadPlanError() ? (
              <AlertDialog
                open={readErrorAlertOpen}
                setOpen={setReadErrorAlertOpen}
                title="Als Vorlage öffnen ist fehlgeschlagen"
                onConfirm={() => {
                  setReadErrorAlertOpen(true)
                  dispatch(planApiActions.readPlanDetails(id))
                }}
              >
                <Typography component="p" variant="body2">
                  Der Plan konnte leider nicht als Vorlage geöffnet werden.
                  Bitte versuchen Sie es in Kürze nochmals oder nehmen mit uns
                  Kontakt auf.
                </Typography>
              </AlertDialog>
            ) : hasSetOwnerError() ? (
              <AlertDialog
                open={setStateErrorAlertOpen}
                setOpen={setSetStateErrorAlertOpen}
                title="Eigentümer Ändern ist fehlgeschlagen"
                onConfirm={() => {
                  setSetStateErrorAlertOpen(true)
                  dispatch(planApiActions.readPlanDetails(id))
                }}
              >
                <Typography component="p" variant="body2">
                  Der Eigentümer konnte leider nicht geändert werden. Bitte
                  versuchen Sie es in Kürze nochmals oder nehmen mit uns Kontakt
                  auf.
                </Typography>
              </AlertDialog>
            ) : hasDeletePlanError() ? (
              <AlertDialog
                open={deleteErrorAlertOpen}
                setOpen={setDeleteErrorAlertOpen}
                title="Plan löschen fehlgeschlagen"
                onConfirm={() => {
                  setDeleteErrorAlertOpen(true)
                  dispatch(planApiActions.readPlanDetails(id))
                }}
              >
                <Typography component="p" variant="body2">
                  Der Plan konnte leider nicht gelöscht werden. Bitte versuchen
                  Sie es in Kürze nochmals oder nehmen mit uns Kontakt auf.
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  style={{ marginTop: "1em" }}
                >
                  {apiRequestState.error}
                </Typography>
              </AlertDialog>
            ) : planDetails.error ? (
              planDetails.error && (
                <Typography
                  component="p"
                  variant="body2"
                  color="error"
                  style={{
                    marginTop: theme.spacing(2)
                  }}
                >
                  {planDetails.error.toString()}
                </Typography>
              )
            ) : (
              !!planDetails &&
              !!planDetails.plan &&
              !!planDetails.customer &&
              !!planDetails.creator && (
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Grid
                      container
                      direction="row"
                      alignItems="flex-start"
                      spacing={2}
                    >
                      <Grid item>
                        <IconButton
                          style={{
                            background: "black",
                            width: "35px",
                            height: "35px"
                          }}
                          onClick={() => {
                            history.push("/plans")
                          }}
                        >
                          <ArrowBackIosIcon
                            style={{ color: "white", marginLeft: "6px" }}
                          />
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <Typography mt="2px" component="h1" variant="h4">
                          Plan {id}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container direction="row" spacing={3}>
                      <Grid item xs={6}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <CustomerPanel
                              customer={planDetails.customer}
                              emailSubject={`Plan ${id}`}
                            ></CustomerPanel>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={6}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <PlanPanel
                              plan={planDetails.plan}
                              customer={planDetails.customer}
                              creator={planDetails.creator}
                            ></PlanPanel>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )
            )}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}
