import React from "react";

import {
  getBevelHeight,
  getLidSize,
  getLidTopToBevelTopDistance,
  hasMultiRowManholes
} from "../../../domain/lid";
import {getClosuresIntersectionPositions, getClosureSizes} from "../../../domain/manhole";
import {dash, renderDashedLine, renderLine} from "../renderFunctions";
import {Group, Shape} from "react-konva";
import {
  hasSupplementaryCoverBack, hasSupplementaryCoverFront,
  hasSupplementaryCoverLeft,
  hasSupplementaryCoverRight,
  hasSupplementaryCover as lidHasSupplementaryCover
} from "../../../domain/subfloorLid";

export const createLidElevationView = (plan, scale, side) => {
  const getLidTop = () => 0
  const getLidHeight = () => getLidSize(plan).height * scale
  const getLidBottom = () => getLidTop() + getLidHeight()
  const getLidLeft = () => 0
  const getLidWidth = () => {
    return side === 'left' || side === 'right'
      ? getLidSize(plan).width * scale
      : getLidSize(plan).length * scale
  }
  const getLidRight = () => getLidLeft() + getLidWidth()
  const getClearMeasureLeft = () => {
    return side === 'left' ? (getLidSize(plan).width - plan.width + getLidSize(plan).y) * scale
      : side === 'right' ? -getLidSize(plan).y * scale
        : side === 'front' ? -getLidSize(plan).x * scale
          : side === 'back' ? (getLidSize(plan).length - plan.length + getLidSize(plan).x) * scale
            : 0
  }
  const getClearMeasureRight = () => {
    return side === 'left' || side === 'right' ? getClearMeasureLeft() + plan.width * scale
      : side === 'front' || side === 'back' ? getClearMeasureLeft() + plan.length * scale
        : 0
  }
  const getVaultLeft = () => getClearMeasureLeft() - plan.wallWidth * scale
  const getVaultRight = () => getClearMeasureRight() + plan.wallWidth * scale
  const hasManhole = () => plan.lid.manholes.length === 1
  const getManhole = () => plan.lid.manholes[0]
  const getManholeClearMeasureLeft = () => {
    return getClearMeasureLeft()
      + (
        side === 'left' ? plan.width - getManhole().y - getManhole().height
          : side === 'right' ? getManhole().y
            : side === 'front' ? getManhole().x
              : side === 'back' ? plan.length - getManhole().x - getManhole().width
                : 0
      ) * scale
  }
  const getManholeClearMeasureRealWidth = () => {
    return side === 'front' || side === 'back'
      ? getManhole().width
      : getManhole().height
  }
  const getManholeClearMeasureWidth = () => getManholeClearMeasureRealWidth() * scale
  const getManholeClearMeasureRight = () => getManholeClearMeasureLeft() + getManholeClearMeasureWidth()
  const isClosuresShortSide = () => {
    return hasManhole()
      && (
        ((side === 'front' || side === 'back') && getManhole().orientation === 'vertical')
        || ((side === 'left' || side === 'right') && getManhole().orientation === 'horizontal')
      )
  }
  const isClosuresLongSide = () => hasManhole() && !isClosuresShortSide()
  const renderRectangularLidOutline = () => renderLine([
    getLidLeft(), getLidTop(),
    getLidLeft(), getLidBottom(),
    getLidRight(), getLidBottom(),
    getLidRight(), getLidTop(),
    getLidLeft(), getLidTop()
  ])
  const renderA15B125D400E600Lid = () => {
    const getClosuresTop = () => getLidTop()
    const getClosuresThickness = () => getClosureSizes(getManhole().type).thickness * scale
    const getClosuresBottom = () => getClosuresTop() + getClosuresThickness()
    const sideHasRabbet = () => {
      return side === 'front' || side === 'back'
        ? getManhole().orientation === 'horizontal'
        : getManhole().orientation === 'vertical'
    }
    const getRabbetWidth = () => getClosureSizes(getManhole().type).rabbetWidth * scale
    const getManholeLeft = () => getManholeClearMeasureLeft() - (sideHasRabbet() ? getRabbetWidth() : 0)
    const getManholeRight = () => getManholeClearMeasureRight() + (sideHasRabbet() ? getRabbetWidth() : 0)
    const renderWithCollar = () => {
      const renderBeveledOutline = () => {
        const getBevelTop = () => getLidTopToBevelTopDistance(plan) * scale
        const getBevelBottom = () => getBevelTop() + getBevelHeight(plan) * scale

        return (
          <React.Fragment>
            {
              renderLine([
                getManholeLeft(), getLidTop(),
                getManholeLeft(), getBevelTop(),
                getLidLeft(), getBevelBottom(),
                getLidLeft(), getLidBottom(),
                getLidRight(), getLidBottom(),
                getLidRight(), getBevelBottom(),
                getManholeRight(), getBevelTop(),
                getManholeRight(), getLidTop(),
                getManholeLeft(), getLidTop()
              ])
            }
            {renderLine([getManholeLeft(getManhole()), getBevelTop(), getManholeRight(getManhole()), getBevelTop()])}
            {renderLine([getLidLeft(), getBevelBottom(), getLidRight(), getBevelBottom()])}
          </React.Fragment>
        )
      }
      const renderClosuresLongSide = () => {
        const innerLeft = getManholeClearMeasureLeft()
        const realInnerWidth = (getManhole().orientation === 'vertical' ? getManhole().height : getManhole().width)
        const innerWidth = realInnerWidth * scale
        const innerRight = innerLeft + innerWidth
        const outerLeft = innerLeft - getRabbetWidth()
        const outerRight = innerRight + getRabbetWidth()

        const renderGirder = (x, i) => {
          const topAndBottomBarWidth = 2
          const middleBarWidth = 1
          const top = 0
          const bottom = 18 * scale
          const left = -9 * scale
          const right = 9 * scale
          const topBarBottom = top + topAndBottomBarWidth * scale
          const bottomBarTop = bottom - topAndBottomBarWidth * scale
          const middleBarLeft = -middleBarWidth / 2 * scale
          const middleBarRight = middleBarWidth / 2 * scale

          return (
            <Group x={x} y={getClosuresBottom()}>
              {renderDashedLine([
                left, top,
                left, topBarBottom,
                middleBarLeft, topBarBottom,
                middleBarLeft, bottomBarTop,
                left, bottomBarTop,
                left, bottom,
                right, bottom,
                right, bottomBarTop,
                middleBarRight, bottomBarTop,
                middleBarRight, topBarBottom,
                right, topBarBottom,
                right, top
              ], 'girder_' + i)}
            </Group>
          )
        }

        return (
          <React.Fragment>
            {renderDashedLine(
              [
                outerLeft,
                getLidTop(),
                outerLeft,
                getClosuresBottom(),
                outerRight,
                getClosuresBottom(),
                outerRight,
                getLidTop()
              ]
            )}
            {renderDashedLine([innerLeft, getClosuresBottom(), innerLeft, getLidBottom()])}
            {renderDashedLine([innerRight, getClosuresBottom(), innerRight, getLidBottom()])}
            {
              getClosuresIntersectionPositions(
                realInnerWidth + 7,
                getClosureSizes(getManhole().type).height + getClosureSizes(getManhole().type).rowSpacing
              )
                .map(p => outerLeft + p * scale)
                .map((p, i) => (
                  <React.Fragment key={"intersection_" + i}>
                    {renderDashedLine([p, getLidTop(), p, getClosuresBottom()])}
                    {renderGirder(p, i)}
                  </React.Fragment>
                ))
            }
          </React.Fragment>
        )
      }
      const renderClosuresShortSide = () => {
        const left = getManholeClearMeasureLeft()
        const realWidth = getManhole().orientation === 'vertical' ? getManhole().width : getManhole().height
        const width = realWidth * scale
        const right = left + width

        const renderGirder = () => {
          const topAndBottomBarWidth = 2
          const top = 0
          const bottom = 18 * scale
          const topBarBottom = top + topAndBottomBarWidth * scale
          const bottomBarTop = bottom - topAndBottomBarWidth * scale

          return (
            <Group x={left} y={getClosuresBottom()}>
              {renderDashedLine([0, topBarBottom, width, topBarBottom])}
              {renderDashedLine([0, bottomBarTop, width, bottomBarTop])}
              {renderDashedLine([0, bottom, width, bottom])}
            </Group>
          )
        }

        return (
          <React.Fragment>
            {renderDashedLine([left, getLidTop(), left, getLidBottom()])}
            {renderDashedLine([right, getLidTop(), right, getLidBottom()])}
            {renderDashedLine([left, getClosuresBottom(), right, getClosuresBottom()])}
            {
              getClosuresIntersectionPositions(realWidth, getClosureSizes(getManhole().type).width)
                .map(p => left + p * scale)
                .map((p, i) => renderDashedLine([p, getLidTop(), p, getClosuresBottom()], 'divider_' + i))
            }
            {hasMultiRowManholes(plan) && renderGirder()}
          </React.Fragment>
        )
      }

      return (
        <React.Fragment>
          {renderBeveledOutline()}
          {isClosuresShortSide() && renderClosuresShortSide()}
          {isClosuresLongSide() && renderClosuresLongSide()}
        </React.Fragment>
      )
    }
    const renderWithoutCollar = () => {
      const renderA15 = () => {
        const getFrameWallWidth = () => 0.5 * scale
        const renderClosuresShortSide = () => {
          const renderLidOutline = () => {
            return renderLine([
              getManholeLeft() - getFrameWallWidth(), getLidTop(),
              getManholeLeft() - getFrameWallWidth(), getLidTop() + 8 * scale,
              getManholeLeft() - 5.5 * scale, getLidTop() + 8 * scale,
              getManholeLeft() - 5.5 * scale, getLidTop() + 8.5 * scale,
              getManholeRight() + 5.5 * scale, getLidTop() + 8.5 * scale,
              getManholeRight() + 5.5 * scale, getLidTop() + 8 * scale,
              getManholeRight() + getFrameWallWidth(), getLidTop() + 8 * scale,
              getManholeRight() + getFrameWallWidth(), getLidTop(),
              getManholeLeft() - getFrameWallWidth(), getLidTop()
            ])
          }
          const renderClosuresOutline = () => {
            return renderDashedLine([
              getManholeLeft(), getLidTop(),
              getManholeLeft(), getLidTop() + getClosureSizes(getManhole().type).thickness * scale,
              getManholeRight(), getLidTop() + getClosureSizes(getManhole().type).thickness * scale,
              getManholeRight(), getLidTop()
            ])
          }
          const renderClosuresIntersectionLines = () => {
            return (
              <React.Fragment>
                {
                  getClosuresIntersectionPositions(
                    getManholeClearMeasureRealWidth(),
                    getClosureSizes(getManhole().type).width
                  )
                    .map(p => getManholeLeft() + p * scale)
                    .map((p, i) => renderDashedLine(
                      [p, getLidTop(), p, getClosuresBottom()],
                      'closures_intersection_' + i
                    ))
                }
              </React.Fragment>
            )

          }
          const renderAnchors = () => {
            const getClosureWidth = () => getClosureSizes(getManhole().type).width
            const getClosuresCount = () => Math.round(getManholeClearMeasureRealWidth() / getClosureWidth())
            const getAnchorXPositionCount = () => Math.ceil(getClosuresCount() / 2)
            const getAnchorXPositionsDistance =
              () => getManholeClearMeasureRealWidth() / getAnchorXPositionCount() * scale
            const getAnchorXPositions = () => {
              return Array(getAnchorXPositionCount()).fill(null).map((curr, i) => {
                return (0.5 + i) * getAnchorXPositionsDistance() + getManholeLeft()
              })
            }
            const getFrameBottom = () => getLidTop() + 8.5 * scale

            return getAnchorXPositions().map(
              (p, i) => {
                return (
                  <React.Fragment key={'lid_elevation_view_anchor_' + i}>
                    {
                      renderLine([
                        p - 1.5 * scale, getFrameBottom(),
                        p - 1.5 * scale, getFrameBottom() + 9 * scale,
                        p + 1.5 * scale, getFrameBottom() + 9 * scale,
                        p + 1.5 * scale, getFrameBottom()
                      ])
                    }
                    {
                      renderLine([
                        p, getFrameBottom() + 6 * scale,
                        p, getFrameBottom() + 9 * scale
                      ])
                    }
                  </React.Fragment>
                )
              }
            )
          }

          return (
            <React.Fragment>
              {renderLidOutline()}
              {renderClosuresOutline()}
              {renderClosuresIntersectionLines()}
              {renderAnchors()}
            </React.Fragment>
          )
        }
        const renderClosuresLongSide = () => {
          const renderLidOutline = () => {
            return renderLine([
              getManholeLeft() - getFrameWallWidth(), getLidTop(),
              getManholeLeft() - getFrameWallWidth(), getLidTop() + 8.5 * scale,
              getManholeRight() + getFrameWallWidth(), getLidTop() + 8.5 * scale,
              getManholeRight() + getFrameWallWidth(), getLidTop(),
              getManholeLeft() - getFrameWallWidth(), getLidTop()
            ])
          }
          const renderFrameLine = () => {
            return renderLine([
              getManholeLeft() - getFrameWallWidth(), getLidTop() + 8 * scale,
              getManholeRight() + getFrameWallWidth(), getLidTop() + 8 * scale
            ])
          }
          const renderClosureLines = () => {
            return (
              <React.Fragment>
                {renderDashedLine([getManholeLeft(), getLidTop(), getManholeLeft(), getLidTop() + 8 * scale])}
                {renderDashedLine([getManholeRight(), getLidTop(), getManholeRight(), getLidTop() + 8 * scale])}
              </React.Fragment>
            )
          }
          const renderAnchors = () => {
            const renderAnchor = () => {
              return (
                <React.Fragment>
                  {
                    renderLine([
                      0.25 * scale, 0,
                      0.25 * scale, 6 * scale,
                      2.25 * scale, 8.7 * scale,
                      1.85 * scale, 9 * scale,
                      -0.15 * scale, 6.3 * scale,
                      -0.25 * scale, 6 * scale,
                      -0.25 * scale, 0
                    ])
                  }
                  {
                    renderLine([
                      -0.25 * scale, 6 * scale,
                      -2.25 * scale, 8.7 * scale,
                      -1.85 * scale, 9 * scale,
                      0, 6.5 * scale
                    ])
                  }
                </React.Fragment>
              )
            }
            const getFrameBottom = () => getLidTop() + 8.5 * scale

            return (
              <React.Fragment>
                <Group x={getManholeLeft() - 0.25 * scale} y={getFrameBottom()}>
                  {renderAnchor()}
                </Group>
                <Group x={getManholeRight() + 0.25 * scale} y={getFrameBottom()}>
                  {renderAnchor()}
                </Group>
              </React.Fragment>
            )
          }

          return (
            <React.Fragment>
              {renderLidOutline()}
              {renderFrameLine()}
              {renderClosureLines()}
              {renderAnchors()}
            </React.Fragment>
          )
        }

        return (
          <React.Fragment>
            {isClosuresShortSide() && renderClosuresShortSide()}
            {isClosuresLongSide() && renderClosuresLongSide()}
          </React.Fragment>
        )
      }
      const renderB125 = () => {
        const getFrameWallWidth = () => 0.5 * scale
        const renderClosuresShortSide = () => {
          const renderLidOutline = () => {
            return renderLine([
              getManholeLeft() - getFrameWallWidth(), getLidTop(),
              getManholeLeft() - getFrameWallWidth(), getLidTop() + 7.5 * scale,
              getManholeLeft() - 4 * scale, getLidTop() + 7.5 * scale,
              getManholeLeft() - 4 * scale, getLidTop() + 8 * scale,
              getManholeRight() + 4 * scale, getLidTop() + 8 * scale,
              getManholeRight() + 4 * scale, getLidTop() + 7.5 * scale,
              getManholeRight() + getFrameWallWidth(), getLidTop() + 7.5 * scale,
              getManholeRight() + getFrameWallWidth(), getLidTop(),
              getManholeLeft() - getFrameWallWidth(), getLidTop()
            ])
          }
          const renderClosuresOutline = () => {
            return renderDashedLine([
              getManholeLeft(), getLidTop(),
              getManholeLeft(), getLidTop() + getClosureSizes(getManhole().type).thickness * scale,
              getManholeRight(), getLidTop() + getClosureSizes(getManhole().type).thickness * scale,
              getManholeRight(), getLidTop()
            ])
          }
          const renderClosuresIntersectionLines = () => {
            return (
              <React.Fragment>
                {
                  getClosuresIntersectionPositions(
                    getManholeClearMeasureRealWidth(),
                    getClosureSizes(getManhole().type).width
                  )
                    .map(p => getManholeLeft() + p * scale)
                    .map((p, i) => renderDashedLine(
                      [p, getLidTop(), p, getClosuresBottom()],
                      'closures_intersection_' + i
                    ))
                }
              </React.Fragment>
            )
          }
          const renderAnchors = () => {
            const getClosureWidth = () => getClosureSizes(getManhole().type).width
            const getClosuresCount = () => Math.round(getManholeClearMeasureRealWidth() / getClosureWidth())
            const getAnchorXPositions = () => {
              return Array(getClosuresCount()).fill(null).map((curr, i) => {
                return (0.5 + i) * getClosureWidth() * scale + getManholeLeft()
              })
            }
            const getFrameBottom = () => getLidTop() + 8 * scale

            return getAnchorXPositions().map(
              p => {
                return (
                  <React.Fragment key={'anchor_x_pos_' + p}>
                    {
                      renderLine([
                        p - 1 * scale, getFrameBottom(),
                        p - 1 * scale, getFrameBottom() + 4.4 * scale,
                        p + 1 * scale, getFrameBottom() + 4.4 * scale,
                        p + 1 * scale, getFrameBottom()
                      ])
                    }
                    {
                      renderLine([
                        p, getFrameBottom() + 2.2 * scale,
                        p, getFrameBottom() + 4.4 * scale
                      ])
                    }
                  </React.Fragment>
                )
              }
            )
          }

          return (
            <React.Fragment>
              {renderLidOutline()}
              {renderClosuresOutline()}
              {renderClosuresIntersectionLines()}
              {renderAnchors()}
            </React.Fragment>
          )
        }
        const renderClosuresLongSide = () => {
          const renderLidOutline = () => {
            return renderLine([
              getManholeLeft() - getFrameWallWidth(), getLidTop(),
              getManholeLeft() - getFrameWallWidth(), getLidTop() + 8 * scale,
              getManholeRight() + getFrameWallWidth(), getLidTop() + 8 * scale,
              getManholeRight() + getFrameWallWidth(), getLidTop(),
              getManholeLeft() - getFrameWallWidth(), getLidTop()
            ])
          }
          const renderFrameLine = () => {
            return renderLine([
              getManholeLeft() - getFrameWallWidth(), getLidTop() + 7.5 * scale,
              getManholeRight() + getFrameWallWidth(), getLidTop() + 7.5 * scale
            ])
          }
          const renderClosureLines = () => {
            return (
              <React.Fragment>
                {renderDashedLine([getManholeLeft(), getLidTop(), getManholeLeft(), getLidTop() + 8 * scale])}
                {renderDashedLine([getManholeRight(), getLidTop(), getManholeRight(), getLidTop() + 8 * scale])}
              </React.Fragment>
            )
          }
          const renderAnchors = () => {
            const renderAnchor = () => {
              return (
                <React.Fragment>
                  {
                    renderLine([
                      0, 0,
                      2 * scale, 0,
                      2 * scale, 2 * scale,
                      3.2 * scale, 4.08 * scale,
                      2.68 * scale, 4.38 * scale,
                      1.48 * scale, 2.3 * scale,
                      1.4 * scale, 2 * scale,
                      1.4 * scale, 0.6 * scale,
                      -1.8 * scale, 0.6 * scale,
                      -1.8 * scale, 2.2 * scale,
                      -3.8 * scale, 2.2 * scale,
                      -3.8 * scale, 0
                    ])
                  }
                  {
                    renderLine([
                      1.4 * scale, 2 * scale,
                      0.2 * scale, 4.08 * scale,
                      0.72 * scale, 4.38 * scale,
                      1.7 * scale, 2.52 * scale
                    ])
                  }
                </React.Fragment>
              )
            }
            const getFrameBottom = () => getLidTop() + 8 * scale

            return (
              <React.Fragment>
                <Group x={getManholeLeft() - getFrameWallWidth()} y={getFrameBottom()} scaleX={-1}>
                  {renderAnchor()}
                </Group>
                <Group x={getManholeRight() + getFrameWallWidth()} y={getFrameBottom()}>
                  {renderAnchor()}
                </Group>
              </React.Fragment>
            )
          }

          return (
            <React.Fragment>
              {renderLidOutline()}
              {renderFrameLine()}
              {renderClosureLines()}
              {renderAnchors()}
            </React.Fragment>
          )
        }

        return (
          <React.Fragment>
            {isClosuresShortSide() && renderClosuresShortSide()}
            {isClosuresLongSide() && renderClosuresLongSide()}
          </React.Fragment>
        )
      }
      const renderD400 = () => {
        const renderClosuresShortSide = () => {
          const renderLidOutline = () => {
            return renderLine([
              getManholeLeft(), getLidTop(),
              getManholeLeft(), getLidTop() + 10.5 * scale,
              getManholeRight(), getLidTop() + 10.5 * scale,
              getManholeRight(), getLidTop(),
              getManholeLeft(), getLidTop()
            ])
          }
          const renderFrameSupportsAlongClosures = () => {
            return (
              <React.Fragment>
                {
                  renderLine([
                    getManholeLeft(), getLidTop() + 4 * scale,
                    getManholeLeft() - 7.1 * scale, getLidTop() + 6.5 * scale,
                    getManholeLeft() - 7.1 * scale, getLidTop() + 10.5 * scale,
                    getManholeLeft(), getLidTop() + 10.5 * scale
                  ])
                }
                {
                  renderLine([
                    getManholeRight(), getLidTop() + 4 * scale,
                    getManholeRight() + 7.1 * scale, getLidTop() + 6.5 * scale,
                    getManholeRight() + 7.1 * scale, getLidTop() + 10.5 * scale,
                    getManholeRight(), getLidTop() + 10.5 * scale
                  ])
                }
              </React.Fragment>
            )
          }
          const renderFrameSupportsAcrossClosures = () => {

            return (
              <React.Fragment>
                {
                  getClosuresIntersectionPositions(
                    getManholeClearMeasureRealWidth(),
                    getClosureSizes(getManhole().type).width
                  )
                    .map(p => getManholeLeft() + p * scale)
                    .map((p, i) => renderLine(
                      [
                        p, getLidTop() + 4 * scale,
                        p, getLidTop() + 10.5 * scale
                      ],
                      'closures_intersection_' + i
                    ))
                }
              </React.Fragment>
            )
          }
          const renderClosuresBottom = () => {
            return renderDashedLine([
              getManholeLeft(), getLidTop() + 6 * scale,
              getManholeRight(), getLidTop() + 6 * scale
            ])
          }
          const renderClosuresIntersections = () => {
            return (
              <React.Fragment>
                {
                  getClosuresIntersectionPositions(
                    getManholeClearMeasureRealWidth(),
                    getClosureSizes(getManhole().type).width
                  )
                    .map(p => getManholeLeft() + p * scale)
                    .map((p, i) => renderDashedLine(
                      [p, getLidTop(), p, getLidTop() + 6 * scale],
                      'closures_intersection_' + i
                    ))
                }
              </React.Fragment>
            )
          }
          const renderClosureSupports = () => {
            const getClosureWidth = () => getClosureSizes(getManhole().type).width
            const getClosuresCount = () => Math.round(getManholeClearMeasureRealWidth() / getClosureWidth())
            const getClosuresXPositions = () => {
              return Array(getClosuresCount()).fill(null).map((curr, i) => {
                return i * getClosureWidth() * scale + getManholeLeft()
              })
            }

            return getClosuresXPositions().map(
              p => {
                return (
                  <React.Fragment key={'closure_support_x_' + p}>
                    {
                      renderDashedLine([
                        p + 1 * scale, getLidTop() + 6 * scale,
                        p + 1 * scale, getLidTop() + 10.5 * scale
                      ])
                    }
                    {
                      renderDashedLine([
                        p + getClosureWidth() * scale - 1 * scale, getLidTop() + 6 * scale,
                        p + getClosureWidth() * scale - 1 * scale, getLidTop() + 10.5 * scale
                      ])
                    }
                    {
                      renderLine([
                        p + 1 * scale, getLidTop() + 10.5 * scale,
                        p + 1 * scale, getLidTop() + 14 * scale,
                        p + getClosureWidth() * scale - 1 * scale, getLidTop() + 14 * scale,
                        p + getClosureWidth() * scale - 1 * scale, getLidTop() + 10.5 * scale,
                      ])
                    }
                  </React.Fragment>
                )
              }
            )
          }

          return (
            <React.Fragment>
              {renderLidOutline()}
              {renderFrameSupportsAlongClosures()}
              {renderFrameSupportsAcrossClosures()}
              {renderClosuresBottom()}
              {renderClosureSupports()}
              {renderClosuresIntersections()}
            </React.Fragment>
          )
        }
        const renderClosuresLongSide = () => {
          const renderFrameAndClosuresOutline = () => {
            return renderLine([
              getManholeLeft(), getLidTop(),
              getManholeLeft(), getLidTop() + 4 * scale,
              getManholeClearMeasureLeft() - 9.1 * scale, getLidTop() + 6.5 * scale,
              getManholeClearMeasureLeft() - 9.1 * scale, getLidTop() + 10.5 * scale,
              getManholeClearMeasureRight() + 9.1 * scale, getLidTop() + 10.5 * scale,
              getManholeClearMeasureRight() + 9.1 * scale, getLidTop() + 6.5 * scale,
              getManholeRight(), getLidTop() + 4 * scale,
              getManholeRight(), getLidTop(),
              getManholeLeft(), getLidTop()
            ])
          }
          const renderClosuresHiddenSideAndBottomLines = () => {
            return renderDashedLine([
              getManholeLeft(), getLidTop() + 4 * scale,
              getManholeLeft(), getLidTop() + 6 * scale,
              getManholeRight(), getLidTop() + 6 * scale,
              getManholeRight(), getLidTop() + 4 * scale
            ])
          }
          const renderClosuresSupport = () => {
            const getDashedLindRatio = () => 4.5 / 8

            return (
              <React.Fragment>
                {
                  renderDashedLine([
                    getManholeClearMeasureLeft(), getLidTop() + 6 * scale,
                    getManholeClearMeasureLeft() + 19.9 * getDashedLindRatio() * scale, getLidTop() + 10.5 * scale
                  ])
                }
                {
                  renderDashedLine([
                    getManholeClearMeasureRight(), getLidTop() + 6 * scale,
                    getManholeClearMeasureRight() - 19.9 * getDashedLindRatio() * scale, getLidTop() + 10.5 * scale
                  ])
                }
                {
                  renderLine([
                    getManholeClearMeasureLeft() + 19.9 * getDashedLindRatio() * scale, getLidTop() + 10.5 * scale,
                    getManholeClearMeasureLeft() + 19.9 * scale, getLidTop() + 14 * scale,
                    getManholeClearMeasureRight() - 19.9 * scale, getLidTop() + 14 * scale,
                    getManholeClearMeasureRight() - 19.9 * getDashedLindRatio() * scale, getLidTop() + 10.5 * scale
                  ])
                }

              </React.Fragment>
            )
          }
          const renderFrameSupportsAlongClosure = () => {
            const getSupportDistance = () => getManholeClearMeasureRealWidth() / 5
            const getSupportPositions = () => {
              return Array(6).fill(null).map((curr, i) => {
                return i * getSupportDistance() * scale + getManholeClearMeasureLeft()
              })
            }

            return getSupportPositions().map(
              (p, i) => {
                return (
                  <React.Fragment key={'across_support_' + i}>
                    {
                      renderLine([
                        p, getLidTop() + 4 * scale,
                        p, getLidTop() + 10.5 * scale
                      ])
                    }
                  </React.Fragment>
                )
              }
            )
          }


          return (
            <React.Fragment>
              {renderFrameAndClosuresOutline()}
              {renderClosuresHiddenSideAndBottomLines()}
              {renderClosuresSupport()}
              {renderFrameSupportsAlongClosure()}
            </React.Fragment>
          )
        }

        return (
          <React.Fragment>
            {isClosuresShortSide() && renderClosuresShortSide()}
            {isClosuresLongSide() && renderClosuresLongSide()}
          </React.Fragment>
        )
      }
      const renderE600 = () => {
        const renderClosuresShortSide = () => {
          const renderLidOutline = () => {
            return renderLine([
              getManholeLeft(), getLidTop(),
              getManholeLeft(), getLidTop() + 15 * scale,
              getManholeRight(), getLidTop() + 15 * scale,
              getManholeRight(), getLidTop(),
              getManholeLeft(), getLidTop()
            ])
          }
          const renderFrameSupportsAlongClosures = () => {
            return (
              <React.Fragment>
                {
                  renderLine([
                    getManholeLeft(), getLidTop() + 6 * scale,
                    getManholeLeft() - 7.1 * scale, getLidTop() + 11 * scale,
                    getManholeLeft() - 7.1 * scale, getLidTop() + 15 * scale,
                    getManholeLeft(), getLidTop() + 15 * scale
                  ])
                }
                {
                  renderLine([
                    getManholeRight(), getLidTop() + 6 * scale,
                    getManholeRight() + 7.1 * scale, getLidTop() + 11 * scale,
                    getManholeRight() + 7.1 * scale, getLidTop() + 15 * scale,
                    getManholeRight(), getLidTop() + 15 * scale
                  ])
                }
              </React.Fragment>
            )
          }
          const renderFrameSupportsAcrossClosures = () => {
            return (
              <React.Fragment>
                {
                  getClosuresIntersectionPositions(
                    getManholeClearMeasureRealWidth(),
                    getClosureSizes(getManhole().type).width
                  )
                    .map(p => getManholeLeft() + p * scale)
                    .map((p, i) => renderLine(
                      [
                        p, getLidTop() + 6 * scale,
                        p, getLidTop() + 15 * scale
                      ],
                      'closures_intersection_' + i
                    ))
                }
              </React.Fragment>
            )
          }
          const renderClosuresBottom = () => {
            return renderDashedLine([
              getManholeLeft(), getLidTop() + 8 * scale,
              getManholeRight(), getLidTop() + 8 * scale
            ])
          }
          const renderClosuresIntersections = () => {
            return (
              <React.Fragment>
                {
                  getClosuresIntersectionPositions(
                    getManholeClearMeasureRealWidth(),
                    getClosureSizes(getManhole().type).width
                  )
                    .map(p => getManholeLeft() + p * scale)
                    .map((p, i) => renderDashedLine(
                      [
                        p, getLidTop(), p,
                        getLidTop() + 8 * scale
                      ],
                      'closures_intersection_' + i
                    ))
                }
              </React.Fragment>
            )
          }
          const renderClosureSupports = () => {
            const getClosureWidth = () => getClosureSizes(getManhole().type).width
            const getClosuresCount = () => Math.round(getManholeClearMeasureRealWidth() / getClosureWidth())
            const getClosuresXPositions = () => {
              return Array(getClosuresCount()).fill(null).map((curr, i) => {
                return i * getClosureWidth() * scale + getManholeLeft()
              })
            }

            return getClosuresXPositions().map(
              (p, i) => {
                return renderDashedLine([
                    p + 1 * scale, getLidTop() + 8 * scale,
                    p + 1 * scale, getLidTop() + 14 * scale,
                    p + getClosureWidth() * scale - 1 * scale, getLidTop() + 14 * scale,
                    p + getClosureWidth() * scale - 1 * scale, getLidTop() + 8 * scale,
                  ],
                  'support_' + i
                )

              }
            )
          }

          return (
            <React.Fragment>
              {renderLidOutline()}
              {renderFrameSupportsAlongClosures()}
              {renderFrameSupportsAcrossClosures()}
              {renderClosuresBottom()}
              {renderClosureSupports()}
              {renderClosuresIntersections()}
            </React.Fragment>
          )
        }
        const renderClosuresLongSide = () => {
          const renderFrameAndClosuresOutline = () => {
            return renderLine([
              getManholeLeft(), getLidTop(),
              getManholeLeft(), getLidTop() + 6 * scale,
              getManholeClearMeasureLeft() - 9.1 * scale, getLidTop() + 11 * scale,
              getManholeClearMeasureLeft() - 9.1 * scale, getLidTop() + 15 * scale,
              getManholeClearMeasureRight() + 9.1 * scale, getLidTop() + 15 * scale,
              getManholeClearMeasureRight() + 9.1 * scale, getLidTop() + 11 * scale,
              getManholeRight(), getLidTop() + 6 * scale,
              getManholeRight(), getLidTop(),
              getManholeLeft(), getLidTop()
            ])
          }
          const renderClosuresHiddenSideAndBottomLines = () => {
            return renderDashedLine([
              getManholeLeft(), getLidTop() + 6 * scale,
              getManholeLeft(), getLidTop() + 8 * scale,
              getManholeRight(), getLidTop() + 8 * scale,
              getManholeRight(), getLidTop() + 6 * scale
            ])
          }
          const renderClosuresSupport = () => {
            return (
              <React.Fragment>
                {
                  renderDashedLine([
                    getManholeClearMeasureLeft(), getLidTop() + 8 * scale,
                    getManholeClearMeasureLeft() + 19.9 * scale, getLidTop() + 14 * scale,
                    getManholeClearMeasureRight() - 19.9 * scale, getLidTop() + 14 * scale,
                    getManholeClearMeasureRight(), getLidTop() + 8 * scale,
                  ])
                }

              </React.Fragment>
            )
          }
          const renderFrameSupportsAlongClosure = () => {
            const getSupportDistance = () => getManholeClearMeasureRealWidth() / 5
            const getSupportPositions = () => {
              return Array(6).fill(null).map((curr, i) => {
                return i * getSupportDistance() * scale + getManholeClearMeasureLeft()
              })
            }

            return getSupportPositions().map(
              (p, i) => {
                return (
                  <React.Fragment key={'across_support_' + i}>
                    {
                      renderLine([
                        p, getLidTop() + 6 * scale,
                        p, getLidTop() + 15 * scale
                      ])
                    }
                  </React.Fragment>
                )
              }
            )
          }

          return (
            <React.Fragment>
              {renderFrameAndClosuresOutline()}
              {renderClosuresHiddenSideAndBottomLines()}
              {renderClosuresSupport()}
              {renderFrameSupportsAlongClosure()}
            </React.Fragment>
          )
        }

        return (
          <React.Fragment>
            {isClosuresShortSide() && renderClosuresShortSide()}
            {isClosuresLongSide() && renderClosuresLongSide()}
          </React.Fragment>
        )
      }

      return (
        <React.Fragment>
          {plan.lid.type === 'A15' && renderA15()}
          {plan.lid.type === 'B125' && renderB125()}
          {plan.lid.type === 'D400' && renderD400()}
          {plan.lid.type === 'E600' && renderE600()}
        </React.Fragment>
      )
    }

    const renderBlockOuts = () => {
      const renderRectangular = (rect, key) => {
        const getRectLeft = () =>
          getClearMeasureLeft()
          + (side === 'left' ? plan.width - rect.height - rect.y
              : side === 'right' ? rect.y
                : side === 'front' ? rect.x
                  : side === 'back' ? plan.length - rect.width - rect.x
                    : 0
          ) * scale
        const getRectWidth = () =>
          side === 'left' || side === 'right'
            ? rect.height * scale
            : rect.width * scale
        const getRectRight = () => getRectLeft() + getRectWidth()
        const getLeft = () => Math.max(getRectLeft(), 0)
        const getRight = () => Math.min(getRectRight(), getClearMeasureRight())

        return (
          <React.Fragment key={key}>
            {renderDashedLine([getLeft(), getLidTop(), getLeft(), getLidBottom()])}
            {renderDashedLine([getRight(), getLidTop(), getRight(), getLidBottom()])}
          </React.Fragment>
        )
      }

      return plan.lid.blockOuts.map(
        (b, i) => {
          return renderRectangular(b, 'block_out_' + i)
        }
      )
    }

    const renderCircularBlockOuts = () => {
      return plan.lid.circularBlockOuts.map((b, i) => {
        const getRadius = () => b.outerDiameter / 2 * scale
        const getX = () =>
          (side === 'left' ? plan.width + plan.wallWidth - b.y
              : side === 'right' ? plan.wallWidth + b.y
                : side === 'front' ? b.x + plan.wallWidth
                  : side === 'back' ? plan.length + plan.wallWidth - b.x
                    : 0
          ) * scale
        const getLeft = () => (getX() - getRadius())
        const getRight = () => (getX() + getRadius())

        return (
          <React.Fragment key={'circular_block_out_' + i}>
            {renderDashedLine([getLeft(), getLidTop(), getLeft(), getLidBottom()])}
            {renderDashedLine([getRight(), getLidTop(), getRight(), getLidBottom()])}
          </React.Fragment>
        )
      })
    }

    return (
      <React.Fragment>
        {!hasManhole() && renderRectangularLidOutline()}
        {hasManhole() && plan.lid.collar !== 'without' && renderWithCollar()}
        {hasManhole() && plan.lid.collar === 'without' && renderWithoutCollar()}
        {renderBlockOuts()}
        {renderCircularBlockOuts()}
      </React.Fragment>
    )
  }
  const renderSubFloorLid = () => {
    const hasSupplementaryCover = side => {
      return side === 'left' ? hasSupplementaryCoverLeft(plan)
        : side === 'right' ? hasSupplementaryCoverRight(plan)
          : side === 'front' ? hasSupplementaryCoverFront(plan)
            : side === 'back' ? hasSupplementaryCoverBack(plan)
              : 0
    }
    const getClosuresBottom = () => getLidBottom()
    const getClosuresHeight = () => getClosureSizes(getManhole().type).thickness * scale
    const getClosuresTop = () => getClosuresBottom() - getClosuresHeight()
    const renderManholeOutline = () => {
      return renderLine([
        getManholeClearMeasureLeft(), getClosuresTop(),
        getManholeClearMeasureLeft(), getClosuresBottom(),
        getManholeClearMeasureRight(), getClosuresBottom(),
        getManholeClearMeasureRight(), getClosuresTop(),
        getManholeClearMeasureLeft(), getClosuresTop()
      ])
    }
    const renderClosuresShortSide = () => {
      const getClosureWidth = () => getClosureSizes(getManhole().type).width
      const renderClosuresIntersections = () => {
        return getClosuresIntersectionPositions(getManholeClearMeasureRealWidth(), getClosureWidth())
          .map(p => getManholeClearMeasureLeft() + p * scale)
          .map((p, i) => renderLine([p, getClosuresTop(), p, getClosuresBottom()], 'divider_' + i))
      }
      const renderHoldingBrackets = () => {
        const getBracketDiameter = () => getClosureSizes(getManhole().type).bracketDiameter * scale
        const getBracketRadius = () => getClosureSizes(getManhole().type).bracketRadius * scale
        const getOuterRadius = () => getBracketRadius() + getBracketDiameter() / 2
        const getInnerRadius = () => getBracketRadius() - getBracketDiameter() / 2
        const getHeight = () => getClosureSizes(getManhole().type).bracketHeight * scale // bottom to top center distance
        const getWidth = () => getClosureSizes(getManhole().type).bracketWidth * scale // center to center distance
        const getLeftCenter = () => ({
          x: -getWidth() / 2 + getOuterRadius(),
          y: -(getHeight() - getOuterRadius() + getBracketDiameter() / 2)
        })
        const getRightCenter = () => ({...getLeftCenter(), x: -getLeftCenter().x})

        return [
          0,
          ...getClosuresIntersectionPositions(getManholeClearMeasureRealWidth(), getClosureWidth())
        ]
          .map(p => getManholeClearMeasureLeft() + (p + getClosureWidth() / 2) * scale)
          .map((p, i) =>
            <Group x={p} y={getClosuresTop()} key={'bracket_' + i}>
              <Shape
                sceneFunc={(context, shape) => {
                  context.beginPath();
                  context.moveTo(getLeftCenter().x - getOuterRadius(), 0)
                  context.lineTo(getLeftCenter().x - getOuterRadius(), getLeftCenter().y)
                  context.arc(getLeftCenter().x, getLeftCenter().y, getOuterRadius(), Math.PI, -Math.PI / 2, false);
                  context.arc(getRightCenter().x, getRightCenter().y, getOuterRadius(), -Math.PI / 2, 0, false);
                  context.lineTo(getRightCenter().x + getOuterRadius(), 0)
                  context.lineTo(getRightCenter().x + getInnerRadius(), 0)
                  context.arc(getRightCenter().x, getRightCenter().y, getInnerRadius(), 0, -Math.PI / 2, true);
                  context.arc(getLeftCenter().x, getLeftCenter().y, getInnerRadius(), -Math.PI / 2, Math.PI, true);
                  context.lineTo(getLeftCenter().x - getInnerRadius(), 0)
                  context.closePath();
                  context.fillStrokeShape(shape);
                }}
                fill={'black'}
                stroke="black"
                strokeWidth={1}
              />
            </Group>
          )
      }
      const renderSupplementaryCovers = () => {
        const hasLeftSupplementaryCover = () => {
          return side === 'front' ? hasSupplementaryCoverLeft(plan)
            : side === 'back' ? hasSupplementaryCoverRight(plan)
              : side === 'left' ? hasSupplementaryCoverBack(plan)
                : side === 'right' ? hasSupplementaryCoverFront(plan)
                  : 0
        }
        const hasRightSupplementaryCover = () => {
          return side === 'front' ? hasSupplementaryCoverRight(plan)
            : side === 'back' ? hasSupplementaryCoverLeft(plan)
              : side === 'left' ? hasSupplementaryCoverFront(plan)
                : side === 'right' ? hasSupplementaryCoverBack(plan)
                  : 0
        }
        const renderLeftSupplementaryCover = () => {
          return renderLine(
            [
              getLidLeft(), getLidTop(),
              getManholeClearMeasureLeft(), getLidTop(),
              getManholeClearMeasureLeft(), getLidBottom(),
              getLidLeft(), getLidBottom(),
              getLidLeft(), getLidTop()
            ]
          )
        }
        const renderRightSupplementaryCover = () => {
          return renderLine(
            [
              getManholeClearMeasureRight(), getLidTop(),
              getLidRight(), getLidTop(),
              getLidRight(), getLidBottom(),
              getManholeClearMeasureRight(), getLidBottom(),
              getManholeClearMeasureRight(), getLidTop()
            ]
          )
        }

        return (
          <React.Fragment>
            {hasLeftSupplementaryCover() && renderLeftSupplementaryCover()}
            {hasRightSupplementaryCover() && renderRightSupplementaryCover()}
          </React.Fragment>
        )
      }

      return (
        <React.Fragment>
          {hasManhole() && renderManholeOutline()}
          {renderClosuresIntersections()}
          {renderHoldingBrackets()}
          {renderSupplementaryCovers()}
        </React.Fragment>
      )
    }
    const renderClosuresLongSide = () => {
      const renderSupplementaryCover = () => {
        const renderBehindManhole = () => {
          return getVaultLeft() < getManholeClearMeasureLeft()
            ? renderLine([
              getManholeClearMeasureLeft(), getLidBottom(),
              getVaultLeft(), getLidBottom(),
              getVaultLeft(), getLidTop(),
              getVaultRight(), getLidTop(),
              getVaultRight(), getLidBottom(),
              getManholeClearMeasureRight(), getLidBottom()
            ])
            : <React.Fragment>
              {
                renderDashedLine([
                  getVaultLeft(), getLidBottom(),
                  getVaultLeft(), getClosuresTop()
                ])
              }
              {
                renderLine([
                  getVaultLeft(), getClosuresTop(),
                  getVaultLeft(), getLidTop(),
                  getVaultRight(), getLidTop(),
                  getVaultRight(), getClosuresTop()
                ])
              }
              {
                renderDashedLine([
                  getVaultRight(), getClosuresTop(),
                  getVaultRight(), getLidBottom()
                ])
              }
            </React.Fragment>
        }
        const render = () => {
          return renderLine([
            getVaultLeft(), getLidTop(),
            getVaultRight(), getLidTop(),
            getVaultRight(), getLidBottom(),
            getVaultLeft(), getLidBottom(),
            getVaultLeft(), getLidTop()
          ])
        }

        return hasSupplementaryCover(side) ? render() : renderBehindManhole()
      }
      const renderManhole = () => {
        const getBracketSpacing = () => getClosureSizes(getManhole().type).bracketPosition * scale
        const getLeftBracketX = () => getManholeClearMeasureLeft() + getBracketSpacing()
        const getRightBracketX = () => getManholeClearMeasureRight() - getBracketSpacing()
        const renderHoldingBracket = (x, y, renderDashed = false) => { // x, y of bottom center
          const getDiameter = () => getClosureSizes(getManhole().type).bracketDiameter * scale
          const getRadius = () => getDiameter() / 2
          const getHeight = () => getClosureSizes(getManhole().type).bracketHeight * scale  // bottom to top center distance
          const getCenterY = () => -getHeight()
          const getStroke = () => renderDashed ? 'black' : null
          const getFill = () => renderDashed ? 'transparent' : 'black'

          return (
            <Group x={x} y={y}>
              <Shape
                sceneFunc={(context, shape) => {
                  context.beginPath();
                  context.moveTo(-getRadius(), 0)
                  context.arc(0, getCenterY(), getRadius(), Math.PI, 0, false);
                  context.lineTo(getRadius(), 0)

                  context.closePath();
                  context.fillStrokeShape(shape);
                }}
                fill={getFill()}
                stroke={getStroke()}
                strokeWidth={1}
                dash={dash}
              />
            </Group>
          )
        }

        const renderBehindSupplementaryCover = () => {
          const isLeftBracketDashed = () => getLeftBracketX() > getVaultLeft()
          const isRightBracketDashed = () => getRightBracketX() <= getVaultRight()

          return getManholeClearMeasureLeft() < getVaultLeft()
            ? <React.Fragment>
              {
                renderLine([
                  getVaultLeft(), getClosuresBottom(),
                  getManholeClearMeasureLeft(), getClosuresBottom(),
                  getManholeClearMeasureLeft(), getClosuresTop(),
                  getVaultLeft(), getClosuresTop()
                ])
              }
              {
                renderDashedLine([
                  getVaultLeft(), getClosuresTop(),
                  getVaultRight(), getClosuresTop()
                ])
              }
              {
                renderLine([
                  getVaultRight(), getClosuresBottom(),
                  getManholeClearMeasureRight(), getClosuresBottom(),
                  getManholeClearMeasureRight(), getClosuresTop(),
                  getVaultRight(), getClosuresTop()
                ])
              }
              {renderHoldingBracket(getLeftBracketX(), getClosuresTop(), isLeftBracketDashed())}
              {renderHoldingBracket(getRightBracketX(), getClosuresTop(), isRightBracketDashed())}
            </React.Fragment>
            : <React.Fragment>
              {
                renderDashedLine([
                  getManholeClearMeasureLeft(), getClosuresBottom(),
                  getManholeClearMeasureLeft(), getClosuresTop(),
                  getManholeClearMeasureRight(), getClosuresTop(),
                  getManholeClearMeasureRight(), getClosuresBottom()
                ])
              }
              {renderHoldingBracket(getLeftBracketX(), getClosuresTop(), isLeftBracketDashed())}
              {renderHoldingBracket(getRightBracketX(), getClosuresTop(), isRightBracketDashed())}
            </React.Fragment>
        }
        const render = () => {
          return <React.Fragment>
            {
              renderLine([
                getManholeClearMeasureLeft(), getClosuresBottom(),
                getManholeClearMeasureLeft(), getClosuresTop(),
                getManholeClearMeasureRight(), getClosuresTop(),
                getManholeClearMeasureRight(), getClosuresBottom(),
                getManholeClearMeasureLeft(), getClosuresBottom()
              ])
            }
            {renderHoldingBracket(getLeftBracketX(), getClosuresTop())}
            {renderHoldingBracket(getRightBracketX(), getClosuresTop())}
          </React.Fragment>
        }

        return hasSupplementaryCover(side) ? renderBehindSupplementaryCover() : render()
      }

      return (
        <React.Fragment>
          {hasManhole() && renderManhole()}
          {lidHasSupplementaryCover(plan) && renderSupplementaryCover()}
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        {!hasManhole() && renderRectangularLidOutline()}
        {isClosuresShortSide() && renderClosuresShortSide()}
        {isClosuresLongSide() && renderClosuresLongSide()}
      </React.Fragment>
    )
  }

  return plan.lid.type === 'A15' || plan.lid.type === 'B125' || plan.lid.type === 'D400' || plan.lid.type === 'E600'
    ? renderA15B125D400E600Lid()
    : plan.lid.type === 'subfloor'
      ? renderSubFloorLid()
      : null
}
