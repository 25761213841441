export const getBlockOutDefinitions = () =>
  [{
    colloquialism: "variable",
    name: "Grösse",
    type: "standard",
    width: 50,
    height: 30
  }]

export const getCircularBlockOutDefinitions = () =>
  [{
    colloquialism: "variable",
    name: "Grösse",
    outerDiameter: 30,
    diameter: 30,
    resizable: true
  }]


