import {isValidEmail} from "./emailValidation";

export const isBillingValid = (project) => {
  return !project.billing ||
    (
      !!project.billing.email
      && isValidEmail(project.billing.email)
      && !!project.billing.name
      && !!project.billing.company
      && !!project.billing.street
      && !!project.billing.zip
      && !!project.billing.city
    )
}
