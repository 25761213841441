import uuid from 'react-uuid' // todo_mga: do not create uuid in reducer (reducer should be pure)
import {planActionTypes as actionTypes} from "../constants"
import {isVaultHeightValid} from "../validation/planFormValidation"

export const initialState = {
  height: 95,
  walls: [
    {sleeves: [], blockOuts: [], drainages: [], groundings: [], cRails: []},
    {sleeves: [], blockOuts: [], drainages: [], groundings: [], cRails: []},
    {sleeves: [], blockOuts: [], drainages: [], groundings: [], cRails: []},
    {sleeves: [], blockOuts: [], drainages: [], groundings: [], cRails: []}
  ]
}

const sleevesReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.addSleeve:
      return [...state, {id: uuid(), ...action.data.sleeve}]

    case actionTypes.removeSleeve:
      return state.filter(sleeve => sleeve.id !== action.data.id)

    case actionTypes.moveSleeve:
      return state.map(sleeve => {
        if (action.data.id !== sleeve.id) {
          return sleeve
        }
        return {...sleeve, x: action.data.x, y: action.data.y}
      })

    default:
      return state
  }
}

const blockOutsReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.addBlockOut:
      return [...state, {id: uuid(), ...action.data.blockOut}]

    case actionTypes.removeBlockOut:
      return state.filter(blockOut => blockOut.id !== action.data.id)

    case actionTypes.moveBlockOut:
      return state.map(blockOut => {
        if (action.data.id !== blockOut.id) {
          return blockOut
        }
        return {...blockOut, x: action.data.x, y: action.data.y}
      })

    case actionTypes.resizeBlockOut:
      return state.map(blockOut => {
        if (action.data.id !== blockOut.id) {
          return blockOut
        }
        return {...blockOut, width: action.data.width, height: action.data.height}
      })

    default:
      return state
  }
}

const drainagesReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.addWallDrainage:
      return [...state, {id: uuid(), ...action.data.drainage}]

    case actionTypes.removeWallDrainage:
      return state.filter(drainage => drainage.id !== action.data.id)

    case actionTypes.moveWallDrainage:
      return state.map(drainage => {
        if (action.data.id !== drainage.id) {
          return drainage
        }
        return {...drainage, x: action.data.x, y: action.data.y}
      })

    default:
      return state
  }
}

const groundingsReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.addGrounding:
      return [...state, {id: uuid(), ...action.data.grounding}]

    case actionTypes.removeGrounding:
      return state.filter(grounding => grounding.id !== action.data.id)

    case actionTypes.moveGrounding:
      return state.map(grounding => {
        if (action.data.id !== grounding.id) {
          return grounding
        }
        return {...grounding, x: action.data.x, y: action.data.y}
      })

    default:
      return state
  }
}

const cRailsReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.addCRail:
      return [...state, {id: uuid(), ...action.data.cRail}]

    case actionTypes.removeCRail:
      return state.filter(cRail => cRail.id !== action.data.id)

    case actionTypes.moveCRail:
      return state.map(cRail => {
        if (action.data.id !== cRail.id) {
          return cRail
        }
        return {...cRail, x: action.data.x, y: action.data.y}
      })

    case actionTypes.resizeCRail:
      return state.map(cRail => {
        if (action.data.id !== cRail.id) {
          return cRail
        }
        return {...cRail, length: action.data.length}
      })

    default:
      return state
  }
}

const wallReducer = (state, action) => {
  const {sleeves, blockOuts, drainages, groundings, cRails, ...others} = state
  const newSleeves = sleevesReducer(sleeves, action)
  const newBlockOuts = blockOutsReducer(blockOuts, action)
  const newDrainages = drainagesReducer(drainages, action)
  const newGroundings = groundingsReducer(groundings, action)
  const newCRails = cRailsReducer(cRails, action)

  if (
    newSleeves !== sleeves
    || newBlockOuts !== blockOuts
    || newDrainages !== drainages
    || newGroundings !== groundings
    || newCRails !== cRails
  ) {
    return {
      sleeves: newSleeves,
      blockOuts: newBlockOuts,
      drainages: newDrainages,
      groundings: newGroundings,
      cRails: newCRails,
      ...others
    }
  }
  return state
}

const toMirrored = (wall, width) => {
  const sleeves = wall.sleeves.map(currentValue => {
    return {
      id: uuid(),
      x: width - currentValue.x,
      y: currentValue.y,
      diameter: currentValue.diameter,
      outerDiameter: currentValue.outerDiameter,
      innerDiameter: currentValue.innerDiameter,
      name: currentValue.name,
      colloquialism: currentValue.colloquialism
    }
  })
  const blockOuts = wall.blockOuts.map(currentValue => {
    return {
      id: uuid(),
      x: width - currentValue.x - currentValue.width,
      y: currentValue.y,
      type: currentValue.type,
      width: currentValue.width,
      height: currentValue.height
    }
  })
  const drainages = wall.drainages.map(currentValue => {
    return {
      ...currentValue,
      id: uuid(),
      x: width - currentValue.x
    }
  })
  const groundings = wall.groundings.map(currentValue => {
    return {
      ...currentValue,
      id: uuid(),
      x: width - currentValue.x
    }
  })
  const cRails = wall.cRails.map(currentValue => {
    const x = width - currentValue.x
      - (
        currentValue.orientation === 'horizontal'
          ? currentValue.length
          : 0
      )

    return {
      ...currentValue,
      id: uuid(),
      x
    }
  })
  return {sleeves, blockOuts, drainages, groundings, cRails}
}

const replaceWall = (walls, newWall, newWallIndex) => {
  return [
    ...walls.slice(0, newWallIndex),
    newWall,
    ...walls.slice(newWallIndex + 1)
  ]
}

export const vaultReducer = (state, action, planState) => {
  switch (action.type) {
    case actionTypes.setVault:
      return state || initialState

    case actionTypes.unsetVault:
      return undefined

    default:
  }
  if (!state) {
    return state
  }
  if (action.type === actionTypes.setVaultHeight) {
    if (Number.isInteger(action.data) && isVaultHeightValid(action.data)) {
      return {...state, height: action.data}
    }
    return state
  }
  if (!action.data || !Number.isInteger(action.data.wallIndex)) {
    return state
  }
  const wallIndex = action.data.wallIndex

  if (wallIndex < 0 || wallIndex > 3) {
    return state
  }
  if (action.type === actionTypes.mirrorFromOppositeSide) {
    if (wallIndex !== 1 && wallIndex !== 3) {
      return state
    }
    const mirrored = wallIndex === 1
      ? toMirrored(state.walls[0], planState.length)
      : toMirrored(state.walls[2], planState.width)

    return {...state, walls: replaceWall(state.walls, mirrored, wallIndex)}
  }
  const newWall = wallReducer(state.walls[wallIndex], action)

  if (state.walls[wallIndex] === newWall) {
    return state
  }
  return {...state, walls: replaceWall(state.walls, newWall, wallIndex)}
}
