export const planApiActionTypes = {
  resetPlans: 'RESET_PLANS',

  createPlanRequest: 'CREATE_PLAN_REQUEST',
  createPlanSuccess: 'CREATE_PLAN_SUCCESS',
  createPlanFailure: 'CREATE_PLAN_FAILURE',

  readPlanRequest: 'READ_PLAN_REQUEST',
  readPlanSuccess: 'READ_PLAN_SUCCESS',
  readPlanFailure: 'READ_PLAN_FAILURE',

  deletePlanRequest: 'DELETE_PLAN_REQUEST',
  deletePlanSuccess: 'DELETE_PLAN_SUCCESS',
  deletePlanFailure: 'DELETE_PLAN_FAILURE',

  readPlansRequest: 'READ_PLANS_REQUEST',
  readPlansSuccess: 'READ_PLANS_SUCCESS',
  readPlansFailure: 'READ_PLANS_FAILURE',

  readPlanDetailsRequest: 'READ_PLAN_DETAILS_REQUEST',
  readPlanDetailsSuccess: 'READ_PLAN_DETAILS_SUCCESS',
  readPlanDetailsFailure: 'READ_PLAN_DETAILS_FAILURE',

  setOwnerRequest: 'SET_PLAN_OWNER_REQUEST',
  setOwnerSuccess: 'SET_PLAN_OWNER_SUCCESS',
  setOwnerFailure: 'SET_PLAN_OWNER_FAILURE'

}
