import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter as Router} from 'react-router-dom';

import App from './App';

import {configStore} from './store/index';
import {loadState, saveState} from "./helpers/localStorage";

/**
 * load initialState for redux store from localStorage
 */
const persistedState = loadState();
export const store = configStore(
  persistedState
);

/**
 * save all redux store changes in localStorage
 */
store.subscribe(() => {
  saveState(store.getState());
});

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App/>
    </Router>
  </Provider>, document.getElementById('root'));
