import { authHeader } from "../helpers/authHeader"
import { handleResponse } from "./httpHelper"
import { apiUrls } from "../constants"

const apiUrl = apiUrls.armouringDataEndpoint

const readArmouringRelevantData = async (planId, projectId) => {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader()
    }
  }
  const queryString = !!projectId
    ? "projectId=" + projectId
    : "planId=" + planId
  const url = `${apiUrl}?${queryString}`

  const response = await fetch(url, requestOptions)
  return handleResponse(response)
}

export const armouringDataService = { readArmouringRelevantData }
