import React from "react"

import {sleeves, blockOuts, groundings, drainages, vaultWallElements, cRails} from "./vaultWallElements"
import {renderGroupElements as renderBlockOut} from "../../Element/blockOutRenderFactory"
import {renderGroupElements as renderGrounding} from "../../Element/groundingRenderFactory"
import {renderGroupElements as renderDrainage} from "../../Element/drainageRenderFactory"
import {renderGroupElements as renderCRail} from "../../Element/cRailRenderFactory"
import {renderGroupElements as renderSleeve} from "../../Element/sleeveRenderFactory";
import {getIconScale, getItemX, getToolbarItemWidth} from "../Toolbar/toolbarFunctions";
import {renderCircularElementToolbarItem as renderCircular} from "../Toolbar/circularElementToolbarFunctions";
import {renderRectangularElementToolbarItem as renderRectangular} from "../Toolbar/rectangularElementToolbarFunctions";
import {renderLinearElementToolbarItem as renderLinear} from "../Toolbar/linearElementToolbarFunctions";
import {getToolbarItemHeight, getToolbarItemY, renderGroupBox} from "../Toolbar/groupBox";

export const createToolbar = (
  toolbarLayout,
  configAreaLayout,
  wallIndex,
  domainFunctions
) => {
  const itemsPerGroupCounts = [sleeves.length, blockOuts.length, groundings.length, drainages.length, cRails.length]
  const itemHeight = getToolbarItemHeight(toolbarLayout.screenHeight)
  const itemY = getToolbarItemY(toolbarLayout.screenY)
  const itemWidth = getToolbarItemWidth(itemsPerGroupCounts, toolbarLayout.screenWidth)
  const iconScale = getIconScale(itemWidth, itemHeight, vaultWallElements)
  const getX = (groupIndex, itemIndex) =>
    toolbarLayout.screenX
    + getItemX(itemsPerGroupCounts, toolbarLayout.screenWidth, groupIndex, itemIndex)
  const renderGroup = (groupIndex, itemCount, groupLabelText, labelTextLength) =>
    renderGroupBox(
      getX(groupIndex, 0),
      toolbarLayout.screenY,
      itemCount * itemWidth,
      toolbarLayout.screenHeight,
      groupLabelText,
      labelTextLength
    )
  const renderItem = (
    groupIndex,
    index,
    element,
    scaleFactor,
    domainFunctions,
    renderGroupElementsFunctions,
    renderFunction
  ) =>
    renderFunction(
      index,
      element,
      getX(groupIndex, index),
      itemY,
      itemWidth,
      toolbarLayout.screenHeight,
      iconScale * scaleFactor,
      configAreaLayout,
      domainFunctions,
      renderGroupElementsFunctions
    )

  return (
    <React.Fragment>
      {renderGroup(0, sleeves.length, 'Muffen', 55)}
      {
        sleeves.map((curr, i) =>
          renderItem(0, i, curr, 1, domainFunctions.sleeveFunctions, renderSleeve, renderCircular)
        )
      }
      {renderGroup(1, blockOuts.length, 'Aussparungen', 107)}
      {
        blockOuts.map((curr, i) =>
          renderItem(1, i, curr, 0.5, domainFunctions.blockOutFunctions, renderBlockOut, renderRectangular)
        )
      }
      {renderGroup(2, groundings.length, 'Erdung Fe 27', 97)}
      {
        groundings.map((curr, i) =>
          renderItem(2, i, curr, 2, domainFunctions.groundingFunctions, renderGrounding, renderCircular)
        )
      }
      {renderGroup(3, drainages.length, 'Ablauf', 52)}
      {
        drainages.map((curr, i) =>
          renderItem(3, i, curr, 2, domainFunctions.drainageFunctions, renderDrainage, renderCircular)
        )
      }
      {renderGroup(4, cRails.length, 'C-Schienen (38 x 17 Profil)', 187)}
      {
        cRails.map((curr, i) =>
          renderItem(
            4,
            i,
            curr,
            1,
            domainFunctions.cRailFunctions,
            (screenLine, cRail) => renderCRail(screenLine, cRail, false, false), renderLinear)
        )
      }
    </React.Fragment>
  )
}

