import React from "react"

import {
  renderGroupElements as renderManhole
} from "../../Element/manholeRenderFactory";
import {getIconScale, getItemX, getToolbarItemWidth} from "../Toolbar/toolbarFunctions";
import {
  getBlockOutDefinitions,
  getCircularBlockOutDefinitions,
  getLidElements,
  getManholeDefinitions
} from "./lidElements";
import {renderRectangularElementToolbarItem as renderRectangular} from "../Toolbar/rectangularElementToolbarFunctions";
import {getToolbarItemHeight, getToolbarItemY, renderGroupBox} from "../Toolbar/groupBox";
import {renderGroupElements as renderBlockOut} from "../../Element/blockOutRenderFactory";
import {renderCircularElementToolbarItem as renderCircular} from "../Toolbar/circularElementToolbarFunctions";
import {renderGroupElements as renderCircularBlockOut} from "../../Element/circularBlockOutRenderFactory";

export const getGroupElementTitle = lidType => lidType === 'subfloor'
  ? 'Betonelemente'
  : 'Einstiegsöffnungen'

export const getGroupElementTitleWidth = lidType => lidType === 'subfloor'
  ? 110
  : 140

export const renderToolbar = (plan, domainFunctions, toolbarLayout, configAreaLayout) => {
  const invisibleItemCount = 6
  const elements = getLidElements(plan)
  const itemsPerGroupCounts = [elements.length + invisibleItemCount]
  const itemHeight = getToolbarItemHeight(toolbarLayout.screenHeight)
  const itemY = getToolbarItemY(toolbarLayout.screenY)
  const itemWidth = getToolbarItemWidth(itemsPerGroupCounts, toolbarLayout.screenWidth)
  const iconScale = getIconScale(itemWidth, itemHeight, elements)
  const getX = (groupIndex, itemIndex) =>
    toolbarLayout.screenX
    + getItemX(itemsPerGroupCounts, toolbarLayout.screenWidth, groupIndex, itemIndex)
  const renderGroup = (groupIndex, itemCount, groupLabelText, labelTextLength) =>
    renderGroupBox(
      getX(groupIndex, 0),
      toolbarLayout.screenY,
      (itemCount + invisibleItemCount) * itemWidth,
      toolbarLayout.screenHeight,
      groupLabelText,
      labelTextLength
    )
  const renderItem = (
    groupIndex,
    index,
    element,
    scaleFactor,
    domainFunctions,
    renderGroupElementsFunctions,
    renderFunction
  ) =>
    renderFunction(
      index,
      element,
      getX(groupIndex, index + invisibleItemCount / 2),
      itemY,
      itemWidth,
      toolbarLayout.screenHeight,
      iconScale * scaleFactor,
      configAreaLayout,
      domainFunctions,
      renderGroupElementsFunctions
    )
  const manholes = getManholeDefinitions(plan.lid.type)
  const blockOuts = getBlockOutDefinitions(plan)
  const circularBlockOuts = getCircularBlockOutDefinitions(plan)

  return (
    <React.Fragment>
      {renderGroup(0, manholes.length + blockOuts.length, getGroupElementTitle(plan.lid.type), getGroupElementTitleWidth(plan.lid.type))}
      {
        manholes.map((curr, i) =>
          renderItem(
            0,
            i,
            curr,
            0.5,
            domainFunctions.manholeFunctions,
            (screenRect, manhole) => renderManhole(
              screenRect,
              manhole,
              null,
              configAreaLayout
            ),
            renderRectangular
          )
        )
      }
      {
        blockOuts.map((curr, i) =>
          renderItem(
            0,
            manholes.length + i,
            curr,
            1,
            domainFunctions.blockOutFunctions,
            (screenRect, blockOut) => renderBlockOut(
              screenRect,
              blockOut
            ),
            renderRectangular
          )
        )
      }
      {
        circularBlockOuts.map((curr, i) =>
          renderItem(
            0,
            manholes.length + blockOuts.length + i,
            curr,
            1,
            domainFunctions.circularBlockOutFunctions,
            (screenCircle, manhole) => renderCircularBlockOut(screenCircle, manhole),
            renderCircular
          )
        )
      }
    </React.Fragment>
  )
}
