import {floorActions} from "../../actions";
import {
  addCircularElement,
  moveCircularElement,
  previewAddCircularElement,
  previewMoveCircularElement
} from "./circularElementDomainFunctions";
import {getBevelWidth} from "./floor";

export const createFloorDomainFunctions = (dispatch, width, height) => {
  return {
    drainageFunctions: {
      previewAdd: item => previewAddCircularElement(width, height, item, getBevelWidth()),
      add: item => addCircularElement(
        item => dispatch(floorActions.addFloorDrainage(item)),
        width,
        height,
        item,
        getBevelWidth()
      ),
      previewMove: item => previewMoveCircularElement(width, height, item, getBevelWidth()),
      move: item => moveCircularElement(
        (id, x, y) => dispatch(floorActions.moveFloorDrainage(id, x, y)),
        width,
        height,
        item,
        getBevelWidth()
      ),
      remove: id => dispatch(floorActions.removeFloorDrainage(id))
    }
  }
}
