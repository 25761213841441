import {Grid, TextField, Typography} from "@mui/material"
import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {ImageButton} from "./ImageButton"
import {getFloorThicknessError, isFloorThicknessValid, planLimits} from "../../../validation/planFormValidation"
import {floorActions} from "../../../actions"
import {
  FloorThickness15Icon,
  FloorThickness17Icon,
  FloorThickness20Icon,
  FloorThickness25Icon
} from "./FloorThicknessIcons";


const TitleGridItem = () => {
  return (
    <Grid item xs={12}>
      <Typography component="h1" variant="h4">
        Boden
      </Typography>
      < hr/>
    </Grid>
  )
}

const FloorThicknessField = props => {
  const {focusThicknessField, setFocusThicknessField} = props
  const dispatch = useDispatch()
  const floor = useSelector(state => state.plan.floor)
  const thickness = floor ? floor.thickness : 0
  const [currentThickness, setCurrentThickness] = useState(thickness)
  const error = !floor ? null : getFloorThicknessError(currentThickness)

  useEffect(() => {
    setCurrentThickness(thickness)
  }, [thickness])

  return (
    <React.Fragment>
      <TextField
        disabled={!floor}
        inputRef={input => {
          if (input != null && focusThicknessField) input.focus()
        }}
        onBlur={() => setFocusThicknessField(false)}
        onFocus={event => event.target.select()}
        type="number"
        InputProps={{
          inputProps: {
            min: planLimits.minFloorThickness,
            max: planLimits.maxFloorThickness,
            step: planLimits.floorThicknessStepWidth
          }
        }}
        variant="outlined"
        fullWidth
        margin="normal"
        id="height"
        label="Bodenstärke [cm]"
        name="thickness"
        value={currentThickness}
        error={!!error}
        helperText={error}
        onChange={e => {
          setCurrentThickness(e.target.value)
          if (isFloorThicknessValid(e.target.value)) {
            dispatch(floorActions.setFloorThickness(parseInt(e.target.value)))
          }
        }}
        style={{width: 207}}
      />
    </React.Fragment>
  )
}

const ThicknessGridItem = () => {
  const dispatch = useDispatch()
  const floor = useSelector(state => state.plan.floor)
  const [focusThicknessField, setFocusThicknessField] = useState(false);

  return (
    <Grid item xs={12}>
      <Typography component="p" variant="body1" style={{marginBottom: '0.5em'}}>
        Wählen Sie die Bodenstärke aus:
      </Typography>
      <Grid container direction={'row'} justifyContent={'flex-start'} alignItems={'flex-end'} spacing={3}>
        <Grid item>
          <ImageButton
            title={'15 cm'}
            checked={floor.thickness === 15}
            onClick={() => {
              setFocusThicknessField(false)
              dispatch(floorActions.setFloorThickness(15))
            }}
          >
            <FloorThickness15Icon/>
          </ImageButton>
        </Grid>
        <Grid item>
          <ImageButton
            title={'20 cm'}
            checked={floor.thickness === 20}
            onClick={() => {
              setFocusThicknessField(false)
              dispatch(floorActions.setFloorThickness(20))
            }}
          >
            <FloorThickness20Icon/>
          </ImageButton>
        </Grid>
        <Grid item>
          <ImageButton
            title={'25 cm'}
            checked={floor.thickness === 25}
            onClick={() => {
              setFocusThicknessField(false)
              dispatch(floorActions.setFloorThickness(25))
            }}
          >
            <FloorThickness25Icon/>
          </ImageButton>
        </Grid>
        <Grid item>
          <ImageButton
            title={'Benutzerdefiniert'}
            checked={floor.thickness !== 15 && floor.thickness !== 20 && floor.thickness !== 25}
            onClick={() => setFocusThicknessField(true)}
          >
            <FloorThickness17Icon/>
          </ImageButton>
        </Grid>
        <Grid item xs={2}  >
          <FloorThicknessField
            focusThicknessField={focusThicknessField}
            setFocusThicknessField={setFocusThicknessField}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export const FloorSection = () => {
  return (
    <Grid container spacing={3}>
      <TitleGridItem/>
      <ThicknessGridItem/>
    </Grid>
  )
}
