import React from "react"

export const FloorThickness15Icon = () => {
  return (
    <svg width="150" transform="translate(5 -25)" version="1.1" viewBox="0 0 103.19 76.729"
         xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(1.735 -332.4)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round">
        <path d="m43.244 372.08 6.6146-7.9375h46.302" strokeWidth="1.0762"/>
        <path d="m96.161 372.08-92.604 1e-5 -4e-6 31.75 92.604-1e-5" strokeWidth="1.0761"/>
      </g>
    </svg>
  )
}

export const FloorThickness17Icon = () => {
  return (
    <svg width="150" transform="translate(5 -25)" version="1.1" viewBox="0 0 103.19 76.729"
         xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(1.735 -332.4)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round">
        <path d="m43.244 366.79 6.6146-7.9375h46.302" strokeWidth="1.0762"/>
        <path transform="translate(-1.735 342.98)" d="m97.896 23.813-92.604 1e-5 -4e-6 37.042 92.604-1e-5"
              strokeWidth="1.0761"/>
      </g>
    </svg>
  )
}

export const FloorThickness20Icon = () => {
  return (
    <svg width="150" transform="translate(5 -25)" version="1.1" viewBox="0 0 103.19 76.729"
         xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(0 10.583)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round">
        <path d="m44.979 15.875 6.6146-7.9375h46.302" strokeWidth="1.0762"/>
        <path d="m97.896 15.875-92.604 1e-5 -4e-6 44.979 92.604-1e-5" strokeWidth="1.0761"/>
      </g>
    </svg>
  )
}

export const FloorThickness25Icon = () => {
  return (
    <svg width="150" transform="translate(5 -25)" version="1.1" viewBox="0 0 103.19 76.729"
         xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(0 10.583)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round">
        <path d="m44.979 2.6458 6.6146-7.9375h46.302" strokeWidth="1.0762"/>
        <path d="m97.896 2.6458-92.604 1e-5 -4e-6 58.208 92.604-1e-5" strokeWidth="1.0761"/>
      </g>
    </svg>
  )
}
