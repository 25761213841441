import {Grid, TextField, Typography} from "@mui/material"
import React, {useEffect, useState} from "react"
import {deliveryActions} from "../../../actions"
import {useDispatch, useSelector} from "react-redux"
import {getDeadlineError, getMinDeadline, isDeadlineValid} from "../../../validation/projectFormValidation"

const TitleGridItem = () => {
  return (
    <Grid item xs={12}>
      <Typography component="h1" variant="h4">
        Liefertermin
      </Typography>
      <hr/>
    </Grid>
  )
}

const DeadlineGridItem = () => {
  const dispatch = useDispatch()
  const [isFirstRender, setIsFirstRender] = useState(true)
  const deadline = useSelector(state => state.project.delivery.desiredSchedule)
  const [currentDeadline, setCurrentDeadline] = useState(deadline)
  const deadlineError = getDeadlineError(currentDeadline)

  useEffect(() => {
      setCurrentDeadline(deadline)
    },
    [deadline]
  )
  if (isFirstRender) {
    setIsFirstRender(false)
    const initialDeadline = ''

    if (deadline < initialDeadline) {
      dispatch(deliveryActions.setDesiredSchedule(initialDeadline))
    }
  }
  return (
    <Grid item xs={12}>
      <TextField
        id="desiredSchedule"
        label="Wunschtermin"
        type="date"
        variant="outlined"
        fullWidth
        InputProps={{
          inputProps: {
            min: getMinDeadline()
          }
        }}
        InputLabelProps={{
          shrink: true,
        }}
        value={currentDeadline}
        error={deadlineError.length > 0}
        helperText={deadlineError}
        onChange={e => {
          setCurrentDeadline(e.target.value)
          if (isDeadlineValid(e.target.value)) {
            dispatch(deliveryActions.setDesiredSchedule(e.target.value))
          }
        }}
      />
    </Grid>
  )
}

export const DeadlineSection = () => {
  return (
    <Grid container spacing={4}>
      <TitleGridItem/>
      <DeadlineGridItem/>
    </Grid>
  )
}
