import {snapNoResizeCRail, snapResizeCRail, snap} from "../Plan/snap";
import {vaultActions} from "../../actions";
import {
  addCircularElement,
  moveCircularElement,
  previewAddCircularElement,
  previewMoveCircularElement
} from "./circularElementDomainFunctions";
import {SNAP_GRID_SIZE} from "../../constants";
import {getGridLinePositions} from "../Plan/Configuration/gridLinePositions";

const snapResizeRect = (rect, gridWidth, gridHeight) => {
  const snapLength = (length, cellWidth) => {
    return Math.round(length / cellWidth) * cellWidth
  }
  const width = snapLength(rect.width, SNAP_GRID_SIZE)
  const height = snapLength(rect.height, SNAP_GRID_SIZE)
  const verticalGridLinePositions = getGridLinePositions(gridWidth, SNAP_GRID_SIZE, true)
    .filter(gridPos => gridPos <= gridWidth - width)
  const horizontalGridLinePositions = getGridLinePositions(gridHeight, SNAP_GRID_SIZE, true)
    .filter(gridPos => gridPos <= gridHeight - height)

  return {
    x: snap(rect.x, verticalGridLinePositions),
    y: snap(rect.y, horizontalGridLinePositions),
    width,
    height
  }
}

const snapMoveRect = (rect, gridWidth, gridHeight) => {
  const width = rect.width
  const height = rect.height
  const verticalGridLinePositions = [
    ...getGridLinePositions(gridWidth, SNAP_GRID_SIZE, true),
    (gridWidth - rect.width),
    (gridWidth - rect.width) / 2
  ]
    .filter(gridPos => gridPos <= gridWidth - width && gridPos >= 0)
  const horizontalGridLinePositions = [
    ...getGridLinePositions(gridHeight, SNAP_GRID_SIZE, true),
    gridHeight - rect.height,
    (gridHeight - rect.height) / 2
  ]
    .filter(gridPos => gridPos <= gridHeight - height && gridPos >= 0)

  return {
    x: snap(rect.x, verticalGridLinePositions),
    y: snap(rect.y, horizontalGridLinePositions),
    width,
    height
  }
}

const previewAddBlockOut = (configAreaWidth, configAreaHeight, item) => {
  const snapped = {
    ...item,
    ...snapMoveRect(item, configAreaWidth, configAreaHeight)
  }

  return {...item, x: snapped.x, y: snapped.y}
}

const addBlockOut = (dispatch, configAreaWidth, configAreaHeight, wallIndex, item) => {
  const snapped = {
    ...item,
    ...snapMoveRect(item, configAreaWidth, configAreaHeight)
  }

  if (Number.isFinite(snapped.x) && Number.isFinite(snapped.y)) {
    dispatch(vaultActions.addBlockOut(wallIndex, {...item, x: snapped.x, y: snapped.y}))
  }
  return {...item, x: snapped.x, y: snapped.y}
}

const previewMoveBlockOut = (configAreaWidth, configAreaHeight, item) => {
  const snapped = {
    ...item,
    ...snapMoveRect(item, configAreaWidth, configAreaHeight)
  }

  return {...item, x: snapped.x, y: snapped.y}
}

const moveBlockOut = (dispatch, configAreaWidth, configAreaHeight, wallIndex, item) => {
  const snapped = {
    ...item,
    ...snapMoveRect(item, configAreaWidth, configAreaHeight)
  }

  dispatch(vaultActions.moveBlockOut(wallIndex, item.id, snapped.x, snapped.y))
  return {...item, x: snapped.x, y: snapped.y}
}

const resizeBlockOut = (dispatch, configAreaWidth, configAreaHeight, wallIndex, item) => {
  const snappedRect = snapResizeRect(item, configAreaWidth, configAreaHeight)
  const {x, y, width, height} = snappedRect

  dispatch(vaultActions.resizeBlockOut(wallIndex, item.id, width, height))
  dispatch(vaultActions.moveBlockOut(wallIndex, item.id, x, y))
  return {...item, ...snappedRect}
}

const previewResizeBlockOut = (configAreaWidth, configAreaHeight, item) => {
  const snappedRect = snapResizeRect(item, configAreaWidth, configAreaHeight)

  return {...item, ...snappedRect}
}

const getSnappedCRail = (configAreaWidth, configAreaHeight, item) => {
  return {
    ...item,
    ...snapNoResizeCRail(item, configAreaWidth, configAreaHeight)
  }
}

const previewAddCRail = (configAreaWidth, configAreaHeight, item) => {
  return getSnappedCRail(configAreaWidth, configAreaHeight, item)
}

const addCRail = (dispatch, configAreaWidth, configAreaHeight, wallIndex, item) => {
  const snapped = getSnappedCRail(configAreaWidth, configAreaHeight, item)

  if (Number.isFinite(snapped.x) && Number.isFinite(snapped.y)) {
    dispatch(vaultActions.addCRail(wallIndex, {...item, x: snapped.x, y: snapped.y}))
  }
  return snapped
}

const previewMoveCRail = (configAreaWidth, configAreaHeight, item) => {
  return getSnappedCRail(configAreaWidth, configAreaHeight, item)
}

const moveCRail = (dispatch, configAreaWidth, configAreaHeight, wallIndex, item) => {
  const snapped = getSnappedCRail(configAreaWidth, configAreaHeight, item)

  dispatch(vaultActions.moveCRail(wallIndex, item.id, snapped.x, snapped.y))
  return snapped
}

const resizeCRail = (dispatch, configAreaWidth, configAreaHeight, wallIndex, item, direction) => {
  const snappedCRail = snapResizeCRail(item, configAreaWidth, configAreaHeight, direction)

  dispatch(vaultActions.resizeCRail(wallIndex, item.id, snappedCRail.length))
  dispatch(vaultActions.moveCRail(wallIndex, item.id, snappedCRail.x, snappedCRail.y))
  return snappedCRail
}

const previewResizeCRail = (configAreaWidth, configAreaHeight, item, direction) => {
  return snapResizeCRail(item, configAreaWidth, configAreaHeight, direction)
}

export const createVaultDomainFunctions = (dispatch, width, height, wallIndex) => {
  return {
    blockOutFunctions: {
      previewAdd: item => previewAddBlockOut(width, height, item),
      add: item => addBlockOut(dispatch, width, height, wallIndex, item),
      previewMove: item => previewMoveBlockOut(width, height, item),
      move: item => moveBlockOut(dispatch, width, height, wallIndex, item),
      remove: id => dispatch(vaultActions.removeBlockOut(wallIndex, id)),
      previewResize: item => previewResizeBlockOut(width, height, item),
      resize: item => resizeBlockOut(dispatch, width, height, wallIndex, item)
    },
    sleeveFunctions: {
      previewAdd: item => previewAddCircularElement(width, height, item),
      add: item => addCircularElement(
        item => dispatch(vaultActions.addSleeve(wallIndex, item)),
        width,
        height,
        item
      ),
      previewMove: item => previewMoveCircularElement(width, height, item),
      move: item => moveCircularElement(
        (id, x, y) => dispatch(vaultActions.moveSleeve(wallIndex, id, x, y)),
        width,
        height,
        item
      ),
      remove: id => dispatch(vaultActions.removeSleeve(wallIndex, id))
    },
    groundingFunctions: {
      previewAdd: item => previewAddCircularElement(width, height, item),
      add: item => addCircularElement(
        item => dispatch(vaultActions.addGrounding(wallIndex, item)),
        width,
        height,
        item
      ),
      previewMove: item => previewMoveCircularElement(width, height, item),
      move: item => moveCircularElement(
        (id, x, y) => dispatch(vaultActions.moveGrounding(wallIndex, id, x, y)),
        width,
        height,
        item
      ),
      remove: id => dispatch(vaultActions.removeGrounding(wallIndex, id))
    },
    drainageFunctions: {
      previewAdd: item => previewAddCircularElement(width, height, item),
      add: item => addCircularElement(
        item => dispatch(vaultActions.addWallDrainage(wallIndex, item)),
        width,
        height,
        item
      ),
      previewMove: item => previewMoveCircularElement(width, height, item),
      move: item => moveCircularElement(
        (id, x, y) => dispatch(vaultActions.moveWallDrainage(wallIndex, id, x, y)),
        width,
        height,
        item
      ),
      remove: id => dispatch(vaultActions.removeWallDrainage(wallIndex, id))
    },
    cRailFunctions: {
      previewAdd: item => previewAddCRail(width, height, item),
      add: item => addCRail(dispatch, width, height, wallIndex, item),
      previewMove: item => previewMoveCRail(width, height, item),
      move: item => moveCRail(dispatch, width, height, wallIndex, item),
      remove: id => dispatch(vaultActions.removeCRail(wallIndex, id)),
      previewResize: (item, direction) => previewResizeCRail(width, height, item, direction),
      resize: (item, direction) => resizeCRail(dispatch, width, height, wallIndex, item, direction)
    }
  }
}
