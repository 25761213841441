import React from "react"

import {renderResizeHandle} from "./resizeHandles"

export const renderResizeHandles = (
  configAreaLayout,
  screenCircle,
  resizingCallback,
  resizedCallback,
  customDragBoundFunc = null
) => {
  const toCircle = handles => {
    const radius = () => handles.right.x()// - screenCircle.x

    return {x: screenCircle.x, y: screenCircle.y, radius: radius(), diameter: 2 * radius()}
  }

  const dragBoundFunc = (pos) => {
    const withinConfigArea = p => {
      return {...p, x: Math.min(p.x, configAreaLayout.screenX + configAreaLayout.screenWidth)}
    }
    const yCentered = p => {
      return {...p, y: screenCircle.y}
    }
    const rightOfCenter = p => {
      return {...p, x: Math.max(pos.x, screenCircle.x)}
    }
    const customFunc = p => {
      return !!customDragBoundFunc ? customDragBoundFunc(p) : p
    }
    return customFunc(withinConfigArea(yCentered(rightOfCenter(pos))))
  }

  return (
    <React.Fragment>
      {
        renderResizeHandle(
          screenCircle.radius,
          0,
          "right",
          toCircle,
          pos => dragBoundFunc(pos),
          resizingCallback,
          resizedCallback,
          true
        )
      }
    </React.Fragment>
  )
}
