import React, { useState } from "react"
import {
  Autocomplete,
  Grid,
  TextField,
} from "@mui/material"
import { useSelector, useDispatch } from "react-redux"

import { cityService } from "../../services"

export const CityItem = props => {
  const {setCityAction, setZipAction, selectCity, selectZip} = props
  const dispatch = useDispatch()
  const sZip = useSelector(selectZip)
  const sCity = useSelector(selectCity)
  const [cities, setCities] = useState([])
  const [city, setCity] = useState({
    label: sZip,
    value: sZip
  })

  const toOptions = cities =>
    Array.isArray(cities)
      ? cities.map(c => {
          return {
            label: `${c.zip} ${c.name}`,
            value: c.zip,
            city: c.name
          }
        })
      : []

  const handleInputChange = (event, value) => {
    setCity({
      label: value,
      value: value
    })


    const cities = cityService.getCities(value)
    if (cities.length < 1) {
      //return
    }
    const options = toOptions(cities)

    setCities(options)
  }

  const handleChange = (event, value) => {
    dispatch(setCityAction(value.city))
    dispatch(setZipAction(value.value))
    return setCity({ label: value.value, value: value.value })
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
          <Autocomplete
            value={sZip}
            inputValue={city.label}
            options={cities}
            renderInput={params => (
              <TextField
                margin="normal"
                id="zip"
                {...params}
                label="PLZ"
                variant="outlined"
                fullWidth
                error={!sZip}
                helperText={!sZip ? "PLZ ist erforderlich" : null}
              />
            )}
            onChange={handleChange}
            onInputChange={handleInputChange}
            disableClearable={true} // hides the clear button 
          />
      </Grid>

      <Grid item xs={8}>
        <TextField
          margin="normal"
          variant="outlined"
          fullWidth
          id="city"
          label="Ort"
          name="city"
          value={sCity}
          error={!sCity}
          helperText={!sCity ? "Ort ist erforderlich" : null}
          onChange={e => {
            dispatch(setCityAction(e.target.value))
          }}
        />
      </Grid>
    </Grid>
  )
}
