import * as React from 'react';
import {orangeButtonColor} from "../../../constants";
import { ButtonBase, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import CheckIcon from "@mui/icons-material/Check";

const useStyles = makeStyles((theme) => ({
  image: {
    position: 'relative',
    height: 207,
    width: 207,
    '&:hover, &$focusVisible': {
      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.1,
      },
      '& $imageTitle': {
        border: '1px solid currentColor',
      }
    },
    '&$disabled': {
      '& $disabledOverlay': {
        opacity: 0.7,
      }
    }
  },
  focusVisible: {},
  disabled: {},
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.3,
    transition: theme.transitions.create('opacity'),
  },
  disabledOverlay: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: 'lightgray',
    opacity: 0,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    width: '100%',
    position: 'relative',
    padding: `calc(${theme.spacing(2)} ${theme.spacing(4)} ${theme.spacing(1)} + 6px)`,
    background: orangeButtonColor
  },
}))

export const ImageButton = props => {
  const classes = useStyles()
  const {
    title, checked, disabled, onClick, image, children, ...rest
  } = props

  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { ...rest })
    }
    return child
  })

  return (
    <ButtonBase
      onClick={onClick}
      disabled={disabled}
      focusRipple
      key={title}
      classes={{disabled: classes.disabled}}
      className={classes.image}
      focusVisibleClassName={classes.focusVisible}
    >
      {childrenWithProps}
      <span className={classes.imageSrc} style={{backgroundImage: `url(${image})`}}/>
      <span className={classes.imageBackdrop}/>
      <span className={classes.imageButton}>
      {
        checked &&
        <CheckIcon
          color={'primary'}
          style={{
            fontSize: 45,
            bottom: '50%',
            position: 'absolute',
          }}/>
      }
      </span>
      <span className={classes.imageButton}>
        <Typography component="span" variant="subtitle1" color="inherit" className={classes.imageTitle} padding={2}>
          {title}
        </Typography>
      </span>
      <span className={classes.disabledOverlay}/>
    </ButtonBase>
  )
}

