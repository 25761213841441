export const userApiActionTypes = {
  reset: 'RESET_USER',

  readRequest: 'READ_USER_REQUEST',
  readSuccess: 'READ_USER_SUCCESS',
  readFailure: 'READ_USER_FAILURE',

  markReleaseNotesReadRequest: 'MARK_RELEASE_NOTES_READ_REQUEST',
  markReleaseNotesReadSuccess: 'MARK_RELEASE_NOTES_READ_SUCCESS',
  markReleaseNotesReadFailure: 'MARK_RELEASE_NOTES_READ_FAILURE'
}
