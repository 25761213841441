import { authHeader } from "../helpers/authHeader"
import { handleResponse } from "./httpHelper"
import { apiUrls } from "../constants"

const apiUrl = apiUrls.plansEndpoint
const ownerApiUrl = apiUrls.planOwnerEndpoint

const createPlan = plan => {
  const body = JSON.stringify(plan)
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    },
    body
  }

  return fetch(apiUrl, requestOptions).then(handleResponse)
}

const readPlan = id => {
  const url = `${apiUrl}/${id}`
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader()
    }
  }

  return fetch(url, requestOptions).then(handleResponse)
}

const deletePlan = id => {
  const url = `${apiUrl}/${id}`
  const requestOptions = {
    method: "DELETE",
    headers: {
      ...authHeader()
    }
  }

  return fetch(url, requestOptions).then(handleResponse)
}

const readPlans = (limit = 15, searchTerm = "") => {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader()
    }
  }
  const url =
    `${apiUrl}?limit=${limit}` + (searchTerm ? `&searchTerm=${searchTerm}` : "")

  return fetch(url, requestOptions).then(handleResponse)
}

const readPlanDetails = async id => {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader()
    }
  }
  const url = `${apiUrls.detailsEndpoint}?planId=${id}`

  const response = await fetch(url, requestOptions)
  return handleResponse(response)
}

const changeOwner = (id, owner) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    },
    body: JSON.stringify({ owner })
  }
  const url = `${ownerApiUrl}/${id}`

  return fetch(url, requestOptions).then(handleResponse)
}

export const planService = {
  createPlan,
  readPlan,
  deletePlan,
  readPlans,
  readPlanDetails,
  changeOwner
}
