import React from "react"
import { useSelector } from "react-redux"
import { Grid, Paper, Typography } from "@mui/material"

import makeStyles from "@mui/styles/makeStyles"

import { orangeBackgroundColor } from "../../../constants"
import { LidPlan } from "../../Plan/Configuration/Lid/LidPlan"
import { AutoResizeDiv } from "../../Plan/AutoResizeDiv"
import { IntermediateCeilingPlan } from "../../Plan/Configuration/IntermediateCeiling/IntermediateCeilingPlan"

import { Alerts } from "../../Alerts"
import { validate } from "../../../validation/lidStepValidation"

const useStyles = makeStyles(() => ({
  plan: {
    background: orangeBackgroundColor,
    padding: "1em 2em 2em 2em"
  }
}))

export const LidStep = () => {
  const classes = useStyles()
  const plan = useSelector(state => state.plan)
  const errors = validate(plan) 

  return !!plan.lid ? (
    <Paper square={true} className={classes.plan} elevation={10}>
      <Grid container spacing={1}>
        {
          errors && errors.length > 0 && 
          <Alerts errors={errors} isPlanAlert={true} />
        }
        <Grid item xs={12} key={"upper_text"}>
          <Typography
            component="p"
            variant="body2"
            align={"center"}
            style={{ fontSize: 0.92 + "rem" }}
          >
            {plan.lid.type === "subfloor"
              ? "Positionieren Sie ein Betonelement per Drag & Drop auf die Abdeckung und ziehen Sie danach am " +
                "orangen Punkt, um die gewünschte Anzahl Elemente zu erhalten. Mit einem Klick entfernen Sie das " +
                "Element."
              : "Positionieren Sie ein Deckelelement per Drag & Drop auf die Abdeckung und ziehen Sie danach am " +
                "orangen Punkt, um die gewünschte Anzahl Deckel zu erhalten. Mit einem Klick entfernen Sie das " +
                "Element."}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <AutoResizeDiv>
            <LidPlan />
          </AutoResizeDiv>
        </Grid>
      </Grid>
    </Paper>
  ) : !!plan.intermediateCeiling ? (
    <Paper square={true} className={classes.plan} elevation={10}>
      <Grid container spacing={1}>
        <Grid item xs={12} key={"upper_text"}>
          <Typography
            component="p"
            variant="body2"
            align={"center"}
            style={{ fontSize: 0.92 + "rem" }}
          >
            {"Positionieren Sie die Einstiegsöffnungen per Drag & Drop auf der Zwischendecke. Die rechteckigen " +
              "Aussparungen können in die gewünschte Grösse gezogen werden. Mit einem Klick entfernen Sie das " +
              "Element."}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <AutoResizeDiv>{<IntermediateCeilingPlan />}</AutoResizeDiv>
        </Grid>
      </Grid>
    </Paper>
  ) : null
}
