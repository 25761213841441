import {Redirect, Route} from "react-router-dom"
import React from "react"

export const GuardedRoute = ({component: Component, auth, path, navigateTo, ...rest}) => {
  return <Route {...rest} render={(props) => (
    auth() === true
      ? <Component {...props} />
      : <Redirect to={'/login?navigateTo=' + navigateTo}/>
  )}/>
}

