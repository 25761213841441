import {getManholeMaxOverlaps} from "./lid";

const getElementsOutsideLimits =  plan => {

  const isCircularOutside = (circ, width, height) =>
    circ.x + circ.outerDiameter / 2 > width
    || circ.y + circ.outerDiameter / 2 > height

  const isLinearOutside = (lin, width, height) => {
    const x = lin.x + (lin.orientation === 'horizontal' ? lin.length : 0)
    const y = lin.y + (lin.orientation === 'vertical' ? lin.length : 0)

    return x > width || y > height
  }

  const isRectangularOutside = (rect, width, height) =>
    rect.x + rect.width > width || rect.y + rect.height > height

  const isManholeOutside = manhole => {
    const overlaps = getManholeMaxOverlaps(plan, manhole)

    return manhole.x + manhole.width > plan.length + overlaps.right
      || manhole.y + manhole.height > plan.width + overlaps.back
  }

  const lidElements = () => ({
    manholes: plan.lid.manholes.filter(m => isManholeOutside(m)),
    blockOuts: plan.lid.blockOuts.filter(r => isRectangularOutside(r, plan.length, plan.width)),
    some() {
      return this.manholes.length > 0 || this.blockOuts.length > 0
    }
  })

  const intermediateCeilingElements = () => ({
    circularBlockOuts: plan.intermediateCeiling.circularBlockOuts.filter(c => isCircularOutside(c, plan.length, plan.width)),
    blockOuts: plan.intermediateCeiling.blockOuts.filter(r => isRectangularOutside(r, plan.length, plan.width)),
    some() {
      return this.circularBlockOuts.length > 0 || this.blockOuts.length > 0
    }
  })

  const vaultElements = () => {
    const wallElements = (wall, width, height) => ({
      sleeves: wall.sleeves.filter(c => isCircularOutside(c, width, height)),
      blockOuts: wall.blockOuts.filter(r => isRectangularOutside(r, width, height)),
      cRails: wall.cRails.filter(l => isLinearOutside(l, width, height)),
      groundings: wall.groundings.filter(c => isCircularOutside(c, width, height)),
      drainages: wall.drainages.filter(c => isCircularOutside(c, width, height)),
      some() {
        return this.sleeves.length > 0
          || this.blockOuts.length > 0
          || this.cRails.length > 0
          || this.groundings.length > 0
          || this.drainages.length > 0
      }
    })

    return {
      walls: [
        wallElements(plan.vault.walls[0], plan.length, plan.vault.height),
        wallElements(plan.vault.walls[1], plan.length, plan.vault.height),
        wallElements(plan.vault.walls[2], plan.width, plan.vault.height),
        wallElements(plan.vault.walls[3], plan.width, plan.vault.height)
      ],
      some() {
        return this.walls.some(w => w.some())
      }
    }
  }

  const floorElements = () => ({
    drainages: plan.floor.drainages.filter(c => isCircularOutside(c, plan.length, plan.width)),
    some() {
      return this.drainages.length > 0
    }
  })

  return {
    ...(plan.lid && {lid: lidElements()}),
    ...(plan.intermediateCeiling && {intermediateCeiling: intermediateCeilingElements()}),
    ...(plan.vault && {vault: vaultElements()}),
    ...(plan.floor && {floor: floorElements()}),
    some() {
      return (this.lid && this.lid.some())
        || (this.intermediateCeiling && this.intermediateCeiling.some())
        || (this.vault && this.vault.some())
        || (this.floor && this.floor.some())
    }
  }
}

export default getElementsOutsideLimits
