import React from "react"
import {
  Grid,
  Typography,
  Button,
  createTheme,
  Divider
} from "@mui/material"
import { useHistory, Link } from "react-router-dom"
import { primary_orange } from "../../constants"
import { mapUrl } from "../../helpers/mapsHelper"
import { ActionButtons } from "../Plans/ActionButtons"
import { lidDescription } from "../Plans/lidDescription"
import { PlanComponentIcons } from "../Plans/PlanComponentIcons"

const toConstructionSiteString = (street, zip, city) => {
  let result = !!street ? street : ""

  if (result.length > 0 && (zip || city)) {
    result += ", "
  }
  if (zip) {
    result += `${zip} `
  }
  if (city) {
    result += city
  }
  return result
}

const toDateTimeString = date => {
  return !!date ? new Date(date).toLocaleString() : ""
}

const theme = createTheme()

export const ProjectListItem = props => {
  const { project } = props
  const history = useHistory()

  return (
    <Grid item xs={12}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Grid container direction="row" spacing={3}>
                <Grid item>
                  <Link
                    to={"/projects/" + project.id}
                    style={{ textDecoration: "none" }}
                  >
                    <Typography color={"primary"} component="p" variant="body1">
                      <b>{project.plan.id}</b>
                    </Typography>
                  </Link>
                </Grid>

                <Grid item mt="3px">
                  <PlanComponentIcons plan={project.plan}></PlanComponentIcons>
                </Grid>

                <Grid item>
                  <Typography display="block" component="p" variant="body1">
                    <b>{project.description}</b>
                  </Typography>

                  <Grid item>
                    <Grid container direction="row" spacing={1}>
                      <Grid item>
                        <Typography component="p" variant="body2">
                          {toConstructionSiteString(
                            project.street,
                            project.zip,
                            project.city
                          )}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={mapUrl(project)}
                          style={{
                            color: primary_orange,
                            textDecoration: "none"
                          }}
                        >
                          <Typography component="p" variant="body2">
                            Karte
                          </Typography>
                        </a>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Typography
                    marginTop={theme.spacing(1)}
                    component="p"
                    variant="body2"
                  >
                    <b>
                      {`${project.plan.length} x ${project.plan.width} cm${
                        !!project.plan.hasLid
                          ? ", " + lidDescription(project.plan)
                          : ""
                      }`}
                    </b>
                  </Typography>
                  <Typography component="p" variant="body2">
                    {`${project.plan.customerCompany}, ${project.plan.customerCity}`}
                  </Typography>
                  <Typography component="p" variant="body2">
                    {`${project.plan.creator}, ${toDateTimeString(
                      project.plan.created
                    )}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container direction="row" spacing={3}>
                <Grid item>
                  <Grid container direction="column" alignItems="flex-end">
                    <Typography component="p" variant="body2">
                      <b>{project.requestStatus}</b>
                    </Typography>
                    <Typography component="p" variant="body2">
                      {`${toDateTimeString(project.modified)}, ${
                        project.modifiedBy
                      }`}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ color: "black", borderColor: "black" }}
                    onClick={() => {
                      history.push("/projects/" + project.id)
                    }}
                  >
                    Projekt anzeigen
                  </Button>
                </Grid>
              </Grid>
              <Grid item marginTop={theme.spacing(1)}>
                <Grid container direction="column" alignItems="flex-end">
                  <ActionButtons
                    plan={project.plan}
                    project={project}
                  ></ActionButtons>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Divider></Divider>
        </Grid>
      </Grid>
    </Grid>
  )
}
