import React from "react"

import {Arc, Circle, Line} from "react-konva"

export const renderGroupElements = (screenCircle, grounding, fillColor = '#eeeeee') => {
  const {radius} = screenCircle
  const dash = [radius  / 3, radius / 2]


  const createOuterCircle = () => {
    return (
      <React.Fragment>
        <Circle
          radius={radius}
          fill={fillColor}
          shadowColor="black"
          shadowBlur={8}
          shadowOpacity={0}
        />
        <Arc
          innerRadius={radius}
          outerRadius={radius}
          fill={fillColor}
          stroke="black"
          strokeWidth={1}
          shadowColor="black"
          shadowBlur={8}
          shadowOpacity={0}
          angle={180}
          rotationDeg={180}
          dashEnabled={grounding.insideAndOrOutside !== "outside"}
          dash={dash}
        />
        <Arc
          innerRadius={radius}
          outerRadius={radius}
          fill={fillColor}
          stroke="black"
          strokeWidth={1}
          shadowColor="black"
          shadowBlur={8}
          shadowOpacity={0}
          angle={180}
          dashEnabled={grounding.insideAndOrOutside === "inside"}
          dash={dash}
        />
      </React.Fragment>
    )
  }
  const createLine = (p1, p2) => {
    return (
      <Line
        name="dimension"
        points={[p1.x, p1.y, p2.x, p2.y]}
        strokeWidth={1}
        stroke="black"
        lineCap="round"
      />
    )
  }

  return (
    <React.Fragment>
      {createOuterCircle()}
      {createLine({x: 0, y: -0.6 * radius}, {x: 0, y: -0.3 * radius})}
      {createLine({x: -0.4 * radius, y: 0}, {x: 0.4 * radius, y: 0})}
      {createLine({x: -0.25 * radius, y: 0.3 * radius}, {x: 0.25 * radius, y: 0.3 * radius})}
      {createLine({x: -0.1 * radius, y: 0.6 * radius}, {x: 0.1 * radius, y: 0.6 * radius})}
    </React.Fragment>
  )
}
