import React, {useRef, useLayoutEffect, useState} from "react"

export const AutoResizeDiv = props => {
  const client = useRef()
  const [clientSize, setClientSize] = useState({})
  const resizeDelay = 100
  let resizeTimer = null
  const updateClientSize = () => {
    if (client.current) {
      setClientSize({
        width: client.current.offsetWidth,
        height: client.current.offsetHeight
      })
    }
  }

  useLayoutEffect(() => updateClientSize(), [])
  window.addEventListener('resize', () => {
    clearInterval(resizeTimer)
    resizeTimer = setTimeout(updateClientSize, resizeDelay)
  })

  const { children , ...rest } = props

  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { clientSize: clientSize, ...rest })
    }
    return child
  })

  return (
    <div ref={client}>
      {childrenWithProps}
    </div>
  )
}

