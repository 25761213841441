import React from "react"

import {Circle, Text} from "react-konva"
import {FONT_SIZE} from "../../../constants";

export const renderGroupElements = (screenCircle, blockOut, fill ="#eeeeee") => {
  const {radius} = screenCircle;
  return (
    <React.Fragment>
      <Circle
        name={"background"}
        radius={screenCircle.radius}
        fill={fill}
        stroke="black"
        strokeWidth={1}
        shadowColor="black"
        shadowBlur={8}
        shadowOpacity={0}
      />
      {
        !!blockOut.x && blockOut.y &&
        <Text
          name="dimension"
          x={-radius}
          y={-radius}
          width={2 * radius}
          height={2 * radius}
          fontSize={FONT_SIZE}
          align="center"
          verticalAlign="middle"
          text={blockOut.diameter * 10}
          fill="black"
        />
      }
    </React.Fragment>
  )
}
