import React from "react";

import {Rect} from 'react-konva';
import {partWallColor, partInsideColor} from '../../../../constants'

const createBackground = (configAreaLayout) => {
  return (
    <Rect
      key="background"
      x={configAreaLayout.screenX}
      y={configAreaLayout.screenY}
      width={configAreaLayout.screenWidth}
      height={configAreaLayout.screenHeight}
      fill={partInsideColor}
    />
  );
};

const createWalls = (partLayout, realWallWidth) => {
  const screenWallWidth = realWallWidth * partLayout.scale;

  return [
    (
      <Rect
        key={"leftWall"}
        x={partLayout.screenX}
        y={partLayout.screenY}
        width={screenWallWidth}
        height={partLayout.screenHeight}
        fill={partWallColor}
      />
    ),
    (
      <Rect
        key={"rightWall"}
        x={partLayout.screenX + partLayout.screenWidth - screenWallWidth}
        y={partLayout.screenY}
        width={screenWallWidth}
        height={partLayout.screenHeight}
        fill={partWallColor}
      />
    )
  ];
};

export const createPart = (partLayout, configAreaLayout, realWallWidth) => {
  return [
    createBackground(configAreaLayout),
    createWalls(partLayout, realWallWidth)
  ];
};

