import { armouringDocumentsActionTypes } from "../constants/armouringDocumentsActionTypes"
import { armouringDocumentsService } from "../services"

const createArmouringDocuments = (planId, projectId) => {
  const request = () => {
    return { type: armouringDocumentsActionTypes.createRequest, planId }
  }
  const success = () => {
    return { type: armouringDocumentsActionTypes.createSuccess, planId }
  }
  const failure = error => {
    return { type: armouringDocumentsActionTypes.createFailure, planId, error }
  }

  return dispatch => {
    dispatch(request())
    setTimeout(
      () =>
        armouringDocumentsService.createArmouringDocuments(planId, projectId).then(
          () => {
            dispatch(success())
          },
          error => {
            dispatch(failure(error))
          }
        ),
      300
    )
  }
}

export const armouringDocumentsActions = {
  createArmouringDocuments
}
