import {projectApiActionTypes} from "../constants"
import {projectService} from "../services"
import {projectActions, planActions} from "./"
import {addIntermediateCeilingSize, addLidSize, prepareImages} from "./planApiHelpers";

const createProject = (project, plan, planImages, requestType) => {
  const request = () => {
    return {type: projectApiActionTypes.createRequest}
  }
  const success = () => {
    return {type: projectApiActionTypes.createSuccess}
  }
  const failure = error => {
    return {type: projectApiActionTypes.createFailure, error}
  }

  return dispatch => {
    dispatch(request())
    setTimeout(() =>
        projectService.createProject({
          project: {
            ...project,
            requestType
          },
          plan: addLidSize(addIntermediateCeilingSize(plan)),
          planImages: prepareImages(plan, planImages)
        })
          .then(
            () => {
              dispatch(success())
              dispatch(projectActions.reset())
              dispatch(planActions.reset())
            },
            error => {
              dispatch(failure(error))
            }
          ),
      300)
  }
}

const setProjectState = (id, state, mmOfferNumber, mmOrderNumber) => {
  const request = () => {
    return {type: projectApiActionTypes.setStateRequest}
  }
  const success = data => {
    return {type: projectApiActionTypes.setStateSuccess, data}
  }
  const failure = error => {
    return {type: projectApiActionTypes.setStateFailure, error}
  }

  return (dispatch, getState) => {
    dispatch(request())
    projectService.changeStatus(id, state, mmOfferNumber, mmOrderNumber)
      .then(
        data => {
          dispatch(success(data))
          if (getState().projectDetails.project.id === id) {
            dispatch(projectApiActions.readProjectDetails(id))
          }
        },
        error => {
          dispatch(failure(error))
        }
      )
  }
}

export const resetProjects = () => {
  return {
    type: projectApiActionTypes.resetProjects
  }
}

const readProjects = (limit = 15, searchTerm = '') => {
  const request = () => {
    return {type: projectApiActionTypes.readProjectsRequest}
  }
  const success = result => {
    return {type: projectApiActionTypes.readProjectsSuccess, data: result}
  }
  const failure = error => {
    return {type: projectApiActionTypes.readProjectsFailure, error}
  }

  return dispatch => {
    dispatch(request())
    projectService.readProjects(limit, searchTerm)
      .then(
        result => dispatch(success(result)),
        error => dispatch(failure(error))
      )
  }
}

const readProjectDetails = (id) => {
  const request = () => {
    return {type: projectApiActionTypes.readProjectDetailsRequest}
  }
  const success = result => {
    return {type: projectApiActionTypes.readProjectDetailsSuccess, data: result}
  }
  const failure = error => {
    return {type: projectApiActionTypes.readProjectDetailsFailure, error}
  }

  return dispatch => {
    dispatch(request())
    projectService.readProjectDetails(id)
      .then(
        result => dispatch(success(result)),
        error => dispatch(failure(error))
      )
  }
}

export const projectApiActions = {
  createProject,
  setProjectState,
  resetProjects,
  readProjectDetails,
  readProjects
}
