import React from "react"

import {Rect, Shape} from 'react-konva'

import {partWallColor, partInsideColor} from '../../../../constants'

const createBackground = (planInsideLayout) => {
  return (
    <Rect
      key="background"
      x={planInsideLayout.screenX}
      y={planInsideLayout.screenY}
      width={planInsideLayout.screenWidth}
      height={planInsideLayout.screenHeight}
      fill={partInsideColor}
    />
  )
}

const createWalls = (partLayout, partInsideLayout) => {
  return (
    <Shape
      key="walls"
      sceneFunc={(ctx, shape) => {
        ctx.beginPath()
        ctx.moveTo(partLayout.screenX, partLayout.screenY)
        ctx.lineTo(partLayout.screenX + partLayout.screenWidth, partLayout.screenY)
        ctx.lineTo(partLayout.screenX + partLayout.screenWidth, partLayout.screenY + partLayout.screenHeight)
        ctx.lineTo(partLayout.screenX, partLayout.screenY + partLayout.screenHeight)
        ctx.moveTo(partInsideLayout.screenX, partInsideLayout.screenY)
        ctx.lineTo(partInsideLayout.screenX, partInsideLayout.screenY + partInsideLayout.screenHeight)
        ctx.lineTo(partInsideLayout.screenX + partInsideLayout.screenWidth, partInsideLayout.screenY + partInsideLayout.screenHeight)
        ctx.lineTo(partInsideLayout.screenX + partInsideLayout.screenWidth, partInsideLayout.screenY)
        ctx.closePath()
        ctx.fillStrokeShape(shape)
      }}
      fill={partWallColor}
    />
  )
}

export const createPart = (partLayout, partInsideLayout) => {
  return [
    createBackground(partInsideLayout),
    createWalls(partLayout, partInsideLayout)
  ]
}

