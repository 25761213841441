export const sleeves = [
  {
    diameter: 8.5,
    outerDiameter: 8.5,
    innerDiameter: 6.5,
    name: "K55",
    colloquialism: "K55"
  },
  {
    diameter: 10,
    outerDiameter: 10,
    innerDiameter: 7.5,
    name : "72/60",
    colloquialism: "60"
  },
  {
    diameter: 12.5,
    outerDiameter: 12.5,
    innerDiameter: 9.5,
    name : "92/80",
    colloquialism: "80"
  },
  {
    diameter: 14.5,
    outerDiameter: 14.5,
    innerDiameter: 11.5,
    name : "112/100",
    colloquialism: "100"
  },
  {
    diameter: 16.5,
    outerDiameter: 16.5,
    innerDiameter: 13.5,
    name : "132/120",
    colloquialism: "120"
  },
  {
    diameter: 21.5,
    outerDiameter: 21.5,
    innerDiameter: 16.5,
    name : "163/148",
    colloquialism: "150"
  },
  {
    diameter: 30,
    outerDiameter: 30,
    innerDiameter: 21.5,
    name : "214/195",
    colloquialism: "200"
  }
]

export const drainages = [
  {
    colloquialism: "PP-Muffe",
    name : "100 mm",
    type: '100',
    diameter: 10,
    outerDiameter: 10,
    innerDiameter: 10
  }
]

export const groundings = [
  {
    colloquialism: "innen",
    name : "",
    diameter: 5,
    outerDiameter: 5,
    innerDiameter: 5,
    insideAndOrOutside: 'inside'
  },
  {
    colloquialism: "aussen",
    name : "",
    diameter: 5,
    outerDiameter: 5,
    innerDiameter: 5,
    insideAndOrOutside: 'outside'
  },
  {
    colloquialism: "innen +",
    name : "aussen",
    diameter: 5,
    outerDiameter: 5,
    innerDiameter: 5,
    insideAndOrOutside: 'both'
  }
]

export const blockOuts = [
  {
    colloquialism: "ohne",
    name : "Füllung",
    type: "standard",
    width: 50,
    height: 30
  },
  {
    colloquialism: "Sagex-",
    name : "Einlage",
    type: "sagex",
    width: 50,
    height: 30
  }
]

export const cRails = [
  {
    colloquialism: "Edelstahl",
    name : "V4A",
    material: "stainless",
    orientation: "horizontal",
    length: 30
  },
  {
    colloquialism: "Edelstahl",
    name : "V4A",
    material: "stainless",
    orientation: "vertical",
    length: 30
  },
  {
    colloquialism: "Verzinkt",
    name : "FZ",
    material: "galvanized",
    orientation: "horizontal",
    length: 30
  },
  {
    colloquialism: "Verzinkt",
    name : "FZ",
    material: "galvanized",
    orientation: "vertical",
    length: 30
  }
]

export const vaultWallElements = [...sleeves, ...drainages, ...groundings, ...blockOuts, ...cRails]

