import React, {useEffect, useRef} from "react"

import {useDispatch, useSelector} from "react-redux"
import {Group, Layer, Stage} from "react-konva"

import {AutoResizeDiv} from "../AutoResizeDiv"
import {createLayout} from "./compositionElevationViewLayout"

import {createFloorElevationView} from "./Floor/floorElevationView"
import {createVaultElevationView} from "./Vault/vaultElevationView"
import {createLidElevationView} from "./Lid/lidElevationView"
import {
  getBevelHeight,
  getLidSize,
  getLidTopToBevelTopDistance,
  lidFunctions,
  lidIsFullyCoveredByManhole
} from "../../domain/lid"
import {getVaultSize} from "../../domain/vault"
import {getOverallSize} from "../../domain/composition"
import {
  createDimensioning,
  createMeasurementStopChainFromMeasurements,
  createMeasurementStopChainFromPositions, toWithOverallMeasurement
} from "../dimensioning"
import {getFloorSize} from "../../domain/floor"
import {getClosureSizes} from "../../domain/manhole"
import {createIntermediateCeilingElevationView} from "./IntermediateCeiling/intermediateCeilingElevationView"
import {intermediateCeilingFunctions} from "../../domain/intermediateCeiling"
import {planImageActions} from "../../../actions/planImageActions"
import {getElevationViewTopMeasurementPositions as getLidTopMeasurementPositions} from "./lidDimensioning"
import {
  getElevationViewTopMeasurementPositions as getIntermediateCeilingTopMeasurementPositions
} from "./intermediateCeilingDimensioning"

const ElevationView = props => {
  const {
    wallIndex,
    clientSize,
  } = props
  const dispatch = useDispatch()
  const theStage = useRef(null)
  const plan = useSelector(state => state.plan)

  useEffect(() => {
      if (!theStage.current) {
        return
      }
      switch (wallIndex) {
        case 0:
          dispatch(planImageActions.setSideAElevationViewImage(theStage.current.toDataURL({pixelRatio: 2})))
          break
        case 1 :
          dispatch(planImageActions.setSideBElevationViewImage(theStage.current.toDataURL({pixelRatio: 2})))
          break
        case 2 :
          dispatch(planImageActions.setSideCElevationViewImage(theStage.current.toDataURL({pixelRatio: 2})))
          break
        case 3:
          dispatch(planImageActions.setSideDElevationViewImage(theStage.current.toDataURL({pixelRatio: 2})))
          break
        default:
          break
      }
    },
    [clientSize, wallIndex, dispatch]
  )
  const innerVaultWidth = useSelector(state => state.plan.width)
  const innerVaultLength = useSelector(state => state.plan.length)
  const wallWidth = useSelector(state => state.plan.wallWidth)
  const vault = useSelector(state => state.plan.vault)
  const vaultHeight = vault ? vault.height : 0
  const floor = useSelector(state => state.plan.floor)
  const lidHeight = getLidSize(plan).height
  const hasLid = lidHeight > 0
  const floorThickness = !!floor ? floor.thickness : 0
  const getSide = () => wallIndex === 0 ? 'front' : wallIndex === 1 ? 'back' : wallIndex === 2 ? 'left' : 'right'
  const layout = createLayout(clientSize, plan, getSide())
  const getLidHeight = () => getLidSize(plan).height
  const getIntermediateCeilingHeight = () => intermediateCeilingFunctions.getHeight(plan)
  const getVaultHeight = () => !!plan.vault ? plan.vault.height : 0
  const getFloorHeight = () => !!plan.floor ? plan.floor.thickness : 0

  const getWallWidth = () => plan.wallWidth
  const getClearMeasureWidth = () => getSide() === 'front' || getSide() === 'back' ? plan.length : plan.width
  const getPartLeft = getPartSize => {
    return getSide() === 'front' ? getPartSize(plan).x - getOverallSize(plan).x
      : getSide() === 'back'
        ? (getOverallSize(plan).x + getOverallSize(plan).length) - (getPartSize(plan).x + getPartSize(plan).length)
        : getSide() === 'left'
          ? (getOverallSize(plan).y + getOverallSize(plan).width) - (getPartSize(plan).y + getPartSize(plan).width)
          : getSide() === 'right' ? getPartSize(plan).y - getOverallSize(plan).y
            : 0
  }
  const getIntermediateCeilingOrVaultOrFloorLeft = () => {
    return !!plan.intermediateCeiling ? getPartLeft(intermediateCeilingFunctions.getSize)
      : !!plan.vault ? getPartLeft(getVaultSize)
        : !!plan.floor ? getPartLeft(getFloorSize)
          : 0
  }
  const getLidLeft = () => {
    return !!plan.lid ? getPartLeft(getLidSize)
      : 0
  }
  const getClearMeasureLeft = () => {
    return getSide() === 'front' ? -getLidSize(plan).x
      : getSide() === 'back' ? getLidSize(plan).length + getLidSize(plan).x - plan.length
        : getSide() === 'left' ? getLidSize(plan).width + getLidSize(plan).y - plan.width
          : getSide() === 'right' ? -getLidSize(plan).y
            : 0
  }
  const getManholeClearMeasureLeft = manhole => {
    return getSide() === 'front' ? getClearMeasureLeft() + manhole.x
      : getSide() === 'back' ? getClearMeasureLeft() + (plan.length - manhole.width - manhole.x)
        : getSide() === 'left' ? getClearMeasureLeft() + (plan.width - manhole.height - manhole.y)
          : getSide() === 'right' ? getClearMeasureLeft() + manhole.y
            : 0
  }
  const getManholeLeft = manhole => {
    const sideHasRabbet = () => getSide() === 'front' || getSide() === 'back'
      ? manhole.orientation === 'horizontal'
      : manhole.orientation === 'vertical'
    const getRabbetWidth = () => !!getClosureSizes(manhole.type).rabbetWidth
      ? getClosureSizes(manhole.type).rabbetWidth
      : 0

    return getManholeClearMeasureLeft(manhole) - (sideHasRabbet() ? getRabbetWidth() : 0)
  }
  const getManholeClearMeasureRight = manhole => {
    return (
      getManholeClearMeasureLeft(manhole)
      + (getSide() === 'front' || getSide() === 'back' ? manhole.width
          : getSide() === 'left' || getSide() === 'front' ? manhole.height
            : 0
      )
    )
  }
  const getManholeCount = () => !!plan.lid ? plan.lid.manholes.length : 0
  const getManhole = () => getManholeCount() === 1 ? plan.lid.manholes[0] : null
  const getLidBevelWidth = () => {
    return !!plan.lid
    && (plan.lid.collar === 'beveled' || plan.lid.collar === 'pavingready')
    && getManholeCount() === 1
      ? getManholeLeft(plan.lid.manholes[0])
      : 0
  }
  const getWall = () => !!plan.vault ? plan.vault.walls[wallIndex] : null
  const getCircularVaultElements = () => {
    return !!plan.vault
      ? [...getWall().sleeves, ...getWall().groundings, ...getWall().drainages]
      : []
  }
  const getRectangularVaultElements = () => !!plan.vault ? [...getWall().blockOuts] : []
  const getLinearVaultElements = () => !!plan.vault ? [...getWall().cRails] : []

  const getTopMeasurementStops = () => {
    const getInnerMeasurements = () => {
      const transformToDrawing = (positions, side) => {
        const getBottom = () => !!plan.lid
          ? -lidFunctions.getHeight(plan)
          : !!plan.intermediateCeiling
            ? -intermediateCeilingFunctions.getHeight(plan)
            : 0

        return side === 'front'
          ? positions.map(
            p => {
              const getZero = () => -getOverallSize(plan).x

              return {
                x: getZero() + p.x,
                y: getBottom() + p.z
              }
            }
          )
          : side === 'back'
            ? positions.map(
              p => {
                const getZero = () => getOverallSize(plan).x + getOverallSize(plan).length

                return {
                  x: getZero() - p.x,
                  y: getBottom() + p.z
                }
              }
            )
            : side === 'left'
              ? positions.map(
                p => {
                  const getZero = () => getOverallSize(plan).y + getOverallSize(plan).width

                  return {
                    x: getZero() - p.y,
                    y: getBottom() + p.z
                  }
                }
              )
              : side === 'right'
                ? positions.map(
                  p => {
                    const getZero = () => -getOverallSize(plan).y

                    return {
                      x: getZero() + p.y,
                      y: getBottom() + p.z
                    }
                  }
                )
                : []
      }

      return createMeasurementStopChainFromPositions(
        transformToDrawing(
          !!plan.lid
            ? getLidTopMeasurementPositions(plan, getSide())
            : !!plan.intermediateCeiling
              ? getIntermediateCeilingTopMeasurementPositions(plan, getSide())
              : []
          ,
          getSide()
        )
      )
    }

    return toWithOverallMeasurement(getInnerMeasurements())
  }

  const getBottomMeasurementStops = () => {
    const getOuterMeasurements = () => createMeasurementStopChainFromMeasurements(
      [getWallWidth(), getClearMeasureWidth(), getWallWidth()],
      getIntermediateCeilingOrVaultOrFloorLeft()
    )
    const getInnerMeasurements = () => {
      const getXPositions = () => [
        0,
        ...getCircularVaultElements().map(e => e.x),
        ...getRectangularVaultElements().reduce((acc, curr) => [...acc, curr.x, curr.x + curr.width], []),
        ...[],
        ...getLinearVaultElements().reduce(
          (acc, curr) => [
            ...acc,
            curr.x,
            ...(curr.orientation === 'horizontal' ? [curr.x + curr.length] : [])
          ],
          []
        ),
        getClearMeasureWidth()
      ].map(x => x + getWallWidth() + getIntermediateCeilingOrVaultOrFloorLeft())

      return getXPositions().length > 2
        ? createMeasurementStopChainFromPositions(getXPositions().map(x => ({x, y: 0})))
        : []
    }

    return [getInnerMeasurements(), getOuterMeasurements()]
  }
  const getVerticalMeasurementStops = () => {
    const getFloorInnerMeasurements = () => {
      const hasElementAboveFloor = () => !!plan.vault || !!plan.intermediateCeiling || !!plan.lid
      const hasLidWithoutCollar = () => !!plan.lid && plan.lid.collar === 'without'
      const hasExtensionLineToBevelTop = () => !hasElementAboveFloor() || hasLidWithoutCollar()

      return getFloorHeight() > 0
        ? [{
          width: getFloorHeight() - 3,
          fromY: -getIntermediateCeilingOrVaultOrFloorLeft(),
          toY: -getIntermediateCeilingOrVaultOrFloorLeft()
        }, {
          width: 3,
          fromY: -getIntermediateCeilingOrVaultOrFloorLeft(),
          toY: -getIntermediateCeilingOrVaultOrFloorLeft() - (hasExtensionLineToBevelTop() ? 3 + getWallWidth() : 0)
        }]
        : []
    }
    const getVaultInnerMeasurements = () => {
      const getDistinctSortedVaultElementYPositions = () => {
        const getVaultElementYPositions = () => [
          ...getCircularVaultElements().map(e => e.y),
          ...getRectangularVaultElements().reduce((acc, curr) => [...acc, curr.y, curr.y + curr.height], []),
          ...getLinearVaultElements().reduce(
            (acc, curr) => [
              ...acc,
              curr.y,
              ...(curr.orientation === 'vertical' ? [curr.y + curr.length] : [])
            ],
            []
          ),
          getVaultHeight()
        ]

        return [...new Set(getVaultElementYPositions())].sort((a, b) => a - b)
      }

      return getVaultHeight() > 0
        ? getDistinctSortedVaultElementYPositions().reduce(
          (acc, curr) => {
            const getCurrentPosition = () => acc.reduce((acc, curr) => acc + curr.width, 0)
            const getWidth = () => curr - getCurrentPosition()

            return [
              ...acc,
              ...(getWidth() > 0
                  ? [{
                    width: getWidth(),
                    fromY: -getIntermediateCeilingOrVaultOrFloorLeft(),
                    toY: -getIntermediateCeilingOrVaultOrFloorLeft()
                  }]
                  : []
              )
            ]
          },
          []
        )
        : []
    }
    const hasSpacingBelowIntermediateCeiling = () => !!plan.intermediateCeiling && !!plan.vault
    const getSpacingBelowIntermediateCeiling = () => hasSpacingBelowIntermediateCeiling() ? 3 : 0
    const getSpacingBelowIntermediateCeilingMeasurements = () => {
      return hasSpacingBelowIntermediateCeiling()
        ? [{
          width: getSpacingBelowIntermediateCeiling(),
          fromY: -getIntermediateCeilingOrVaultOrFloorLeft(),
          toY: -getLidLeft()
        }]
        : []
    }
    const getIntermediateCeilingInnerMeasurements = () => {
      return getIntermediateCeilingHeight() > 0
        ? [{
          width: getIntermediateCeilingHeight(),
          fromY: -getIntermediateCeilingOrVaultOrFloorLeft(),
          toY: -getIntermediateCeilingOrVaultOrFloorLeft()
        }]
        : []
    }
    const hasSpacingBelowLid = () => {
      const hasElementStraightBelowLid = () => !!plan.intermediateCeiling || !!plan.vault

      return !!plan.lid
        && hasElementStraightBelowLid()
        && plan.lid.type !== 'subfloor'
        && plan.lid.collar !== 'without'
    }
    const getSpacingBelowLid = () => hasSpacingBelowLid() ? 3 : 0
    const getSpacingBelowLidMeasurements = () => {
      return hasSpacingBelowLid()
        ? [{
          width: getSpacingBelowLid(),
          fromY: -getIntermediateCeilingOrVaultOrFloorLeft(),
          toY: -getLidLeft()
        }]
        : []
    }
    const getLidInnerMeasurements = () => {
      const hasBevelMeasurement = () => {
        return !!plan.lid
          && (plan.lid.collar === 'beveled' || plan.lid.collar === 'pavingready')
          && getManholeCount() === 1
      }
      const getBeveledLidMeasurements = () => [
        {
          width: getLidHeight() - getBevelHeight(plan) - getLidTopToBevelTopDistance(plan),
          fromY: -getLidLeft(),
          toY: -getLidLeft()
        },
        {
          width: getBevelHeight(plan) + getLidTopToBevelTopDistance(plan),
          fromY: -getLidLeft(),
          toY: -getLidLeft() - getLidBevelWidth()
        }
      ]
      const hasExtensionLineToHoldingBracket = () => {
        const hasSupplementaryCovers = () => !lidIsFullyCoveredByManhole(plan)

        return plan.lid.type === 'subfloor'
          && getManholeCount() === 1
          && (!hasSupplementaryCovers() || !!plan.intermediateCeiling)
      }
      const isClosuresShortSide = () => {
        return ((getSide() === 'left' || getSide() === 'right') && getManhole().orientation === 'horizontal')
          || ((getSide() === 'front' || getSide() === 'back') && getManhole().orientation === 'vertical')
      }
      const getExtensionLineToHoldingBracketLength = () => {
        const getManholeLeftToBracketLength = () => {
          const getSizes = () => getClosureSizes(plan.lid.manholes[0].type)
          const getShortSideManholeLeftToBracketLength =
            () => (getSizes().width - getSizes().bracketWidth) / 2 + getSizes().bracketRadius
          const getLongSideManholeLeftToBracketLength =
            () => getSizes().bracketPosition

          return isClosuresShortSide()
            ? getShortSideManholeLeftToBracketLength()
            : getLongSideManholeLeftToBracketLength()
        }

        return getManholeLeft(getManhole()) + getManholeLeftToBracketLength()
      }
      const hasExtensionLineToSupplementaryCoverOnTheRight = () => {
        return getManholeCount() === 1
          && isClosuresShortSide()
          && getManholeClearMeasureLeft(getManhole()) <= getClearMeasureLeft()
      }
      const getExtensionLineToSupplementaryCoverOnTheRightLength = () => {
        return getManholeClearMeasureRight(getManhole())
      }
      const getVaultLeft = () => getPartLeft(plan => getVaultSize(plan, true))
      const hasExtensionsLineToSupplementaryCoversLongSide = () => {
        return getManholeCount() === 1
          && !isClosuresShortSide()
          && getManholeClearMeasureLeft(getManhole()) <= getVaultLeft()
      }
      const getExtensionsLineToSupplementaryCoversLongSideLength = () => {
        return getVaultLeft()
      }

      return getLidHeight() <= 0 || plan.lid.collar === 'without' ? []
        : hasBevelMeasurement()
          ? getBeveledLidMeasurements()
          : [{
            width: getLidHeight(),
            fromY: -getLidLeft(),
            toY:
              -getLidLeft()
              - (hasExtensionLineToHoldingBracket()
                  ? getExtensionLineToHoldingBracketLength()
                  : hasExtensionLineToSupplementaryCoverOnTheRight()
                    ? getExtensionLineToSupplementaryCoverOnTheRightLength()
                    : hasExtensionsLineToSupplementaryCoversLongSide()
                      ? getExtensionsLineToSupplementaryCoversLongSideLength()
                      : 0
              )
          }]
    }
    const getInnerMeasurements = () => {
      const getLidWithoutCollarMeasurements = () => {
        const isClosuresShortSide = () => {
          return ((getSide() === 'left' || getSide() === 'right') && getManhole().orientation === 'horizontal')
            || ((getSide() === 'front' || getSide() === 'back') && getManhole().orientation === 'vertical')
        }
        const getGapHeight = () => /*!!plan.floor || */!!plan.vault || !!plan.intermediateCeiling ? 3 : 0
        const getMeasurement = () => {
          return plan.lid.type === 'A15' ? 8.5
            : plan.lid.type === 'B125' ? 8
              : plan.lid.type === 'D400' ? 10.5
                : plan.lid.type === 'E600' ? 15
                  : 0
        }
        const getBottomExtensionLineX = () => getGapHeight() + getLidHeight() - getMeasurement()
        const getBottomExtensionLineY = () => {
          return -getLidLeft()
            - (plan.lid.type === 'A15' ? isClosuresShortSide() ? 0 : 2
                : plan.lid.type === 'B125' ? isClosuresShortSide() ? 0 : 1.2
                  : 0
            )
        }
        const getTopExtensionLineX = () => getGapHeight() + getLidHeight()
        const getTopExtensionLineY = () => {
          return -getLidLeft()
            - (plan.lid.type === 'A15' ? isClosuresShortSide() ? 5 : 2
                : plan.lid.type === 'B125' ? isClosuresShortSide() ? 3.5 : 3.2
                  : plan.lid.type === 'D400' ? isClosuresShortSide() ? 7.1 : 5.6
                    : plan.lid.type === 'E600' ? isClosuresShortSide() ? 7.1 : 5.6
                      : 0
            )
        }

        return !getManhole() ? []
          : [
            {
              from: {x: getBottomExtensionLineX(), y: getBottomExtensionLineY()},
              to: {x: getTopExtensionLineX(), y: getTopExtensionLineY()},
              label: getMeasurement()
            }
          ]
      }
      const stops = [
        ...getFloorInnerMeasurements(),
        ...getVaultInnerMeasurements(),
        ...getSpacingBelowIntermediateCeilingMeasurements(),
        ...getIntermediateCeilingInnerMeasurements(),
        ...getSpacingBelowLidMeasurements(),
        ...getLidInnerMeasurements()
      ]
        .reduce(
          (acc, curr, i) => {
            const getFromX = () => i > 0 ? acc[i - 1].to.x : 0
            const getToX = () => getFromX() + curr.width
            const createMeasurement = () => ({
              from: {x: getFromX(), y: curr.fromY},
              to: {x: getToX(), y: curr.toY},
              label: curr.width
            })

            return [...acc, createMeasurement()]
          },
          []
        )
      const getStopsHeight = () => {
        return stops.length < 1 ? 0 : stops[stops.length - 1].to.x
      }

      return [
        ...stops,
        ...(
          !plan.lid || plan.lid.collar !== 'without' ? []
            : getLidWithoutCollarMeasurements().map(m => ({
              ...m,
              from: {...m.from, x: m.from.x + getStopsHeight()},
              to: {...m.to, x: m.to.x + getStopsHeight()},
            }))
        )
      ]
    }
    const getMiddleMeasurements = () => {
      const getFloorMeasurements = () => {
        return getFloorHeight() > 0
          ? [{
            from: {x: 0, y: -getIntermediateCeilingOrVaultOrFloorLeft()},
            to: {x: getFloorHeight(), y: -getIntermediateCeilingOrVaultOrFloorLeft()},
            label: getFloorHeight()
          }]
          : []
      }
      const getVaultMeasurements = () => {
        return getVaultHeight() > 0 && getVaultInnerMeasurements().length > 1
          ? [{
            from: {x: getFloorHeight(), y: -getIntermediateCeilingOrVaultOrFloorLeft()},
            to: {x: getFloorHeight() + getVaultHeight(), y: -getIntermediateCeilingOrVaultOrFloorLeft()},
            label: getVaultHeight()
          }]
          : []
      }
      const getLidMeasurements = () => {
        return getLidHeight() > 0 && plan.lid.collar !== 'without' && getLidInnerMeasurements().length > 1
          ? [{
            from: {
              x: getFloorHeight()
                + getVaultHeight()
                + (getVaultHeight() > 0 && getIntermediateCeilingHeight() > 0 ? 3 : 0)
                + getIntermediateCeilingHeight()
                + getSpacingBelowLid(),
              y: -getIntermediateCeilingOrVaultOrFloorLeft()
            },
            to: {
              x: getFloorHeight()
                + getVaultHeight()
                + (getVaultHeight() > 0 && getIntermediateCeilingHeight() > 0 ? 3 : 0)
                + getIntermediateCeilingHeight()
                + getSpacingBelowLid()
                + getLidHeight(),
              y: -getIntermediateCeilingOrVaultOrFloorLeft()
            },
            label: getLidHeight()
          }]
          : []
      }

      return [
        ...getFloorMeasurements(),
        ...getVaultMeasurements(),
        ...getLidMeasurements()
      ]
    }
    const getOuterMeasurements = () => {
      const getFrom = () => getInnerMeasurements()[0].from
      const getFromX = () => getFrom().x
      const getFromY = () => getFrom().y
      const getTo = () => getInnerMeasurements()[getInnerMeasurements().length - 1].to
      const getToX = () => getTo().x
      const getToY = () => getTo().y
      const getWidth = () => getToX() - getFromX()

      return [{
        from: {x: getFromX(), y: getFromY()},
        to: {x: getToX(), y: getToY()},
        label: getWidth()
      }]
    }

    return getInnerMeasurements().length > 1 && !(!!plan.lid && plan.lid.collar === 'without')
      ? [getInnerMeasurements(), getMiddleMeasurements(), getOuterMeasurements()].filter(a => a.length > 0)
      : [getInnerMeasurements(), getMiddleMeasurements()].filter(a => a.length > 0)
  }
  const hasTopMeasurements = () => !!plan.lid || !!plan.intermediateCeiling
  const hasBottomMeasurements = () => !!plan.vault || !!plan.floor

  return (
    <div>
      {!isNaN(layout.verticalMeasurements.screenHeight) &&
      <Stage
        ref={theStage}
        name={"stage"}
        width={clientSize.width}
        height={layout.overall.screenHeight}
      >
        <Layer>
          {
            hasTopMeasurements() &&
            <Group
              x={layout.topMeasurements.screenX}
              y={layout.topMeasurements.screenY + layout.topMeasurements.screenHeight}
            >
              {
                createDimensioning(
                  getTopMeasurementStops().map(
                    r => r.map(s => ({
                      ...s
                    }))
                  ),
                  'above',
                  {
                    width: layout.topMeasurements.screenWidth,
                    height: layout.topMeasurements.screenHeight
                  },
                  layout.verticalMeasurements.scale
                )
              }
            </Group>
          }
          {
            hasLid &&
            <Group x={layout.lid.screenX} y={layout.lid.screenY}>
              {createLidElevationView(plan, layout.lid.scale, getSide())}
            </Group>
          }
          {
            layout.intermediateCeiling &&
            <Group x={layout.intermediateCeiling.screenX} y={layout.intermediateCeiling.screenY}>
              {createIntermediateCeilingElevationView(plan, layout.intermediateCeiling.scale, getSide())}
            </Group>
          }
          {
            vaultHeight > 0
            && createVaultElevationView(layout.vault, plan, innerVaultLength, innerVaultWidth, wallWidth, getSide())
          }
          {
            floorThickness > 0 &&
            <Group x={layout.floor.screenX} y={layout.floor.screenY}>
              {createFloorElevationView(plan, layout.floor.scale, getSide())}
            </Group>
          }
          <Group
            x={layout.bottomMeasurements.screenX}
            y={layout.bottomMeasurements.screenY}
          >
            {
              hasBottomMeasurements() ?
                createDimensioning(
                  getBottomMeasurementStops(),
                  'below',
                  {
                    width: layout.bottomMeasurements.screenWidth,
                    height: layout.bottomMeasurements.screenHeight
                  },
                  layout.verticalMeasurements.scale
                )
                : null
            }
          </Group>
          <Group
            x={layout.verticalMeasurements.screenX + layout.verticalMeasurements.screenWidth}
            y={layout.verticalMeasurements.screenY + layout.verticalMeasurements.screenHeight}
            rotation={-90}
          >
            {
              createDimensioning(
                getVerticalMeasurementStops(),
                'above',
                {width: layout.verticalMeasurements.screenHeight, height: layout.verticalMeasurements.screenWidth},
                layout.verticalMeasurements.scale
              )
            }
          </Group>
        </Layer>
      </Stage>
      }
    </div>
  )
}

export const CompositionElevationView = props => {
  return (
    <AutoResizeDiv>
      <ElevationView {...props} />
    </AutoResizeDiv>
  )
}
