import {handleResponse} from "./httpHelper"
import {frontendUrl} from "../constants"

const readVersion = () => {
  const url = `${frontendUrl}/version.json`
  const requestOptions = { method: 'GET' }

  return fetch(url, requestOptions).then(handleResponse)
}

export const frontendVersionService = {
  readVersion
}
