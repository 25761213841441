import React from "react"

import {Arrow, Group, Line, Rect, Text} from "react-konva"
import {anthracite, concreteGray, FONT_SIZE} from "../../../constants"
import {getClosureSizes, getTypeLabelText} from "../../domain/manhole"

const widthTextSpacing = 4
const heightTextSpacing = 11

const renderRectangle = screenRect => {
  return (
    <Rect
      name={"background"}
      width={screenRect.width}
      height={screenRect.height}
      fill="white"
      stroke="black"
      strokeWidth={1}
      shadowColor="black"
      shadowBlur={8}
      shadowOpacity={0}
    />
  )
}

const getClosureCornerPositionsAlongClosureHeights = (length, sizes, scale) => {
  if (!sizes) return []
  const fullOffset = sizes.height + sizes.rowSpacing
  const count = (length - sizes.height) / fullOffset

  return Array(count).fill(null).reduce(
    (total, _, i) => {
      const pos1 = sizes.height + i * fullOffset
      const pos2 = pos1 + sizes.rowSpacing

      return [...total, pos1 * scale, pos2 * scale]
    },
    []
  )
}

const getClosureCornerPositionsAlongClosureWidths = (length, sizes, scale) => {
  const count = length / sizes.width - 1

  return Array.from({length: count}, (_, i) => {
    return (i + 1) * sizes.width * scale
  })
}

const getClosureLinesAlongClosureHeights = (screenRect, manhole, configAreaLayout) => {
  const lengthAlongClosureWiths = manhole.orientation === 'vertical' ? manhole.width : manhole.height
  const lengthAlongClosureHeights = manhole.orientation === 'vertical' ? manhole.height : manhole.width
  const screenLengthAlongClosureHeights = manhole.orientation === 'vertical' ? screenRect.height : screenRect.width

  return getClosureCornerPositionsAlongClosureWidths(
    lengthAlongClosureWiths,
    getClosureSizes(manhole.type),
    configAreaLayout.scale
  )
    .reduce(
      (total, pAlongWidths) => {
        const newLines = [
          0,
          ...getClosureCornerPositionsAlongClosureHeights(
            lengthAlongClosureHeights,
            getClosureSizes(manhole.type),
            configAreaLayout.scale
          ),
          screenLengthAlongClosureHeights
        ]
          .reduce(
            (total, _, i, arr) => {
              return i % 2 === 0
                ? [...total, {p1: arr[i], p2: arr[i + 1]}]
                : total
            },
            [])
          .map(l => (
            manhole.orientation === 'vertical'
              ? {
                p1: {x: pAlongWidths, y: l.p1},
                p2: {x: pAlongWidths, y: l.p2}
              }
              : {
                p1: {x: l.p1, y: pAlongWidths},
                p2: {x: l.p2, y: pAlongWidths}
              }
          ))

        return [...total, ...newLines]
      },
      []
    )
}

const getClosureLinesAlongClosureWidths = (screenRect, manhole, configAreaLayout) => {
  return manhole.orientation === 'vertical'
    ?
    getClosureCornerPositionsAlongClosureHeights(
      manhole.height,
      getClosureSizes(manhole.type),
      configAreaLayout.scale
    )
      .map(p => ({
        p1: {x: 0, y: p},
        p2: {x: screenRect.width, y: p}
      }))
    :
    getClosureCornerPositionsAlongClosureHeights(
      manhole.width,
      getClosureSizes(manhole.type),
      configAreaLayout.scale
    )
      .map(p => ({
        p1: {x: p, y: 0},
        p2: {x: p, y: screenRect.height}
      }))
}

const renderClosures = (screenRect, manhole, configAreaLayout) => {
  return (
    <React.Fragment>
      {
        getClosureLinesAlongClosureHeights(screenRect, manhole, configAreaLayout)
          .map((l, i) => (
            <Line
              key={'along_heights_' + i}
              name="fill"
              points={[l.p1.x, l.p1.y, l.p2.x, l.p2.y]}
              stroke={'black'}
              strokeWidth={0.3}
            />
          ))
      }
      {
        getClosureLinesAlongClosureWidths(screenRect, manhole, configAreaLayout)
          .map((l, i) => (
            <Line
              key={'along_widths_' + i}
              name="fill"
              points={[l.p1.x, l.p1.y, l.p2.x, l.p2.y]}
              stroke={'black'}
              strokeWidth={0.3}
            />
          ))
      }
    </React.Fragment>
  )
}

const renderTypeLabel = (screenRect, manhole, configAreaLayout) => {
  const manholeHeight = getClosureSizes(manhole.type).height
  const manholeScreenHeight = manholeHeight * configAreaLayout.scale
  const distanceToBorder = 2 * FONT_SIZE + 5

  const label = (
    <React.Fragment>
      <Rect
        name="fill"
        width={140}
        height={2 * FONT_SIZE}
        stroke={'black'}
        strokeWidth={0.8}
      />
      <Text
        name="fill"
        y={FONT_SIZE / 2}
        width={140}
        height={FONT_SIZE}
        fontSize={FONT_SIZE}
        align="center"
        verticalAlign="bottom"
        text={getTypeLabelText(manhole.type)}
        fill="black"
      />
    </React.Fragment>
  )
  return manhole.orientation === 'horizontal'
    ? (
      <Group
        x={manholeScreenHeight / 2 - 70}
        y={distanceToBorder}
      >
        {label}
      </Group>
    )
    : (
      <Group
        x={distanceToBorder}
        y={manholeScreenHeight / 2 + 70}
        rotation={-90}
      >
        {label}
      </Group>
    )
}

const renderFillLabel = (screenRect, manhole, lid, configAreaLayout) => {
  const manholeHeight = getClosureSizes(manhole.type).height
  const manholeScreenHeight = manholeHeight * configAreaLayout.scale
  const distanceToBorder = 5 * FONT_SIZE
  const getText =
    () => lid.fill === 'partialfill' ? 'Pflästerung, Teilfüllung (Beton)'
      : lid.fill === 'without' ? 'Pflästerung, ohne Betonfüllung'
        : lid.fill === 'concretegray' ? 'Betongrau'
          : lid.fill === 'anthracite' ? 'Anthrazit'
            : ''
  const getColor = () => lid.fill === 'concretegray' ? concreteGray
    : lid.fill === 'anthracite' ? anthracite
      : ''
  const getXOffset =
    () => lid.fill === 'partialfill' ? 110
      : lid.fill === 'without' ? 115
        : 50
  const renderPavingIcon = (x, y, sideLength) => {
    const getLineCount = () => 3
    const getLineDistance = () => sideLength / (getLineCount() + 1)
    const getLinePosition = i => i * getLineDistance()
    const getLeft = () => x
    const getRight = () => getLeft() + sideLength
    const getTop = () => y
    const getBottom = () => y + sideLength

    const renderLine = (points, key) => <Line key={key} points={points} stroke={'black'} strokeWidth={0.3}/>

    return Array(3).fill(null).reduce(
      (total, _, i) =>
        [...total,
          renderLine(
            [getLeft() + getLinePosition(i + 1), getTop(), getLeft() + getLinePosition(i + 1), getBottom()],
            'horizontal_paving_' + i
          ),
          renderLine(
            [getLeft(), getTop() + getLinePosition(i + 1), getRight(), getTop() + getLinePosition(i + 1)],
            'vertical_paving_' + i
          ),
        ],
      []
    )
  }

  const label = (
    <React.Fragment>
      {
        (lid.fill === 'partialfill' || lid.fill === 'without') &&
        renderPavingIcon(0, 0.2 * FONT_SIZE, 20)
      }
      <Rect
        y={0.2 * FONT_SIZE + 5}
        name="fill"
        width={1.4 * FONT_SIZE}
        height={1.4 * FONT_SIZE}
        fill={getColor()}
      />
      <Text
        name="fill"
        x={2 * FONT_SIZE}
        y={FONT_SIZE / 2 + 5}
        width={200}
        height={FONT_SIZE}
        fontSize={FONT_SIZE}
        verticalAlign="bottom"
        text={getText()}
        fill="black"
      />
    </React.Fragment>
  )

  return manhole.orientation === 'horizontal'
    ? (
      <Group
        x={manholeScreenHeight / 2 - getXOffset()}
        y={distanceToBorder}
      >
        {label}
      </Group>
    )
    : (
      <Group
        x={distanceToBorder}
        y={manholeScreenHeight / 2 + getXOffset()}
        rotation={-90}
      >
        {label}
      </Group>
    )
}

const getClosureCenterPositionsAlongClosureHeights = (length, sizes, scale) => {
  const halfWidth = sizes.height / 2
  const fullOffset = sizes.height + sizes.rowSpacing
  const count = (length - sizes.height) / fullOffset + 1

  return Array.from({length: count}, (_, i) => {
    return (halfWidth + i * fullOffset) * scale
  })
}

const getClosureCenterPositionsAlongClosureWidths = (length, sizes, scale) => {
  const count = length / sizes.width

  return Array.from({length: count}, (_, i) => {
    return (i + 0.5) * sizes.width * scale
  })
}

const getHorizontalMeasurementsXPositions = (screenRect, manhole, configAreaLayout) => {
  return manhole.orientation === 'vertical'
    ? getClosureCenterPositionsAlongClosureWidths(manhole.width, getClosureSizes(manhole.type), configAreaLayout.scale)
    : getClosureCenterPositionsAlongClosureHeights(manhole.width, getClosureSizes(manhole.type), configAreaLayout.scale)
}

const getVerticalMeasurementsYPositions = (screenRect, manhole, configAreaLayout) => {
  return manhole.orientation === 'vertical'
    ? getClosureCenterPositionsAlongClosureHeights(manhole.height, getClosureSizes(manhole.type), configAreaLayout.scale)
    : getClosureCenterPositionsAlongClosureWidths(manhole.height, getClosureSizes(manhole.type), configAreaLayout.scale)
}

const renderMeasurements = (screenRect, manhole, configAreaLayout) => {
  const sizes = getClosureSizes(manhole.type)
  const measurement = manhole.orientation === 'vertical'
    ? {horizontal: sizes.width, vertical: sizes.height}
    : {horizontal: sizes.height, vertical: sizes.width}
  const horizontalMeasurementXPositions = getHorizontalMeasurementsXPositions(screenRect, manhole, configAreaLayout)
  const verticalMeasurementYPositions = getVerticalMeasurementsYPositions(screenRect, manhole, configAreaLayout)

  return (
    <React.Fragment>
      {
        horizontalMeasurementXPositions.map((p, i) => {
          return (<Text
              key={'h' + i}
              name="fill"
              x={p - 50}
              y={screenRect.height - FONT_SIZE - widthTextSpacing}
              width={100}
              height={FONT_SIZE}
              fontSize={FONT_SIZE}
              align="center"
              verticalAlign="bottom"
              text={measurement.horizontal}
              fill="black"
            />
          )
        })
      }
      {
        verticalMeasurementYPositions.map((p, i) => {
          return (
            <Text
              key={'v' + i}
              name="fill"
              x={screenRect.width - 100 - heightTextSpacing}
              y={p - FONT_SIZE / 2}
              width={100}
              height={FONT_SIZE}
              fontSize={FONT_SIZE}
              align="right"
              verticalAlign="middle"
              text={measurement.vertical}
              fill="black"
            />
          )
        })
      }
    </React.Fragment>
  )
}

const getGirderCenterPositions = (length, sizes, scale) => {
  const count = (length - sizes.height) / (sizes.height + sizes.rowSpacing)
  const firstOffset = sizes.height + sizes.rowSpacing / 2
  const subsequentOffset = sizes.height + sizes.rowSpacing

  return Array.from({length: count}, (_, i) => {
    return (firstOffset + i * subsequentOffset) * scale
  })
}

const renderGirderLabels = (screenRect, manhole, configAreaLayout) => {
  const sizes = getClosureSizes(manhole.type)
  const lengthAlongClosureHeight = manhole.orientation === 'vertical'
    ? manhole.height
    : manhole.width
  const girderPositions = getGirderCenterPositions(lengthAlongClosureHeight, sizes, configAreaLayout.scale)

  return manhole.orientation === 'vertical'
    ? (
      <React.Fragment>
        {
          girderPositions.map((p, i) => {
            return (
              <Text
                key={'girderLabel' + i}
                name="fill"
                x={screenRect.width / 2 - 100}
                y={p - FONT_SIZE / 2}
                width={200}
                height={FONT_SIZE}
                fontSize={FONT_SIZE}
                align="center"
                verticalAlign="bottom"
                text={'Stahlträger herausnehmbar'}
                fill="black"
              />
            )
          })
        }
      </React.Fragment>
    )
    : (
      <React.Fragment>
        {
          girderPositions.map((p, i) => {
            return (
              <Text
                key={'girderLabel' + i}
                name="fill"
                x={p - FONT_SIZE / 2}
                y={screenRect.height / 2 + 100}
                rotation={-90}
                width={200}
                height={FONT_SIZE}
                fontSize={FONT_SIZE}
                align="center"
                verticalAlign="bottom"
                text={'Stahlträger herausnehmbar'}
                fill="black"
              />
            )
          })
        }
      </React.Fragment>
    )
}

const renderResizeLabels = (screenRect, manhole) => {
  const resizeRowsText = manhole.type === 'V2A-1000' || manhole.type === 'V2A-10' ? 'Reihen ändern' : ''
  const resizeColumnsText = 'Grösse ändern'
  const topLabelText = manhole.orientation === 'vertical' ? resizeRowsText : resizeColumnsText
  const rightLabelText = manhole.orientation === 'vertical' ? resizeColumnsText : resizeRowsText

  return (
    <React.Fragment>
      <Text
        name="fill"
        x={screenRect.width / 2 - 100}
        y={FONT_SIZE / 2 + 5}
        width={200}
        height={FONT_SIZE}
        fontSize={FONT_SIZE}
        align="center"
        verticalAlign="bottom"
        text={topLabelText}
        fill="black"
      />
      <Text
        name="fill"
        x={screenRect.width - FONT_SIZE * 3 -5}
        y={screenRect.height / 2 + 100}
        rotation={-90}
        width={200}
        height={FONT_SIZE}
        fontSize={FONT_SIZE}
        align="center"
        verticalAlign="bottom"
        text={rightLabelText}
        fill="black"
      />
    </React.Fragment>
  )
}

const renderToolbarElementOpeningDirection = (screenRect, manhole) => {
  const rectToVertical = () => {
    return manhole.orientation === 'vertical'
      ? screenRect
      : {...screenRect, width: screenRect.height, height: screenRect.width}
  }
  const arrowLeft = () => rectToVertical().width * 0.2
  const arrowRight = () => rectToVertical().width * 0.8
  const arrowTop = () => rectToVertical().height * 0.2
  const arrow = () => {
    return <React.Fragment>
      <Arrow
        key={'arrow_'}
        points={[arrowLeft(), arrowTop(), arrowRight(), arrowTop()]}
        strokeWidth={0.5}
        stroke={'black'}
        fill={"black"}
        pointerWidth={5}
      />
    </React.Fragment>
  }
  const scale = () => {
    return (manhole.orientation === 'vertical' && manhole.openingDirection === 'right')
    || (manhole.orientation === 'horizontal' && manhole.openingDirection === 'up')
      ? 1
      : -1
  }
  const rotation = () => manhole.orientation === 'vertical' ? 0 : -90
  const offsetX = () => {
    return manhole.orientation === 'vertical'
      ? manhole.openingDirection === 'right'
        ? 0
        : rectToVertical().width
      : manhole.openingDirection === 'down'
        ? 0
        : rectToVertical().width
  }

  return (
    <Group scaleX={scale()} rotation={rotation()} offsetX={offsetX()} name={'fill'}>
      {arrow()}
    </Group>
  )
}

const renderDirections = (screenRect, manhole, configAreaLayout) => {
  const rectToVertical = () => {
    return {
      width: getClosureSizes(manhole.type).width * configAreaLayout.scale,
      height: getClosureSizes(manhole.type).height * configAreaLayout.scale
    }
  }
  const rotation = () => manhole.orientation === 'vertical' ? 0 : -90
  const openingDirectionY = () => rectToVertical().height * 0.1
  const drivingDirectionY = () => rectToVertical().height * 0.15
  const labelWidth = () => 110
  const labelLeft = () => (rectToVertical().width - labelWidth()) / 2
  const labelRight = () => labelLeft() + labelWidth()

  const renderArrows = () => {
    const scale = () => {
      return (manhole.orientation === 'vertical' && manhole.openingDirection === 'right')
      || (manhole.orientation === 'horizontal' && manhole.openingDirection === 'up')
        ? 1
        : -1
    }
    const offsetX = () => {
      return manhole.orientation === 'vertical'
        ? manhole.openingDirection === 'right'
          ? 0
          : rectToVertical().width
        : manhole.openingDirection === 'down'
          ? 0
          : rectToVertical().width
    }
    const renderOpeningDirectionArrow = () => {
      const arrowLeft = () => labelRight() + 10
      const arrowRight = () => rectToVertical().width * 0.9

      return (
        <Group scaleX={scale()} rotation={rotation()} offsetX={offsetX()} name={'fill'}>
          <Arrow
            key={'arrow_'}
            points={[arrowLeft(), openingDirectionY(), arrowRight(), openingDirectionY()]}
            strokeWidth={1}
            stroke={'black'}
            fill={"black"}
            pointerWidth={5}
          />
        </Group>
      )
    }
    const renderDrivingDirectionArrow = () => {
      const arrowLeft = () => rectToVertical().width * 0.1
      const arrowRight = () => labelLeft()

      return (
        <Group scaleX={scale()} rotation={rotation()} offsetX={offsetX()} name={'fill'}>
          <Arrow
            key={'arrow_'}
            points={[arrowRight(), drivingDirectionY(), arrowLeft(), drivingDirectionY()]}
            strokeWidth={3}
            stroke={'black'}
            fill={"black"}
            pointerWidth={7}
          />
        </Group>
      )
    }

    return (
      <React.Fragment>
        {renderOpeningDirectionArrow()}
        {renderDrivingDirectionArrow()}
      </React.Fragment>
    )
  }
  const renderLabels = () => {
    const offsetX = () => {
      return manhole.orientation === 'vertical'
        ? 0
        : rectToVertical().width
    }
    const renderOpeningDirectionLabel = () => {
      const labelTop = () => openingDirectionY() - FONT_SIZE / 2

      return (
        <Group rotation={rotation()} offsetX={offsetX()} name={'fill'}>
          <Text
            name="fill"
            x={labelLeft()}
            y={labelTop()}
            width={labelWidth()}
            height={FONT_SIZE}
            fontSize={FONT_SIZE}
            align="center"
            verticalAlign="bottom"
            text={"Öffnungsrichtung"}
            fill="black"
          />
        </Group>
      )
    }
    const renderDrivingDirectionLabel = () => {
      const labelTop = () => drivingDirectionY() - FONT_SIZE / 2

      return (
        <Group rotation={rotation()} offsetX={offsetX()} name={'fill'}>
          <Text
            name="fill"
            x={labelLeft()}
            y={labelTop()}
            width={labelWidth()}
            height={FONT_SIZE}
            fontSize={FONT_SIZE}
            align="center"
            verticalAlign="bottom"
            text={"Fahrtrichtung"}
            fill="black"
          />
        </Group>
      )
    }
    return (
      <React.Fragment>
        {renderOpeningDirectionLabel()}
        {renderDrivingDirectionLabel()}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {renderArrows()}
      {renderLabels()}
    </React.Fragment>
  )
}

export const renderGroupElements = (screenRect, manhole, lid, configAreaLayout) => {
  const isPlanElement = !!manhole && Number.isFinite(manhole.x) && Number.isFinite(manhole.y)

  return (
    <React.Fragment>
      {renderRectangle(screenRect)}
      {isPlanElement && renderClosures(screenRect, manhole, configAreaLayout)}
      {isPlanElement && renderTypeLabel(screenRect, manhole, configAreaLayout)}
      {isPlanElement && renderFillLabel(screenRect, manhole, lid, configAreaLayout)}
      {isPlanElement && renderGirderLabels(screenRect, manhole, configAreaLayout)}
      {isPlanElement && renderResizeLabels(screenRect, manhole)}
      {isPlanElement && renderMeasurements(screenRect, manhole, configAreaLayout)}
      {!isPlanElement && !!manhole.openingDirection && renderToolbarElementOpeningDirection(screenRect, manhole)}
      {isPlanElement && !!manhole.openingDirection && renderDirections(screenRect, manhole, configAreaLayout)}
    </React.Fragment>
  )
}
