import React from "react";

import {Grid, TextField, Typography} from "@mui/material";
import {planActions} from "../../../actions";
import {useDispatch, useSelector} from "react-redux";

const TitleGridItem = () => {
  return (
    <Grid item xs={12}>
      <Typography component="h1" variant="h4">
        {"Kabelschacht"}
      </Typography>
      < hr/>
    </Grid>
  );
};

const DescriptionGridItem = () => {
  const dispatch = useDispatch();
  const description = useSelector(state => state.plan.description);

  return (
    <Grid item xs={12}>
      <TextField
        variant="outlined"
        multiline
        minRows={2}
        fullWidth
        margin="normal"
        id="description"
        label="Schachtbezeichnung"
        name="description"
        value={description}
        onChange={e => {
          dispatch(planActions.setDescription(e.target.value));
        }}
      />
    </Grid>
  );
};

export const PlanDescriptionSection = () => {
  return (
    <Grid container spacing={1}>
      <TitleGridItem/>
      <DescriptionGridItem/>
    </Grid>
  );
};

