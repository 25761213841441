import {createHorizontalMeasurements, createVerticalMeasurements} from "./measurement";

const getCircularElementsXPositions = circularElements => {
  return circularElements.map(element => {
    return element.x
  })
}

const getLinearElementsXPositions = elements => {
  return elements.reduce(
    (acc, curr) => {
      return acc.concat(
        [curr.x, ...(curr.orientation === 'horizontal' ? [curr.x + curr.length] : [])])
    },
    [])
}

const getRectangularElementsXPositions = elements => {
  return elements.reduce(
    (acc, curr) => {
      return acc.concat(curr.x, curr.x + curr.width)
    },
    [])
}

const getMeasurements = (positions, reverseOrder = false) => {
  if (reverseOrder) {
    positions.sort((a, b) => b - a)
  } else {
    positions.sort((a, b) => a - b)
  }
  const distinctPositions = [...new Set(positions)]
  let current = 0
  let measurements = distinctPositions.map((value) => {
    const width = value - current
    current = value

    return width
  })

  return measurements.filter(val => val > 0)
}

export const getPartialXMeasurements = (circularElements, linearElements, rectangularElements, realWidth) => {
  const xPositions =
    getCircularElementsXPositions(circularElements)
      .concat(getLinearElementsXPositions(linearElements))
      .concat(getRectangularElementsXPositions(rectangularElements))
      .concat(0, realWidth)

  if (xPositions.length < 1) {
    return []
  }
  return getMeasurements(xPositions)
}

const getCircularElementsYPositions = circularElements => {
  return circularElements.map(element => {
    return element.y
  })
}

const getLinearElementsYPositions = elements => {
  return elements.reduce(
    (acc, curr) => {
      return acc.concat(
        [curr.y, ...(curr.orientation === 'vertical' ? [curr.y + curr.length] : [])])
    },
    [])
}

const getRectangularElementsYPositions = elements => {
  return elements.reduce(
    (acc, curr) => {
      return acc.concat(curr.y, curr.y + curr.height)
    },
    [])
}

export const getPartialYMeasurements = (circularElements, linearElements, rectangularElements, realHeight) => {
  const yPositions =
    getCircularElementsYPositions(circularElements)
      .concat(getLinearElementsYPositions(linearElements))
      .concat(getRectangularElementsYPositions(rectangularElements))
      .concat(0, realHeight)

  if (yPositions.length < 1) {
    return []
  }
  return getMeasurements(yPositions)
}

export const createMeasurements = (
  circularElements,
  linearElements,
  rectangularElements,
  innerWidth,
  wallWidth,
  horizontalMeasurementsLayout,
  verticalMeasurementsLayout,
) => {
  let horizontalMeasurementRows = [[wallWidth, innerWidth, wallWidth]]
  let horizontalOffsets = [0]
  const partialXMeasurements = getPartialXMeasurements(circularElements, linearElements, rectangularElements, innerWidth)

  if (partialXMeasurements.length > 1) {
    horizontalMeasurementRows.unshift(partialXMeasurements)
    horizontalOffsets.unshift(wallWidth)
  }
  let verticalMeasurementRows = [[verticalMeasurementsLayout.realHeight]]
  const partialYMeasurements = getPartialYMeasurements(
    circularElements,
    linearElements,
    rectangularElements,
    verticalMeasurementsLayout.realHeight
  )

  if (partialYMeasurements.length > 1) {
    verticalMeasurementRows.push(partialYMeasurements)
  }
  return [
    createHorizontalMeasurements(
      horizontalMeasurementsLayout,
      horizontalMeasurementRows,
      horizontalOffsets),
    createVerticalMeasurements(
      verticalMeasurementsLayout,
      verticalMeasurementRows)
  ]
}
