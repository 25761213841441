import {apiUrls} from "../constants"
import {authHeader} from "../helpers/authHeader"

const registrationUrl = apiUrls.authenticationService + "/registration.php"
const emailVerificationUrl = apiUrls.authenticationService + "/verification.php"
const loginUrl = apiUrls.authenticationService + "/login.php"
const extendLoginUrl = apiUrls.authenticationService + "/extendLogin.php"
const resetPasswordUrl = apiUrls.authenticationService + "/reset.php"
const changePasswordUrl = apiUrls.authenticationService + "/change.php"

const handleResponse = response => {
  return response.text().then(text => {
    const data = text && JSON.parse(text)

    if (!response.ok) {
      if (response.status === 401) {
        logout()
      }
      const error = (data && data.message) || response.statusText

      return Promise.reject(error)
    }
    return data
  })
}

const register = user => {
  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({...user})
  }

  return fetch(registrationUrl, requestOptions)
    .then(handleResponse)
}

const verifyEmail = verificationCode => {
  return fetch(emailVerificationUrl + "?code=" + verificationCode)
    .then(handleResponse)
}

const login = (email, password) => {
  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({email, password})
  }

  return fetch(loginUrl, requestOptions)
    .then(handleResponse)
    .then(user => {
      localStorage.setItem('user', JSON.stringify(user))
      return user
    })
}

const extendLogin = () => {
  const requestOptions = {
    method: 'GET',
    headers: {
      ...authHeader()
    }
  }

  return fetch(extendLoginUrl, requestOptions)
    .then(handleResponse)
    .then(user => {
      localStorage.setItem('user', JSON.stringify(user))
      return user
    })
}

const logout = () => {
  localStorage.removeItem('user')
}

const resetPassword = email => {
  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({email})
  }

  return fetch(resetPasswordUrl, requestOptions)
    .then(handleResponse)
}

const changePassword = (resetCode, password) => {
  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({passwordResetCode: resetCode, password})
  }

  return fetch(changePasswordUrl, requestOptions)
    .then(handleResponse)
}

export const authenticationService = {
  register,
  verifyEmail,
  login,
  extendLogin,
  logout,
  resetPassword,
  changePassword
}
