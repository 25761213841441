import {renderGroupElements as renderDrainageGroupElements} from "../../Element/drainageRenderFactory"
import {getIconScale, getItemX, getToolbarItemWidth} from "../Toolbar/toolbarFunctions";
import {drainages} from "./floorElements";
import {renderCircularElementToolbarItem} from "../Toolbar/circularElementToolbarFunctions";
import {getToolbarItemHeight, getToolbarItemY, renderGroupBox} from "../Toolbar/groupBox";
import React from "react";

export const renderToolbar = (domainFunctions, toolbarLayout, configAreaLayout) => {
  const invisibleItemCount = 8
  const itemsPerGroupCounts = [drainages.length + invisibleItemCount]
  const itemHeight = getToolbarItemHeight(toolbarLayout.screenHeight)
  const itemY = getToolbarItemY(toolbarLayout.screenY)
  const itemWidth = getToolbarItemWidth(itemsPerGroupCounts, toolbarLayout.screenWidth)
  const iconScale = getIconScale(itemWidth, itemHeight, drainages)
  const getX = (groupIndex, itemIndex) =>
    toolbarLayout.screenX
    + getItemX(itemsPerGroupCounts, toolbarLayout.screenWidth, groupIndex, itemIndex)
  const renderGroup = (groupIndex, itemCount, groupLabelText, labelTextLength) =>
    renderGroupBox(
      getX(groupIndex, 0),
      toolbarLayout.screenY,
      (itemCount + invisibleItemCount) * itemWidth,
      toolbarLayout.screenHeight,
      groupLabelText,
      labelTextLength
    )
  const renderItem = (
    groupIndex,
    index,
    element,
    scaleFactor,
    domainFunctions,
    renderGroupElementsFunctions,
    renderFunction
  ) =>
    renderFunction(
      index,
      element,
      getX(groupIndex, index + invisibleItemCount / 2),
      itemY,
      itemWidth,
      toolbarLayout.screenHeight,
      iconScale * scaleFactor,
      configAreaLayout,
      domainFunctions,
      renderGroupElementsFunctions
    )

  return (
    <React.Fragment>
      {renderGroup(0, drainages.length, 'Abläufe', 60)}
      {
        drainages.map((curr, i) =>
          renderItem(0, i, curr, 1, domainFunctions.drainageFunctions, renderDrainageGroupElements, renderCircularElementToolbarItem)
        )
      }
    </React.Fragment>
  )
}
