import {projectActionTypes as actionTypes} from "../constants"

const reset = () => {
  return {
    type: actionTypes.reset
  }
}

const setVaultQuantity = quantity => {
  return {
    type: actionTypes.setVaultQuantity,
    data: quantity
  }
}

const setLidQuantity = quantity => {
  return {
    type: actionTypes.setLidQuantity,
    data: quantity
  }
}

const setIntermediateCeilingQuantity = quantity => {
  return {
    type: actionTypes.setIntermediateCeilingQuantity,
    data: quantity
  }
}

const setFloorQuantity = quantity => {
  return {
    type: actionTypes.setFloorQuantity,
    data: quantity
  }
}

export const projectActions = {
  reset,
  setVaultQuantity,
  setLidQuantity,
  setIntermediateCeilingQuantity,
  setFloorQuantity
}
