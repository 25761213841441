import React from "react"
import {Layer, Stage} from "react-konva"
import {useSelector, useDispatch} from "react-redux"
import {createPart} from "./part"
import {createBottomAlignedGrid} from "../grid"
import {createMeasurements} from "../../configurationPlanMeasurement"
import {createToolbar} from "./vaultPlanToolbar"
import {createCircularElementCrossHairLayer} from "../../Element/circularElementCrossHairs"
import {createRectangularElementCrossHairLayer} from "../../Element/rectangularElementCrossHairs"
import {createLinearElementCrossHairLayer} from "../../Element/linearElementCrossHairs"
import {AutoResizeDiv} from "../../AutoResizeDiv"
import {renderGroupElements as renderSleeveGroupElements} from "../../Element/sleeveRenderFactory"
import {renderGroupElements as renderDrainageGroupElements} from "../../Element/drainageRenderFactory"
import {renderGroupElements as renderGroundingGroupElements} from "../../Element/groundingRenderFactory"
import {renderGroupElements as renderBlockOutGroupElements} from "../../Element/blockOutRenderFactory"
import {renderGroupElements as renderCRailGroupElements} from "../../Element/cRailRenderFactory"
import {renderCircularPlanElement} from "../../Element/circularElementFunctions"
import {renderRectangularPlanElement} from "../../Element/rectangularElementFunctions";
import {renderLinearPlanElement} from "../../Element/linearElementFunctions";
import {createVaultDomainFunctions} from "../../../domain/vaultDomainFunctions";
import {createElevationPlanLayout} from "../planLayout";

const Plan = props => {
  const {
    wallIndex,
    isToolbarVisible,
    clientSize
  } = props
  const dispatch = useDispatch()
  const plan = useSelector(state => state.plan)
  const planWidth = useSelector(state => state.plan.width)
  const planLength = useSelector(state => state.plan.length)
  const wallWidth = useSelector(state => state.plan.wallWidth)
  const vault = useSelector(state => state.plan.vault)
  const planHeight = vault ? vault.height : 0
  const wall = vault && wallIndex >= 0 ? vault.walls[wallIndex] : null
  const sleeves = wall ? wall.sleeves : []
  const blockOuts = wall ? wall.blockOuts : []
  const groundings = wall ? wall.groundings : []
  const drainages = wall ? wall.drainages : []
  const cRails = wall ? wall.cRails : []
  const layout = createElevationPlanLayout(clientSize, plan, wallIndex, isToolbarVisible)
  const width = wallIndex === 0 || wallIndex === 1 ? planLength : planWidth
  const domainFunctions = createVaultDomainFunctions(dispatch, width, planHeight, wallIndex)

  return (
    <div>
      {!isNaN(layout.scale) && vault &&
      <Stage
        name={"stage"}
        width={clientSize.width}
        height={layout.overall.screenHeight}
      >
        <Layer>
          {createPart(layout.part, layout.configArea, wallWidth)}
          {wallIndex >= 0 && createBottomAlignedGrid(layout.configArea, layout.configAreaRealWorldTransform)}
          {
            createMeasurements(
              [...sleeves, ...groundings, ...drainages],
              cRails,
              blockOuts,
              layout.configArea.realWidth,
              wallWidth,
              layout.horizontalMeasurements,
              layout.verticalMeasurements)
          }
          {
            sleeves.map(sleeve => renderCircularPlanElement(
              domainFunctions.sleeveFunctions,
              layout.configArea,
              sleeve,
              renderSleeveGroupElements
            ))
          }
          {
            drainages.map(drainage => renderCircularPlanElement(
              domainFunctions.drainageFunctions,
              layout.configArea,
              drainage,
              renderDrainageGroupElements
            ))
          }
          {
            blockOuts.map(blockOut => renderRectangularPlanElement(
              domainFunctions.blockOutFunctions,
              layout.configArea,
              blockOut,
              renderBlockOutGroupElements
            ))
          }
          {
            cRails.map(cRail => renderLinearPlanElement(
              domainFunctions.cRailFunctions,
              layout.configArea,
              cRail,
              renderCRailGroupElements
            ))
          }
          {
            groundings.map(grounding => renderCircularPlanElement(
              domainFunctions.groundingFunctions,
              layout.configArea,
              grounding,
              renderGroundingGroupElements
            ))
          }
          {
            isToolbarVisible
              ? createToolbar(
              layout.toolbar,
              layout.configArea,
              wallIndex,
              domainFunctions
              )
              : null
          }
        </Layer>
        {createRectangularElementCrossHairLayer()}
        {createLinearElementCrossHairLayer()}
        {createCircularElementCrossHairLayer()}
      </Stage>
      }
    </div>
  )
}

export const VaultPlan = props => {
  return (
    <AutoResizeDiv>
      <Plan {...props}/>
    </AutoResizeDiv>
  )
}

