import {userApiActionTypes as actionTypes} from "../constants";

const initialState = {};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.reset:
    case actionTypes.readRequest:
    case actionTypes.readFailure:
      return initialState;

    case actionTypes.readSuccess:
      return action.data;

    default:
      return state;
  }
};