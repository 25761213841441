import React from "react"

import {useTheme, Paper, Typography, RadioGroup, Radio} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

import {orangeBackgroundColor} from "../../../constants"
import FormControlLabel from "@mui/material/FormControlLabel";
import {BillingSection} from "./BillingSection";
import {useDispatch, useSelector} from "react-redux";
import {billingActions} from "../../../actions";
import Grid from "@mui/material/Grid";

const useStyles = theme => {
  return makeStyles({
    plan: {
      background: orangeBackgroundColor,
      padding: theme.spacing(6)
    },
    italic: {
      fontStyle: 'italic',
    }
  })()
}

const TitleItem = () => {
  return (
    <React.Fragment>
      <Typography component="h1" variant="h4">
        Rechnungsadresse
      </Typography>
      <hr/>
    </React.Fragment>
  )
}

export const BillingStep = () => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const dispatch = useDispatch()
  const billing = useSelector(state => state.project.billing)
  const customer = useSelector(state => state.user)
  const hasCustomBilling = useSelector(state => !!state.project.billing)

  const handleCustomBillingChange = event => {
    const customBilling = event.target.value === 'true'

    dispatch(customBilling ? billingActions.setBilling() : billingActions.unsetBilling())
  }

  return (
    <Paper square={true} className={classes.plan}>
      <TitleItem/>
      <Grid mt={4} mb={2}>
        <RadioGroup
          value={hasCustomBilling}
          onChange={handleCustomBillingChange}
        >
          <Grid>
            <FormControlLabel value={false} control={<Radio/>} label="Gleich wie Kundenadresse"/>
            <Typography ml={4}>
              <b> {customer.company},</b> {customer.lastname} {customer.firstname}, {customer.address}, {customer.zip} {customer.city}
            </Typography>
          </Grid>
          <Grid mt={3}>
            <FormControlLabel value={true} control={<Radio/>} label="Alternative Rechnungsadresse erfassen"/>
          </Grid>
        </RadioGroup>
      </Grid>
      {!!billing &&
        <BillingSection></BillingSection>
      }
    </Paper>
  )
}
