import {billingApiActionTypes as actionTypes} from "../constants"

const initialState = []

export const billingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.resetBillings:
      return initialState

    case actionTypes.readBillingsRequest:
      return {fetching: true}

    case actionTypes.readBillingsFailure:
      return {error: action.error}

    case actionTypes.readBillingsSuccess:
      return Array.isArray(action.data) ? [...action.data] : []

    default:
      return state
  }
}
