import { useTheme, Button, Alert, backdropClasses } from "@mui/material"
import Grid from "@mui/material/Grid"
import makeStyles from "@mui/styles/makeStyles"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

const useStyles = theme => {
  return makeStyles({
    alert: {
      margin: theme.spacing(1)
    },
    planAlert: {
      margin: theme.spacing(1),
      color: "#0055d4",
      backgroundColor: "transparent",
      "& .MuiAlert-icon": {
        color: "#0055d4"
      }
    }
  })()
}

const AlertItem = props => {
  const { error, isPlanAlert } = props
  const theme = useTheme()
  const classes = useStyles(theme)
  const dispatch = useDispatch()
  const history = useHistory()

  const hasButton = error.resolveAction || error.resolveLocation
  const buttonText = error.resolveAction
    ? error.resolveActionText
    : error.resolveLocation
    ? error.resolveLocationText
    : ""
  const buttonAction = error.resolveAction
    ? () => dispatch(error.resolveAction())
    : error.resolveLocation
    ? () => history.push(error.resolveLocation)
    : null

  return (
    <Alert
      sx={{ whiteSpace: "pre-line" }}
      severity={error.severity ? error.severity : "error"}
      className={isPlanAlert ? classes.planAlert : classes.alert}
      action={
        hasButton && (
          <Button color="inherit" size="small" onClick={buttonAction}>
            {" "}
            {buttonText}{" "}
          </Button>
        )
      }
      backgroundcolor="transparent"
    >
      {error.message}
    </Alert>
  )
}

export const Alerts = props => {
  const { errors, isPlanAlert } = props
  return (
    <Grid item xs={12} key={"upper_text"}>
      {errors.map((e, i) => (
        <AlertItem key={"error_" + i} error={e} isPlanAlert={isPlanAlert} />
      ))}
    </Grid>
  )
}
