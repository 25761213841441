import {getGridLinePositions} from "../Plan/Configuration/gridLinePositions";
import {SNAP_GRID_SIZE} from "../../constants";
import {snap} from "../Plan/snap";

export const createLidAndIntermediateCeilingBlockOutDomainFunctions = (dispatch, plan, actions) => {
  const snapBlockOutPosition = blockOut => {
    const getSnapPositions = (length, elementLength) => [
      0,
      ...getGridLinePositions(length - elementLength, SNAP_GRID_SIZE, true),
      (length - elementLength),
      (length - elementLength) / 2
    ].filter(p =>
      p <= length - elementLength
      && p >= 0
    )
    const getXSnapPositions = () => getSnapPositions(plan.length, blockOut.width)
    const getYSnapPositions = () => getSnapPositions(plan.width, blockOut.height)

    return {...blockOut, x: snap(blockOut.x, getXSnapPositions()), y: snap(blockOut.y, getYSnapPositions())}
  }
  const snapBlockOutSize = (blockOut, direction) => {
    const round = val => Math.round(val * 10) / 10
    const getSnapPositions = length => getGridLinePositions(length, SNAP_GRID_SIZE, true)

    const getXSnapPositions = () => getSnapPositions(plan.length)
    const getYSnapPositions = () => getSnapPositions(plan.width)

    switch (direction) {
      case 'left': {
        const x = snap(blockOut.x, getXSnapPositions().filter(p => p < blockOut.x + blockOut.width))
        const width = round((blockOut.x + blockOut.width - x))

        return {...blockOut, x, width}
      }
      case 'right': {
        const right = snap(blockOut.x + blockOut.width, getXSnapPositions().filter(p => p > blockOut.x))
        const width = round(right - blockOut.x)
        const x = round((right - width))

        return {...blockOut, x, width}
      }
      case 'top': {
        const top = snap(blockOut.y + blockOut.height, getYSnapPositions().filter(p => p > blockOut.y))
        const height = round(top - blockOut.y)
        const y = round((top - height))

        return {...blockOut, y, height}
      }
      case 'bottom': {
        const y = snap(blockOut.y, getYSnapPositions().filter(p => p < blockOut.y + blockOut.height))
        const height = round((blockOut.y + blockOut.height - y))

        return {...blockOut, y, height}
      }
      default:
        return blockOut
    }
  }
  const blockOutDragBoundFunc = (manhole, pos) => {
    return {
      x: Math.max(0, Math.min(pos.x, plan.length - manhole.width)),
      y: Math.max(0, Math.min(pos.y, plan.width - manhole.height))
    }
  }
  const blockOutResizeBoundFunc = (manhole, pos) => {
    return {
      x: Math.max(0, Math.min(pos.x, plan.length)),
      y: Math.max(0, Math.min(pos.y, plan.width))
    }
  }

  return {
    previewAdd: item => snapBlockOutPosition(item),
    add: item => {
      const snapped = snapBlockOutPosition(item)

      if (Number.isFinite(snapped.x) && Number.isFinite(snapped.y)) {
        dispatch(actions.addBlockOut(snapped))
      }
      return snapped
    },
    previewMove: item => snapBlockOutPosition(item),
    move: item => {
      const snapped = snapBlockOutPosition(item)

      dispatch(actions.moveBlockOut(item.id, snapped.x, snapped.y))
      return snapped
    },
    remove: id => dispatch(actions.removeBlockOut(id)),
    previewResize: (item, direction) => snapBlockOutSize(item, direction),
    resize: (manhole, direction) => {
      const snapped = snapBlockOutSize(manhole, direction)

      dispatch(actions.resizeBlockOut(
        manhole.id,
        snapped.width,
        snapped.height
      ))
      dispatch(actions.moveBlockOut(
        manhole.id,
        snapped.x,
        snapped.y
      ))
      return snapped
    },
    dragBoundFunc: blockOutDragBoundFunc,
    resizeBoundFunc: blockOutResizeBoundFunc
  }
}
