export const CONFIGURATION_STEPS = [
  {id: "1", description: "Abmessungen"},
  {id: "2a", description: "Ansicht A"},
  {id: "2b", description: "Ansicht B"},
  {id: "2c", description: "Ansicht C"},
  {id: "2d", description: "Ansicht D"},
  {id: "3", description: "Abdeckung"},
  {id: "4", description: "Boden"},
  {id: "5", description: "Lieferung"},
  {id: "6", description: "Rechnung"},
  {id: "7", description: "Kontrolle"}
]

export const isStepEnabled = (plan, stepId) => {
  switch (stepId) {
    case '1':
      return true
    case '2a':
    case '2b':
    case '2c':
    case '2d':
      return !!plan.vault
    case '3':
      return !!plan.lid || !!plan.intermediateCeiling
    case '4':
      return !!plan.floor
    case '5':
    case '6':
      return true
    case '7':
      return true
    default:
      return false
  }
}

export const getStepDescription = (plan, stepId) =>
  stepId === "3" && !!plan.intermediateCeiling && !plan.lid
    ? "Zwischendecke"
    : getStep(stepId).description

const getStep = id => {
  const steps = CONFIGURATION_STEPS.filter(s => s.id === id)

  if (steps.length !== 1) {
    return null
  }
  return steps[0]
}

export const getStepByRoute = route => {
  const slashIndex = route.lastIndexOf('/')

  return getStep(route.slice(slashIndex + 1))
}

export const getStepIndex = id => {
  const step = getStep(id)

  return CONFIGURATION_STEPS.indexOf(step)
}

export const getNextStep = (plan, currentStepId) => {
  const currentStepIndex = getStepIndex(currentStepId)

  if (currentStepIndex === -1) {
    return null
  }
  let i = currentStepIndex + 1

  while (i < CONFIGURATION_STEPS.length) {
    const step = CONFIGURATION_STEPS[i]

    if (isStepEnabled(plan, step.id)) {
      return step
    }
    i++
  }
  return null
}

export const getPreviousStep = (plan, currentStepId) => {
  const currentStepIndex = getStepIndex(currentStepId)

  if (currentStepIndex === -1) {
    return null
  }
  let i = currentStepIndex - 1

  while (i > -1) {
    const step = CONFIGURATION_STEPS[i]

    if (isStepEnabled(plan, step.id)) {
      return step
    }
    i--
  }
  return null
}
