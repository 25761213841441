import React from "react";
import {renderLine} from "../renderFunctions";
import {Circle, Group, Text} from "react-konva";
import {intermediateCeilingFunctions} from "../../../domain/intermediateCeiling";
import {renderGroupElements as renderBlockOutGroupElements} from "../../Element/blockOutRenderFactory";
import {FONT_SIZE} from "../../../../constants";
import {renderMeasurements} from "../../Element/rectangularElementFunctions";
import {renderGroupElements as renderCircularBlockOutGroupElements} from "../../Element/circularBlockOutRenderFactory";

export const createIntermediateCeilingGroundView = (plan, scale) => {
  const getWallWidth = () => plan.wallWidth * scale
  const getTop = () => 0
  const getIntermediateCeilingHeight = () => intermediateCeilingFunctions.getWidth(plan) * scale
  const getBottom = () => getTop() + getIntermediateCeilingHeight()
  const getLeft = () => 0
  const getWidth = () => intermediateCeilingFunctions.getLength(plan) * scale
  const getRight = () => getLeft() + getWidth()
  const getClearMeasureLeft = () => getLeft() + getWallWidth()
  const getClearMeasureTop = () => getTop() + getWallWidth()
  const getClearMeasureHeight = () => plan.width * scale

  const renderOutline = () => (
    renderLine([
      getLeft(), getTop(),
      getLeft(), getBottom(),
      getRight(), getBottom(),
      getRight(), getTop(),
      getLeft(), getTop()
    ])
  )

  const renderBlockOuts = () => {
    return plan.intermediateCeiling.blockOuts.map((b, i) => {
      const getX = () => b.x * scale
      const getY = () => getClearMeasureHeight() - (b.y + b.height) * scale
      const getWidth = () => b.width * scale
      const getHeight = () => b.height * scale
      const getScreenRect = () => ({x: getX(), y: getY(), width: getWidth(), height: getHeight()})

      return (
        <Group x={getX()} y={getY()} key={'block_out_' + i}>
          {renderBlockOutGroupElements(getScreenRect(), b, 'transparent')}
          {renderMeasurements(getScreenRect(), b, true)}
        </Group>
      )
    })
  }

  const renderCircularBlockOuts = () => {
    return plan.intermediateCeiling.circularBlockOuts.map((m, i) => {
      const getRadius = () => m.diameter / 2 * scale

      return (
        <Group
          key={'circular_block_out_' + i}
          x={m.x * scale}
          y={getClearMeasureHeight() - m.y * scale}
        >
          <Circle
            radius={m.diameter / 2 * scale}
            stroke={'black'}
            strokeWidth={1}
          />
          {renderCircularBlockOutGroupElements({radius: m.diameter / 2 * scale}, m, "transparent")}
          <Text
            x={-0.8 * getRadius() - 200}
            y={0.8 * getRadius()}
            width={200}
            height={FONT_SIZE}
            fontSize={FONT_SIZE}
            align="right"
            verticalAlign="top"
            text={m.x + "/" + m.y}
            fill="black"
          />
        </Group>
      )
    })
  }

  return (
    <React.Fragment>
      {renderOutline()}
      <Group x={getClearMeasureLeft()} y={getClearMeasureTop()}>
        {renderBlockOuts()}
        {renderCircularBlockOuts()}
      </Group>
    </React.Fragment>
  )
}
