import {planApiActionTypes as actionTypes} from "../constants"

const initialState = []

export const planDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.resetPlans:
      return initialState

    case actionTypes.readPlanDetailsRequest:
      return {fetching: true}

    case actionTypes.readPlanDetailsFailure:
      return {error: action.error}

    case actionTypes.readPlanDetailsSuccess:
        return action.data

    default:
      return state
  }
}
