import {userApiActionTypes} from "../constants"
import {userService} from "../services"

export const reset = () => {
  return {
    type: userApiActionTypes.reset
  }
}

const read = () => {
  const request = () => {
    return {type: userApiActionTypes.readRequest}
  }
  const success = result => {
    return {type: userApiActionTypes.readSuccess, data: result}
  }
  const failure = error => {
    return {type: userApiActionTypes.readFailure, error}
  }

  return dispatch => {
    dispatch(request())
    userService.read()
      .then(
        result => dispatch(success(result)),
        error => dispatch(failure(error))
      )
  }
}

const markReleaseNotesRead = () => {
  const request = () => {
    return {type: userApiActionTypes.markReleaseNotesReadRequest}
  }
  const success = result => {
    return {type: userApiActionTypes.markReleaseNotesReadSuccess, data: result}
  }
  const failure = error => {
    return {type: userApiActionTypes.markReleaseNotesReadFailure, error}
  }

  return dispatch => {
    dispatch(request())
    userService.markReleaseNotesRead()
      .then(
        result => {
          dispatch(success(result))
          dispatch(userApiActions.read())
        },
        error => dispatch(failure(error))
      )
  }
}

export const userApiActions = {
  read,
  markReleaseNotesRead
}
