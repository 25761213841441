import React from "react"
import {useDispatch, useSelector} from "react-redux"

import {Layer, Stage} from "react-konva"

import {createPart} from "../GroundPlan/part"
import {createHorizontalMeasurements, createVerticalMeasurements} from "../../measurement"
import {getPartialXMeasurements, getPartialYMeasurements} from "../../configurationPlanMeasurement"
import {createVerticallyCenteredGrid} from "../grid"
import {AutoResizeDiv} from "../../AutoResizeDiv"
import {renderToolbar} from "./floorPlanToolbar"
import {createCircularElementCrossHairLayer} from "../../Element/circularElementCrossHairs"
import {renderGroupElements as renderDrainageGroupElements} from "../../Element/drainageRenderFactory"
import {renderCircularPlanElement} from "../../Element/circularElementFunctions"
import {createFloorDomainFunctions} from "../../../domain/floorDomainFunctions";
import {getBevelWidth} from "../../../domain/floor";
import {createGroundPlanLayout} from "../planLayout";


const getHorizontalMeasurementStops = (layout, drainages) => {
  const wallWidth = (layout.part.realWidth - layout.configArea.realWidth) / 2
  const outerStops = [wallWidth, layout.configArea.realWidth, wallWidth]
  const innerStops = getPartialXMeasurements(drainages, [], [], layout.configArea.realWidth)

  return innerStops.length > 1
    ? {stops: [innerStops, outerStops], offsets: [wallWidth, 0]}
    : {stops: [outerStops], offsets: [0]}
}

const getVerticalMeasurementStops = (layout, drainages) => {
  const wallWidth = (layout.part.realHeight - layout.configArea.realHeight) / 2
  const outerStops = [wallWidth, layout.configArea.realHeight, wallWidth]
  const innerStops = getPartialYMeasurements(drainages, [], [], layout.configArea.realHeight)

  return innerStops.length > 1
    ? {stops: [outerStops, innerStops], offsets: [0, wallWidth]}
    : {stops: [outerStops], offsets: [0]}
}

const renderMeasurements = (layout, drainages) => {
  const horizontalMeasurementStops = getHorizontalMeasurementStops(layout, drainages)
  const verticalMeasurementStops = getVerticalMeasurementStops(layout, drainages)

  return (
    <React.Fragment>
      {
        createHorizontalMeasurements(
          layout.horizontalMeasurements,
          horizontalMeasurementStops.stops,
          horizontalMeasurementStops.offsets
        )
      }
      {
        createVerticalMeasurements(
          layout.verticalMeasurements,
          verticalMeasurementStops.stops,
          verticalMeasurementStops.offsets
        )
      }
    </React.Fragment>
  )
}

const Plan = props => {
  const {clientSize} = props
  const dispatch = useDispatch()
  const plan = useSelector(state => state.plan)
  const layout = createGroundPlanLayout(clientSize, plan)
  const domainFunctions = createFloorDomainFunctions(dispatch, plan.length, plan.width)

  return (
    <div>
      {!isNaN(layout.scale) &&
      <Stage
        width={clientSize.width}
        height={layout.overall.screenHeight}
      >
        <Layer>
          {createPart(layout.part, layout.configArea)}
          {createVerticallyCenteredGrid(layout.configArea, layout.configAreaRealWorldTransform)}
          {renderMeasurements(layout, plan.floor.drainages)}
          {
            plan.floor.drainages.map(drainage => renderCircularPlanElement(
              domainFunctions.drainageFunctions,
              layout.configArea,
              drainage,
              renderDrainageGroupElements,
              getBevelWidth() * layout.scale
            ))
          }
          {renderToolbar(domainFunctions, layout.toolbar, layout.configArea)}
        </Layer>
        {createCircularElementCrossHairLayer()}
      </Stage>
      }
    </div>
  )
}

export const FloorPlan = props => {
  return (
    <AutoResizeDiv>
      <Plan {...props}/>
    </AutoResizeDiv>
  )
}
