import { LOCALSTORAGE_KEY } from "../constants";

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(LOCALSTORAGE_KEY);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(LOCALSTORAGE_KEY, serializedState);
  } catch (err) {
    //error, data can't be write to localStorage
  }
};
