import {planActionTypes, planApiActionTypes, projectActionTypes as actionTypes} from "../constants"
import {deliveryActionTypes, billingActionTypes} from "../constants"

const initialState = {
  vaultQuantity: 1,
  lidQuantity: 0,
  intermediateCeilingQuantity: 0,
  floorQuantity: 0,
  delivery: {
    description: '',
    deliveryType: 'delivery',
    desiredSchedule: '',
    street: '',
    zip: '',
    city: '',
    number: '', // optional
    person: '', // optional
    phone: '', // optional
    commission: '', // optional
    usersOrderNumber: '', // optional
    remarks: '' // optional
  }
}

export const projectRootReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.reset:
      return initialState

    case actionTypes.setVaultQuantity:
      if (Number.isInteger(action.data)) {
        return {...state, vaultQuantity: action.data}
      }
      return state

    case actionTypes.setLidQuantity:
      if (Number.isInteger(action.data)) {
        return {...state, lidQuantity: action.data}
      }
      return state

    case actionTypes.setIntermediateCeilingQuantity:
      if (Number.isInteger(action.data)) {
        return {...state, intermediateCeilingQuantity: action.data}
      }
      return state

    case actionTypes.setFloorQuantity:
      if (Number.isInteger(action.data)) {
        return {...state, floorQuantity: action.data}
      }
      return state

    case planActionTypes.setVault:
      return state.vaultQuantity < 1 ? {...state, vaultQuantity: 1} : state

    case planActionTypes.unsetVault:
      return state.vaultQuantity > 0 ? {...state, vaultQuantity: 0} : state

    case planActionTypes.setLid:
      return state.lidQuantity < 1 ? {...state, lidQuantity: 1} : state

    case planActionTypes.unsetLid:
      return state.lidQuantity > 0 ? {...state, lidQuantity: 0} : state

    case planActionTypes.setIntermediateCeiling:
      return state.intermediateCeilingQuantity < 1 ? {...state, intermediateCeilingQuantity: 1} : state

    case planActionTypes.unsetIntermediateCeiling:
      return state.intermediateCeilingQuantity > 0 ? {...state, intermediateCeilingQuantity: 0} : state

    case planActionTypes.setFloor:
      return state.floorQuantity < 1 ? {...state, floorQuantity: 1} : state

    case planActionTypes.unsetFloor:
      return state.floorQuantity > 0 ? {...state, floorQuantity: 0} : state

    case planApiActionTypes.readPlanSuccess:
      return {
        ...initialState,
        vaultQuantity: (!!action.data.vault ? 1 : 0),
        lidQuantity: (!!action.data.lid ? 1 : 0),
        intermediateCeilingQuantity: (!!action.data.intermediateCeiling ? 1 : 0),
        floorQuantity: (!!action.data.floor ? 1 : 0)
      }

    default:
      return state
  }
}

export const deliveryReducer = (state, action) => {
  switch (action.type) {

    case deliveryActionTypes.setDescription:
      return {...state, description: action.data}

    case deliveryActionTypes.setDeliveryType:
      return {...state, deliveryType: action.data}

    case deliveryActionTypes.setDesiredSchedule:
      return {...state, desiredSchedule: action.data}

    case deliveryActionTypes.setStreet:
      return {...state, street: action.data}

    case deliveryActionTypes.setZip:
      return {...state, zip: action.data}

    case deliveryActionTypes.setCity:
      return {...state, city: action.data}

    case deliveryActionTypes.setCoordinates:
      return {...state, latitude: action.data.lat, longitude: action.data.lng}

    case deliveryActionTypes.unsetCoordinates:
      return {...state, latitude: undefined, longitude: undefined}

    case deliveryActionTypes.setNumber:
      return {...state, number: action.data}

    case deliveryActionTypes.setPerson:
      return {...state, person: action.data}

    case deliveryActionTypes.setPhone:
      return {...state, phone: action.data}

    case deliveryActionTypes.setCommission:
      return {...state, commission: action.data}

    case deliveryActionTypes.setUsersOrderNumber:
      return {...state, usersOrderNumber: action.data}

    case deliveryActionTypes.setRemarks:
      return {...state, remarks: action.data}

    default:
      return state
  }
}

export const billingReducer = (state, action) => {
  switch (action.type) {
    case billingActionTypes.setBilling:
      return state ?? {
        name: '',
        email: '',
        phone: '',
        company: '',
        street: '',
        zip: '',
        city: ''
      }

    case billingActionTypes.unsetBilling:
      return null;

    case billingActionTypes.setName:
      return {...state, name: action.data}

    case billingActionTypes.setEmail:
      return {...state, email: action.data}

    case billingActionTypes.setPhone:
      return {...state, phone: action.data}

    case billingActionTypes.setCompany:
      return {...state, company: action.data}

    case billingActionTypes.setStreet:
      return {...state, street: action.data}

    case billingActionTypes.setZip:
      return {...state, zip: action.data}

    case billingActionTypes.setCity:
      return {...state, city: action.data}

    default:
      return state
  }
}

export const projectReducer = (state = initialState, action) => {
  const rootState = projectRootReducer(state, action)
  const deliveryState = deliveryReducer(rootState.delivery, action)
  const billingState = billingReducer(rootState.billing, action)

  if (rootState.delivery === deliveryState && rootState.billing === billingState)
    return rootState;
  const {delivery, billing, ...others} = rootState;

  return  {
      ...others,
      delivery: deliveryState,
      ...(billingState) && { billing: billingState}
    }
}
