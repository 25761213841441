import React, {useEffect, useState} from "react"

import {Grid, TextField, Typography} from "@mui/material"
import {useDispatch, useSelector} from "react-redux"

import {projectActions} from "../../../actions"
import {getQuantityError} from "../../../validation/projectFormValidation"

const TitleGridItem = () => {
  return (
    <Grid item xs={12}>
      <Typography component="h1" variant="h4">
        Menge
      </Typography>
      < hr/>
    </Grid>
  )
}

const QuantityGridItem = props => {
  const {quantitySelector, setQuantityActionCreator, elementName, text} = props
  const dispatch = useDispatch()
  const quantity = useSelector(quantitySelector)
  const plan = useSelector(state => state.plan)
  const project = useSelector(state => state.project)
  const [currentQuantity, setCurrentQuantity] = useState(quantity)
  const quantityError = getQuantityError(currentQuantity, elementName, plan, project)

  useEffect(() => {setCurrentQuantity(quantity)}, [quantity])
  return (
    <Grid item xs={12}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={4}>
          <TextField
            type="number"
            InputProps={{inputProps: {min: 0, max: 1000}}}
            variant="outlined"
            fullWidth
            margin="normal"
            id="quantity"
            name="quantity"
            label="Menge"
            value={currentQuantity}
            error={quantityError.length > 0}
            helperText={quantityError}
            onChange={e => {
              setCurrentQuantity(e.target.value)
              const quantity = parseInt(e.target.value)

              if (Number.isFinite(quantity) && quantity > -1 && quantity <= 1000) {
                dispatch(setQuantityActionCreator(quantity))
              }
            }}
          />
        </Grid>
        <Grid item>
          <Typography component="p" variant="body1">
            {text}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export const QuantitySection = () => {
  const plan = useSelector(state => state.plan)

  return (
    <Grid container spacing={2}>
      <TitleGridItem/>
      <Grid item>
        <Grid container>
          {
            !!plan.vault &&
            <QuantityGridItem
              quantitySelector={state => state.project.vaultQuantity}
              setQuantityActionCreator={projectActions.setVaultQuantity}
              elementName={'vault'}
              text={`Kabelschacht ${plan.length}/${plan.width}/${plan.vault.height}/${plan.wallWidth} gem. Zeichnung`}
            />
          }
          {
            !!plan.lid &&
            <QuantityGridItem
              quantitySelector={state => state.project.lidQuantity}
              setQuantityActionCreator={projectActions.setLidQuantity}
              elementName={'lid'}
              text={'Abdeckung gem. Zeichnung'}
            />
          }
          {
            !!plan.intermediateCeiling &&
            <QuantityGridItem
              quantitySelector={state => state.project.intermediateCeilingQuantity}
              setQuantityActionCreator={projectActions.setIntermediateCeilingQuantity}
              elementName={'intermediateCeiling'}
              text={'Zwischendecke gem. Zeichnung'}
            />
          }
          {
            !!plan.floor &&
            <QuantityGridItem
              quantitySelector={state => state.project.floorQuantity}
              setQuantityActionCreator={projectActions.setFloorQuantity}
              elementName={'floor'}
              text={'Boden gem. Zeichnung'}
            />
          }
        </Grid>
      </Grid>
    </Grid>
  )
}
