import React from "react"

import {renderResizeHandle} from "./resizeHandles"

export const renderResizeHandles = (
  configAreaLayout,
  screenRect,
  resizingCallback,
  resizedCallback,
  directions = ["top", "left", "bottom", "right"],
  customDragBoundFunc = null
) => {
  const toRect = handles => {
    return {
      x: handles.left.x(),
      y: handles.top.y(),
      width: handles.right.x() - handles.left.x(),
      height: handles.bottom.y() - handles.top.y()
    }
  }

  const dragBoundFunc = (pos, name) => {
    const defaultDragBoundFunc = () => ({
      x: Math.max(configAreaLayout.screenX, Math.min(pos.x, configAreaLayout.screenX + configAreaLayout.screenWidth)),
      y: Math.max(configAreaLayout.screenY, Math.min(pos.y, configAreaLayout.screenY + configAreaLayout.screenHeight))
    })

    let {x, y} = !!customDragBoundFunc
      ? customDragBoundFunc(pos)
      : defaultDragBoundFunc()

    const centerX = screenRect.x + screenRect.width / 2
    const centerY = screenRect.y + screenRect.height / 2

    switch (name) {
      case "top":
        x = centerX
        y = Math.min(y, screenRect.y + screenRect.height)
        break

      case "bottom":
        x = centerX
        y = Math.max(y, screenRect.y)
        break

      case "left":
        x = Math.min(x, screenRect.x + screenRect.width)
        y = centerY
        break

      case "right":
        x = Math.max(x, screenRect.x)
        y = centerY
        break

      default:
        break
    }
    return {x: x, y: y}
  }

  return (
    <React.Fragment>
      {
        renderResizeHandle(
          screenRect.width / 2,
          0,
          "top",
          toRect,
          pos => dragBoundFunc(pos, "top"),
          resizingCallback,
          resizedCallback,
          directions.includes("top")
        )
      }
      {
        renderResizeHandle(
          0,
          screenRect.height / 2,
          "left",
          toRect,
          pos => dragBoundFunc(pos, "left"),
          resizingCallback,
          resizedCallback,
          directions.includes("left")
        )
      }
      {
        renderResizeHandle(
          screenRect.width / 2,
          screenRect.height,
          "bottom",
          toRect,
          pos => dragBoundFunc(pos, "bottom"),
          resizingCallback,
          resizedCallback,
          directions.includes("bottom")
        )
      }
      {
        renderResizeHandle(
          screenRect.width,
          screenRect.height / 2,
          "right",
          toRect,
          pos => dragBoundFunc(pos, "right"),
          resizingCallback,
          resizedCallback,
          directions.includes("right")
        )
      }
    </React.Fragment>
  )
}
