import {getOverallSize} from "../../domain/composition";

export const getMeasurementsAreaWidth = clientWidth => {
  return Math.min(clientWidth - 4, 65)
}

export const getScale = (screenWidth, overallSize) => {
  const greatestOuterWidth = Math.max(overallSize.width, overallSize.length)

  return screenWidth / greatestOuterWidth
}

export const createGroundViewLayout = (clientSize, plan, partSize) => {
  const overallSize = getOverallSize(plan)
  const verticalMeasurementsWidth = getMeasurementsAreaWidth(clientSize.width)
  const horizontalMeasurementsHeight = getMeasurementsAreaWidth(clientSize.width)
  const availableRemainingWidth = clientSize.width - 4 - verticalMeasurementsWidth
  const scale = getScale(availableRemainingWidth, overallSize)
  const overallX = 2
  const overallY = 2
  const verticalMeasurementsX = overallX
  const partX = verticalMeasurementsX
    + verticalMeasurementsWidth
    + (partSize.x - overallSize.x) * scale
  const part = {
    screenX: partX,
    screenY: overallY,
    screenWidth: partSize.length * scale,
    screenHeight: partSize.width * scale,
    scale: scale
  }
  const lidsBottom = part.screenY + part.screenHeight
  const verticalMeasurements = {
    screenX: verticalMeasurementsX,
    screenY: overallY,
    screenWidth: verticalMeasurementsWidth,
    screenHeight: part.screenHeight,
    scale: scale
  }
  const horizontalMeasurements = {
    screenX: verticalMeasurementsX + verticalMeasurementsWidth,
    screenY: lidsBottom,
    screenWidth: part.screenWidth,
    screenHeight: horizontalMeasurementsHeight,
    scale: scale
  }
  const overall = {
    screenX: overallX,
    screenY: overallY,
    screenWidth: clientSize.width - 4,
    screenHeight: horizontalMeasurements.screenY + horizontalMeasurements.screenHeight + 2
  }

  return {overall, part, horizontalMeasurements, verticalMeasurements}
}


