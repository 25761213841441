import React from "react"

import {renderDashedLine, renderLine} from "../renderFunctions";
import {
  getManholeClearMeasure,
  intermediateCeilingFunctions
} from "../../../domain/intermediateCeiling";
import {Group} from "react-konva";

export const createIntermediateCeilingElevationView = (plan, scale, side) => {
  const getWallWidth = () => plan.wallWidth * scale
  const getIntermediateCeilingTop = () => 0
  const getIntermediateCeilingHeight = () => intermediateCeilingFunctions.getHeight(plan) * scale
  const getIntermediateCeilingBottom = () => getIntermediateCeilingTop() + getIntermediateCeilingHeight()

  const getIntermediateCeilingLeft = () => 0
  const getIntermediateCeilingWidth = () =>
    side === 'left' || side === 'right'
      ? intermediateCeilingFunctions.getWidth(plan) * scale
      : intermediateCeilingFunctions.getLength(plan) * scale
  const getIntermediateCeilingRight = () => getIntermediateCeilingLeft() + getIntermediateCeilingWidth()

  const getClearMeasureLeft = () => getIntermediateCeilingLeft() + getWallWidth()
  const getCLearMeasureWidth = () => getIntermediateCeilingWidth() - 2 * getWallWidth()

  const renderOutline = () => renderLine([
    getIntermediateCeilingLeft(), getIntermediateCeilingTop(),
    getIntermediateCeilingRight(), getIntermediateCeilingTop(),
    getIntermediateCeilingRight(), getIntermediateCeilingBottom(),
    getIntermediateCeilingLeft(), getIntermediateCeilingBottom(),
    getIntermediateCeilingLeft(), getIntermediateCeilingTop()
  ])

  const renderRectangular = (rect, key) => {
    const getRectLeft = () =>
      (side === 'left' ? plan.width - rect.height - rect.y
          : side === 'right' ? rect.y
            : side === 'front' ? rect.x
              : side === 'back' ? plan.length - rect.width - rect.x
                : 0
      ) * scale
    const getRectWidth = () =>
      side === 'left' || side === 'right'
        ? rect.height * scale
        : rect.width * scale
    const getRectRight = () => getRectLeft() + getRectWidth()
    const getLeft = () => Math.max(getRectLeft(), 0)
    const getRight = () => Math.min(getRectRight(), getCLearMeasureWidth())

    return (
      <React.Fragment key={key}>
        {renderDashedLine([getLeft(), getIntermediateCeilingTop(), getLeft(), getIntermediateCeilingBottom()])}
        {renderDashedLine([getRight(), getIntermediateCeilingTop(), getRight(), getIntermediateCeilingBottom()])}
      </React.Fragment>
    )
  }

  const renderBlockOuts = () => {
    return plan.intermediateCeiling.blockOuts.map(
      (b, i) => {
        return renderRectangular(b, 'block_out_' + i)
      }
    )
  }
  const renderManholes = () => {
    return plan.lid.manholes.map((m, i) => {
      return renderRectangular(getManholeClearMeasure(plan, m), 'manhole_' + i)
    })
  }

  const renderCircularBlockOuts = () => {
    return plan.intermediateCeiling.circularBlockOuts.map((m, i) => {
      const getRadius = () => m.diameter / 2 * scale
      const getX = () =>
        (side === 'left' ? plan.width - m.y
            : side === 'right' ? m.y
              : side === 'front' ? m.x
                : side === 'back' ? plan.length - m.x
                  : 0
        ) * scale
      const getLeft = () => (getX() - getRadius())
      const getRight = () => (getX() + getRadius())

      return (
        <React.Fragment key={'circular_manhole_' + i}>
          {renderDashedLine([getLeft(), getIntermediateCeilingTop(), getLeft(), getIntermediateCeilingBottom()])}
          {renderDashedLine([getRight(), getIntermediateCeilingTop(), getRight(), getIntermediateCeilingBottom()])}
        </React.Fragment>
      )
    })
  }

  return (
    <React.Fragment>
      {renderOutline()}
      {
        <Group x={getClearMeasureLeft()} y={getIntermediateCeilingTop()}>
          {!!plan.lid && renderManholes()}
          {renderBlockOuts()}
          {renderCircularBlockOuts()}
        </Group>
      }
    </React.Fragment>
  )
}
