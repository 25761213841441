import {PNG} from "pngjs";

/* thehref: DataURL form of a png file. */
export const removeAlphaFromDataUrl = thehref => {
  const dataurlstring = "data:image/png;base64,"
  const buff = new Buffer(thehref.slice(22), 'base64')
  const png = PNG.sync.read(buff);
  const options = {colorType: 2};
  const buffer = PNG.sync.write(png, options);
  const output = buffer.toString('base64');

  return dataurlstring + output;
}

