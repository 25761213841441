import {Grid, Typography} from "@mui/material"
import React from "react"
import {useDispatch, useSelector} from "react-redux"
import InfoIcon from '@mui/icons-material/Info'
import {vaultActions, lidActions, floorActions} from "../../../actions"
import {ImageButton} from "./ImageButton";
import floorImage from '../../../image/buttonImage/floor.jpg';
import lidImage from '../../../image/buttonImage/lid.jpg';
import vaultImage from '../../../image/buttonImage/vault.jpg'
import intermediateCeilingImage from '../../../image/buttonImage/intermediate_ceiling.jpg'
import {intermediateCeilingActions} from "../../../actions/intermediateCeilingActions";


const TitleGridItem = () => {
  return (
    <Grid item xs={12}>
      <Typography component="h1" variant="h4">
        Kabelschacht Konfigurator
      </Typography>
      < hr/>
    </Grid>
  )
}

const TextGridItem = () => {
  return (
    <Grid item xs={12} key={'upper_text'}>
      <Typography component="p" variant="body1">
        Konfigurieren Sie einen Kabelschacht genau nach Ihrer Vorstellung!
        Definiern Sie zuerst die Dimension und positionieren Sie anschliessend auf allen Seiten per Drag & Drop
        die gewünschten Elemente (z.B. Aussparungen, Muffen, C-Schienen, Abflüsse, Erdungsanschlüsse und
        Einstiegsöffnungen).
      </Typography>
    </Grid>
  )
}

const RemarksTextGridItem = () => {
  return (
    <Grid item xs={12}>
      <Grid direction={"row"} container alignItems={'center'}>
        <Grid item>
          <InfoIcon style={{marginRight: '1em'}}/>
        </Grid>
        <Grid item xs={10}>
          <Typography component="p" variant="body1">
            Mit einer 25 cm dicken Zwischendecke können Sie auch bei überbreiten Kabelschächten eine Standard-Abdeckung
            verwenden.
            Die Gesamthöhe erhöht sich dementsprechend.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

const ComponentButtonsGridItem = () => {
  const dispatch = useDispatch()
  const vault = useSelector(state => state.plan.vault)
  const lid = useSelector(state => state.plan.lid)
  const intermediateCeiling = useSelector(state => state.plan.intermediateCeiling)
  const floor = useSelector(state => state.plan.floor)

  return (
    <Grid item xs={12}>
      <Typography component="p" variant="body1" style={{marginBottom: '0.5em'}}>
        Was möchten Sie konfigurieren (Mehrfachauswahl)?
      </Typography>
      <Grid container direction={'row'} justifyContent={'flex-start'} spacing={3}>
        <Grid item>
          <ImageButton
            title={'Abdeckung'}
            checked={lid !== undefined}
            image={lidImage}
            onClick={() => {
              if (!!lid) {
                dispatch(lidActions.unsetLid())
              } else {
                dispatch(lidActions.setLid())
              }
            }}
          />
        </Grid>
        <Grid item>
          <ImageButton
            title={'Zwischendecke'}
            checked={intermediateCeiling !== undefined}
            image={intermediateCeilingImage}
            onClick={() => {
              if (!!intermediateCeiling) {
                dispatch(intermediateCeilingActions.unsetIntermediateCeiling())
              } else {
                dispatch(intermediateCeilingActions.setIntermediateCeiling())
              }
            }}
          />
        </Grid>
        <Grid item>
          <ImageButton
            title={'Kabelschacht'}
            checked={vault !== undefined}
            image={vaultImage}
            onClick={() => {
              if (!!vault) {
                dispatch(vaultActions.unsetVault())
              } else {
                dispatch(vaultActions.setVault())
              }
            }}
          />
        </Grid>
        <Grid item>
          <ImageButton
            title={'Boden'}
            checked={floor !== undefined}
            image={floorImage}
            onClick={() => {
              if (!!floor) {
                dispatch(floorActions.unsetFloor())
              } else {
                dispatch(floorActions.setFloor())
              }
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export const ComponentsSection = () => {
  return (
    <Grid container spacing={3}>
      <TitleGridItem/>
      <TextGridItem/>
      <ComponentButtonsGridItem/>
      <RemarksTextGridItem/>
    </Grid>
  )
}
