import React from "react"

import {renderDashedLine, renderLine} from "../renderFunctions";
import {getBevelHeight, getFloorSize} from "../../../domain/floor";
import {getBevelWidth} from "../../../domain/floor";
import {Group} from "react-konva";

export const createFloorElevationView = (plan, scale, side) => {
  const getWallWidth = () => plan.wallWidth * scale
  const getFloorTop = () => 0
  const getFloorHeight = () => getFloorSize(plan).height * scale
  const getFloorBottom = () => getFloorTop() + getFloorHeight()

  const getFloorLeft = () => 0
  const getFloorWidth = () =>
    side === 'left' || side === 'right'
      ? getFloorSize(plan).width * scale
      : getFloorSize(plan).length * scale
  const getFloorRight = () => getFloorLeft() + getFloorWidth()

  const getBevelBottom = () => getFloorTop() + getBevelHeight() * scale
  const getClearMeasureRight = () => getFloorRight() - getWallWidth()
  const getRightBevelLeft = () => getClearMeasureRight() - getBevelWidth() * scale
  const getClearMeasureLeft = () => getFloorLeft() + getWallWidth()
  const getLeftBevelRight = () => getClearMeasureLeft() + getBevelWidth() * scale

  const renderOutline = () => renderLine([
    getFloorLeft(), getFloorBottom(),
    getFloorRight(), getFloorBottom(),
    getFloorRight(), getBevelBottom(),
    getClearMeasureRight(), getBevelBottom(),
    getRightBevelLeft(), getFloorTop(),
    getLeftBevelRight(), getFloorTop(),
    getClearMeasureLeft(), getBevelBottom(),
    getFloorLeft(), getBevelBottom(),
    getFloorLeft(), getFloorBottom()
  ])

  const renderBevelLine = () => renderLine([
    getClearMeasureLeft(), getBevelBottom(),
    getClearMeasureRight(), getBevelBottom()
  ])

  const renderDrainages = () =>
    plan.floor.drainages.map((d, i) => {
      const getDrainageRadius = () => d.diameter / 2 * scale
      const getDrainageX = () => (
          side === 'left' ? plan.width - d.y
            : side === 'right' ? d.y
            : side === 'front' ? d.x
              : side === 'back' ? plan.length - d.x
                : 0
        ) * scale
      const getDrainageLeft = () => (getDrainageX() - getDrainageRadius())
      const getDrainageRight = () => (getDrainageX() + getDrainageRadius())

      return (
        <React.Fragment key={'drainage_' + i}>
          {renderDashedLine([getDrainageLeft(), getFloorTop(), getDrainageLeft(), getFloorBottom()])}
          {renderDashedLine([getDrainageRight(), getFloorTop(), getDrainageRight(), getFloorBottom()])}
        </React.Fragment>
      )
    })

  return (
    <React.Fragment>
      {renderOutline()}
      {renderBevelLine()}
      <Group x={getClearMeasureLeft()} y={getFloorTop()}>
        {renderDrainages()}
      </Group>
    </React.Fragment>
  )
}
