import {getLidSize} from "./lid";
import {getVaultSize} from "./vault";
import {getFloorSize} from "./floor";
import {intermediateCeilingFunctions} from "./intermediateCeiling";

export const getCombinedSize = (getSizes, plan) => {
  const getMinX = () => Math.min(...getSizes.map(f => f(plan).x))
  const getMaxX = () => Math.max(...getSizes.map(f => f(plan).x + f(plan).length))
  const getMinY = () => Math.min(...getSizes.map(f => f(plan).y))
  const getMaxY = () => Math.max(...getSizes.map(f => f(plan).y + f(plan).width))

  return {length: getMaxX() - getMinX(), width: getMaxY() - getMinY(), x: getMinX(), y: getMinY()}
}

export const getLeftToLeft = (getSize, getSizes, plan) => getSize(plan).x - getCombinedSize(getSizes, plan).x
export const getTopToTop = (getSize, getSizes, plan) =>
  (getCombinedSize(getSizes, plan).y + getCombinedSize(getSizes, plan).width)
  - (getSize(plan).y + getSize(plan).width)
export const getBottomToBottom = (getSize, getSizes, plan) => getSize(plan).y - getCombinedSize(getSizes, plan).y

export const getOverallSize =
  plan => getCombinedSize([getLidSize, intermediateCeilingFunctions.getSize, getVaultSize, getFloorSize], plan)


export const getLidToIntermediateCeilingSpacing =
  plan => !!plan.lid && !!plan.intermediateCeiling && plan.lid.type !== 'subfloor'
    ? 3
    : 0
