import React from "react"

import {renderGroupElements as renderBlockOut} from "../../Element/blockOutRenderFactory";
import {renderGroupElements as renderCircularBlockOut} from "../../Element/circularBlockOutRenderFactory";
import {getIconScale, getItemX, getToolbarItemWidth} from "../Toolbar/toolbarFunctions";
import {
  getBlockOutDefinitions,
  getCircularBlockOutDefinitions
} from "./intermediateCeilingElements";
import {renderRectangularElementToolbarItem as renderRectangular} from "../Toolbar/rectangularElementToolbarFunctions";
import {renderCircularElementToolbarItem as renderCircular} from "../Toolbar/circularElementToolbarFunctions";
import {getToolbarItemHeight, getToolbarItemY, renderGroupBox} from "../Toolbar/groupBox";


export const renderToolbar = (domainFunctions, toolbarLayout, configAreaLayout) => {
  const blockOutDefinitions = getBlockOutDefinitions()
  const circularBlockOutDefinitions = getCircularBlockOutDefinitions()
  const elements = [...blockOutDefinitions, ...circularBlockOutDefinitions]
  const itemsPerGroupCounts = [12]
  const itemHeight = getToolbarItemHeight(toolbarLayout.screenHeight)
  const itemY = getToolbarItemY(toolbarLayout.screenY)
  const itemWidth = getToolbarItemWidth(itemsPerGroupCounts, toolbarLayout.screenWidth)
  const iconScale = getIconScale(itemWidth, itemHeight, elements)
  const getX = (groupIndex, itemIndex) =>
    toolbarLayout.screenX
    + getItemX(itemsPerGroupCounts, toolbarLayout.screenWidth, groupIndex, itemIndex)
  const renderGroup = (groupIndex, itemCount, groupLabelText, labelTextLength) =>
    renderGroupBox(
      getX(groupIndex, 0),
      toolbarLayout.screenY,
      itemCount * itemWidth,
      toolbarLayout.screenHeight,
      groupLabelText,
      labelTextLength
    )
  const renderItem = (
    groupIndex,
    index,
    element,
    scaleFactor,
    domainFunctions,
    renderGroupElementsFunctions,
    renderFunction
  ) =>
    renderFunction(
      index,
      element,
      getX(groupIndex, index),
      itemY,
      itemWidth,
      toolbarLayout.screenHeight,
      iconScale * scaleFactor,
      configAreaLayout,
      domainFunctions,
      renderGroupElementsFunctions
    )

  return (
    <React.Fragment>
      {renderGroup(0, 12, 'Aussparungen', 105)}
      {
        blockOutDefinitions.map((curr, i) =>
          renderItem(
            0,
            i + 5,
            curr,
            1,
            domainFunctions.blockOutFunctions,
            (screenRect, blockOut) => renderBlockOut(
              screenRect,
              blockOut
            ),
            renderRectangular
          )
        )
      }
      {
        circularBlockOutDefinitions.map((curr, i) =>
          renderItem(
            0,
            i + 6,
            curr,
            1,
            domainFunctions.circularBlockOutFunctions,
            (screenCircle, manhole) => renderCircularBlockOut(screenCircle, manhole),
            renderCircular
          )
        )
      }
    </React.Fragment>
  )
}
