import React, {useEffect, useState} from "react"

import {Grid, TextField, Typography} from "@mui/material"
import {useDispatch, useSelector} from "react-redux"

import {VaultPlan} from "../../Plan/Configuration/VaultPlan/VaultPlan"
import {
  planLimits,
  getVaultHeightError,
  getWallWidthError,
  isVaultHeightValid,
  isWallWidthValid, getLengthError, isLengthValid, getWidthError, isWidthValid,
} from "../../../validation/planFormValidation"
import {lidActions, planActions, vaultActions} from "../../../actions"
import InfoIcon from "@mui/icons-material/Info"
import {ImageButton} from "./ImageButton"
import {GroundPlan} from "../../Plan/Configuration/GroundPlan/GroundPlan"
import {getManholeDefinitions} from "../../Plan/Configuration/Lid/lidElements";
import {lidIsFullyCoveredByOrHasNoManholesAtAll} from "../../domain/lid";
import {getStandardVaultPresetDimensions} from "../../domain/vault";
import {DimensionsIcon} from "./DimensionsIcons";

const TitleGridItem = () => {
  return (
    <Grid item xs={12}>
      <Typography component="h1" variant="h4">
        Abmessung
      </Typography>
      < hr/>
    </Grid>
  )
}

const StandardDimensionsGridItem = props => {
  const {setFocusLengthField} = props
  const dispatch = useDispatch()
  const plan = useSelector(state => state.plan)
  const lid = useSelector(state => state.plan.lid)
  const standardDimensions = getStandardVaultPresetDimensions(!!lid ? lid.type : 'B125')
  const isStandardVaultOfDimensions = d =>
    plan.length === d.length
    && plan.width === d.width
    && (!lid || (lid.manholes.length > 0 && lidIsFullyCoveredByOrHasNoManholesAtAll(plan)))
  const isStandardVault = () => standardDimensions.some(d => isStandardVaultOfDimensions(d))
  const [userClickedCustomVault, setUserClickedCustomVault] = useState(false);
  const setStandardVault = d => {
    unsetStandardVault()
    dispatch(planActions.setLength(d.length))
    dispatch(planActions.setWidth(d.width))
    if (!!lid) {
      if (lid.manholes.length > 0) {
        dispatch(lidActions.removeManhole(lid.manholes[0].id))
      }
      const getManholeDefinition = () => getManholeDefinitions(lid.type)[0]
      const getManholeWidth = () => !!getManholeDefinition()
        ? lid.type === 'subfloor'
          ? Math.ceil(d.length / getManholeDefinition().width) * getManholeDefinition().width
          : Math.floor((d.length + 8) / getManholeDefinition().width) * getManholeDefinition().width
        : 0
      const getManholeX = () => !!getManholeDefinition() ? (d.length - getManholeWidth()) / 2 : 0
      const getManholeY = () => !!getManholeDefinition() ? (d.width - getManholeDefinition().height) / 2 : 0
      const manhole = {
        ...getManholeDefinition(),
        x: getManholeX(),
        y: getManholeY(),
        width: getManholeWidth()
      }

      dispatch(lidActions.addManhole(manhole))
    }
    setUserClickedCustomVault(false);
  }
  const unsetStandardVault = () => {
    if (!!lid && isStandardVault()) {
      dispatch(lidActions.removeManhole(lid.manholes[0].id))
    }
    setUserClickedCustomVault(true);
  }

  return (
    <Grid item xs={12}>
      <Typography component="p" variant="body1" style={{marginBottom: '0.5em'}}>
        Wählen Sie einen Standard-Kabelschacht aus oder definieren Sie die Abmessung selbst (in 5 cm Schritten):
      </Typography>
      <Grid container direction={'row'} justifyContent={'flex-start'} alignItems={'flex-end'} spacing={3}>
        {
          getStandardVaultPresetDimensions(!!lid ? lid.type : 'B125')
            .map(d =>
              <Grid item key={d.length + ' x ' + d.width + ' cm'}>
                <ImageButton
                  title={d.length + ' x ' + d.width + ' cm'}
                  checked={!userClickedCustomVault && isStandardVaultOfDimensions(d)}
                  onClick={() => {
                    setFocusLengthField(false)
                    setStandardVault(d)
                  }}
                >
                  <DimensionsIcon width={d.length} height={d.width}/>
                </ImageButton>
              </Grid>
            )
        }
        <Grid item>
          <ImageButton
            title={'Benutzerdefiniert'}
            checked={!isStandardVault() || userClickedCustomVault}
            onClick={() => {
              unsetStandardVault()
              setFocusLengthField(true)
            }}
          >
            <DimensionsIcon width={200} height={140}/>
          </ImageButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

const StandardDimensionsRemarksTextGridItem = () => {
  return (
    <Grid item xs={12} key={'upper_text'}>
      <Grid direction={"row"} container alignItems={'center'}>
        <Grid item>
          <InfoIcon style={{marginRight: '1em'}}/>
        </Grid>
        <Grid item xs={10}>
          <Typography component="p" variant="body1">
            Die Standardabmessungen sind von der Deckelklasse abhänging!
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

const LengthField = props => {
  const {focusLengthField, setFocusLengthField} = props
  const dispatch = useDispatch()
  const length = useSelector(state => state.plan.length)
  const [currentLength, setCurrentLength] = useState(length)
  const error = getLengthError(currentLength)

  useEffect(() => {
      setCurrentLength(length)
    },
    [length]
  )
  return (
    <TextField
      inputRef={input => {
        if (input != null && focusLengthField) {
          input.focus()
        }
      }}
      onBlur={() => setFocusLengthField(false)}
      onFocus={event => event.target.select()}
      type="number"
      InputProps={{
        inputProps: {
          min: planLimits.minLength,
          max: planLimits.maxLength,
          step: planLimits.lengthStepWidth
        }
      }}
      variant="outlined"
      fullWidth
      margin="normal"
      id="length"
      label="Länge im Licht [cm]"
      name="length"
      value={currentLength}
      error={!!error}
      helperText={error}
      onChange={e => {
        setCurrentLength(e.target.value)
        if (isLengthValid(e.target.value)) {
          dispatch(planActions.setLength(parseInt(e.target.value)))
        }
      }}
    />
  )
}

const WidthField = () => {
  const dispatch = useDispatch()
  const width = useSelector(state => state.plan.width)
  const [currentWidth, setCurrentWidth] = useState(width)
  const error = getWidthError(currentWidth)

  useEffect(() => {
      setCurrentWidth(width)
    },
    [width]
  )
  return (
    <TextField
      onFocus={event => event.target.select()}
      type="number"
      InputProps={{
        inputProps: {
          min: planLimits.minWidth,
          max: planLimits.maxWidth,
          step: planLimits.widthStepWidth
        }
      }}
      variant="outlined"
      fullWidth
      margin="normal"
      id="length"
      label="Breite im Licht [cm]"
      name="width"
      value={currentWidth}
      error={!!error}
      helperText={error}
      onChange={e => {
        setCurrentWidth(e.target.value)
        if (isWidthValid(e.target.value)) {
          dispatch(planActions.setWidth(parseInt(e.target.value)))
        }
      }}
    />
  )
}

const GroundPlanInputFieldsGridItem = props => {
  const {focusLengthField, setFocusLengthField} = props

  return (
    <Grid item xs={12}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <LengthField focusLengthField={focusLengthField} setFocusLengthField={setFocusLengthField}/>
        </Grid>
        <Grid item xs={6}>
          <WidthField/>
        </Grid>
      </Grid>
    </Grid>
  )
}

const GroundPlanGirdItem = () => {
  return (
    <Grid item xs={12}>
      <GroundPlan/>
    </Grid>
  )
}

const HeightField = () => {
  const dispatch = useDispatch()
  const vault = useSelector(state => state.plan.vault)
  const height = vault ? vault.height : 0
  const [currentHeight, setCurrentHeight] = useState(height)
  const error = !vault ? null : getVaultHeightError(currentHeight)

  useEffect(() => {
      setCurrentHeight(height)
    },
    [height]
  )
  return (
    <TextField
      disabled={!vault}
      onFocus={event => event.target.select()}
      type="number"
      InputProps={{
        inputProps: {
          min: planLimits.minVaultHeight,
          max: planLimits.maxVaultHeight,
          step: planLimits.vaultHeightStepWidth
        }
      }}
      variant="outlined"
      fullWidth
      margin="normal"
      id="height"
      label="Höhe [cm]"
      name="height"
      value={currentHeight}
      error={!!error}
      helperText={error}
      onChange={e => {
        setCurrentHeight(e.target.value)
        if (isVaultHeightValid(e.target.value)) {
          dispatch(vaultActions.setVaultHeight(parseInt(e.target.value)))
        }
      }}
    />
  )
}

const WallWidthField = () => {
  const dispatch = useDispatch()
  const wallWidth = useSelector(state => state.plan.wallWidth)
  const [currentWallWidth, setCurrentWallWidth] = useState(wallWidth)
  const error = getWallWidthError(currentWallWidth)

  useEffect(() => {
      setCurrentWallWidth(wallWidth)
    },
    [wallWidth]
  )
  return (
    <TextField
      onFocus={event => event.target.select()}
      type="number"
      InputProps={{
        inputProps: {
          min: planLimits.minWallWidth,
          max: planLimits.maxWallWidth,
          step: planLimits.wallWidthStepWidth
        }
      }}
      variant="outlined"
      fullWidth
      margin="normal"
      id="wallWidth"
      label="Wandstärke [cm]"
      name="wallWidth"
      value={currentWallWidth}
      error={!!error}
      helperText={error}
      onChange={e => {
        setCurrentWallWidth(e.target.value)
        if (isWallWidthValid(e.target.value)) {
          dispatch(planActions.setWallWidth(parseInt(e.target.value)))
        }
      }}
    />
  )
}

const ElevationPlanInputFieldsGridItem = () => {
  return (
    <Grid item xs={12}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <HeightField/>
        </Grid>
        <Grid item xs={6}>
          <WallWidthField/>
        </Grid>
      </Grid>
    </Grid>
  )
}

const ElevationPlanGridItem = () => {
  return (
    <Grid item xs={12}>
      <VaultPlan isToolbarVisible={false}/>
    </Grid>
  )
}

export const DimensionsSection = () => {
  const lid = useSelector(state => state.plan.lid)
  const [focusLengthField, setFocusLengthField] = useState(false);

  return (
    <Grid container spacing={4}>
      <TitleGridItem/>
      <StandardDimensionsGridItem setFocusLengthField={setFocusLengthField}/>
      {
        !!lid &&
        <StandardDimensionsRemarksTextGridItem/>
      }
      <Grid item xs={6}>
        <Grid container spacing={3}>
          <GroundPlanInputFieldsGridItem focusLengthField={focusLengthField}
                                         setFocusLengthField={setFocusLengthField}/>
          <GroundPlanGirdItem/>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={3}>
          <ElevationPlanInputFieldsGridItem/>
          <ElevationPlanGridItem/>
        </Grid>
      </Grid>
    </Grid>
  )
}
