import {billingActionTypes as actionTypes} from "../constants"

const setBilling = () => {
  return {type: actionTypes.setBilling}
}

const unsetBilling = () => {
  return {type: actionTypes.unsetBilling}
}

const setName = name => {
  return {
    type: actionTypes.setName,
    data: name
  }
}

const setEmail = email => {
  return {
    type: actionTypes.setEmail,
    data: email
  }
}

const setPhone = phone => {
  return {
    type: actionTypes.setPhone,
    data: phone
  }
}

const setCompany = company => {
  return {
    type: actionTypes.setCompany,
    data: company
  }
}

const setStreet = street => {
  return {
    type: actionTypes.setStreet,
    data: street
  }
}

const setZip = zip => {
  return {
    type: actionTypes.setZip,
    data: zip
  }
}

const setCity = city => {
  return {
    type: actionTypes.setCity,
    data: city
  }
}

export const billingActions = {
  setBilling,
  unsetBilling,
  setName,
  setEmail,
  setPhone,
  setCompany,
  setStreet,
  setZip,
  setCity
}
