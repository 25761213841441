import React, {useRef, useLayoutEffect, useState} from "react"
import {Stage, Layer, Line, Group, Text} from "react-konva"
import {useSelector} from "react-redux"
import {createHorizontalMeasurements, createVerticalMeasurements} from "../../measurement"
import {createPart} from "./part"
import {FONT_SIZE} from "../../../../constants"

export const GroundPlan = () => {
  const client = useRef()
  const [clientSize, setClientSize] = useState({})
  const resizeDelay = 100
  let resizeTimer = null

  const updateClientSize = () => {
    if (client.current) {
      setClientSize({
        width: client.current.offsetWidth,
        height: client.current.offsetHeight
      })
    }
  }

  useLayoutEffect(() => updateClientSize(), [])
  window.addEventListener('resize', () => {
    clearInterval(resizeTimer)
    resizeTimer = setTimeout(updateClientSize, resizeDelay)
  })

  const innerLength = useSelector(state => state.plan.length)
  const innerWidth = useSelector(state => state.plan.width)
  const wallWidth = useSelector(state => state.plan.wallWidth)
  const outerLength = innerLength + 2 * wallWidth
  const outerWidth = innerWidth + 2 * wallWidth

  const measurementsAreaScreenWidth = Math.min(clientSize.width - 4, 65)
  const sideIndicatorWidth = Math.min(clientSize.width - 4, 12)
  const partScreenWidth = (clientSize.width - measurementsAreaScreenWidth - sideIndicatorWidth - 4)
  const scale = partScreenWidth / outerLength
  const partScreenHeight = outerWidth * scale

  const layout = {
    screenX: 2,
    screenY: 0,
    screenWidth: clientSize.width - 4,
    screenHeight: measurementsAreaScreenWidth + partScreenHeight + sideIndicatorWidth,
    realWidth: outerWidth,
    realLength: outerLength,
    scale: scale
  }
  // noinspection JSSuspiciousNameCombination
  const partLayout = {
    screenX: layout.screenX + measurementsAreaScreenWidth,
    screenY: layout.screenY + sideIndicatorWidth,
    screenWidth: outerLength * scale,
    screenHeight: outerWidth * scale,
    realWidth: outerLength,
    realHeight: outerWidth,
    scale: scale
  }
  // noinspection JSSuspiciousNameCombination
  const partInsideLayout = {
    screenX: partLayout.screenX + wallWidth * scale,
    screenY: partLayout.screenY + wallWidth * scale,
    screenWidth: innerLength * scale,
    screenHeight: innerWidth * scale,
    realWidth: innerLength,
    realHeight: innerWidth,
    scale: scale
  }
  // noinspection JSSuspiciousNameCombination
  const horizontalMeasurementLayout = {
    screenX: partLayout.screenX,
    screenY: partLayout.screenY + partLayout.screenHeight,
    screenWidth: partLayout.screenWidth,
    screenHeight: measurementsAreaScreenWidth,
    realWidth: outerLength,
    scale: scale
  }
  // noinspection JSSuspiciousNameCombination
  const verticalMeasurementLayout = {
    screenX: layout.screenX,
    screenY: partLayout.screenY,
    screenWidth: measurementsAreaScreenWidth,
    screenHeight: partLayout.screenHeight,
    realHeight: outerWidth,
    scale: scale
  }

  const getPartLeft = () => partLayout.screenX
  const getPartRight = () => partLayout.screenX + partLayout.screenWidth
  const getPartTop = () => partLayout.screenY
  const getPartBottom = () => partLayout.screenY + partLayout.screenHeight
  const getPartCenterX = () => (getPartLeft() + getPartRight()) / 2
  const getPartCenterY = () => (getPartTop() + getPartBottom()) / 2

  const renderSideIndicators = () => {
    const getLabelWidth = () => 100
    const getLabelOffset = () => 0.2 * FONT_SIZE
    const renderArrow = rotation =>
      <Line
        points={[0, 0, 3, 4, -3, 4].map(i => i * 0.2 * FONT_SIZE)}
        fill={'black'}
        closed={true}
        rotation={rotation}
      />

    return (
      <React.Fragment>
        <Group x={getPartCenterX()} y={getPartBottom()}>
          {renderArrow(0)}
          <Text
            text={'Ansicht A'}
            fontSize={FONT_SIZE}
            align={'center'}
            width={getLabelWidth()}
            x={-getLabelWidth() / 2}
            y={-FONT_SIZE - getLabelOffset() + 2}
          />
        </Group>
        <Group x={getPartCenterX()} y={getPartTop()}>
          {renderArrow(180)}
          <Text
            text={'Ansicht B'}
            fontSize={FONT_SIZE}
            align={'center'}
            width={getLabelWidth()}
            x={-getLabelWidth() / 2}
            y={getLabelOffset()}
          />
        </Group>
        <Group x={getPartLeft()} y={getPartCenterY()}>
          {renderArrow(90)}
          <Text
            text={'Ansicht C'}
            fontSize={FONT_SIZE}
            align={'center'}
            width={getLabelWidth()}
            x={getLabelOffset()}
            y={getLabelWidth() / 2}
            rotation={-90}
          />
        </Group>
        <Group x={getPartRight()} y={getPartCenterY()}>
          {renderArrow(-90)}
          <Text
            text={'Ansicht D'}
            fontSize={FONT_SIZE}
            align={'center'}
            width={getLabelWidth()}
            x={-FONT_SIZE - getLabelOffset() + 2}
            y={getLabelWidth() / 2}
            rotation={-90}
          />
        </Group>
      </React.Fragment>
    )
  }

  return (
    <div ref={client}>
      {!isNaN(scale) &&
      <Stage
        width={clientSize.width}
        height={layout.screenHeight}
      >
        <Layer>
          {createPart(partLayout, partInsideLayout)}
          {
            createHorizontalMeasurements(
              horizontalMeasurementLayout,
              [[wallWidth, partInsideLayout.realWidth, wallWidth]],
              [0]
            )
          }
          {
            createVerticalMeasurements(
              verticalMeasurementLayout,
              [[wallWidth, partInsideLayout.realHeight, wallWidth]]
            )
          }
          {renderSideIndicators()}
        </Layer>
      </Stage>
      }
    </div>
  )
}

