export const getClosureSizes = manholeType => manholeDefinitions.filter(m => m.type === manholeType)[0]

export const manholeDefinitions =
  [
    {
      type: 'BEDEC-100',
      width: 25,
      height: 100,
      thickness: 8,
      rowSpacing: 0,
      rabbetWidth: 5.25,
      applicableToLidType: 'A15'
    },
    {
      type: 'BEDEC-80',
      width: 25,
      height: 80,
      thickness: 8,
      rowSpacing: 0,
      rabbetWidth: 5.25,
      applicableToLidType: 'A15'
    },
    {
      type: 'BEDEC-60',
      width: 25,
      height: 60,
      thickness: 8,
      rowSpacing: 0,
      rabbetWidth: 5.25,
      applicableToLidType: 'A15'
    },
    {
      type: 'V2A-1000',
      width: 50,
      height: 100,
      thickness: 7.5,
      rowSpacing: 7,
      rabbetWidth: 3.5,
      applicableToLidType: 'B125'
    },
    {
      type: 'V2A-10',
      width: 47,
      height: 68,
      thickness: 7.5,
      rowSpacing: 7,
      rabbetWidth: 3.5,
      applicableToLidType: 'B125'
    },
    {
      type: 'GBDC',
      width: 52,
      height: 100,
      thickness: 7.5,
      rowSpacing: 0,
      rabbetWidth: 3.5,
      applicableToLidType: 'D400'
    },
    {
      type: 'GBE',
      width: 52,
      height: 100,
      thickness: 7.5,
      rowSpacing: 0,
      rabbetWidth: 3.5,
      applicableToLidType: 'E600'
    },
    {
      type: 'BET-130',
      width: 27,
      height: 130,
      thickness: 10,
      rowSpacing: 0,
      applicableToLidType: 'subfloor',
      bracketDiameter: 1.5,
      bracketRadius: 3,
      bracketWidth: 20,
      bracketHeight: 6.25,
      bracketPosition: 8.5
    },
    {
      type: 'BET-150',
      width: 27,
      height: 150,
      thickness: 10,
      rowSpacing: 0,
      applicableToLidType: 'subfloor',
      bracketDiameter: 1.5,
      bracketRadius: 3,
      bracketWidth: 20,
      bracketHeight: 6.25,
      bracketPosition: 8.5
    }
  ]

export const getClosuresIntersectionPositions = (manholeSideLength, closureSideLength) => {
  const intersectionCount = (manholeSideLength + 0.1) / closureSideLength - 1

  return Array.from({length: intersectionCount}, (_, i) => {
    return (i + 1) * closureSideLength
  })
}

export const getTypeLabelText = manholeType => {
  return manholeType === 'BEDEC-100' ? 'BEDEC-100/A15'
    : manholeType === 'BEDEC-80' ? 'BEDEC-80/A15'
      : manholeType === 'BEDEC-60' ? 'BEDEC-60/A15'
        : manholeType === 'V2A-1000' ? 'V2A-1000/B125'
          : manholeType === 'V2A-10' ? 'V2A-10/B125'
            : manholeType === 'GBDC' ? 'GBDC/D400'
              : manholeType === 'GBE' ? 'GBE/E600'
                : manholeType === 'BET-130' ? 'BET-130/Unterflur'
                  : manholeType === 'BET-150' ? 'BET-150/Unterflur'
                    : ''
}
