import React from "react"

import {Circle, Line, Rect, Text} from "react-konva"
import {FONT_SIZE} from "../../../constants";

const getGridMeasurements = (radius, halfMeshWidth) => {
  const meshWidth = 2 * halfMeshWidth
  let result = []

  for (let p = halfMeshWidth; p < radius; p += meshWidth) {
    const alpha = Math.acos(p / radius)
    const halfLength = Math.sin(alpha) * radius

    result.push({p, halfLength})
  }
  return result
}

const renderMeshGrid = (key, radius) => {
  const halfMeshWidth = 2
  const createLine = (p1, p2) => {
    return (
      <Line
        key={key + "_" + p1.x + "_" + p1.y + "_" + p2.x + "_" + p2.y}
        name="dimension"
        points={[p1.x, p1.y, p2.x, p2.y]}
        strokeWidth={1}
        stroke="gray"
        lineCap="round"
      />
    )
  }
  const createHorizontalLine = (y, halfLength) => {
    return createLine({x: -halfLength, y}, {x: halfLength, y})
  }
  const createVerticalLine = (x, halfLength) => {
    return createLine({x, y: -halfLength}, {x, y: halfLength})
  }

  return getGridMeasurements(radius, halfMeshWidth).map(m => {
    return [
      createHorizontalLine(m.p, m.halfLength),
      createHorizontalLine(-m.p, m.halfLength),
      createVerticalLine(m.p, m.halfLength),
      createVerticalLine(-m.p, m.halfLength)
    ]
  })
}

const renderHoleGrid = (key, radius) => {
  const halfMeshWidth = 10
  const holeRadius = 8
  const createCircle = (x, y) => {
    return (
      <Circle
        key={key + "_hole_" + x + "_" + y}
        name="dimension"
        x={x} y={y}
        radius={holeRadius}
        stroke="gray"
        strokeWidth={1}
      />)
  }

  return getGridMeasurements(radius - holeRadius, halfMeshWidth)
    .map((y, i, arr) => {
      return arr.filter(x => {
        return x.p < y.halfLength
      }).map(x => {
        return [
          createCircle(y.p, x.p),
          createCircle(y.p, -x.p),
          createCircle(-y.p, x.p),
          createCircle(-y.p, -x.p)
        ]
      })
    })
}

export const renderGroupElements = (screenCircle, drainage, fillColor = '#eeeeee') => {
  const {radius} = screenCircle;
  return (
    <React.Fragment>
      <Circle
        radius={radius}
        fill={fillColor}
        stroke="black"
        strokeWidth={1}
        shadowColor="black"
        shadowBlur={8}
        shadowOpacity={0}
      />
      {
        drainage.type === '100'
        && renderMeshGrid('key', radius)
      }
      {
        drainage.type === '300'
        && renderHoleGrid('key', radius)
      }
      {
        !!drainage.x && drainage.y &&
        <Rect
          name="dimension-bg"
          x={-1 * FONT_SIZE}
          y={-0.55 * FONT_SIZE}
          width={2 * FONT_SIZE}
          height={FONT_SIZE}
          align="center"
          verticalAlign="middle"
          fill={fillColor === 'transparent' ? 'white' : fillColor}
          cornerRadius={5}
          blurRadius={40}
        />
      }
      {
        !!drainage.x && drainage.y &&
        <Text
          name="dimension"
          x={-radius}
          y={-radius}
          width={2 * radius}
          height={2 * radius}
          fontSize={FONT_SIZE}
          align="center"
          verticalAlign="middle"
          text={drainage.diameter * 10}
          fill="black"
        />
      }
    </React.Fragment>
  )
}
