export const planLimits = {
  minLength: 20,
  maxLength: 700,
  lengthStepWidth: 5,
  minWidth: 20,
  maxWidth: 340,
  widthStepWidth: 5,
  minVaultHeight: 15,
  maxVaultHeight: 400,
  vaultHeightStepWidth: 1,
  minFloorThickness: 10,
  maxFloorThickness: 50,
  floorThicknessStepWidth: 1,
  minWallWidth: 10,
  maxWallWidth: 50,
  wallWidthStepWidth: 1
};

const getError = (value, displayName, min, max, stepWidth) => {
  if (!value) return displayName + " ist erforderlich";
  const intValue = parseInt(value);

  if (
    !(typeof intValue == 'number')
    || isNaN(intValue)
    || !isFinite(intValue)
    || intValue < min
    || intValue > max
    || intValue % stepWidth !== 0
  ) {
    if (stepWidth > 1) {
      return displayName + " muss ganzzahlig und durch "
        + stepWidth + " teilbar sein sowie zwischen "
        + min + " und "
        + max + " liegen";
    }
    return displayName + " muss ganzzahlig sein und zwischen " + min + " und " + max + " liegen";
  }
};

export const getLengthError = value => {
  return getError(value, "Länge", planLimits.minLength, planLimits.maxLength, planLimits.lengthStepWidth);
};
export const isLengthValid = value => !getLengthError(value);

export const getWidthError = value => {
  return getError(value, "Breite", planLimits.minWidth, planLimits.maxWidth, planLimits.widthStepWidth);
};
export const isWidthValid = value => !getWidthError(value);

export const getWallWidthError = value => {
  return getError(
    value,
    "Wandstärke",
    planLimits.minWallWidth,
    planLimits.maxWallWidth,
    planLimits.wallWidthStepWidth
  );
};
export const isWallWidthValid = value => !getWallWidthError(value);

export const getVaultHeightError = value => {
  return getError(
    value,
    "Höhe",
    planLimits.minVaultHeight,
    planLimits.maxVaultHeight,
    planLimits.vaultHeightStepWidth
  );
};
export const isVaultHeightValid = value => !getVaultHeightError(value);

export const getFloorThicknessError = value => {
  return getError(
    value,
    "Bodenstärke",
    planLimits.minFloorThickness,
    planLimits.maxFloorThickness,
    planLimits.floorThicknessStepWidth
  );
};
export const isFloorThicknessValid = value => !getFloorThicknessError(value);

