import {planActions} from "../actions";
import getElementsOutsideLimits from "../containers/domain/getElementsOutsideLimits";
import {isProjectValid} from "./projectFormValidation";
import {isBillingValid} from "./billingFormValidation";

const validate = (plan, project) => {

  const elementsOutsideLimits = () => {
    const elementsOutsideLimits = getElementsOutsideLimits(plan)

    return elementsOutsideLimits.some()
      ? [{
        message: 'Durch die Gössenänderung befinden sich Elemente ausserhalb des gültigen Bereiches! Vergrössern Sie' +
          ' die Abmessungen oder löschen Sie die Elemente: ' +
          [
            ...(elementsOutsideLimits.vault && elementsOutsideLimits.vault.walls[0].some() ? ['Ansicht A'] : []),
            ...(elementsOutsideLimits.vault && elementsOutsideLimits.vault.walls[1].some() ? ['Ansicht B'] : []),
            ...(elementsOutsideLimits.vault && elementsOutsideLimits.vault.walls[2].some() ? ['Ansicht C'] : []),
            ...(elementsOutsideLimits.vault && elementsOutsideLimits.vault.walls[3].some() ? ['Ansicht D'] : []),
            ...(elementsOutsideLimits.lid && elementsOutsideLimits.lid.some() ? ['Abdeckung'] : []),
            ...(elementsOutsideLimits.intermediateCeiling && elementsOutsideLimits.intermediateCeiling.some() ? ['Zwischendecke'] : []),
            ...(elementsOutsideLimits.floor && elementsOutsideLimits.floor.some() ? ['Boden'] : []),
          ]
            .join(", "),
        resolveAction: () => planActions.removeElementsOutsideLimits(),
        resolveActionText: 'Elemente löschen'
      }]
      : [];
  }

  const insufficientWallWidthForSleeves = () => {
    const hasSleeves = () => plan.vault && plan.vault.walls.some(w => w.sleeves.length > 0)

    return plan.wallWidth < 15 && hasSleeves()
      ? [{
        message: 'Muffen setzten eine Wandstärke von 15 cm voraus! Erhöhen Sie die Wandstärke oder löschen Sie die ' +
          'Muffen aus den Ansichten.'
      }]
      : []
  }

  const maxClearMeasureForSubFloorLidExceeded = () => {
    const hasSubFloorLidWithManhole = () => plan.lid && plan.lid.type === 'subfloor' && plan.lid.manholes.length > 0
    const hasIntermediateCeiling = () => plan.intermediateCeiling
    const getManhole = () => plan.lid.manholes[0]
    const getVaultClearMeasure = () => getManhole().orientation === 'vertical' ? plan.width : plan.length
    const getManholeMeasure = () => getManhole().orientation === 'vertical' ? getManhole().height : getManhole().width

    return !hasSubFloorLidWithManhole() || hasIntermediateCeiling()
    || getManholeMeasure() >= getVaultClearMeasure() + 20
      ? []
      : [{
        message: "Die Betonelemente der Unterflurabdeckung müssen die Wand mindestens 10 cm überlappen!" +
          (getVaultClearMeasure() <= 130
            ? ' Korrigieren Sie die Positionierung oder verwenden Sie das grössere Betonelement.'
            : ' Verkleinern Sie den Schacht.')
      }]
  }

  const invalidProject = () => {
    return !isProjectValid(plan, project)
      ? [{
        message: 'Bitte füllen Sie alle Pflichtfelder beim Schritt 5 (Lieferung) aus.',
        resolveLocation: '/step/5',
        resolveLocationText: 'Zu Schritt 5'
      }]
      : []
  }

  const invalidBilling = () => {
    return !isBillingValid(project)
      ? [{
        message: 'Bitte füllen Sie alle Pflichtfelder beim Schritt 6 (Rechnungsadresse) aus.',
        resolveLocation: '/step/6',
        resolveLocationText: 'Zu Schritt 6'
      }]
      : []
  }

  const coveredVaultWithoutOpening = () => {

    const hasLid = () => plan.lid
    const hasLidWithoutOpening = () =>
      plan.lid
      && plan.lid.manholes.length < 1
      && plan.lid.blockOuts.length < 1
      && plan.lid.circularBlockOuts.length < 1
    const hasIntermediateCeilingWithoutOpening = () =>
      plan.intermediateCeiling
      && plan.intermediateCeiling.circularBlockOuts.length < 1
      && plan.intermediateCeiling.blockOuts.length < 1

    return hasLidWithoutOpening()
      ? [{
        message: "Es wurde keine Einstiegsmöglichket definiert.",
        severity: 'error',
        resolveLocation: '/step/3',
        resolveLocationText: 'Zu Schritt 3'
      }]
      : (!hasLid() && hasIntermediateCeilingWithoutOpening())
        ? [{
          message: "Es wurde keine Einstiegsmöglichket definiert.",
          severity: 'info',
          resolveLocation: '/step/3',
          resolveLocationText: 'Zu Schritt 3'
        }]
        : []
  }

  return [
    ...elementsOutsideLimits(),
    ...insufficientWallWidthForSleeves(),
    ...maxClearMeasureForSubFloorLidExceeded(),
    ...coveredVaultWithoutOpening(),
    ...(project ? invalidProject() : []),
    ...(project ? invalidBilling() : []),
  ]
}

export const hasErrors = (plan, project) =>
  validate(plan, project).some(e => !e.severity || e.severity === "error")


export default validate
