import {planActionTypes as actionTypes} from "../constants";

const setFloor = () => {
  return {
    type: actionTypes.setFloor
  };
};

const unsetFloor = () => {
  return {
    type: actionTypes.unsetFloor
  }
};

const setFloorThickness = floorThickness => {
  return {
    type: actionTypes.setFloorThickness,
    data: floorThickness
  };
};

const addFloorDrainage = drainage => {
  return {
    type: actionTypes.addFloorDrainage,
    data: drainage
  };
};

const removeFloorDrainage = id => {
  return {
    type: actionTypes.removeFloorDrainage,
    data: id
  };
};

const moveFloorDrainage = (id, x, y) => {
  return {
    type: actionTypes.moveFloorDrainage,
    data: {id, x, y}
  };
};

export const floorActions = {
  setFloor,
  unsetFloor,
  setFloorThickness,
  addFloorDrainage,
  removeFloorDrainage,
  moveFloorDrainage
};
