import { authHeader } from "../helpers/authHeader"
import { handleResponse } from "./httpHelper"
import { apiUrls } from "../constants"

const read = () => {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader()
    }
  }

  return fetch(apiUrls.userEndpoint, requestOptions)
    .then(handleResponse)
    /*
    .then(user => {
      const { readReleaseNote, ...others } = user

      console.log(others, readReleaseNote = "123")

      return others
    })
    */
}

const markReleaseNotesRead = () => {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader()
    }
  }

  return fetch(apiUrls.releaseNotesEndpoint, requestOptions).then(
    handleResponse
  )
}

export const userService = {
  read,
  markReleaseNotesRead
}
