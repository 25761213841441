import React from "react"

import {Arrow, Line, Text} from "react-konva"

import {FONT_SIZE} from "../../constants"

const color = "black"
const arrowStrokeWidth = 0.5
const limiterStrokeWidth = 0.5

const margin = 0.1 // of measurement area height
const labelMargin = 1 // in pixel
const arrowPosition = 0.7 // of measurement row height, from top
const limiterOverlap = 0.1 // of measurement area height

const createArrow = (key, x1, y1, x2, y2) => {
  return (
    <Arrow
      key={key + "_arrow"}
      points={[x1, y1, x2, y2]}
      strokeWidth={arrowStrokeWidth}
      stroke={color}
      fill={color}
      lineCap="round"
      pointerAtBeginning={true}
      pointerWidth={5}
    />
  )
}

const createHorizontalArrowLabel = (key, x, y, width, height, text) => {
  return (
    <Text
      key={key + "_label"}
      x={x - 50}
      y={y}
      width={width + 100}
      height={height}
      align="center"
      verticalAlign="bottom"
      fontSize={FONT_SIZE}
      text={text}
      fill={color}
    />
  )
}

const createVerticalArrowLabel = (key, screenX, screenY, screenHeight, textHeight, text) => {
  const y = screenY + screenHeight

  return (
    <Text
      key={key + "_label"}
      x={screenX}
      y={y + 50}
      width={screenHeight + 100}
      height={textHeight}
      align="center"
      verticalAlign="bottom"
      fontSize={FONT_SIZE}
      text={text}
      fill={color}
      rotation={-90}
    />
  )
}

// extension line
const createMeasurementLimiter = (key, screenX1, screenY1, screenX2, screenY2) => {
  return (
    <Line
      key={key}
      points={[screenX1, screenY1, screenX2, screenY2]}
      strokeWidth={limiterStrokeWidth}
      stroke={color}
      lineCap="round"
    />
  )
}

const createHorizontalMeasurementLimiters = (
  key,
  screenX,
  screenY,
  screenHeight,
  measurementRows,
  rowOffsets,
  scale,
) => {
  const getPositions = i => {
    const getPositions = i =>
      [0, ...measurementRows[i]].reduce(
        (acc, curr, i) => [...acc, (i > 0 ? acc[i - 1] : 0) + curr],
        []
      ).map(p => p + (!!rowOffsets[i] ? rowOffsets[i] : 0))
    const getOuterRowsIndices = () => i < measurementRows.length - 1
      ? [...Array(measurementRows.length - i - 1).keys()].map(j => j + i + 1)
      : []
    const contains = (rowIndex, pos) => getPositions(rowIndex).some(pos2 => pos > pos2 - 0.1 && pos < pos2 + 0.1)

    return getPositions(i).filter(pos => !getOuterRowsIndices().some(j => contains(j, pos)))
  }
  const getLimiterLength = rowIndex => {
    const screenMargin = margin * screenHeight
    const screenRowHeight = (screenHeight - screenMargin) / measurementRows.length

    return screenMargin
      + rowIndex * screenRowHeight
      + screenRowHeight * arrowPosition
      + limiterOverlap * screenHeight
  }

  return measurementRows.map(
    (row, i) => {
      const getX = realX => realX * scale + screenX
      const getTop = () => screenY
      const getBottom = () => getTop() + getLimiterLength(i)

      return getPositions(i).map(
        (value, index) => createMeasurementLimiter(
          key + "_overall_limiter_" + i + "_" + index,
          getX(value),
          getTop(),
          getX(value),
          getBottom()
        )
      )
    }
  )
}

const createVerticalMeasurementLimiters = (
  key,
  screenX,
  screenY,
  screenWidth,
  scale,
  rowOffsets,
  measurementRows
) => {
  const getPositions = i => {
    const getPositions = i =>
      [0, ...measurementRows[i]].reduce(
        (acc, curr, i) => [...acc, (i > 0 ? acc[i - 1] : 0) + curr],
        []
      ).map(p => p + (!!rowOffsets[i] ? rowOffsets[i] : 0))
    const getOuterRowsIndices = () => i > 0
      ? [...Array(i).keys()]
      : []
    const contains = (rowIndex, pos) => getPositions(rowIndex).some(pos2 => pos > pos2 - 0.1 && pos < pos2 + 0.1)

    return getPositions(i).filter(pos => !getOuterRowsIndices().some(j => contains(j, pos)))
  }
  const getLimiterLength = rowIndex => {
    const rowHeight = (1 - margin) / measurementRows.length
    const rowsBetween = measurementRows.length - rowIndex - 1

    return (
      margin
      + rowsBetween * rowHeight
      + (1 - arrowPosition) * rowHeight
      + limiterOverlap
    ) * screenWidth
  }

  return measurementRows.map(
    (row, i) => {
      const getY = realY => realY * scale + screenY
      const getRight = () => screenX + screenWidth
      const getLeft = () => getRight() - getLimiterLength(i)

      return getPositions(i).map(
        (value, index) => createMeasurementLimiter(
          key + "_overall_limiter_" + i + "_" + index,
          getLeft(),
          getY(value),
          getRight(),
          getY(value)
        )
      )
    }
  )
}

const createHorizontalMeasurementsRow = (
  key,
  screenX,
  screenY,
  screenHeight,
  measurements,
  scale
) => {
  const textHeight = 0.75 * screenHeight
  const arrowY = screenY + textHeight
  let fromX = screenX

  return measurements.map((measurement, i) => {
    const screenWidth = measurement * scale
    const toX = fromX + screenWidth
    const renderedArrow = createArrow(key + "_" + i, fromX, arrowY, toX, arrowY)
    const renderedLabel = createHorizontalArrowLabel(
      key + "_" + i,
      fromX,
      screenY - labelMargin,
      screenWidth,
      textHeight,
      measurement)

    fromX = toX
    return [
      renderedArrow,
      renderedLabel
    ]
  })
}

const createVerticalMeasurementsRow = (
  key,
  screenX,
  screenY,
  screenWidth,
  measurements,
  scale
) => {
  const textHeight = arrowPosition * screenWidth
  const arrowX = screenX + textHeight
  let fromY = screenY

  return measurements.reverse().map((measurement, i) => {
    const screenHeight = measurement * scale
    const toY = fromY + screenHeight
    const renderedArrow = createArrow("_" + i, arrowX, fromY, arrowX, toY)
    const renderedLabel = createVerticalArrowLabel(
      key + "_" + i,
      screenX - labelMargin,
      fromY,
      screenHeight,
      textHeight,
      measurement)

    fromY = toY
    return [
      renderedArrow,
      renderedLabel
    ]
  })
}

export const createHorizontalMeasurements = (layout, measurementRows, rowOffsets = []) => {
  const key = "horizontal_measurements"
  const marginHeight = layout.screenHeight * margin
  const rowHeight = (layout.screenHeight - marginHeight) / measurementRows.length
  let result = []

  for (let i = 0; i < measurementRows.length; i++) {
    const measurements = measurementRows[i]
    const screenX = layout.screenX + (rowOffsets[i] ? rowOffsets[i] * layout.scale : 0)

    result.push(
      createHorizontalMeasurementsRow(
        key + "_" + i,
        screenX,
        layout.screenY + marginHeight + i * rowHeight,
        rowHeight,
        measurements,
        layout.scale)
    )
  }
  result.push(
    createHorizontalMeasurementLimiters(
      key,
      layout.screenX,
      layout.screenY,
      layout.screenHeight,
      measurementRows,
      rowOffsets,
      layout.scale
    )
  )
  return result
}

export const createVerticalMeasurements = (layout, measurementRows, rowOffsets = []) => {
  const key = "vertical_measurements"
  const screenWidthWithinMargin = layout.screenWidth * (1 - margin)
  const rowWidth = screenWidthWithinMargin / measurementRows.length
  let result = []

  for (let i = 0; i < measurementRows.length; i++) {
    const measurements = measurementRows[i]
    const screenY = layout.screenY + (rowOffsets[i] ? rowOffsets[i] * layout.scale : 0)

    result.push(
      createVerticalMeasurementsRow(
        key + "_" + i,
        layout.screenX + rowWidth * i,
        screenY,
        rowWidth,
        measurements,
        layout.scale)
    )
  }
  result.push(
    createVerticalMeasurementLimiters(
      key,
      layout.screenX,
      layout.screenY,
      layout.screenWidth,
      layout.scale,
      rowOffsets,
      measurementRows
    )
  )
  return result
}
