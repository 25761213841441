import {
  getBevelBottom,
  getHorizontalAnchorWidth,
  getManhole,
  getVerticalAnchorWidth,
  lidFunctions,
  manholeFunctions
} from "../../domain/lid";
import {vaultFunctions} from "../../domain/vault";
import {supplementaryCoverFunctions} from "../../domain/subfloorLid";
import {getManholeClearMeasure} from "../../domain/intermediateCeiling";
import {getClosureSizes} from "../../domain/manhole";
import {getLidToIntermediateCeilingSpacing} from "../../domain/composition";

const getMeasurementPositions = plan => {
  const lidOuterCorners = () => {
    const getLeft = () => lidFunctions.getLeft(plan) + getHorizontalAnchorWidth(plan)
    const getRight = () => lidFunctions.getRight(plan) - getHorizontalAnchorWidth(plan)
    const getFront = () => lidFunctions.getFront(plan) + getVerticalAnchorWidth(plan)
    const getBack = () => lidFunctions.getBack(plan) - getVerticalAnchorWidth(plan)
    const getTop = () => getBevelBottom(plan)

    return [
      {x: getLeft(), y: getFront(), z: getTop()},
      {x: getRight(), y: getFront(), z: getTop()},
      {x: getRight(), y: getBack(), z: getTop()},
      {x: getLeft(), y: getBack(), z: getTop()}
    ]
  }
  const vaultOuterCorners = () => {
    const getTop = () => lidFunctions.getHeight(plan)

    return [
      {x: vaultFunctions.getLeft(plan), y: vaultFunctions.getFront(plan), z: getTop()},
      {x: vaultFunctions.getRight(plan), y: vaultFunctions.getFront(plan), z: getTop()},
      {x: vaultFunctions.getRight(plan), y: vaultFunctions.getBack(plan), z: getTop()},
      {x: vaultFunctions.getLeft(plan), y: vaultFunctions.getBack(plan), z: getTop()},
    ]
  }
  const getTop = () => lidFunctions.getHeight(plan)
  const toRect = size => ({...size, width: size.length, height: size.width})
  const corners = rect => {

    return [
      {x: rect.x, y: rect.y, z: getTop()},
      {x: rect.x, y: rect.y + rect.height, z: getTop()},
      {x: rect.x + rect.width, y: rect.y, z: getTop()},
      {x: rect.x + rect.width, y: rect.y + rect.height, z: getTop()}
    ]
  }

  const getA15B125D400E600Positions = () => {
    return [
      ...lidOuterCorners().map(c => ({
        ...c,
        z: plan.lid.collar === 'without'
          ? lidFunctions.getHeight(plan) - (
          plan.lid.type === 'A15' ? 8
            : plan.lid.type === 'B125' ? 7.5
              : plan.lid.type === 'D400' ? 6.5
                : plan.lid.type === 'E600' ? 11
                  : 0
        )
          : c.z
      })),
      ...plan.lid.manholes.reduce((acc, curr) => [...acc, ...corners(curr)], []),
      ...plan.lid.blockOuts.reduce((acc, curr) => [...acc, ...corners(curr)], []),
      ...plan.lid.circularBlockOuts.reduce(
        (acc, curr) => [...acc, {x: curr.x, y: curr.y, z: getTop()}],
        []
      )
    ]
  }
  const getSubFloorPositions = () => {
    return [
      ...(
        plan.lid.manholes.length === 1
          ? corners(plan.lid.manholes[0]).map(p => ({...p, z: getClosureSizes(plan.lid.manholes[0].type).thickness}))
          : lidOuterCorners()
      ),
      ...(
        !!plan.vault
          ? vaultOuterCorners().map(p => ({...p, z: 0}))
          : []
      ),
      ...(
        supplementaryCoverFunctions.has(plan, 'left')
          ? corners(toRect(supplementaryCoverFunctions.getSize(plan, 'left')))
          : []
      ),
      ...(
        supplementaryCoverFunctions.has(plan, 'right')
          ? corners(toRect(supplementaryCoverFunctions.getSize(plan, 'right')))
          : []
      ),
      ...(
        supplementaryCoverFunctions.has(plan, 'front')
          ? corners(toRect(supplementaryCoverFunctions.getSize(plan, 'front')))
          : []
      ),
      ...(
        supplementaryCoverFunctions.has(plan, 'back')
          ? corners(toRect(supplementaryCoverFunctions.getSize(plan, 'back')))
          : []
      )
    ]
  }
  const getLidOnIntermediateCeilingPositions = () => {
    return [
      ...(
        plan.lid.manholes.length === 1
          ? corners(getManholeClearMeasure(plan, plan.lid.manholes[0])).map(
            p => ({
              ...p,
              z: plan.lid.type === 'subfloor' ? getClosureSizes(plan.lid.manholes[0].type).thickness : p.z
            })
          )
          : []
      ),
      ...vaultOuterCorners().map(p => ({...p, z: -getLidToIntermediateCeilingSpacing(plan)}))
    ]
  }

  return !!plan.intermediateCeiling
    ? getLidOnIntermediateCeilingPositions()
    : plan.lid.type === 'A15' || plan.lid.type === 'B125' || plan.lid.type === 'D400' || plan.lid.type === 'E600'
      ? getA15B125D400E600Positions()
      : plan.lid.type === 'subfloor'
        ? getSubFloorPositions()
        : []
}
const getExtensionLineLimits = plan => {
  const manhole = getManhole(plan)
  const closureThickness = () => manhole ? getClosureSizes(manhole.type).thickness : 0

  const getLidOnIntermediateCeilingLimits = () => {
    return [
      {
        from: {x: vaultFunctions.getLeft(plan), y: vaultFunctions.getFront(plan)},
        to: {x: vaultFunctions.getLeft(plan), y: vaultFunctions.getBack(plan)}
      },
      {
        from: {x: vaultFunctions.getLeft(plan), y: vaultFunctions.getFront(plan)},
        to: {x: vaultFunctions.getRight(plan), y: vaultFunctions.getFront(plan)}
      },
      {
        from: {x: lidFunctions.getLeft(plan), y: lidFunctions.getFront(plan)},
        to: {x: lidFunctions.getLeft(plan), y: lidFunctions.getBack(plan)}
      },
      {
        from: {x: lidFunctions.getLeft(plan), y: lidFunctions.getFront(plan)},
        to: {x: lidFunctions.getRight(plan), y: lidFunctions.getFront(plan)}
      },
      {
        from: {
          x: lidFunctions.getLeft(plan),
          z: plan.lid.type === 'subfloor' ? closureThickness() : lidFunctions.getTop(plan)
        },
        to: {
          x: lidFunctions.getRight(plan),
          z: plan.lid.type === 'subfloor' ? closureThickness() : lidFunctions.getTop(plan)
        }
      },
      {
        from: {
          y: lidFunctions.getFront(plan),
          z: plan.lid.type === 'subfloor' ? closureThickness() : lidFunctions.getTop(plan)
        },
        to: {
          y: lidFunctions.getBack(plan),
          z: plan.lid.type === 'subfloor' ? closureThickness() : lidFunctions.getTop(plan)
        }
      }
    ]
  }
  const getA15B125D400E600Limits = () => {
    return [
      {
        from: {
          x: lidFunctions.getLeft(plan) + getHorizontalAnchorWidth(plan),
          y: lidFunctions.getFront(plan)
        },
        to: {
          x: lidFunctions.getLeft(plan) + getHorizontalAnchorWidth(plan),
          y: lidFunctions.getBack(plan)
        }
      },
      {
        from: {
          x: lidFunctions.getLeft(plan),
          y: lidFunctions.getFront(plan) + getVerticalAnchorWidth(plan)
        },
        to: {
          x: lidFunctions.getRight(plan),
          y: lidFunctions.getFront(plan) + getVerticalAnchorWidth(plan)
        }
      }
    ]
  }
  const getSubFloorLimits = () => {
    return [
      {
        from: {x: manholeFunctions.getLeft(plan), y: manholeFunctions.getFront(plan)},
        to: {x: manholeFunctions.getLeft(plan), y: manholeFunctions.getBack(plan)}
      },
      {
        from: {x: manholeFunctions.getLeft(plan), y: manholeFunctions.getFront(plan)},
        to: {x: manholeFunctions.getRight(plan), y: manholeFunctions.getFront(plan)}
      },
      {
        from: {x: manholeFunctions.getLeft(plan), z: closureThickness()},
        to: {x: manholeFunctions.getRight(plan), z: closureThickness()}
      },
      {
        from: {y: manholeFunctions.getFront(plan), z: closureThickness()},
        to: {y: manholeFunctions.getBack(plan), z: closureThickness()}
      },
      ...(
        supplementaryCoverFunctions.has(plan, 'left')
          ? [
            {
              from: {
                x: supplementaryCoverFunctions.getLeft(plan, 'left'),
                y: supplementaryCoverFunctions.getFront(plan, 'left')
              },
              to: {
                x: supplementaryCoverFunctions.getLeft(plan, 'left'),
                y: supplementaryCoverFunctions.getBack(plan, 'left')
              }
            },
            {
              from: {
                y: supplementaryCoverFunctions.getFront(plan, 'left'),
                z: supplementaryCoverFunctions.getTop(plan, 'left')
              },
              to: {
                y: supplementaryCoverFunctions.getBack(plan, 'left'),
                z: supplementaryCoverFunctions.getTop(plan, 'left')
              }
            }
          ]
          : []
      ),
      ...(
        supplementaryCoverFunctions.has(plan, 'right')
          ? [
            {
              from: {
                y: supplementaryCoverFunctions.getFront(plan, 'right'),
                z: supplementaryCoverFunctions.getTop(plan, 'right')
              },
              to: {
                y: supplementaryCoverFunctions.getBack(plan, 'right'),
                z: supplementaryCoverFunctions.getTop(plan, 'right')
              }
            }
          ]
          : []
      ),
      ...(
        supplementaryCoverFunctions.has(plan, 'front')
          ? [
            {
              from: {
                x: supplementaryCoverFunctions.getLeft(plan, 'front'),
                y: supplementaryCoverFunctions.getFront(plan, 'front')
              },
              to: {
                x: supplementaryCoverFunctions.getRight(plan, 'front'),
                y: supplementaryCoverFunctions.getFront(plan, 'front')
              }
            },
            {
              from: {
                x: supplementaryCoverFunctions.getLeft(plan, 'front'),
                z: supplementaryCoverFunctions.getTop(plan, 'front')
              },
              to: {
                x: supplementaryCoverFunctions.getRight(plan, 'front'),
                z: supplementaryCoverFunctions.getTop(plan, 'front')
              }
            }
          ]
          : []
      ),
      ...(
        supplementaryCoverFunctions.has(plan, 'back')
          ? [
            {
              from: {
                x: supplementaryCoverFunctions.getLeft(plan, 'back'),
                z: supplementaryCoverFunctions.getTop(plan, 'back')
              },
              to: {
                x: supplementaryCoverFunctions.getRight(plan, 'back'),
                z: supplementaryCoverFunctions.getTop(plan, 'back')
              }
            }
          ]
          : []
      ),
      ...(!!plan.vault ? [
          {
            from: {x: vaultFunctions.getLeft(plan), y: vaultFunctions.getFront(plan)},
            to: {x: vaultFunctions.getLeft(plan), y: vaultFunctions.getBack(plan)}
          },
          {
            from: {x: vaultFunctions.getLeft(plan), y: vaultFunctions.getFront(plan)},
            to: {x: vaultFunctions.getRight(plan), y: vaultFunctions.getFront(plan)}
          }
        ] : [])
    ];
  }

  return !!plan.intermediateCeiling
    ? getLidOnIntermediateCeilingLimits()
    : plan.lid.type === 'A15' || plan.lid.type === 'B125' || plan.lid.type === 'D400' || plan.lid.type === 'E600'
      ? getA15B125D400E600Limits()
      : plan.lid.type === 'subfloor'
        ? getSubFloorLimits()
        : []
}

export const getGroundViewLeftMeasurementPositions = plan => {
  const filterRelevant = positions => {
    return [
      ...positions.reduce(
        (acc, curr) => {
          if (!acc.has(curr.y) || acc.get(curr.y).x > curr.x) {
            acc.set(curr.y, curr)
          }
          return acc
        },
        new Map()
      ).values()
    ]
  }
  const applyLimits = (positions, limits) => {
    const getRelevantLimits = y => limits.filter(l =>
      Number.isFinite(l.from.x)
      && l.from.x === l.to.x
      && l.from.y <= y && l.to.y >= y
    )
    const getLimitedX = pos => Math.min(pos.x, ...getRelevantLimits(pos.y).map(l => l.from.x))

    return positions.map(p => ({...p, x: getLimitedX(p)}))
  }

  return applyLimits(
    filterRelevant(
      getMeasurementPositions(plan)
    ),
    getExtensionLineLimits(plan)
  )

}
export const getGroundViewBottomMeasurementPositions = plan => {
  const filterRelevant = positions => {
    return [
      ...positions.reduce(
        (acc, curr) => {
          if (!acc.has(curr.x) || acc.get(curr.x).y > curr.y) {
            acc.set(curr.x, curr)
          }
          return acc
        },
        new Map()
      ).values()
    ]
  }
  const applyLimits = (positions, limits) => {
    const getRelevantLimits = x => limits.filter(l =>
      Number.isFinite(l.from.y)
      && l.from.y === l.to.y
      && l.from.x <= x && l.to.x >= x
    )
    const getLimitedY = (x, y) => Math.min(y, ...getRelevantLimits(x).map(l => l.from.y))

    return positions.map(p => ({...p, y: getLimitedY(p.x, p.y)}))
  }

  return applyLimits(
    filterRelevant(
      getMeasurementPositions(plan)
    ),
    getExtensionLineLimits(plan)
  )

}
export const getElevationViewTopMeasurementPositions = (plan, side) => {
  const filterRelevant = positions => {
    const getHorizontalAxisPosition = pos => side === 'front' || side === 'back' ? pos.x : pos.y
    return [
      ...positions.reduce(
        (acc, curr) => {
          if (!acc.has(getHorizontalAxisPosition(curr)) || acc.get(getHorizontalAxisPosition(curr)).z < curr.z) {
            acc.set(getHorizontalAxisPosition(curr), curr)
          }
          return acc
        },
        new Map()
      ).values()
    ]
  }
  const applyLimits = (positions, limits) => {
    const getHorizontalAxisPosition = pos => side === 'front' || side === 'back' ? pos.x : pos.y

    const getRelevantLimits = pos => limits.filter(l =>
      Number.isFinite(l.from.z)
      && l.from.z === l.to.z
      && getHorizontalAxisPosition(l.from) <= getHorizontalAxisPosition(pos)
      && getHorizontalAxisPosition(l.to) >= getHorizontalAxisPosition(pos)
    )
    const getLimitedZ = pos => Math.max(pos.z, ...getRelevantLimits(pos).map(l => l.from.z))

    return positions.map(p => ({...p, z: getLimitedZ(p)}))
  }

  return applyLimits(
    filterRelevant(
      getMeasurementPositions(plan)
    ),
    getExtensionLineLimits(plan)
  )
}
