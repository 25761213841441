import {removeAlphaFromDataUrl} from "../helpers/pngHelpers";
import {getLidSize} from "../containers/domain/lid";
import {intermediateCeilingFunctions} from "../containers/domain/intermediateCeiling";

export const prepareImages = (plan, planImages) => ({
  ...(!!plan.lid && {lidGroundView: removeAlphaFromDataUrl(planImages.lidGroundView)}),
  ...(
    !!plan.intermediateCeiling && !plan.lid &&
    {intermediateCeilingGroundView: removeAlphaFromDataUrl(planImages.intermediateCeilingGroundView)}
  ),
  ...(!!plan.floor && {floorGroundView: removeAlphaFromDataUrl(planImages.floorGroundView)}),
  sideAElevationView: removeAlphaFromDataUrl(planImages.sideAElevationView),
  sideBElevationView: removeAlphaFromDataUrl(planImages.sideBElevationView),
  sideCElevationView: removeAlphaFromDataUrl(planImages.sideCElevationView),
  sideDElevationView: removeAlphaFromDataUrl(planImages.sideDElevationView)
})

export const addLidSize = plan => {
  return !plan.lid
    ? plan
    : {
      ...plan, lid: {
        ...plan.lid,
        length: Math.round(getLidSize(plan).length),
        width: Math.round(getLidSize(plan).width),
        height: Math.round(getLidSize(plan).height),
        x: Math.round(getLidSize(plan).x),
        y: Math.round(getLidSize(plan).y)
      }
    }
}

export const addIntermediateCeilingSize = plan => {
  return !plan.intermediateCeiling
    ? plan
    : {
      ...plan, intermediateCeiling:
        {
          ...plan.intermediateCeiling,
          length: Math.round(intermediateCeilingFunctions.getLength(plan)),
          width: Math.round(intermediateCeilingFunctions.getWidth(plan)),
          height: Math.round(intermediateCeilingFunctions.getHeight(plan)),
          x: Math.round(intermediateCeilingFunctions.getLeft(plan)),
          y: Math.round(intermediateCeilingFunctions.getFront(plan))
        }
    }
}
