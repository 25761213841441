import {intermediateCeilingActions} from "../../actions/intermediateCeilingActions";
import {
  addCircularElement, circularElementResizeBoundFunc,
  moveCircularElement,
  previewAddCircularElement,
  previewMoveCircularElement, previewResizeCircularElement, resizeCircularElement
} from "./circularElementDomainFunctions";
import {createLidAndIntermediateCeilingBlockOutDomainFunctions} from "./lidAndIntermediateCeilingBlockOutDomainFunctions";

export const createIntermediateCeilingDomainFunctions = (dispatch, plan) => {
  return {
    blockOutFunctions: createLidAndIntermediateCeilingBlockOutDomainFunctions(
      dispatch,
      plan,
      intermediateCeilingActions
    ),
    circularBlockOutFunctions : {
      previewAdd: item => previewAddCircularElement(plan.length, plan.width, item),
      add: item => addCircularElement(
        item => dispatch(intermediateCeilingActions.addCircularBlockOut(item)),
        plan.length,
        plan.width,
        item
      ),
      previewMove: item => previewMoveCircularElement(plan.length, plan.width, item),
      move: item => moveCircularElement(
        (id, x, y) => dispatch(intermediateCeilingActions.moveCircularBlockOut(id, x, y)),
        plan.length,
        plan.width,
        item
      ),
      remove: id => dispatch(intermediateCeilingActions.removeCircularBlockOut(id)),
      previewResize: (item) => previewResizeCircularElement(plan.length, plan.width, item),
      resize: item => resizeCircularElement(
        (id, diameter) => dispatch(intermediateCeilingActions.resizeCircularBlockOut(id, diameter)),
        plan.length,
        plan.width,
        item
      ),
      resizeBoundFunc: (blockOut, pos) => circularElementResizeBoundFunc(pos, plan.length, plan.width, blockOut)
    }
  }
}
