export const drainages = [
  {
    colloquialism: "Gussrost",
    name: "300 mm",
    type: '300',
    diameter: 30,
    outerDiameter: 30,
    innerDiameter: 30
  },
  {
    colloquialism: "PP-Muffe",
    name: "100 mm",
    type: "100",
    diameter: 10,
    outerDiameter: 10,
    innerDiameter: 10
  }
]

export const floorElements = [...drainages]
