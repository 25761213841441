import {planApiActionTypes, projectApiActionTypes} from "../constants"
import { armouringDocumentsActionTypes } from "../constants/armouringDocumentsActionTypes"

const initialState = {}

export const apiRequestStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case planApiActionTypes.readPlansRequest:
      return {
        request: 'read_plans',
        state: 'pending'
      }

    case planApiActionTypes.readPlansSuccess:
      return {
        request: 'read_plans',
        state: 'success'
      }

    case planApiActionTypes.readPlansFailure:
      return {
        request: 'read_plans',
        state: 'failed',
        error: action.error
      }

    case planApiActionTypes.readPlanDetailsRequest:
      return {
        request: 'read_plan_details',
        state: 'pending'
      }

    case planApiActionTypes.readPlanDetailsSuccess:
      return {
        request: 'read_plan_details',
        state: 'success'
      }

    case planApiActionTypes.readPlanDetailsFailure:
      return {
        request: 'read_plan_details',
        state: 'failed',
        error: action.error
      }

    case planApiActionTypes.readPlanRequest:
      return {
        request: 'read_plan',
        state: 'pending'
      }

    case planApiActionTypes.readPlanSuccess:
      return {
        request: 'read_plan',
        state: 'success'
      }

    case planApiActionTypes.readPlanFailure:
      return {
        request: 'read_plan',
        state: 'failed',
        error: action.error
      }

    case planApiActionTypes.createPlanRequest:
      return {
        request: 'create_plan',
        state: 'pending'
      }

    case planApiActionTypes.createPlanSuccess:
      return {
        request: 'create_plan',
        state: 'success'
      }

    case planApiActionTypes.createPlanFailure:
      return {
        request: 'create_plan',
        state: 'failed',
        error: action.error
      }

    case planApiActionTypes.deletePlanRequest:
      return {
        request: 'delete_plan',
        state: 'pending'
      }

    case planApiActionTypes.deletePlanSuccess:
      return {
        request: 'delete_plan',
        state: 'success'
      }

    case planApiActionTypes.deletePlanFailure:
      return {
        request: 'delete_plan',
        state: 'failed',
        error: action.error
      }

    case projectApiActionTypes.readProjectsRequest:
      return {
        request: 'read_projects',
        state: 'pending'
      }

    case projectApiActionTypes.readProjectsSuccess:
      return {
        request: 'read_projects',
        state: 'success'
      }

    case projectApiActionTypes.readProjectsFailure:
      return {
        request: 'read_projects',
        state: 'failed',
        error: action.error
      }

    case projectApiActionTypes.readProjectDetailsRequest:
      return {
        request: 'read_project_details',
        state: 'pending'
      }

    case projectApiActionTypes.readProjectDetailsSuccess:
      return {
        request: 'read_project_details',
        state: 'success'
      }

    case projectApiActionTypes.readProjectDetailsFailure:
      return {
        request: 'read_project_details',
        state: 'failed',
        error: action.error
      }

    case projectApiActionTypes.createRequest:
      return {
        request: 'create_project',
        state: 'pending'
      }

    case projectApiActionTypes.createSuccess:
      return {
        request: 'create_project',
        state: 'success'
      }

    case projectApiActionTypes.createFailure:
      return {
        request: 'create_project',
        state: 'failed',
        error: action.error
      }

    case projectApiActionTypes.setStateRequest:
      return {
        request: 'set_project_state',
        state: 'pending'
      }

    case projectApiActionTypes.setStateSuccess:
      return {
        request: 'set_project_state',
        state: 'success'
      }

    case projectApiActionTypes.setStateFailure:
      return {
        request: 'set_project_state',
        state: 'failed',
        error: action.error
      }

    case planApiActionTypes.setOwnerRequest:
      return {
        request: 'set_plan_owner',
        state: 'pending'
      }

    case planApiActionTypes.setOwnerSuccess:
      return {
        request: 'set_plan_owner',
        state: 'success'
      }

    case planApiActionTypes.setOwnerFailure:
      return {
        request: 'set_plan_owner',
        state: 'failed',
        error: action.error
      }

    case armouringDocumentsActionTypes.createRequest:
      return {
        request: 'create_armouring_documents',
        state: 'pending',
        planId: action.planId
      }

    case armouringDocumentsActionTypes.createSuccess:
      return {
        request: 'create_armouring_documents',
        state: 'success',
        planId: action.planId
      }

    case armouringDocumentsActionTypes.createFailure:
      return {
        request: 'create_armouring_documents',
        state: 'failed',
        planId: action.planId,
        error: action.error
      }

    default:
      return state

  }
}
