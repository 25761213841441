export const getFloorSize = plan => {
  if (!plan.floor) {
    return {width: 0, length: 0, height: 0, x: 0, y: 0}
  }
  return {
    width: plan.width + 2 * plan.wallWidth,
    length: plan.length + 2 * plan.wallWidth,
    height: plan.floor.thickness,
    x: -plan.wallWidth,
    y: -plan.wallWidth
  }
}

export const getBevelWidth = () => 2.5
export const getBevelHeight = () => 3
