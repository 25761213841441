import React from "react"

import {Button, Grid, Typography} from "@mui/material"
import {useHistory} from "react-router-dom";
import {IntermediateCeilingGroundView} from "../../Plan/Verification/IntermediateCeilingGroundView";

const TitleGridItem = () => {
  const history = useHistory()

  return (
    <Grid item xs={12}>
      <Grid container justifyContent="space-between" alignItems="flex-end">
        <Grid item>
          <Typography component="h1" variant="h4">
            {"Zwischendecke"}
          </Typography>
        </Grid>
        <Grid item>
          <Button color="primary" onClick={() => history.push("/step/3")}>
            bearbeiten
          </Button>
        </Grid>
      </Grid>
      < hr/>
    </Grid>
  )
}

const PlanGridItem = () => {
  return (
    <Grid item xs={12}>
      <IntermediateCeilingGroundView/>
    </Grid>
  )
}

export const IntermediateCeilingGroundViewSection = () => {
  return (
    <Grid container spacing={4}>
      <TitleGridItem/>
      <PlanGridItem/>
    </Grid>
  )
}

