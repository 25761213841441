import React from "react";

export const AnthraciteIcon = () => {
  return (
    <svg version="1.1" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg" style={{backgroundColor: "#526266"}}/>
  )
}

export const ConcreteGrayIcon = () => {
  return (
    <svg version="1.1" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg" style={{backgroundColor: "#C4C4C4"}}/>
  )
}
