import {planImageActionTypes as actionTypes} from "../constants"

const initialState = { }

export const planImageReducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.setLidGroundViewImage:
      return {...state, lidGroundView: action.data}

    case actionTypes.setIntermediateCeilingGroundViewImage:
      return {...state, intermediateCeilingGroundView: action.data}

    case actionTypes.setFloorGroundViewImage:
      return {...state, floorGroundView: action.data}

    case actionTypes.setSideAElevationViewImage:
      return {...state, sideAElevationView: action.data}

    case actionTypes.setSideBElevationViewImage:
      return {...state, sideBElevationView: action.data}

    case actionTypes.setSideCElevationViewImage:
      return {...state, sideCElevationView: action.data}

    case actionTypes.setSideDElevationViewImage:
      return {...state, sideDElevationView: action.data}

    default:
      return state
  }
}
