import {planActionTypes as actionTypes} from '../constants'
import uuid from 'react-uuid'

const initialState = {
  type: 'B125',
  collar: 'beveled',
  fill: 'anthracite',
  manholes: [],
  blockOuts: [],
  circularBlockOuts: []
}

const manholesReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.addManhole:
      return [...state, {id: uuid(), ...action.data}] // todo_mga: dont generate uuid in reducer

    case actionTypes.removeManhole:
      return state.filter(manhole => manhole.id !== action.data)

    case actionTypes.moveManhole:
      return state.map(manhole => {
        if (action.data.id !== manhole.id) {
          return manhole
        }
        return {...manhole, x: action.data.x, y: action.data.y}
      })

    case actionTypes.resizeManhole:
      return state.map(manhole => {
        if (action.data.id !== manhole.id) {
          return manhole
        }
        return {...manhole, width: action.data.width, height: action.data.height}
      })

    default:
      return state
  }
}

const blockOutsReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.addLidBlockOut:
      return [...state, {id: uuid(), ...action.data}] // todo_mga: dont generate uuid in reducer

    case actionTypes.removeLidBlockOut:
      return state.filter(blockOut => blockOut.id !== action.data)

    case actionTypes.moveLidBlockOut:
      return state.map(blockOut => {
        if (action.data.id !== blockOut.id) {
          return blockOut
        }
        return {...blockOut, x: action.data.x, y: action.data.y}
      })

    case actionTypes.resizeLidBlockOut:
      return state.map(blockOut => {
        if (action.data.id !== blockOut.id) {
          return blockOut
        }
        return {...blockOut, width: action.data.width, height: action.data.height}
      })

    default:
      return state
  }
}

const circularBlockOutsReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.addCircularLidBlockOut:
      return [...state, {id: uuid(), ...action.data}] // todo_mga: dont generate uuid in reducer

    case actionTypes.removeCircularLidBlockOut:
      return state.filter(blockOut => blockOut.id !== action.data)

    case actionTypes.moveCircularLidBlockOut:
      return state.map(blockOut => {
        if (action.data.id !== blockOut.id) {
          return blockOut
        }
        return {...blockOut, x: action.data.x, y: action.data.y}
      })

    case actionTypes.resizeCircularLidBlockOut:
      return state.map(blockOut => {
        if (action.data.id !== blockOut.id) {
          return blockOut
        }
        return {...blockOut, diameter: action.data.diameter, outerDiameter: action.data.diameter}
      })

    default:
      return state
  }
}

export const lidReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.setLid:
      return state || initialState

    case actionTypes.unsetLid:
      return undefined

    case actionTypes.setLidType:
      if (state && state.type !== action.data) {
        if (action.data === 'subfloor') {
          return {type: action.data, manholes: [], blockOuts: [], circularBlockOuts: []}
        }
        if (action.data === 'A15') {
          return {type: action.data, collar: 'beveled', fill: 'concretegray', manholes: [], blockOuts: [], circularBlockOuts: []}
        }

        return {type: action.data, collar: 'beveled', fill: 'anthracite', manholes: [], blockOuts: [], circularBlockOuts: []}
      }
      return state

    case actionTypes.setLidCollar:
      if (state && state.collar !== action.data) {
        return {
          ...state,
          collar: action.data,
          fill: action.data === 'pavingready'
            ? state.type === 'B125'
              ? 'partialfill'
              : 'without'
            : action.data === 'lifted' || state.type === 'A15'
              ? 'concretegray'
              : 'anthracite',
          blockOuts: [],
          circularBlockOuts: []
        }
      }
      return state

    case actionTypes.setLidFill:
      if (state) {
        return {...state, fill: action.data}
      }
      return state

    case actionTypes.setIntermediateCeiling:
      return !!state ? {...state, blockOuts: []} : state

    default:
  }
  if (!state) return state
  const newManholes = manholesReducer(state.manholes, action)
  const newBlockOuts = blockOutsReducer(state.blockOuts, action)
  const newCircularBlockOuts = circularBlockOutsReducer(state.circularBlockOuts, action)

  if (
    state.manholes === newManholes
    && state.blockOuts === newBlockOuts
    && state.circularBlockOuts === newCircularBlockOuts
  ) {
    return state
  }
  return {...state, manholes: newManholes, blockOuts: newBlockOuts, circularBlockOuts: newCircularBlockOuts}
}
