import {planActionTypes as actionTypes} from '../constants';
import uuid from 'react-uuid';

const initialState = {
  blockOuts: [],
  circularBlockOuts: []
}

const blockOutsReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.addIntermediateCeilingBlockOut:
      return [...state, {id: uuid(), ...action.data}]

    case actionTypes.removeIntermediateCeilingBlockOut:
      return state.filter(blockOut => blockOut.id !== action.data)

    case actionTypes.moveIntermediateCeilingBlockOut:
      return state.map(blockOut => {
        if (action.data.id !== blockOut.id) {
          return blockOut
        }
        return {...blockOut, x: action.data.x, y: action.data.y}
      })

    case actionTypes.resizeIntermediateCeilingBlockOut:
      return state.map(blockOut => {
        if (action.data.id !== blockOut.id) {
          return blockOut
        }
        return {...blockOut, width: action.data.width, height: action.data.height}
      })

    default:
      return state
  }
}

const circularBlockOutsReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.addCircularIntermediateCeilingBlockOut:
      return [...state, {id: uuid(), ...action.data}] // todo_mga: dont generate uuid in reducer

    case actionTypes.removeCircularIntermediateCeilingBlockOut:
      return state.filter(blockOut => blockOut.id !== action.data)

    case actionTypes.moveCircularIntermediateCeilingBlockOut:
      return state.map(blockOut => {
        if (action.data.id !== blockOut.id) {
          return blockOut
        }
        return {...blockOut, x: action.data.x, y: action.data.y}
      })

    case actionTypes.resizeCircularIntermediateCeilingBlockOut:
      return state.map(blockOut => {
        if (action.data.id !== blockOut.id) {
          return blockOut
        }
        return {...blockOut, diameter: action.data.diameter, outerDiameter: action.data.diameter}
      })

    default:
      return state
  }
}

export const intermediateCeilingReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.setIntermediateCeiling:
      return state || initialState

    case actionTypes.unsetIntermediateCeiling:
      return undefined;

    case actionTypes.setLid:
      return !!state ? initialState : state

    default:
  }
  if (!state) return state
  const newBlockOuts = blockOutsReducer(state.blockOuts, action)
  const newCircularBlockOuts = circularBlockOutsReducer(state.circularBlockOuts, action)

  if (state.blockOuts === newBlockOuts && state.circularBlockOuts === newCircularBlockOuts) {
    return state
  }
  return {...state, blockOuts: newBlockOuts, circularBlockOuts: newCircularBlockOuts}
}
