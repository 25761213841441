import React from "react"
import {useDispatch, useSelector} from "react-redux"

import {Layer, Stage} from "react-konva"

import {createPart} from "../GroundPlan/part"
import {
  createHorizontalMeasurements,
  createVerticalMeasurements,
} from "../../measurement"
import {
  getPartialXMeasurements,
  getPartialYMeasurements
} from "../../configurationPlanMeasurement"
import {createVerticallyCenteredGrid} from "../grid"
import {AutoResizeDiv} from "../../AutoResizeDiv"
import {renderToolbar} from "./intermediateCeilingPlanToolbar"
import {renderGroupElements as renderBlockOutGroupElements} from "../../Element/blockOutRenderFactory"
import {renderGroupElements as renderCircularBlockOutGroupElements} from "../../Element/circularBlockOutRenderFactory";
import {createRectangularElementCrossHairLayer} from "../../Element/rectangularElementCrossHairs"
import {renderRectangularPlanElement} from "../../Element/rectangularElementFunctions";
import {createIntermediateCeilingDomainFunctions} from "../../../domain/intermediateCeilingDomainFunctions";
import {createCircularElementCrossHairLayer} from "../../Element/circularElementCrossHairs";
import {renderCircularPlanElement} from "../../Element/circularElementFunctions";
import {createGroundPlanLayout} from "../planLayout";

const getHorizontalMeasurementStops = (layout, circularBlockOuts, blockOuts) => {
  const wallWidth = (layout.part.realWidth - layout.configArea.realWidth) / 2
  const outerStops = [wallWidth, layout.configArea.realWidth, wallWidth]
  const innerStops = getPartialXMeasurements(circularBlockOuts, [], blockOuts, layout.configArea.realWidth)
  const innerOffset = wallWidth + (blockOuts.some(b => b.x < 0) ? Math.min(...blockOuts.map(b => b.x)) : 0)

  return innerStops.length > 1
    ? {stops: [innerStops, outerStops], offsets: [innerOffset, 0]}
    : {stops: [outerStops], offsets: [0]}
}

const getVerticalMeasurementStops = (layout, circularBlockOuts, blockOuts) => {
  const wallWidth = (layout.part.realHeight - layout.configArea.realHeight) / 2
  const outerStops = [wallWidth, layout.configArea.realHeight, wallWidth]
  const innerStops = getPartialYMeasurements(circularBlockOuts, [], blockOuts, layout.configArea.realHeight)
  const getClearMeasureTop = () => layout.configArea.realHeight
  const getMaxBlockOutTop = () => blockOuts.length > 0 ? Math.max(...blockOuts.map(b => b.y + b.height)) : 0
  const getInnerOffset = () => wallWidth
    - (
      getMaxBlockOutTop() > getClearMeasureTop()
        ? getMaxBlockOutTop() - getClearMeasureTop()
        : 0
    )

  return innerStops.length > 1
    ? {stops: [outerStops, innerStops], offsets: [0, getInnerOffset()]}
    : {stops: [outerStops], offsets: [0]}
}

const renderMeasurements = (layout, circularBlockOuts, blockOuts) => {
  const horizontalMeasurementStops = getHorizontalMeasurementStops(layout, circularBlockOuts, blockOuts)
  const verticalMeasurementStops = getVerticalMeasurementStops(layout, circularBlockOuts, blockOuts)

  return (
    <React.Fragment>
      {
        createHorizontalMeasurements(
          layout.horizontalMeasurements,
          horizontalMeasurementStops.stops,
          horizontalMeasurementStops.offsets
        )
      }
      {
        createVerticalMeasurements(
          layout.verticalMeasurements,
          verticalMeasurementStops.stops,
          verticalMeasurementStops.offsets
        )
      }
    </React.Fragment>
  )
}

const Plan = props => {
  const {clientSize} = props
  const dispatch = useDispatch()
  const plan = useSelector(state => state.plan)
  const layout = createGroundPlanLayout(clientSize, plan)

  return (
    <div>
      <React.Fragment/>
      {!isNaN(layout.scale) &&
      <Stage
        width={clientSize.width}
        height={layout.overall.screenHeight}
      >
        <Layer>
          {createPart(layout.part, layout.configArea)}
          {createVerticallyCenteredGrid(layout.configArea, layout.configAreaRealWorldTransform)}
          {renderMeasurements(layout, plan.intermediateCeiling.circularBlockOuts, plan.intermediateCeiling.blockOuts)}
          {
            plan.intermediateCeiling.blockOuts.map(blockOut => renderRectangularPlanElement(
              createIntermediateCeilingDomainFunctions(dispatch, plan).blockOutFunctions,
              layout.configArea,
              blockOut,
              (screenRect, blockOut) => renderBlockOutGroupElements(
                screenRect,
                blockOut
              )
            ))
          }
          {
            plan.intermediateCeiling.circularBlockOuts.map(blockOut => renderCircularPlanElement(
              createIntermediateCeilingDomainFunctions(dispatch, plan).circularBlockOutFunctions,
              layout.configArea,
              blockOut,
              (screenCircle, blockOut) => renderCircularBlockOutGroupElements(
                screenCircle,
                blockOut
              )
            ))
          }
          {
            renderToolbar(
              createIntermediateCeilingDomainFunctions(dispatch, plan),
              layout.toolbar,
              layout.configArea
            )
          }
        </Layer>
        {createRectangularElementCrossHairLayer()}
        {createCircularElementCrossHairLayer()}
      </Stage>
      }
    </div>
  )
}

export const IntermediateCeilingPlan = props => {
  return (
    <AutoResizeDiv>
      <Plan {...props}/>
    </AutoResizeDiv>
  )
}
