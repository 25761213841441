export const deliveryActionTypes = {
  setDescription: 'SET_PROJECT_DESCRIPTION',
  setDeliveryType: 'SET_DELIVERY_TYPE',
  setDesiredSchedule: 'SET_DESIRED_SCHEDULE',
  setStreet: 'SET_DELIVERY_STREET',
  setZip: 'SET_DELIVERY_ZIP',
  setCity: 'SET_DELIVERY_CITY',
  setCoordinates: 'SET_DELIVERY_COORDINATES',
  unsetCoordinates: 'UNSET_DELIVERY_COORDINATES',
  setNumber: 'SET_DELIVERY_NUMBER',
  setPerson: 'SET_DELIVERY_PERSON',
  setPhone: 'SET_DELIVERY_PHONE',
  setCommission: 'SET_COMMISSION',
  setUsersOrderNumber: 'SET_USERS_ORDER_NUMBER',
  setRemarks: 'SET_REMARKS'
}
