import React from "react"

import {Group} from "react-konva"

import {FONT_SIZE} from "../../../../constants"
import {idleDragPreviewOpacity} from "./toolbarConstants";
import {toRealLine, toScreenLine} from "../../realWorldTransform"
import {setOpacity} from "../../Element/elementFunctions"
import {hideCrossHairs, updateCrossHairs} from "../../Element/linearElementCrossHairs"
import {renderDragStarterRect, renderToolbarIcon, renderToolbarItemLabel} from "./toolbarFunctions";

export const renderLinearElementToolbarDragPreview = (
  toolbarItemRect,
  screenLine,
  element,
  configAreaLayout,
  onDragMove,
  onDragEnd,
  renderGroupElementsFunction
) => {
  const handleDragStart = e => {
    setOpacity(e.target, "dragging")
  }
  const handleDragMove = e => {
    const group = e.target
    const line = {...screenLine, x: group.x(), y: group.y()}
    const dragMoveResult = onDragMove(line)
    const stage = group.getStage()

    updateCrossHairs(configAreaLayout, stage, dragMoveResult.screenLine, dragMoveResult.element)
  }
  const handleDragEnd = e => {
    const group = e.target
    const line = {...screenLine, x: group.x(), y: group.y()}

    onDragEnd(line)
    hideCrossHairs(group.getStage())
    setOpacity(group)
    e.target.to({
      opacity: idleDragPreviewOpacity,
      duration: 0,
      x: e.target.attrs.origin.x,
      y: e.target.attrs.origin.y,
    })
  }

  return (
    <Group
      x={screenLine.x}
      y={screenLine.y}
      draggable
      opacity={idleDragPreviewOpacity}
      onDragStart={handleDragStart}
      onDragMove={handleDragMove}
      onDragEnd={handleDragEnd}
      origin={{x: screenLine.x, y: screenLine.y}}
    >
      <Group listening={false}>
        {renderGroupElementsFunction(screenLine, element)}
      </Group>
      {
        renderDragStarterRect({
          x: toolbarItemRect.x,
          y: toolbarItemRect.y,
          width: toolbarItemRect.width,
          height: toolbarItemRect.height - FONT_SIZE
        })
      }
    </Group>
  )
}

export const renderLinearElementToolbarItem = (
  key,
  element,
  x,
  y,
  width,
  height,
  iconScale,
  configAreaLayout,
  domainFunctions,
  renderGroupElementsFunction
) => {
  const iconLength = element.length * iconScale
  const toolbarItemRect =
    {
      x: (
        element.orientation === 'horizontal'
          ? 0
          : -width / 2
      ),
      y: -2 * FONT_SIZE - 10 - (
        element.orientation === 'horizontal'
          ? iconLength / 2
          : iconLength
      ),
      width,
      height
    }
  const iconScreenLine = {
    x: x + (
      element.orientation === 'horizontal'
        ? (width - iconLength) / 2
        : element.orientation === 'vertical'
        ? width / 2
        : 0
    ),
    y: y + 2 * FONT_SIZE + 10 + (
      element.orientation === 'horizontal'
        ? iconLength / 2
        : element.orientation === 'vertical'
        ? iconLength
        : 0
    ),
    length: iconLength,
    orientation: element.orientation
  }
  const dragPreviewLength = element.length * configAreaLayout.scale
  const dragPreviewScreenLine = {...iconScreenLine, length: dragPreviewLength}
  const configAreaScreenZero = {x: configAreaLayout.screenX, y: configAreaLayout.screenY}
  const onDragMove = screenLine => {
    const realLine = {
      ...toRealLine(screenLine, configAreaLayout.transform, configAreaScreenZero)
    }
    const addElement = domainFunctions.previewAdd({...element, x: realLine.x, y: realLine.y})
    const newScreenLine = toScreenLine(addElement, configAreaLayout.transform, configAreaScreenZero)

    return {
      screenLine: newScreenLine,
      element: addElement
    }
  }
  const onDragEnd = screenLine => {
    const realLine = {
      ...toRealLine(screenLine, configAreaLayout.transform, configAreaScreenZero)
    }
    const addElement = domainFunctions.add({...element, x: realLine.x, y: realLine.y})
    const newScreenLine = toScreenLine(addElement, configAreaLayout.transform, configAreaScreenZero)

    return {
      screenLine: newScreenLine,
      element: addElement
    }
  }

  return (
    <React.Fragment key={key}>
      {renderToolbarItemLabel(x, y, width, element.colloquialism, element.name)}
      {renderToolbarIcon(iconScreenLine, element, renderGroupElementsFunction)}
      {renderLinearElementToolbarDragPreview(
        toolbarItemRect,
        dragPreviewScreenLine,
        element,
        configAreaLayout,
        onDragMove,
        onDragEnd,
        renderGroupElementsFunction
      )}
    </React.Fragment>
  )
}
