import {authHeader} from "../helpers/authHeader"
import {handleResponse} from "./httpHelper"
import {apiUrls} from "../constants"

const apiUrl = apiUrls.projectsEndpoint
const billingAddressApiUrl = apiUrls.billingAddressEndpoint
const deliveryAddressApiUrl = apiUrls.deliveryAddressEndpoint
const statusApiUrl = apiUrls.projectStatusEndpoint

const createProject = project => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    },
    body: JSON.stringify(project)
  }

  return fetch(apiUrl, requestOptions).then(handleResponse)
}

const readProjects = (limit = 15, searchTerm = "") => {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader()
    }
  }
  const url =
    `${apiUrl}?limit=${limit}` + (searchTerm ? `&searchTerm=${searchTerm}` : "")

  return fetch(url, requestOptions).then(handleResponse)
}

const readProjectDetails = async (id) => {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader()
    }
  }
  const url =
    `${apiUrls.detailsEndpoint}?projectId=${id}`

  const response = await fetch(url, requestOptions)
  return handleResponse(response)
}

const changeStatus = (id, status, mmOfferNumber, mmOrderNumber) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    },
    body: JSON.stringify({
      requestStatus: status,
      ourOfferNumber: mmOfferNumber,
      ourOrderNumber: mmOrderNumber
    })
  }
  const url = `${statusApiUrl}/${id}`

  return fetch(url, requestOptions).then(handleResponse)
}

const readConstructionSites = (limit = 5, searchTerm = "") => {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader()
    }
  }
  const url = `${deliveryAddressApiUrl}?limit=${limit}` + (searchTerm ? `&searchTerm=${searchTerm}` : "")

  return fetch(url, requestOptions).then(handleResponse)
}

const readBillingAddresses = (limit = 5, searchTerm = "") => {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader()
    }
  }
  const url = `${billingAddressApiUrl}?limit=${limit}` + (searchTerm ? `&searchTerm=${searchTerm}` : "")

  return fetch(url, requestOptions).then(handleResponse)
}

export const projectService = {
  createProject,
  readProjects,
  readProjectDetails,
  changeStatus,
  readConstructionSites,
  readBillingAddresses
}
