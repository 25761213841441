import React, {useEffect, useState} from "react"

import { CircularProgress, Container, Grid, Paper, Typography, useTheme } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import {Header} from "../Header"
import {grayBackgroundColor, orangeButtonStyle} from "../../constants"
import {useDispatch, useSelector} from "react-redux"
import {projectApiActions} from "../../actions"
import Button from "@mui/material/Button";
import {AlertDialog} from "../AlertDialog";
import {ProjectListItem} from "./ProjectListItem";
import {Search} from "../Search";

const useStyles = theme => {
  return makeStyles({
    plan: {
      background: grayBackgroundColor,
      padding: theme.spacing(6)
    },
    orangeButton: orangeButtonStyle
  })()
}

const TitleGridItem = props => {
  const {searchTerm, onSearchTermChange, onSearchTermClear, onSearchEnter} = props
  const theme = useTheme()

  return (
    <Grid item xs={12}>
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-end"
            style={{marginBottom: theme.spacing(1)}}>
        <Typography component="h1" variant="h4">
          Bestellungen und Offertanfragen
        </Typography>
        <Search
          searchTerm={searchTerm}
          onSearchTermChange={onSearchTermChange}
          onSearchTermClear={onSearchTermClear}
          onSearchEnter={onSearchEnter}
        />
      </Grid>
      < hr/>
    </Grid>
  )
}

export const Projects = () => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const dispatch = useDispatch()
  const projects = useSelector(state => state.projects)
  const apiRequestState = useSelector(state => state.apiRequestState)
  const [filter, setFilter] = useState('')
  const [resultCount, setResultCount] = useState(15)
  const [readErrorAlertOpen, setReadErrorAlertOpen] = useState(true)

  useEffect(
    () => dispatch(projectApiActions.readProjects(resultCount, filter)),
    [dispatch, resultCount, filter]
  )

  const hasReadPlanError = () =>
    !!apiRequestState
    && apiRequestState.request === 'read_plan'
    && apiRequestState.state === 'failed'
  const hasCreateArmouringDocsError = () =>
    !!apiRequestState &&
    apiRequestState.request === "create_armouring_documents" &&
    apiRequestState.state === "failed"


  return (
    <Container maxWidth="lg" style={{paddingBottom: theme.spacing(4), minWidth: '1200px'}}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Header/>
        </Grid>
        <Grid item xs={12}>
          <Paper square={true} className={classes.plan}>
            {
              projects.fetching &&
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <CircularProgress style={{justifySelf: "center"}}/>
                </Grid>
              </Grid>
            }
            {
              hasReadPlanError() &&
              <AlertDialog
                open={readErrorAlertOpen}
                setOpen={setReadErrorAlertOpen}
                title="Als Vorlage öffnen ist fehlgeschlagen"
                onConfirm={() => {
                  setReadErrorAlertOpen(true)
                  dispatch(projectApiActions.readProjects(resultCount, filter))
                }}
              >
                <Typography component="p" variant="body2">
                  Der Plan konnte leider nicht als Vorlage geöffnet werden. Bitte versuchen Sie es in Kürze nochmals
                  oder nehmen mit uns Kontakt auf.
                </Typography>
              </AlertDialog>
            }

            {hasCreateArmouringDocsError() && (
              <AlertDialog
                open={readErrorAlertOpen}
                setOpen={setReadErrorAlertOpen}
                title="Armierung generieren ist fehlgeschlagen"
                onConfirm={() => {
                  setReadErrorAlertOpen(true)
                  dispatch(projectApiActions.readProjects(resultCount, filter))
                }}
              >
                <Typography component="p" variant="body2">
                  Die Armierungs-Dokumente konnten nicht generiert werden. Bitte
                  versuchen Sie es in Kürze nochmals oder nehmen mit uns Kontakt
                  auf.
                </Typography>
              </AlertDialog>
            )}
            {
              projects.error &&
              <Typography
                component="p"
                variant="body2"
                color="error"
                style={{
                  marginTop: theme.spacing(2)
                }}
              >
                {projects.error.toString()}
              </Typography>
            }
            {
              Array.isArray(projects) &&
              <Grid container spacing={4}>
                <TitleGridItem
                  searchTerm={filter}
                  onSearchTermChange={searchTerm => setFilter(searchTerm)}
                  onSearchTermClear={() => {
                    setFilter('')
                    setResultCount(15)
                    dispatch(projectApiActions.readProjects(15, ''))
                  }}
                  onSearchEnter={searchTerm => {
                    setFilter(searchTerm)
                    setResultCount(15)
                  }}
                />
                {
                  projects.map(project => {
                    return (<ProjectListItem project={project} key={"plan_item_" + project.id}/>)
                  })}
              </Grid>
            }

            <Grid container justifyContent={'center'}>
              <Grid item xs={2}/>
              <Grid item xs={8}>
                <Grid container justifyContent={'center'}>
                  {
                    Array.isArray(projects) && projects.length >= resultCount &&
                    <Button
                      className={classes.orangeButton}
                      style={{marginTop: theme.spacing(6)}}
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        setResultCount(resultCount + 15)
                      }}
                    >
                      Mehr anzeigen
                    </Button>
                  }
                </Grid>
              </Grid>
              <Grid item xs={2}>
                <Grid container justifyContent={'flex-end'}>
                  <Button
                    style={{marginTop: theme.spacing(6)}}
                    color="primary"
                    onClick={() => {
                      window.scrollTo(0, 0)
                    }}
                  >
                    Nach oben
                  </Button>
                </Grid>
              </Grid>
            </Grid>

          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}
