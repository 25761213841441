import React from "react"
import { Grid, Typography, Button, createTheme, Divider } from "@mui/material"
import { useHistory, Link } from "react-router-dom"
import { concreteGray, primary_orange } from "../../constants"
import { mapUrl } from "../../helpers/mapsHelper"
import { ActionButtons } from "./ActionButtons"
import { lidDescription } from "./lidDescription"
import { PlanComponentIcons } from "./PlanComponentIcons"

const toConstructionSiteString = (street, zip, city) => {
  let result = street

  if (result.length > 0 && (zip || city)) {
    result += ", "
  }
  if (zip) {
    result += `${zip} `
  }
  if (city) {
    result += city
  }
  return result
}

const toDateTimeString = date => {
  return new Date(date).toLocaleString()
}

const theme = createTheme()

export const PlanListItem = props => {
  const { plan } = props
  const history = useHistory()

  return (
    <Grid item xs={12}>
      <Grid container direction="column" spacing={2}>
        <Grid item xs={12}>
          <Grid container direction="row" spacing={3} alignItems="stretch">
            <Grid item display="flex">
              <Link to={"/plans/" + plan.id} style={{ textDecoration: "none" }}>
                <Typography color={"primary"} component="p" variant="body1">
                  <b>{plan.id}</b>
                </Typography>
              </Link>
            </Grid>
            <Grid item mt="3px" display="flex">
              <PlanComponentIcons plan={plan}></PlanComponentIcons>
            </Grid>
            <Grid item display="flex" flex={1}>
              <Grid container direction="column">
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Grid item>
                      <Typography component="p" variant="body1">
                        <b>
                          {`${plan.length} x ${plan.width} cm${
                            !!plan.hasLid ? ", " + lidDescription(plan) : ""
                          }`}
                        </b>
                      </Typography>

                      <Typography component="p" variant="body2">
                        {`${plan.customerCompany}, ${plan.customerCity}`}
                      </Typography>
                      <Typography component="p" variant="body2">
                        {`${plan.creator}, ${toDateTimeString(plan.created)}`}
                      </Typography>

                    </Grid>

                    <Grid item>
                      <Grid container direction="row">
                        <Grid item>
                          <ActionButtons plan={plan}></ActionButtons>
                        </Grid>
                        <Grid item ml={theme.spacing(2)}>
                          <Button
                            variant="outlined"
                            color="primary"
                            style={{ color: "black", borderColor: "black" }}
                            onClick={() => {
                              history.push("/plans/" + plan.id)
                            }}
                          >
                            Plan anzeigen
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item mt={theme.spacing(2)}>
                  <Typography component="p" variant="body2">
                    {plan.description}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Divider></Divider>
        </Grid>
      </Grid>
    </Grid>
  )
}
