import {projectApiActionTypes as actionTypes} from "../constants"

const initialState = []

export const projectDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.resetProjects:
      return initialState

    case actionTypes.readProjectDetailsRequest:
      return {fetching: true}

    case actionTypes.readProjectDetailsFailure:
      return {error: action.error}

    case actionTypes.readProjectDetailsSuccess:
        return action.data

    default:
      return state
  }
}
