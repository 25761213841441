import React from "react"

import {Circle} from "react-konva"
import {primary_orange} from "../../../constants";

const strokeWidth = 1
const mouseOverStrokeWidth = 2

export const getHandles = handle => {
  const parent = handle.getParent()

  return {
    top: parent.find('.top')[0],
    left: parent.find('.left')[0],
    bottom: parent.find('.bottom')[0],
    right: parent.find('.right')[0]
  }
}

export const handleMouseOver = e => {
  const handle = e.target
  const layer = handle.getLayer()

  document.body.style.cursor = 'pointer'
  handle.strokeWidth(mouseOverStrokeWidth)
  if (layer) layer.draw()
}

export const handleMouseOut = e => {
  const handle = e.target
  const layer = handle.getLayer()

  document.body.style.cursor = 'default'
  handle.strokeWidth(strokeWidth)

  if (layer) layer.draw()
}

export const handleMouseDown = e => {
  const handle = e.target
  const group = handle.parent

  group.draggable(false)
  handle.moveToTop()
}

export const handleDragStart = e => {
  e.cancelBubble = true
}

const handleDragMove = (e, resizingCallback, handlesToElement) => {
  e.cancelBubble = true
  const handle = e.target
  const handles = getHandles(handle)

  switch (handle.getName(0)) {
    case 'top':
    case 'bottom':
      if (handles.left && handles.right) {
        const y = handles.top.y() + (handles.bottom.y() - handles.top.y()) / 2

        handles.left.y(y)
        handles.right.y(y)
      }
      break

    case 'left':
    case 'right':
      if (handles.top && handles.bottom){
        const x = handles.left.x() + (handles.right.x() - handles.left.x()) / 2

        handles.top.x(x)
        handles.bottom.x(x)
      }
      break

    default:
      break
  }

  resizingCallback(handle, handlesToElement(handles))
}

export const handleDragEnd = (e, resizedCallback, handlesToElementFunction) => {
  e.cancelBubble = true
  const handle = e.target
  const handles = getHandles(handle)
  const resizedElement = handlesToElementFunction(handles)
  const resizedRect = resizedCallback(handle, resizedElement)
  const group = handle.parent

  group.draggable(true)
  if (resizedRect) {
    handles.top.x(resizedRect.width / 2)
    handles.top.y(0)
    handles.left.x(0)
    handles.left.y(resizedRect.height / 2)
    handles.right.x(resizedRect.width)
    handles.right.y(resizedRect.height / 2)
    handles.bottom.x(resizedRect.width / 2)
    handles.bottom.y(resizedRect.height)
  }
}

export const renderResizeHandle = (
  x,
  y,
  name,
  handlesToElement,
  dragBoundFunc,
  resizingCallback,
  resizedCallback,
  visible = true
) => {
  return (
    <Circle
      x={x}
      y={y}
      name={name}
      radius={10}
      fill={primary_orange}
      stroke="darkgray"
      strokeWidth={1}
      draggable={visible}
      opacity={visible ? 1 : 0}
      dragontop={false}
      onMouseOver={visible ? handleMouseOver : () => {}}
      onMouseOut={visible ? handleMouseOut : () => {}}
      onMouseDown={visible ? handleMouseDown : () => {}}
      onTouchStart={visible ? handleMouseDown : () => {}}
      onDragStart={visible ? handleDragStart : () => {}}
      onDragMove={e => handleDragMove(e, resizingCallback, handlesToElement)}
      onDragEnd={e => handleDragEnd(e, resizedCallback, handlesToElement)}
      dragBoundFunc={dragBoundFunc}
    />
  )
}
