import {constructionSiteApiActionTypes} from "../constants"
import {projectService} from "../services"

export const resetConstructionSites = () => {
  return {
    type: constructionSiteApiActionTypes.resetConstructionSites
  }
}

const readConstructionSites = (limit = 5, searchTerm = '') => {
  const request = () => {
    return {type: constructionSiteApiActionTypes.readConstructionSitesRequest}
  }
  const success = result => {
    return {type: constructionSiteApiActionTypes.readConstructionSitesSuccess, data: result}
  }
  const failure = error => {
    return {type: constructionSiteApiActionTypes.readConstructionSitesFailure, error}
  }

  return dispatch => {
    dispatch(request())
    projectService.readConstructionSites(limit, searchTerm)
      .then(
        result => dispatch(success(result)),
        error => dispatch(failure(error))
      )
  }
}

export const constructionSiteApiActions = {
  resetConstructionSites,
  readConstructionSites
}
