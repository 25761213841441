import React from "react"

import {Button, Grid, Typography} from "@mui/material"

import {CompositionElevationView} from "../../Plan/Verification/CompositionElevationView"
import {useHistory} from "react-router-dom";
import {StepIcon} from "../StepIcon";

const TitleGridItem = props => {
  const history = useHistory()
  const {wallIndex} = props
  let viewDescription
  let step

  switch (wallIndex) {
    case 0:
      viewDescription = "A"
      step = "2a"
      break
    case 1:
      viewDescription = "B"
      step = "2b"
      break
    case 2:
      viewDescription = "C"
      step = "2c"
      break
    case 3:
      viewDescription = "D"
      step = "2d"
      break
    default:
      viewDescription = ""
      step = ""
  }

  return (
    <Grid item xs={12}>
      <Grid container justifyContent="space-between" alignItems="flex-end">
        <Grid item>
          <Grid container spacing={3} alignItems="flex-end">
            <Grid item>
              <StepIcon scale={5} step={wallIndex + 1}/>
            </Grid>
            <Grid item>
              <Typography component="h1" variant="h4">
                {"Ansicht " + viewDescription}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Button color="primary" onClick={() => history.push("/step/" + step)}>
            bearbeiten
          </Button>
        </Grid>
      </Grid>
      < hr/>
    </Grid>
  )
}

const PlanGridItem = props => {
  const {wallIndex} = props

  return (
    <Grid item xs={12}>
      <CompositionElevationView wallIndex={wallIndex}/>
    </Grid>
  )
}

export const CompositionElevationViewSection = props => {
  const {wallIndex} = props

  return (
    <Grid container spacing={4}>
      <TitleGridItem wallIndex={wallIndex}/>
      <PlanGridItem wallIndex={wallIndex}/>
    </Grid>
  )
}

