import {
  getManhole,
  getVaultClearMeasureBack,
  getVaultClearMeasureFront,
  getVaultClearMeasureLeft,
  getVaultClearMeasureRight,
  manholeFunctions
} from "./lid";
import {vaultFunctions} from "./vault";

export const supplementaryCoverFunctions = {
  has: (plan, position) => !plan.intermediateCeiling
    && getManhole(plan)
    && (
      position === 'left' && manholeFunctions.getLeft(plan) > getVaultClearMeasureLeft(plan) ? true
        : position === 'right' && manholeFunctions.getRight(plan) < getVaultClearMeasureRight(plan) ? true
          : position === 'front' && manholeFunctions.getFront(plan) > getVaultClearMeasureFront(plan) ? true
            : position === 'back' && manholeFunctions.getBack(plan) < getVaultClearMeasureBack(plan)
    ),
  getSize: (plan, position) => {
    return position === 'left' ? {
        x: vaultFunctions.getLeft(plan),
        y: vaultFunctions.getFront(plan),
        z: 0,
        length: manholeFunctions.getLeft(plan) - vaultFunctions.getLeft(plan),
        width: vaultFunctions.getWidth(plan),
        height: 20
      }
      : position === 'right' ? {
          x: manholeFunctions.getRight(plan),
          y: vaultFunctions.getFront(plan),
          z: 0,
          length: vaultFunctions.getRight(plan) - manholeFunctions.getRight(plan),
          width: vaultFunctions.getWidth(plan),
          height: 20
        }
        : position === 'front' ? {
            x: vaultFunctions.getLeft(plan),
            y: vaultFunctions.getFront(plan),
            z: 0,
            length: vaultFunctions.getLength(plan),
            width: manholeFunctions.getFront(plan) - vaultFunctions.getFront(plan),
            height: 20
          }
          : position === 'back' ? {
              x: vaultFunctions.getLeft(plan),
              y: manholeFunctions.getBack(plan),
              z: 0,
              length: vaultFunctions.getLength(plan),
              width: vaultFunctions.getBack(plan) - manholeFunctions.getBack(plan),
              height: 20
            }
            : {}
  },
  getLeft: (plan, position) => supplementaryCoverFunctions.getSize(plan, position).x,
  getLength: (plan, position) => supplementaryCoverFunctions.getSize(plan, position).length,
  getRight: (plan, position) =>
    supplementaryCoverFunctions.getLeft(plan, position) + supplementaryCoverFunctions.getLength(plan, position),
  getFront: (plan, position) => supplementaryCoverFunctions.getSize(plan, position).y,
  getWidth: (plan, position) => supplementaryCoverFunctions.getSize(plan, position).width,
  getBack: (plan, position) =>
    supplementaryCoverFunctions.getFront(plan, position) + supplementaryCoverFunctions.getWidth(plan, position),
  getBottom: (plan, position) => supplementaryCoverFunctions.getSize(plan, position).z,
  getHeight: (plan, position) => supplementaryCoverFunctions.getSize(plan, position).height,
  getTop: (plan, position) =>
    supplementaryCoverFunctions.getBottom(plan, position) + supplementaryCoverFunctions.getHeight(plan, position)
}

export const hasSupplementaryCoverLeft = plan => supplementaryCoverFunctions.has(plan, 'left')
export const hasSupplementaryCoverRight = plan => supplementaryCoverFunctions.has(plan, 'right')
export const hasSupplementaryCoverFront = plan => supplementaryCoverFunctions.has(plan, 'front')
export const hasSupplementaryCoverBack = plan => supplementaryCoverFunctions.has(plan, 'back')

export const hasSupplementaryCover = plan => {
  return hasSupplementaryCoverLeft(plan) || hasSupplementaryCoverRight(plan)
    || hasSupplementaryCoverFront(plan) || hasSupplementaryCoverBack(plan)
}
