export const whiteBackgroundColor = 'rgba(255, 255, 255, 0.9)'
export const grayBackgroundColor = 'rgba(224, 224, 224, 0.9)'
export const orangeBackgroundColor = 'rgba(239, 194, 154, 0.9)'
export const orangeBackgroundColorOpaque = 'rgba(239, 194, 154)'

export const partWallColor = grayBackgroundColor
export const partInsideColor = whiteBackgroundColor

export const primary_orange = '#ef8b2d'
export const secondary_orange = '#efbb2d'
export const error_color = '#ff0000'

export const orangeButtonColor = 'rgba(239, 139, 45, 0.6)'

export const concreteGray = '#c4c4c4'
export const anthracite = '#526266'
