import cities from "./cities.json"

const getCities = zip => {
  const result = cities.filter(t => zip.length > 2 && t.postleitzahl.startsWith(zip))
  .map(t => ({ zip: t.postleitzahl, name: t.ortbez27 }))

  return result
}


export const cityService = {
  getCities
}
