import React from "react"
import {Grid, Button, ButtonGroup, CircularProgress} from "@mui/material"
import {useHistory} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import {
  planApiActions,
  authenticationActions,
  armouringDocumentsActions
} from "../../actions"
import {apiUrls} from "../../constants"

export const ActionButtons = props => {
  const {plan, project} = props
  const dispatch = useDispatch()
  const history = useHistory()

  const apiRequestState = useSelector(state => state.apiRequestState)
  const isAdmin = useSelector(state => state.user.isAdmin)

  const isCreateArmouringDocsEnabled = () => {
    if (!isAdmin) return false;
    if (plan.hasLid && !plan.hasIntermediateCeiling) {
      return plan.lidManholeCount === 1
        && plan.lidBlockOutCount === 0
        && plan.lidCircularBlockOutCount === 0
        && ["B125", "A15", "D400", "E600"].includes(plan.lidType)
    } else if (plan.hasLid && plan.hasIntermediateCeiling) {
      return plan.lidManholeCount === 1
        && plan.lidBlockOutCount === 0
        && plan.lidCircularBlockOutCount === 0
        && plan.intermediateCeilingBlockOutCount === 0
        && plan.intermediateCeilingCircularBlockOutCount === 0
    } else if (!plan.hasLid && plan.hasIntermediateCeiling) {
      return plan.intermediateCeilingBlockOutCount === 1
        && plan.intermediateCeilingCircularBlockOutCount === 0
        && plan.lidManholeCount === 0
        && plan.lidBlockOutCount === 0
        && plan.lidCircularBlockOutCount === 0
    }
    return false
  }

  const isCreatingArmouringDocsForThis = () =>
    !!apiRequestState &&
    apiRequestState.request === "create_armouring_documents" &&
    apiRequestState.planId === plan.id &&
    apiRequestState.state === "pending"
  const isCreatingArmouringDocsForOther = () =>
    !!apiRequestState &&
    apiRequestState.request === "create_armouring_documents" &&
    apiRequestState.planId !== plan.id &&
    apiRequestState.state === "pending"

  return (
    <ButtonGroup variant="text" aria-label="text button group">
      <Button
        onClick={() => {
          dispatch(authenticationActions.extendLoginIfRequired())
          dispatch(planApiActions.readPlan(plan.id, history))
        }}
      >
        Als Vorlage öffnen
      </Button>

      <Button
        onClick={() => {
          window.open(apiUrls.planPdfDownload + "?linkId=" + plan.linkId)
        }}
      >
        PDF anzeigen
      </Button>

      {isCreateArmouringDocsEnabled() && (
        <React.Fragment>
          {isCreatingArmouringDocsForThis() ? (
            <Grid item>
              <CircularProgress
                style={{marginTop: "4px", marginLeft: "2em"}}
              />
            </Grid>
          ) : isCreatingArmouringDocsForOther() ? (
            <Button disabled={true}>Armierung generieren</Button>
          ) : (
            <Button
              onClick={() => {
                dispatch(authenticationActions.extendLoginIfRequired())
                dispatch(
                  armouringDocumentsActions.createArmouringDocuments(
                    plan.id,
                    !!project && project.id
                  )
                )
              }}
            >
              Armierung generieren
            </Button>
          )}
        </React.Fragment>
      )}
    </ButtonGroup>
  )
}
