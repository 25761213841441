import {projectApiActionTypes as actionTypes} from "../constants"
import {planApiActionTypes} from "../constants"

const initialState = []

export const projectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.resetProjects:
      return initialState

    case actionTypes.readProjectsRequest:
      return {fetching: true}

    case actionTypes.readProjectsFailure:
      return {error: action.error}

    case actionTypes.readProjectsSuccess:
      return Array.isArray(action.data) ? [...action.data] : []

    case planApiActionTypes.setOwnerSuccess:
      return Array.isArray(state)
        ? state.map(p => p.plan.id === action.data.id ? {...p, plan: action.data} : p)
        : state

    case planApiActionTypes.setOwnerFailure:
      return {
        error: {
          action: 'setPlanOwner',
          message: !!action.error ? action.error : ''
        }
      }

    default:
      return state
  }
}
