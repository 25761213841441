export const lidDescription = plan =>
  [
    !plan.lidType
      ? ""
      : plan.lidType.toLowerCase() === "a15"
      ? "A15"
      : plan.lidType.toLowerCase() === "b125"
      ? "B125"
      : plan.lidType.toLowerCase() === "d400"
      ? "D400"
      : plan.lidType.toLowerCase() === "e600"
      ? "E600"
      : plan.lidType.toLowerCase() === "subfloor"
      ? "Unterflur"
      : "",
    !plan.lidCollar
      ? ""
      : plan.lidCollar.toLowerCase() === "beveled"
      ? "abgeschrägt"
      : plan.lidCollar.toLowerCase() === "lifted"
      ? "hochgezogen"
      : plan.lidCollar.toLowerCase() === "without"
      ? "ohne Sockel"
      : plan.lidCollar.toLowerCase() === "pavingready"
      ? "für Pflästerung"
      : "",
    !plan.lidFill
      ? ""
      : plan.lidFill.toLowerCase() === "anthracite"
      ? "anthrazit"
      : plan.lidFill.toLowerCase() === "concretegray"
      ? "betongrau"
      : plan.lidFill.toLowerCase() === "partialfill"
      ? "Teilfüllung"
      : plan.lidFill.toLowerCase() === "without"
      ? "ohne Füllung"
      : ""
  ]
    .filter(s => s.length > 0)
    .join(", ")
