import React from 'react'

import {Typography, Grid, Button, ButtonGroup, CircularProgress} from '@mui/material'
import { useTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import {useHistory} from 'react-router-dom'

import {orangeButtonStyle, dialogStyles} from "../../constants"
import {mannhartWebsite} from "../../constants"
import {useSelector} from "react-redux"
import {RequestFailed} from "./RequestFailed"

const useStyles = () => {
  return makeStyles({
    orangeButton: orangeButtonStyle,
    paragraph: dialogStyles.paragraphStyle
  })()
}

export const CreatePlan = props => {
  const apiRequestState = useSelector(state => state.apiRequestState)

  switch (apiRequestState.state) {
    case 'pending':
      return <Pending/>

    case 'success':
      return <Success/>

    case 'failed':
      return <RequestFailed location={props.location}/>

    default:
      return null
  }
}

const Pending = () => {
  const classes = useStyles()

  return (
    <React.Fragment>
      <Typography component="h1" variant="h4">
        Plan wird gespeichert
      </Typography>
      <Typography className={classes.paragraph} component="h1" variant="body2">
        Die Speicherung dauert länger als erwartet. Bitte haben Sie einen Moment Geduld...
      </Typography>
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <CircularProgress disableShrink/>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export const Success = () => {
  const theme = useTheme()
  const classes = useStyles()
  const history = useHistory()

  return (
    <React.Fragment>
      <Typography component="h1" variant="h4">
        Plan gespeichert
      </Typography>
      <Typography className={classes.paragraph} component="h1" variant="body2">
        Der Plan wurde erfolgreich in Ihrem Profil unter "Persönliche Pläne" gespeichert.
      </Typography>
      <Typography className={classes.paragraph} component="h1" variant="body2">
        Sie können dort ein PDF mit dem konfigurierten Plan herunterladen oder ihn als Vorlage für künftige
        Bestellungen verwenden.
      </Typography>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        className={classes.orangeButton}
        style={{
          margin: theme.spacing(3, 0, 2),
        }}
        onClick={() => {
          history.push('/plans')
        }}
      >
        Persönliche Pläne anzeigen
      </Button>
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <ButtonGroup variant="text" aria-label="full-width contained primary button group">
            <Button onClick={() => history.push('/step/1')}>
              <Typography component="p" color="primary" variant="body2" texttransform="none">
                Zurück zum Konfigurator
              </Typography>
            </Button>
            <Button onClick={() => window.location = mannhartWebsite}>
              <Typography component="p" color="primary" variant="body2" texttransform="none">
                Webseite MM Mannhart AG
              </Typography>
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

