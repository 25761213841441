import React from 'react';

import {Typography, Grid, Button, ButtonGroup, CircularProgress} from '@mui/material';
import { useTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import {useHistory} from 'react-router-dom';

import {orangeButtonStyle, dialogStyles} from "../../constants";
import {mannhartWebsite} from "../../constants";
import * as queryString from "query-string";
import {useSelector} from "react-redux";
import {RequestFailed} from "./RequestFailed";

const useStyles = () => {
  return makeStyles({
    orangeButton: orangeButtonStyle,
    paragraph: dialogStyles.paragraphStyle
  })();
};

export const CreateProject = props => {
  const apiRequestState = useSelector(state => state.apiRequestState);

  switch (apiRequestState.state) {
    case 'pending':
      return <Pending location={props.location}/>;

    case 'success':
      return <Success location={props.location}/>;

    case 'failed':
      return <RequestFailed location={props.location}/>;

    default:
      return null;
  }
};

const Pending = props => {
  const classes = useStyles();
  const requestTypeQueryString = queryString.parse(props.location.search).requestType;
  const requestType = requestTypeQueryString === undefined ? 'offer' : requestTypeQueryString;

  return (
    <React.Fragment>
      <Typography component="h1" variant="h4">
        {
          requestType === "order"
            ? "Bestellung wird übermittelt"
            : "Offertanfrage wird übermittelt"
        }
      </Typography>
      <Typography className={classes.paragraph} component="h1" variant="body2">
        Die Übermittlung dauert länger als erwartet. Bitte haben Sie einen Moment Geduld...
      </Typography>
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <CircularProgress disableShrink/>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const Success = props => {
  const requestTypeQueryString = queryString.parse(props.location.search).requestType;
  const requestType = requestTypeQueryString === undefined ? 'offer' : requestTypeQueryString;
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();

  return (
    <React.Fragment>
      <Typography component="h1" variant="h4">
        {
          requestType === "order"
            ? "Bestellung übermittelt"
            : "Offertanfrage übermittelt"
        }
      </Typography>
      <Typography className={classes.paragraph} component="h1" variant="body2">
        {
          requestType === "order"
            ? "Besten Dank für Ihre Bestellung! Sie erhalten in Kürze eine Auftragsbestätigung."
            : "Besten Dank für Ihre Anfrage! Sie erhalten in Kürze eine Offerte."
        }
      </Typography>
      <Typography className={classes.paragraph} component="h1" variant="body2">
        {
          requestType === "order"
            ? 'Ihre Bestellung wurde in Ihrem Profil unter "Bestellungen und Offertanfragen" gespeichert.'
            : 'Ihre Offertanfrage wurde in Ihrem Profil unter "Bestellungen und Offertanfragen" gespeichert.'
        }
      </Typography>
      <Typography className={classes.paragraph} component="h1" variant="body2">
        Sie können dort ein PDF mit dem konfigurierten Plan herunterladen oder ihn als Vorlage für künftige
        Bestellungen verwenden.
      </Typography>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        className={classes.orangeButton}
        style={{
          margin: theme.spacing(3, 0, 2),
        }}
        onClick={() => {
          history.push('/projects');
        }}
      >
        Bestellungen anzeigen
      </Button>
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <ButtonGroup variant="text" aria-label="full-width contained primary button group">
            <Button onClick={() => history.push('/step/1')}>
              <Typography component="p" color="primary" variant="body2" texttransform="none">
                Zurück zum Konfigurator
              </Typography>
            </Button>
            <Button onClick={() => window.location = mannhartWebsite}>
              <Typography component="p" color="primary" variant="body2" texttransform="none">
                Webseite MM Mannhart AG
              </Typography>
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

