import { combineReducers } from 'redux'

import {authenticationReducer} from "./authenticationReducer"
import {userReducer} from "./userReducer"
import {planReducer} from "./planReducer"
import {projectReducer} from "./projectReducer"
import {plansReducer} from "./plansReducer"
import {planDetailsReducer} from "./planDetailsReducer"
import {projectsReducer} from "./projectsReducer"
import {projectDetailsReducer} from "./projectDetailsReducer"
import {apiRequestStateReducer} from "./apiRequestStateReducer"
import {planImageReducer} from "./planImageReducer"
import {constructionSitesReducer} from "./constructionSitesReducer";
import {billingsReducer} from "./billingsReducer";

export default combineReducers({
    authentication: authenticationReducer,
    user: userReducer,
    plan: planReducer,
    planImages: planImageReducer,
    project: projectReducer,
    plans: plansReducer,
    planDetails: planDetailsReducer,
    projects: projectsReducer,
    projectDetails: projectDetailsReducer,
    apiRequestState: apiRequestStateReducer,
    constructionSites: constructionSitesReducer,
    billings: billingsReducer
})
