import React from "react"

import {Group, Rect, Text} from "react-konva"

import {FONT_SIZE} from "../../../../constants"

const horizontalGroupDistance = 16

export const getToolbarItemWidth = (itemCounts, availableWidth) => {
  const spacerCount = itemCounts.length - 1
  const totalItemCount = itemCounts.reduce((total, curr) => total + curr, 0)

  return (availableWidth - spacerCount * horizontalGroupDistance) / totalItemCount
}

export const getGroupX = (itemCounts, availableWidth, groupIndex) => {
  const itemWidth = getToolbarItemWidth(itemCounts, availableWidth)

  return Array(groupIndex).fill(null).reduce(
    (total, _, i) => total + itemCounts[i] * itemWidth + horizontalGroupDistance,
    0
  )
}

export const getItemX = (itemCounts, availableWidth, groupIndex, itemIndex) => {
  const itemWidth = getToolbarItemWidth(itemCounts, availableWidth)

  return getGroupX(itemCounts, availableWidth, groupIndex) + itemIndex * itemWidth
}


export const getMinScale = (itemWidth, itemHeight, toolbarItemDefinitions) => {
  const getMax = (itemDefinitions, getValueFunc) => {
    return Math.max(...itemDefinitions.map(i => getValueFunc(i)))
  }
  const getWidth = itemDefinition => {
    return itemDefinition.width ? itemDefinition.width / 2
      : itemDefinition.outerDiameter ? itemDefinition.outerDiameter
        : itemDefinition.orientation && itemDefinition.length
          ? itemDefinition.orientation === 'horizontal' ? itemDefinition.length : 0
          : 0
  }
  const getHeight = itemDefinition => {
    return itemDefinition.height ? itemDefinition.height / 2
      : itemDefinition.outerDiameter ? itemDefinition.outerDiameter
        : itemDefinition.orientation && itemDefinition.length
          ? itemDefinition.orientation === 'vertical' ? itemDefinition.length : 0
          : 0
  }
  const minXScale = itemWidth / getMax(toolbarItemDefinitions, getWidth)
  const minYScale = itemHeight / getMax(toolbarItemDefinitions, getHeight)

  return Math.min(minXScale, minYScale)
}

export const getIconScale =  (itemWidth, itemHeight, toolbarItems) => {
  const iconHeight = itemHeight - 10 - 2 * FONT_SIZE

  return  getMinScale(itemWidth, iconHeight, toolbarItems)
}
export const renderToolbarItemLabel = (x, y, width, text1, text2) => {
  return (
    <React.Fragment>
      <Text
        x={x}
        y={y}
        width={width}
        align="center"
        text={text1}
        fontSize={FONT_SIZE}
        wrap={'none'}
      />
      <Text
        x={x}
        y={y + FONT_SIZE + 2}
        width={width}
        align="center"
        text={text2}
        fontSize={FONT_SIZE}
        wrap={'none'}
      />
    </React.Fragment>
  )
}

export const renderToolbarIcon = (screenElement, element, renderGroupElementsFunction) => {
  return (
    <Group
      x={screenElement.x}
      y={screenElement.y}
    >
      {renderGroupElementsFunction(screenElement, element)}
    </Group>
  )
}

export const renderDragStarterRect = rect => {
  const handleDragStarterDragStart = e => {
    e.cancelBubble = true
    const dragStarterRect = e.target
    const dragPreviewGroup = dragStarterRect.parent

    dragStarterRect.stopDrag()
    dragPreviewGroup.startDrag(e)
  }
  const handleDragStarterDragEnd = e => {
    e.cancelBubble = true
  }

  return (
    <Rect
      x={rect.x}
      y={rect.y}
      width={rect.width}
      height={rect.height}
      draggable
      onDragStart={handleDragStarterDragStart}
      onDragEnd={handleDragStarterDragEnd}
      //fill={'blue'}
    />
  )
}
