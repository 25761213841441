import React, {useState} from 'react'
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'

export const Search = props => {
  const {searchTerm, onSearchTermClear, onSearchEnter} = props
  const [value, setValue] = useState(searchTerm)
  const handleTextFieldKeyDown = e => {
    switch (e.key) {
      case 'Enter':
        !!onSearchEnter && onSearchEnter(value)
        break
      default: break
    }
  }
  const useStyles = makeStyles(() => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      width: '30ch',
    },
  }))
  const classes = useStyles()

  return (
    <FormControl className={classes.textField} variant="outlined">
      <InputLabel htmlFor="search">Suche</InputLabel>
      <OutlinedInput
        id="search"
        value={value}
        onChange={e => setValue(e.target.value)}
        onKeyDown={e => handleTextFieldKeyDown(e)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={() => {
                !!searchTerm && searchTerm.length > 0
                  ? onSearchTermClear()
                  : !!onSearchEnter && onSearchEnter(value)
              }}
              onMouseDown={e => e.preventDefault()}
              edge="end"
              size="large">
              {
                !!searchTerm && searchTerm.length > 0
                  ? <ClearIcon />
                  : <SearchIcon />
              }
            </IconButton>
          </InputAdornment>
        }
        label={"Suche"}
      />
    </FormControl>
  );
}
