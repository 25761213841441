import React from "react"

import { Button, Grid, Paper } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {useDispatch, useSelector} from "react-redux"
import {useRouteMatch, useHistory} from "react-router-dom"

import {orangeButtonStyle, grayBackgroundColor} from "../../constants"
import {
  authenticationActions,
  planActions,
  vaultActions,
  planApiActions,
  projectActions,
  projectApiActions
} from "../../actions/"
import {isLoginRequired} from "../../helpers/authHelper"
import {getNextStep, getPreviousStep, getStepByRoute} from "./configurationSteps"
import { hasErrors } from "../../validation/validation";

const useStyles = makeStyles(() => ({
  bottomBar: {
    background: grayBackgroundColor,
    padding: '1em',
  },
  orangeButton: orangeButtonStyle
}))

const ContextActions = () => {
  const history = useHistory()
  const authentication = useSelector(state => state.authentication)
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()
  const plan = useSelector(state => state.plan)
  const planImages = useSelector(state => state.planImages)
  const match = useRouteMatch()
  const step = match.params.step

  switch (step) {
    case '1':
      return (
        <Button
          color="primary"
          onClick={() => {
            dispatch(planActions.reset())
            dispatch(projectActions.reset())
            dispatch(projectApiActions.resetProjects())
            dispatch(planApiActions.resetPlans())
          }}>
          Alle Eingaben zurücksetzen
        </Button>
      )

    case '2b':
    case '2d':
      const wallIndex = step === '2b' ? 1 : 3
      return (
        <Button
          color="primary"
          onClick={() => dispatch(vaultActions.mirrorFromOppositeSide(wallIndex))}
        >
          Spiegelverkehrt von gegenüberliegenden Seite kopieren
        </Button>
      )

    case '7':
      return (
        <Button
          color="primary"
          onClick={() => {
            if (hasErrors(plan, null)) {
              window.scrollTo({top: 0, behavior: 'smooth'})
            } else if (isLoginRequired(authentication)) {
              history.push("/login?navigateTo=/step/" + step)
            } else {
              dispatch(authenticationActions.extendLoginIfRequired())
              history.push('/apirequest/createplan?navigateTo=/step/' + step)
              dispatch(planApiActions.createPlan(plan, planImages))
            }
          }}
        >
          In meinem Profil speichern
        </Button>
      )

    default:
      return null
  }
}

const StepNavigation = () => {
  const classes = useStyles()
  const history = useHistory()
  const plan = useSelector(state => state.plan)
  const dispatch = useDispatch()
  const match = useRouteMatch()
  const step = getStepByRoute(match.url)

  return (
    <React.Fragment>
      {(!!step && getNextStep(plan, step.id) !== null)
        ? <Grid item>
          <Button
            className={classes.orangeButton}
            color="primary"
            variant="contained"
            onClick={() => {
              history.push("/step/" + (getNextStep(plan, step.id)).id)
              dispatch(authenticationActions.extendLoginIfRequired())
            }}
          >
            Weiter
          </Button>
        </Grid>
        : null
      }
      <Grid item>
        <Button
          color="primary"
          disabled={!!step && !getPreviousStep(plan, step.id)}
          onClick={() => {
            history.push("/step/" + (getPreviousStep(plan, step.id).id))
            dispatch(authenticationActions.extendLoginIfRequired())
          }}
        >
          Zurück
        </Button>
      </Grid>
    </React.Fragment>
  )
}

const SubmitActions = () => {
  const classes = useStyles()
  const history = useHistory()
  const match = useRouteMatch()
  const step = match.params.step
  const dispatch = useDispatch()
  const plan = useSelector(state => state.plan)
  const planImages = useSelector(state => state.planImages)
  const project = useSelector(state => state.project)
  const authentication = useSelector(state => state.authentication)
  const user = useSelector(state => state.user)

  return (
    <React.Fragment>
      <Grid item>
        <Button
          className={classes.orangeButton}
          color="primary"
          variant="contained"
          onClick={() => {
            if (hasErrors(plan, project)) {
              window.scrollTo({top: 0, behavior: 'smooth'})
            } else if (isLoginRequired(authentication)) {
              history.push("/login?navigateTo=/step/" + step)
            } else {
              dispatch(authenticationActions.extendLoginIfRequired())
              history.push('/apirequest/createproject?requestType=offer&navigateTo=/step/7')
              dispatch(projectApiActions.createProject(project, plan, planImages, 'offer'))
            }
          }}
        >
          Offerte anfordern
        </Button>
      </Grid>
      <Grid item>
        <Button
          className={classes.orangeButton}
          color="primary"
          variant="contained"
          onClick={() => {
            if (hasErrors(plan, project)) {
              window.scrollTo({top: 0, behavior: 'smooth'})
            } else if (isLoginRequired(authentication)) {
              history.push("/login?navigateTo=/step/" + step)
            } else {
              dispatch(authenticationActions.extendLoginIfRequired())
              history.push('/apirequest/createproject?requestType=order&navigateTo=/step/7')
              dispatch(projectApiActions.createProject(project, plan, planImages, 'order'))
            }
          }}
        >
          Bestellung auslösen
        </Button>
      </Grid>
    </React.Fragment>
  )

}

export const BottomBar = () => {
  const classes = useStyles()
  const match = useRouteMatch()
  const step = match.params.step

  return (
    <Paper square={true} className={classes.bottomBar}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <ContextActions step={step}/>
        </Grid>
        <Grid item>
          <Grid container spacing={1} direction="row-reverse">
            {step === '7'
              ? <SubmitActions/>
              : null
            }
            <StepNavigation/>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}
