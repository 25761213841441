import React from "react";

import * as queryString from "query-string";
import { Button, Typography, useTheme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {useHistory} from 'react-router-dom';

import {dialogStyles, orangeButtonStyle} from "../../constants";

const useStyles = () => {
  return makeStyles({
    orangeButton: orangeButtonStyle,
    paragraph: dialogStyles.paragraphStyle
  })();
};

export const RequestFailed = props => {
  const navigateToQueryString = queryString.parse(props.location.search).navigateTo;
  const navigateTo = navigateToQueryString === undefined ? '/step/1' : navigateToQueryString;
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();

  return (
    <React.Fragment>
      <Typography component="h1" variant="h4">
        Aktion fehlgeschlagen!
      </Typography>
      <Typography className={classes.paragraph} component="p" variant="body2">
        Es ist unerwartet ein Fehler aufgetreten.
        Bite versuchen Sie es nochmals und nehmen bei weiteren Problemen Kontakt mit uns auf.
      </Typography>
      <Typography className={classes.paragraph} component="p" variant="body2">
        081 733 11 36 oder info@mannhart.swiss
      </Typography>
      <Typography className={classes.paragraph} component="p" variant="body2">
        Wir bedauern, dass es bei Ihnen nicht geklappt hat und entschuldigen und für die Umstände?
      </Typography>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        className={classes.orangeButton}
        style={{
          margin: theme.spacing(3, 0, 2),
        }}
        onClick={() => history.push(navigateTo)}
      >
        Zurück
      </Button>
    </React.Fragment>
  );
};
