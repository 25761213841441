import {planActionTypes as actionTypes} from "../constants"

const setLid = () => {
  return {
    type: actionTypes.setLid
  }
}

const unsetLid = () => {
  return {
    type: actionTypes.unsetLid
  }
}

const setLidType = lidType => {
  return {
    type: actionTypes.setLidType,
    data: lidType
  }
}

const setLidCollar = collar => {
  return {
    type: actionTypes.setLidCollar,
    data: collar
  }
}

const setLidFill = fill => {
  return {
    type: actionTypes.setLidFill,
    data: fill
  }
}

const addManhole = manhole => {
  return {
    type: actionTypes.addManhole,
    data: manhole
  }
}

const removeManhole = id => {
  return {
    type: actionTypes.removeManhole,
    data: id
  }
}

const moveManhole = (id, x, y) => {
  return {
    type: actionTypes.moveManhole,
    data: {id, x, y}
  }
}

const resizeManhole = (id, width, height) => {
  return {
    type: actionTypes.resizeManhole,
    data: {id, width, height}
  }
}

const addBlockOut = blockOut => {
  return {
    type: actionTypes.addLidBlockOut,
    data: blockOut
  }
}

const removeBlockOut = id => {
  return {
    type: actionTypes.removeLidBlockOut,
    data: id
  }
}

const moveBlockOut = (id, x, y) => {
  return {
    type: actionTypes.moveLidBlockOut,
    data: {id, x, y}
  }
}

const resizeBlockOut = (id, width, height) => {
  return {
    type: actionTypes.resizeLidBlockOut,
    data: {id, width, height}
  }
}

const addCircularBlockOut = blockOut => {
  return {
    type: actionTypes.addCircularLidBlockOut,
    data: blockOut
  }
}

const removeCircularBlockOut = id => {
  return {
    type: actionTypes.removeCircularLidBlockOut,
    data: id
  }
}

const moveCircularBlockOut = (id, x, y) => {
  return {
    type: actionTypes.moveCircularLidBlockOut,
    data: {id, x, y}
  }
}

const resizeCircularBlockOut = (id, diameter) => {
  return {
    type: actionTypes.resizeCircularLidBlockOut,
    data: {id, diameter}
  }
}

export const lidActions = {
  setLid,
  unsetLid,
  setLidType,
  setLidCollar,
  setLidFill,
  addManhole,
  removeManhole,
  moveManhole,
  resizeManhole,
  addBlockOut,
  removeBlockOut,
  moveBlockOut,
  resizeBlockOut,
  addCircularBlockOut,
  removeCircularBlockOut,
  moveCircularBlockOut,
  resizeCircularBlockOut
}
