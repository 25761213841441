import {getClosureSizes} from "./manhole";

export const lidIsFullyCoveredByOrHasNoManholesAtAll = plan => !plan.lid || lidIsFullyCoveredByManhole(plan)
export const lidIsFullyCoveredByManhole = plan => {
  return !!plan.lid
    && plan.lid.manholes.length === 1
    && (
      plan.lid.manholes[0].x <= 0
      && plan.lid.manholes[0].x + plan.lid.manholes[0].width >= plan.length
      && plan.lid.manholes[0].y <= 0
      && plan.lid.manholes[0].y + plan.lid.manholes[0].height >= plan.width
    )
}
export const hasMultiRowManholes = plan => {
  return !!plan.lid
    && plan.lid.manholes.length > 0
    && plan.lid.manholes.some(m => (m.orientation === 'horizontal' ? m.width : m.height) > getClosureSizes(m.type).height)
}

export const getVaultClearMeasureLeft = () => 0
export const getVaultClearMeasureLength = plan => plan.length
export const getVaultClearMeasureRight = plan => getVaultClearMeasureLeft(plan) + getVaultClearMeasureLength(plan)
export const getVaultClearMeasureFront = () => 0
export const getVaultClearMeasureWidth = plan => plan.width
export const getVaultClearMeasureBack = plan => getVaultClearMeasureFront(plan) + getVaultClearMeasureWidth(plan)
export const getVaultLeft = plan => -plan.wallWidth
export const getVaultLength = plan => plan.length + 2 * plan.wallWidth
export const getVaultRight = plan => getVaultLeft(plan) + getVaultLength(plan)
export const getVaultFront = plan => -plan.wallWidth
export const getVaultWidth = plan => plan.width + 2 * plan.wallWidth
export const getVaultBack = plan => getVaultFront(plan) + getVaultWidth(plan)
export const getManhole = plan => plan.lid.manholes.length === 1 ? plan.lid.manholes[0] : null
const getManholeLeft = plan => !!getManhole(plan) ? getManhole(plan).x : 0
const getManholeLength = plan => !!getManhole(plan) ? getManhole(plan).width : 0
const getManholeRight = plan => getManholeLeft(plan) + getManholeLength(plan)
const getManholeFront = plan => !!getManhole(plan) ? getManhole(plan).y : 0
const getManholeWidth = plan => !!getManhole(plan) ? getManhole(plan).height : 0
const getManholeBack = plan => getManholeFront(plan) + getManholeWidth(plan)

export const manholeFunctions = {
  getSize: plan => getManhole(plan),
  getLeft: getManholeLeft,
  getLength: getManholeLength,
  getRight: getManholeRight,
  getFront: getManholeFront,
  getWidth: getManholeWidth,
  getBack: getManholeBack
}

export const getLidSize = plan => {
  const getA15B125D400E600LidSize = () => {
    const getLidWithoutCollarSize = () => {

      const getWidthOutsideClearMeasure = side => {
        return ((side === 'left' || side === 'right') && getManhole(plan).orientation === 'horizontal')
        || ((side === 'front' || side === 'back') && getManhole(plan).orientation === 'vertical')
          ? plan.lid.type === 'A15' ? 7.75
            : plan.lid.type === 'B125' ? 7.2
              : plan.lid.type === 'D400' ? 9.1
                : plan.lid.type === 'E600' ? 9.1
                  : 0
          : plan.lid.type === 'A15' ? 5.5
            : plan.lid.type === 'B125' ? 4
              : plan.lid.type === 'D400' ? 7.1
                : plan.lid.type === 'E600' ? 7.1
                  : 0
      }
      const getSidePosition = side => {
        return side === 'left' ? getManholeLeft(plan) - getWidthOutsideClearMeasure(side)
          : side === 'right' ? getManholeRight(plan) + getWidthOutsideClearMeasure(side)
            : side === 'front' ? getManholeFront(plan) - getWidthOutsideClearMeasure(side)
              : side === 'back' ? getManholeBack(plan) + getWidthOutsideClearMeasure(side)
                : 0
      }
      const getHeight = () => {
        return plan.lid.type === 'A15' ? 17.5
          : plan.lid.type === 'B125' ? 12.4
            : plan.lid.type === 'D400' ? 14
              : plan.lid.type === 'E600' ? 15
                : 0
      }

      return !getManhole(plan) ? {width: 0, length: 0, height: 0, x: 0, y: 0}
        : {
          width: getSidePosition('back') - getSidePosition('front'),
          length: getSidePosition('right') - getSidePosition('left'),
          height: getHeight(),
          x: getSidePosition('left'),
          y: getSidePosition('front')
        }
    }
    const getLidWithCollarSize = () => {
      const isStandardVault = () => lidIsFullyCoveredByOrHasNoManholesAtAll(plan) || !!plan.intermediateCeiling
      const getMinWidthOutsideManholeClearMeasures = side => {
        const getA15B125MinWidthOutsideManholeClearMeasures = () => hasMultiRowManholes(plan)
          ? 35
          : 14
        const getD400E600MinWidthOutsideManholeClearDimensions = () => {
          const is19CmExceptionStandardLid =
            (length, width) => !!getManhole(plan)
              && ((getManhole(plan).orientation === 'vertical' && length >= 100 && length <= 260)
                || (getManhole(plan).orientation === 'horizontal' && width >= 100 && width <= 260))
          const is19CmExceptionStandardVault =
            () => is19CmExceptionStandardLid(plan.length, plan.width) && lidIsFullyCoveredByManhole(plan)
          const is19CmExceptionStandardLidOnIntermediateCeiling =
            () => !!getManhole(plan)
              && !!plan.intermediateCeiling
              && is19CmExceptionStandardLid(getManhole(plan).width, getManhole(plan).height)
          const isSideWithoutRabbet =
            side => ((side === 'left' || side === 'right') && getManhole(plan).orientation === 'vertical')
              || ((side === 'front' || side === 'back') && getManhole(plan).orientation === 'horizontal')
          const getMinCollarWidth = () =>
            (is19CmExceptionStandardVault() || is19CmExceptionStandardLidOnIntermediateCeiling())
            && isSideWithoutRabbet(side)
              ? 19
              : 20

          return getMinCollarWidth()
        }

        return plan.lid.type === 'A15' || plan.lid.type === 'B125' ? getA15B125MinWidthOutsideManholeClearMeasures()
          : plan.lid.type === 'D400' || plan.lid.type === 'E600' ? getD400E600MinWidthOutsideManholeClearDimensions()
            : 0
      }

      const getLeft =
        () => !getManhole(plan) ? getVaultLeft(plan)
          : isStandardVault() ? getManholeLeft(plan) - getMinWidthOutsideManholeClearMeasures('left')
            : Math.min(getManholeLeft(plan) - getMinWidthOutsideManholeClearMeasures(), getVaultLeft(plan))
      const getRight =
        () => !getManhole(plan) ? getVaultRight(plan)
          : isStandardVault() ? getManholeRight(plan) + getMinWidthOutsideManholeClearMeasures('right')
            : Math.max(getManholeRight(plan) + getMinWidthOutsideManholeClearMeasures(), getVaultRight(plan))
      const getFront =
        () => !getManhole(plan) ? getVaultFront(plan)
          : isStandardVault() ? getManholeFront(plan) - getMinWidthOutsideManholeClearMeasures('front')
            : Math.min(getManholeFront(plan) - getMinWidthOutsideManholeClearMeasures(), getVaultFront(plan))
      const getBack =
        () => !getManhole(plan) ? getVaultBack(plan)
          : isStandardVault() ? getManholeBack(plan) + getMinWidthOutsideManholeClearMeasures('back')
            : Math.max(getManholeBack(plan) + getMinWidthOutsideManholeClearMeasures(), getVaultBack(plan))
      const getHeight = () => {
        const getA15Height = () => plan.lid.collar === 'lifted'
          ? 20
          : lidIsFullyCoveredByOrHasNoManholesAtAll(plan)
            ? 20
            : 25
        const getB125Height = () => hasMultiRowManholes(plan)
          ? 35
          : isStandardVault()
            ? 20
            : plan.lid.collar === 'pavingready' || plan.lid.collar === 'beveled'
              ? 25
              : 22
        const getD400E600Height = () => isStandardVault()
          ? 25
          : 30

        return plan.lid.type === 'B125' ? getB125Height()
          : plan.lid.type === 'A15' ? getA15Height()
            : plan.lid.type === 'D400' || plan.lid.type === 'E600' ? getD400E600Height()
              : 0
      }

      return {
        width: getBack() - getFront(),
        length: getRight() - getLeft(),
        height: getHeight(),
        x: getLeft(),
        y: getFront()
      }
    }

    return plan.lid.collar === 'without'
      ? getLidWithoutCollarSize()
      : getLidWithCollarSize()
  }
  const getSubFloorLidSize = () => {
    const getLeft = () => !getManhole(plan) ? getVaultLeft(plan)
      : getManhole(plan).orientation === 'vertical'
        ? getManholeLeft(plan) <= getVaultClearMeasureLeft(plan) || !!plan.intermediateCeiling
          ? getManholeLeft(plan)
          : getVaultLeft(plan)
        : lidIsFullyCoveredByManhole(plan) || !!plan.intermediateCeiling
          ? getManholeLeft(plan)
          : Math.min(getManholeLeft(plan), getVaultLeft(plan))
    const getRight = () => !getManhole(plan) ? getVaultRight(plan)
      : getManhole(plan).orientation === 'vertical'
        ? getManholeRight(plan) >= getVaultClearMeasureRight(plan) || !!plan.intermediateCeiling
          ? getManholeRight(plan)
          : getVaultRight(plan)
        : lidIsFullyCoveredByManhole(plan) || !!plan.intermediateCeiling
          ? getManholeRight(plan)
          : Math.max(getManholeRight(plan), getVaultRight(plan))
    const getFront = () => !getManhole(plan) ? getVaultFront(plan)
      : getManhole(plan).orientation === 'horizontal'
        ? getManholeFront(plan) <= getVaultClearMeasureFront(plan) || !!plan.intermediateCeiling
          ? getManholeFront(plan)
          : getVaultFront(plan)
        : lidIsFullyCoveredByManhole(plan) || !!plan.intermediateCeiling
          ? getManholeFront(plan)
          : Math.min(getManholeFront(plan), getVaultFront(plan))
    const getBack = () => !getManhole(plan) ? getVaultBack(plan)
      : getManhole(plan).orientation === 'horizontal'
        ? getManholeBack(plan) >= getVaultClearMeasureBack(plan) || !!plan.intermediateCeiling
          ? getManholeBack(plan)
          : getVaultBack(plan)
        : lidIsFullyCoveredByManhole(plan) || !!plan.intermediateCeiling
          ? getManholeBack(plan)
          : Math.max(getManholeBack(plan), getVaultBack(plan))
    const getLength = () => getRight() - getLeft()
    const getWidth = () => getBack() - getFront()
    const getThickness = () =>
      (
        lidIsFullyCoveredByManhole(plan)
        || (!!getManhole(plan) && !!plan.intermediateCeiling)
      )
        ? getClosureSizes(getManhole(plan).type).thickness
        + getClosureSizes(getManhole(plan).type).bracketHeight
        + getClosureSizes(getManhole(plan).type).bracketDiameter / 2
        : 20

    return {
      length: getLength(),
      width: getWidth(),
      height: getThickness(),
      x: getLeft(),
      y: getFront()
    }
  }

  return !plan.lid ? {width: 0, length: 0, height: 0, x: 0, y: 0}
    : plan.lid.type === 'A15' || plan.lid.type === 'B125' || plan.lid.type === 'D400' || plan.lid.type === 'E600'
      ? getA15B125D400E600LidSize()
      : plan.lid.type === 'subfloor' ? getSubFloorLidSize()
        : {width: 0, length: 0, height: 0, x: 0, y: 0}
}

const getLidLeft = plan => getLidSize(plan).x
const getLidLength = plan => getLidSize(plan).length
const getLidRight = plan => getLidLeft(plan) + getLidLength(plan)
const getLidFront = plan => getLidSize(plan).y
const getLidWidth = plan => getLidSize(plan).width
const getLidBack = plan => getLidFront(plan) + getLidWidth(plan)

export const lidFunctions = {
  getSize: plan => getLidSize(plan),
  getBottom: () => 0,
  getHeight: plan => lidFunctions.getSize(plan).height,
  getTop: plan => lidFunctions.getBottom() + lidFunctions.getHeight(plan),
  getLeft: getLidLeft,
  getLength: getLidLength,
  getRight: getLidRight,
  getFront: getLidFront,
  getWidth: getLidWidth,
  getBack: getLidBack,
}

export const getHorizontalAnchorWidth = plan => {
  return (plan.lid.type === 'A15' || plan.lid.type === 'B125' || plan.lid.type === 'E400' || plan.lid.type === 'D600')
  && plan.lid.collar === 'without'
  && plan.lid.manholes.length === 1
  && plan.lid.manholes[0].orientation === 'horizontal'
    ? plan.lid.type === 'A15' ? 2
      : plan.lid.type === 'B125' ? 3.2
        : 0
    : 0
}
export const getVerticalAnchorWidth = plan => {
  return (plan.lid.type === 'A15' || plan.lid.type === 'B125' || plan.lid.type === 'E400' || plan.lid.type === 'D600')
  && plan.lid.collar === 'without'
  && plan.lid.manholes.length === 1
  && plan.lid.manholes[0].orientation === 'vertical'
    ? plan.lid.type === 'A15' ? 2
      : plan.lid.type === 'B125' ? 3.2
        : 0
    : 0
}

export const getLidTopToBevelTopDistance = plan => {
  return plan.lid.collar === 'pavingready'
    ? 4
    : plan.lid.collar === 'without'
      ? 8
      : plan.lid.collar === 'beveled'
        ? plan.lid.type === 'A15' || plan.lid.type === 'B125'
          ? 3
          : 4
        : 0
}
export const getBevelHeight = plan => {
  return plan.lid.collar === 'beveled'
    ? plan.lid.type === 'D400' || plan.lid.type === 'E600'
      ? 4
      : !lidIsFullyCoveredByOrHasNoManholesAtAll(plan) || hasMultiRowManholes(plan)
        ? 2
        : 4
    : 0
}
export const getBevelBottom = plan => {
  return lidFunctions.getHeight(plan) - getLidTopToBevelTopDistance(plan) - getBevelHeight(plan)
}
export const getDefaultManholeMaxOverlap = () => 4
export const getSubFloorManholeMaxOverlap = plan => plan.wallWidth
export const getManholeMaxOverlap = plan => {
  return plan.lid.type === 'subfloor'
    ? getSubFloorManholeMaxOverlap(plan)
    : getDefaultManholeMaxOverlap()
}
export const getManholeMaxOverlaps = (plan, manhole) => {
  return plan.lid.type === 'subfloor'
    ? manhole.orientation === 'vertical'
      ? {
        left: getSubFloorManholeMaxOverlap(plan),
        right: getSubFloorManholeMaxOverlap(plan),
        front: Number.POSITIVE_INFINITY,
        back: Number.POSITIVE_INFINITY
      }
      : {
        left: Number.POSITIVE_INFINITY,
        right: Number.POSITIVE_INFINITY,
        front: getSubFloorManholeMaxOverlap(plan),
        back: getSubFloorManholeMaxOverlap(plan)
      }
    : {
      left: getDefaultManholeMaxOverlap(),
      right: getDefaultManholeMaxOverlap(),
      front: getDefaultManholeMaxOverlap(),
      back: getDefaultManholeMaxOverlap()
    }

}
export const getSubFloorManholeMinOverlap = () => 10


