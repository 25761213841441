import React, {useEffect, useRef} from "react"

import {useDispatch, useSelector} from "react-redux"
import {Group, Layer, Stage} from "react-konva"

import {AutoResizeDiv} from "../AutoResizeDiv"
import {createGroundViewLayout} from "./layoutFunctions"
import {getLidSize} from "../../domain/lid"
import {getCombinedSize, getOverallSize} from "../../domain/composition"
import {createDimensioning, createMeasurementStopChainFromPositions, toWithOverallMeasurement} from "../dimensioning"
import {getVaultSize, vaultFunctions} from "../../domain/vault"
import {createIntermediateCeilingGroundView} from "./IntermediateCeiling/intermediateCeilingGroundView";
import {planImageActions} from "../../../actions/planImageActions";
import {
  getGroundViewBottomMeasurementPositions,
  getGroundViewLeftMeasurementPositions
} from "./intermediateCeilingDimensioning";
import {intermediateCeilingFunctions} from "../../domain/intermediateCeiling";

const GroundView = props => {
  const {clientSize} = props
  const plan = useSelector(state => state.plan)
  const dispatch = useDispatch()
  const theStage = useRef(null)

  useEffect(() => {
      if (!theStage.current) {
        return
      }
      dispatch(planImageActions.setIntermediateCeilingGroundViewImage(theStage.current.toDataURL()))
    },
    [clientSize, dispatch]
  )
  const getLidWithIntermediateCeilingSize = () => getCombinedSize([getLidSize, intermediateCeilingFunctions.getSize], plan)
  const getLidWithVaultSize = () => getCombinedSize([getLidSize, getVaultSize], plan)
  const getVisualPartSize = () => {
    return !!plan.intermediateCeiling ? getLidWithIntermediateCeilingSize()
      : plan.lid.type === 'subfloor' && !!plan.vault ? getLidWithVaultSize()
        : getLidSize(plan)
  }
  const layout = createGroundViewLayout(clientSize, plan, getVisualPartSize())

  const getHorizontalMeasurementStops = () => {
    const getInnerMeasurements = () => {
      const transformToDrawing = positions => {
        return positions.map(
          p => ({
            x: p.x - getOverallSize(plan).x,
            y: p.y - vaultFunctions.getFront(plan)
          })
        )
      }

      return createMeasurementStopChainFromPositions(
        transformToDrawing(
          getGroundViewBottomMeasurementPositions(plan)
        )
      )
    }

    return toWithOverallMeasurement(getInnerMeasurements())
  }
  const getVerticalMeasurementStops = () => {
    const getInnerMeasurements = () => {
      const transformToDrawing = positions => {
        return positions.map(
          p => ({
            x: p.y - vaultFunctions.getFront(plan),
            y: -(p.x - vaultFunctions.getLeft(plan))
          })
        )
      }

      return createMeasurementStopChainFromPositions(
        transformToDrawing(
          getGroundViewLeftMeasurementPositions(plan)
        )
      )
    }

    return toWithOverallMeasurement(getInnerMeasurements())
  }

  return (
    <div>
      {!isNaN(layout.verticalMeasurements.screenHeight) &&
        <Stage
          ref={theStage}
          name={"stage"}
          width={clientSize.width}
          height={layout.overall.screenHeight}
        >
          <Layer>
            {
              <Group x={layout.part.screenX} y={layout.part.screenY}>
                {createIntermediateCeilingGroundView(plan, layout.part.scale)}
              </Group>
            }
            <Group
              x={layout.horizontalMeasurements.screenX}
              y={layout.horizontalMeasurements.screenY}
            >
              {
                createDimensioning(
                  getHorizontalMeasurementStops(),
                  'below',
                  {
                    width: layout.horizontalMeasurements.screenWidth,
                    height: layout.horizontalMeasurements.screenHeight
                  },
                  layout.verticalMeasurements.scale
                )
              }
            </Group>
            <Group
              x={layout.verticalMeasurements.screenX + layout.verticalMeasurements.screenWidth}
              y={layout.verticalMeasurements.screenY + layout.verticalMeasurements.screenHeight}
              rotation={-90}
            >
              {
                createDimensioning(
                  getVerticalMeasurementStops(),
                  'above',
                  {width: layout.verticalMeasurements.screenHeight, height: layout.verticalMeasurements.screenWidth},
                  layout.verticalMeasurements.scale
                )
              }
            </Group>
          </Layer>
        </Stage>
      }
    </div>
  )
}

export const IntermediateCeilingGroundView = props => {
  return (
    <AutoResizeDiv>
      <GroundView {...props} />
    </AutoResizeDiv>
  )
}
