import React from "react"
import {
  Grid,
  Tooltip
} from "@mui/material"
import { concreteGray, primary_orange } from "../../constants"

const rectStyle = {
  borderWidth: "1px",
  borderColor: "black",
  borderStyle: "solid",
  marginBottom: 5,
  background: concreteGray,
  width: 20,
  height: 10
}
const bigRectStyle = { ...rectStyle, height: 20 }
const orangeRectStyle = { ...rectStyle, background: primary_orange }
const bigOrangeRectStyle = { ...bigRectStyle, background: primary_orange }

export const PlanComponentIcons = props => {
  const { plan } = props
  return (
    <Grid item>
      <Tooltip title="Abdeckung" placement="right">
        <div style={plan.hasLid ? orangeRectStyle : rectStyle}></div>
      </Tooltip>
      <Tooltip title="Zwischendecke" placement="right">
        <div
          style={plan.hasIntermediateCeiling ? orangeRectStyle : rectStyle}
        ></div>
      </Tooltip>
      <Tooltip title="Kabelschacht" placement="right">
        <div style={plan.hasVault ? bigOrangeRectStyle : bigRectStyle}></div>
      </Tooltip>
      <Tooltip title="Boden" placement="right">
        <div style={plan.hasFloor ? orangeRectStyle : rectStyle}></div>
      </Tooltip>
    </Grid>
  )
}
