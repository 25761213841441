import React, {useEffect} from "react"
import {
  Autocomplete,
  FormControl,
  Grid,
  TextField,
  Typography,
  useTheme
} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {useSelector, useDispatch} from "react-redux"

import {orangeButtonStyle} from "../../../constants"
import {billingActions, billingApiActions} from "../../../actions"
import InfoIcon from "@mui/icons-material/Info"
import {CityItem} from "../City"
import {isValidEmail} from "../../../validation/emailValidation";

const useStyles = () => {
  return makeStyles({
    italic: {
      fontStyle: "italic"
    },
    orangeButton: orangeButtonStyle
  })()
}

const PresetSelectGridItem = () => {
  const dispatch = useDispatch()
  const billings = useSelector(state => state.billings)

  const toOptions = billings =>
    Array.isArray(billings)
      ? billings.map(b => {
        return {
          label: `${b.company}, ${b.name}, ${b.street}, ${b.zip} ${b.city}`,
          value: b.id
        }
      })
      : []

  const handleInputChange = (event, value) => {
    dispatch(billingApiActions.readBillings(5, value))
  }

  const handleChange = (event, value) => {
    if (!value) {
      return
    }
    const address = billings.find(b => b.id === value.value)

    if (!!address) {
      dispatch(billingActions.setName(address.name))
      dispatch(billingActions.setEmail(address.email))
      dispatch(billingActions.setPhone(address.phone))
      dispatch(billingActions.setCompany(address.company))
      dispatch(billingActions.setStreet(address.street))
      dispatch(billingActions.setZip(address.zip))
      dispatch(billingActions.setCity(address.city))
    }
  }

  useEffect(() => {
    dispatch(billingApiActions.readBillings())
  }, [dispatch])

  return (
    <Grid item xs={12}>
      <FormControl fullWidth variant="outlined">
        <Autocomplete
          options={toOptions(billings)}
          getOptionLabel={option => option.label.toString()}
          renderInput={params => (
            <TextField
              {...params}
              label="Suchbegriff eingeben..."
              variant="outlined"
              fullWidth
            />
          )}
          onChange={handleChange}
          onInputChange={handleInputChange}
        />
      </FormControl>
    </Grid>
  )
}

const LoginForPresetsTextGridItem = () => {
  return (
    <Grid item xs={12}>
      <Grid direction={"row"} container alignItems={"center"}>
        <Grid item>
          <InfoIcon style={{marginRight: "1em"}}/>
        </Grid>
        <Grid item xs={10}>
          <Typography component="p" variant="body1">
            Sie müssen angemeldet sein um Angaben aus bereits erfassten
            Rechnungsadressen zu übernehmen.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

const PresetItem = () => {
  const authentication = useSelector(state => state.authentication)

  return (
    <Grid container spacing={2}>
      {!!authentication.loggedIn && <PresetSelectGridItem/>}
      {!authentication.loggedIn && <LoginForPresetsTextGridItem/>}
    </Grid>
  )
}


const NameItem = () => {
  const dispatch = useDispatch()
  const name = useSelector(state => state.project.billing.name)

  return (
    <TextField
      margin="normal"
      variant="outlined"
      fullWidth
      id="name"
      label="Vor- und Nachname"
      name="name"
      value={name}
      error={!name}
      helperText={!name ? "Name ist erforderlich" : null}
      onChange={e => {
        dispatch(billingActions.setName(e.target.value))
      }}
    />
  )
}

const EMailItem = () => {
  const dispatch = useDispatch()
  const email = useSelector(state => state.project.billing.email)

  return (
    <TextField
      margin="normal"
      variant="outlined"
      fullWidth
      id="email"
      label="E-Mail"
      name="email"
      value={email}
      error={!email || !isValidEmail(email)}
      helperText={!email ? "E-Mail ist erforderlich" : !isValidEmail(email) ? "E-Mail ist ungültig" : null}
      onChange={e => {
        dispatch(billingActions.setEmail(e.target.value))
      }}
    />
  )
}

const PhoneItem = () => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const dispatch = useDispatch()
  const phone = useSelector(state => state.project.billing.phone)

  return (
    <TextField
      margin="normal"
      variant="outlined"
      fullWidth
      className={classes.italic}
      id="phone"
      label="Telefon"
      name="phone"
      value={phone}
      onChange={e => {
        dispatch(billingActions.setPhone(e.target.value))
      }}
    />
  )
}

const CompanyItem = () => {
  const dispatch = useDispatch()
  const company = useSelector(state => state.project.billing.company)

  return (
    <TextField
      margin="normal"
      variant="outlined"
      fullWidth
      id="company"
      label="Firma"
      name="company"
      value={company}
      error={!company}
      helperText={!company ? "Firma ist erforderlich" : null}
      onChange={e => {
        dispatch(billingActions.setCompany(e.target.value))
      }}
    />
  )
}

const StreetItem = () => {
  const dispatch = useDispatch()
  const street = useSelector(state => state.project.billing.street)

  return (
    <TextField
      margin="normal"
      variant="outlined"
      fullWidth
      id="street"
      label="Strasse und Nummer"
      name="street"
      value={street}
      error={!street}
      helperText={!street ? "Strasse ist erforderlich" : null}
      onChange={e => {
        dispatch(billingActions.setStreet(e.target.value))
      }}
    />
  )
}

export const BillingSection = () =>
  (
    <Grid container spacing={6} alignItems="flex-end">
      <Grid item xs={6}>
        <Grid container>
          <Grid item xs={12} key={"upper_text"}>
            <Typography component="p" variant="body1">
              Wählen Sie eine bereits erfasste Rechnungsadresse aus, um die Angaben zu
              übernehmen.
            </Typography>
          </Grid>
          <Grid item xs={12} mt={2} mb={4}>
            <PresetItem/>
          </Grid>

          <Grid item xs={12}>
            <NameItem/>
          </Grid>
          <Grid item xs={12}>
            <EMailItem/>
          </Grid>
          <Grid item xs={12}>
            <PhoneItem/>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={6}>
        <Grid container>
          <Grid item xs={12}>
            <CompanyItem/>
          </Grid>
          <Grid item xs={12}>
            <StreetItem/>
          </Grid>
          <Grid item xs={12}>
            <CityItem
              setZipAction={billingActions.setZip} setCityAction={billingActions.setCity}
              selectZip={state => state.project.billing.zip} selectCity={state => state.project.billing.city}
            />
          </Grid>
        </Grid>

      </Grid>

    </Grid>
  )
