import React from "react"

import {getRealWorldTransform} from "../../realWorldTransform";
import {renderCircularPlanElement} from "../../Element/circularElementFunctions";
import {renderRectangularPlanElement} from "../../Element/rectangularElementFunctions";
import {renderLinearPlanElement} from "../../Element/linearElementFunctions";
import {renderGroupElements as renderSleeveGroupElements} from "../../Element/sleeveRenderFactory";
import {renderGroupElements as renderDrainageGroupElements} from "../../Element/drainageRenderFactory"
import {renderGroupElements as renderGroundingGroupElements} from "../../Element/groundingRenderFactory"
import {renderGroupElements as renderBlockOutGroupElements} from "../../Element/blockOutRenderFactory"
import {renderGroupElements as renderCRailGroupElements} from "../../Element/cRailRenderFactory"
import {renderDashedLine, renderLine} from "../renderFunctions";
import {Group} from "react-konva";

const createVault = (plan, scale, side) => {
  const left = 0
  const width = ((side === 'left' || side === 'right' ? plan.width : plan.length) + 2 * plan.wallWidth) * scale
  const right = left + width
  const top = 0
  const height = plan.vault.height * scale
  const bottom = top + height
  const wallWidth = plan.wallWidth * scale

  return (
    <React.Fragment>
      {
        renderLine([
          left, bottom,
          right, bottom,
          right, top,
          left, top,
          left, bottom
        ])
      }
      {renderDashedLine([left + wallWidth, top, left + wallWidth, bottom])}
      {renderDashedLine([right - wallWidth, top, right - wallWidth, bottom])}
    </React.Fragment>
  )
}

export const createVaultElevationView = (layout, plan, innerVaultLength, innerVaultWidth, wallWidth, side) => {
  const innerWidth = side === 'left' || side === 'right' ? plan.width : plan.length
  const height = plan.vault.height
  const configAreaLayout = {
    screenX: layout.screenX + wallWidth * layout.scale,
    screenY: layout.screenY,
    screenWidth: innerWidth * layout.scale,
    screenHeight: layout.screenHeight,
    scale: layout.scale,
    transform: getRealWorldTransform(innerWidth, height, innerWidth * layout.scale)
  }
  const vaultWall = plan.vault.walls[side === 'front' ? 0 : side === 'back' ? 1 : side === 'left' ? 2 : 3]
  const renderCircular = (element, renderGroupElementsFunction) => renderCircularPlanElement(
    null,
    configAreaLayout,
    element,
    renderGroupElementsFunction
  )
  const renderRectangular = (element, renderGroupElementsFunction) => renderRectangularPlanElement(
    null,
    configAreaLayout,
    element,
    renderGroupElementsFunction
  )
  const renderLinear = (element, renderGroupElementsFunction) => renderLinearPlanElement(
    null,
    configAreaLayout,
    element,
    renderGroupElementsFunction
  )

  return (
    <React.Fragment>
      <Group x={layout.screenX} y={layout.screenY}>
        {createVault(plan, layout.scale, side)}
      </Group>
      {vaultWall.sleeves.map(sleeve => renderCircular(
        sleeve,
        (screenCircle, sleeve) => renderSleeveGroupElements(screenCircle, sleeve, 'transparent')
      ))}
      {vaultWall.drainages.map(drainage => renderCircular(
        drainage,
        (screenCircle, drainage) => renderDrainageGroupElements(screenCircle, drainage, 'transparent')
      ))}
      {vaultWall.blockOuts.map(blockOut => renderRectangular(
        blockOut,
        (screenRect, blockOut) => renderBlockOutGroupElements(screenRect, blockOut, 'transparent')
      ))}
      {vaultWall.cRails.map(cRail => renderLinear(
        cRail,
        (screenLine, element) => renderCRailGroupElements(screenLine, element, true, true, 'black', 'transparent')
      ))}
      {vaultWall.groundings.map(grounding => renderCircular(
        grounding,
        screenCircle => renderGroundingGroupElements(screenCircle, grounding, 'transparent')
      ))}
    </React.Fragment>
  )
}
