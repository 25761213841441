import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

export const ConfirmDialog = (props) => {
  const {title, children, open, setOpen, onConfirm} = props

  return (
    <Dialog
      style={{font: "Gothic"}}
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle
        style={{font: "Gothic"}}
        id="confirm-dialog">{title}</DialogTitle>
      <DialogContent
        style={{font: "Gothic"}}
      >{children}</DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => setOpen(false)}
          color="primary"
        >
          Nein
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setOpen(false)
            onConfirm()
          }}
        >
          Ja
        </Button>
      </DialogActions>
    </Dialog>
  )
}
